import http from '../request/http';
import {Message} from 'element-ui';
import router from '../router/index';
export default{
// 保存token令牌
saveToken({commit},token){
    commit("saveToken",{token});
},
// 保存用户信息
saveUserInfo({commit},userInfo){
    commit("saveUserInfo",{userInfo});
},
saveUserName({commit},userName){
    commit('saveUserName',{userName});
},
saveUserPwd({commit},userPwd){
    commit('saveUserPwd',{userPwd});
},
initUserName({commit}){
    commit('initUserName');
},
initUserPwd({commit}){
    commit('initUserPwd');
},
saveCollectSchool({commit},{collectSchool,pc}){
    commit('saveCollectSchool',{collectSchool,pc});
},
saveLrzyfaList({commit},{lrzyfaList,pc}){
    commit('saveLrzyfaList',{lrzyfaList,pc});
},
saveCompareSchool({commit},{compareSchool}){
    commit("saveCompareSchool",{compareSchool});
},
initCompareSchool({commit}){
    commit("initCompareSchool");
},
// 院校查询
async getSchoolFind({commit},params){
const res=await http.postHttp("/yxcx/selectYx",params.requestParam);
if(res.data.code===200){
    const data=res.data.obj.records;
    const total=res.data.obj.total;
    const pageSize=res.data.obj.size;
    const pageNum=res.data.obj.current;
    commit("getSchoolFind",{schoolFindData:{data:{list:data,total:total,pageSize:pageSize,pageNum:pageNum}}});
}else if(res.data.code===500){
    Message.error({message:res.data.message});
}else if(res.status===401){
    Message.error({message: '登录超时，请重新登录'});
    await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
    router.replace({
        path: '/home',
        query: {redirect: router.currentRoute.fullPath}
    });
}else if(res.data.code===0){
    Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
    await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
    router.replace({
        path: '/home',
        query: {redirect: router.currentRoute.fullPath}
    });
}else if(res.status===409||res.status===410||res.status===411){
    Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
    await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
    router.replace({
        path: '/home',
        query: {redirect: router.currentRoute.fullPath}
    });
}
},
// 院校推荐
async getRecommendYx({commit},params){
    const res=await http.postHttp("/yxcx/yxtj",params.requestParam);
    if(res.data.code===200){
        const data=res.data.obj.records;
        const total=res.data.obj.total;
        const pageSize=res.data.obj.size;
        const pageNum=res.data.obj.current;
        commit("getRecommend",{recommendData:{data:{list:data,total:total,pageSize:pageSize,pageNum:pageNum}}});
    }else if(res.data.code===500){
        Message.error({message:res.data.message});
    }else if(res.status===401){
        Message.error({message: '登录超时，请重新登录'});
        await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }else if(res.data.code===0){
        Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
        await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }else if(res.status===409||res.status===410||res.status===411){
        Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
        await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }
},
// 专业组推荐
async getRecommendZyz({commit},params){
    const res=await http.postHttp("/yxZyzTj",params.requestParam);
    if(res.data.code===200){
        const data=res.data.obj.records;
        const total=res.data.obj.total;
        const pageSize=res.data.obj.size;
        const pageNum=res.data.obj.current;
        commit("getRecommend",{recommendData:{data:{list:data,total:total,pageSize:pageSize,pageNum:pageNum}}});
    }else if(res.data.code===500){
        Message.error({message:res.data.message});
    }else if(res.status===401){
        Message.error({message: '登录超时，请重新登录'});
        await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }else if(res.data.code===0){
        Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
        await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }else if(res.status===409||res.status===410||res.status===411){
        Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
        await this.dispatch('loginOut',{sjhm:this.state.userInfo.sjhm});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }
},
// 退出登录
async loginOut({commit},param){
    const sjhm=param.sjhm;
    localStorage.removeItem('whBkTableData');
    localStorage.removeItem('whZkTableData');
    localStorage.removeItem('pc');
    const res=await http.getHttp("/login/logout?sjhm="+sjhm);
    if(res.data.code===200){
        commit('initUserInfo');
        commit('initToken');
        commit('initRecommend');
        commit('initSchoolFind');
        commit('initCollectSchool');
        commit('initLrzyfaList');
        commit("initCompareSchool");
    }else if(res.data.code===500){
        if(res.data.message){
            Message.error({message:res.data.message});
        }
    }else if(res.status===401){
        Message.error({message: '登录超时，请重新登录'});
        commit('initRecommend');
        commit('initSchoolFind');
        commit('initUserInfo');
        commit('initToken');
        commit('initCollectSchool');
        commit('initLrzyfaList');
        commit("initCompareSchool");
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }else if(res.data.code===0){
        // Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
        commit('initRecommend');
        commit('initSchoolFind');
        commit('initUserInfo');
        commit('initToken');
        commit('initCollectSchool');
        commit('initLrzyfaList');
        commit("initCompareSchool");
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }else if(res.status===409||res.status===410||res.status===411){
        // Message.warning({message:"您的账号在其他地点登陆了！",showClose:true});
        commit('initRecommend');
        commit('initSchoolFind');
        commit('initUserInfo');
        commit('initToken');
        commit('initCollectSchool');
        commit('initLrzyfaList');
        commit("initCompareSchool");
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }
}
};
