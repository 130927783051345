<template>
    <div>
        <div class="zyxkyq-title">普通高校本科招生专业选考科目要求指引</div>
        <div>
            <div class="explain-wrapper">
            <div class="sign"><span>表示某专业类必须提该科目要求</span></div>
            <div class="plain"><span>表示某专业类可提该科目要求</span></div>
            </div>
            <div id="zyxk-data">
            <el-table border :data="zyxkList" height="660"
            :header-cell-style="{'background-color':'#B0E2FF','color': '#3B3B3B',
            'border':'1px solid #0099FF','text-align':'center'}"
            :cell-style="{'border':'1px solid #0099FF','text-align':'center'}">
                <el-table-column show-overflow-tooltip label="学科门类" prop="xkml" width="160" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="本科专业类" prop="bkzyl" width="160" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="内设专业" prop="nszy" width="500" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="首选科目" prop="sxkm" width="100" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="再选科目要求" width="160" :resizable="false">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.zxkm1" style="margin-right:5px;">{{scope.row.zxkm1}}</el-tag>
                        <el-tag v-if="scope.row.zxkm2" type="warning">{{scope.row.zxkm2}}</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <div class="zyxk-page">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="zyxkParam.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="zyxkParam.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="zyxkTotal">
                </el-pagination>
            </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        zyxkList:[],
        zyxkParam:{
            pageNum:1,
            pageSize:10
        },
        zyxkTotal:0
    };
},
methods:{
    async getZyxk(){
        await this.loadData();
    },
    async loadData(pageNum=1,pageSize=10){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("zyxk-data"),text:"正在加载中"});
        const res=await this.$http.getHttp("/lnsj/zyxkyq?pageNum="+pageNum+"&pageSize="+pageSize+"");
        if(res.data.code===200){
            this.zyxkList=res.data.obj.records;
            this.zyxkTotal=res.data.obj.total;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    async handleSizeChange(pageSize){
        await this.loadData(1,pageSize);
    },
    async handleCurrentChange(pageNum){
        await this.loadData(pageNum,10);
    }
},
computed:{
    ...mapState(['userInfo'])
},
async mounted(){
    await this.getZyxk();
}
};
</script>
<style lang="less" scoped>
.zyxkyq-title{
    width:1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    margin:10px auto;
}
.explain-wrapper{
    margin-left: 15px;
    text-align: center;
    margin:10px 0;
    .sign{
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        margin-right: 20px;
        span::before{
            content: "";
            width: 18px;
            height: 18px;
            border: 1px solid #409EFF;
            display: inline-block;
            margin-right: 10px;
            vertical-align: sub;
            border-radius: 1px;
            background-color: #409EFF;
        }
    }
    .plain{
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        margin-right: 20px;
        span::before{
            content: "";
            width: 18px;
            height: 18px;
            border: 1px solid #ff6900;
            display: inline-block;
            margin-right: 10px;
            vertical-align: sub;
            border-radius: 1px;
            background-color: #ff6900;
        }
    }
}
.zyxk-page{
    width:800px;
    margin:20px auto;
}
</style>
