<template>
    <div>
        <div class="main_box px1005 box950" style="padding-left:30px;">
            <nav>
                <a class="maincolor" href="/">首页</a> <span class="c-666 en">&gt;</span>常见问题
            </nav>
        </div>

        <div class="clear_div center_box">
            <!--<div class="i_left">
                <dl class="left_nav th clear_div">
                    <dt></dt>
                    <dd class="light">
                        <a href="questions"><span class="l_6"></span>常见问题</a>
                    </dd>
                    <dd>
                        <a href="contact"><span class="l_5"></span>联系我们</a>
                    </dd>
                    <dd>
                        <a href="about"><span class="l_3"></span>关于我们</a>
                    </dd>
                </dl>
            </div>-->
            <div class="i_right" style="width:984px;float:none;box-sizing: border-box;">
                <dl class="r_th" style="display:flex;justify-content:center;align-items:center;margin-right: 13px;">
                    <dd class="th">
                        <span style="font-weight:bold;font-size:28px;">常见问题</span>
                    </dd>
                </dl>
                <dl class="clear_div r_contact th">
                    <dd>
                        <!--<p>&nbsp;</p>-->
                        <!--<p>3.&nbsp;游客在线购卡后还需要注册吗？</p>
                        <p>答：游客在线购卡后，可以在游客的用户中心升级成会员，不需要重新注册。</p>-->
                        <!-- <p>1.&nbsp;有卡号和密码为什么不能直接登录系统？</p>
                        <p>答：本系统卡号需注册激活才能使用，第一次登录先要注册成功，再凭用户名或手机号码加上注册时根据自己记忆习惯设置的密码登录系统。</p>
                        <p>&nbsp;</p>
                        <p>2.&nbsp;忘记密码怎么找回？</p>
                        <p>答：进入登录界面，点击“忘记密码”，然后按页面提示操作即可。</p>
                        <p>&nbsp;</p>
                        <p>3.&nbsp;何谓等效分？</p>
                        <p>答：等效分就是今年的分数相当于往年的多少分数，它使得不同年份的分数能直接进行对比分析。比如说2015理科100名是628分，等效于2014年理科100名的623分。2013年理科100名的588分，就是说按照试题难度系数、考生分数分布情况等，如果2015理科100名是628分的成绩，那放在2014年的高考中，相当于考了623分，要是在2013年比较难的情况下，那相当于考了588分。等效分是基于考生成绩分布情况，系统通过科学的数学模型自动换算得来的。具体到使用时，比如某学校14年在湖南的投档线是623分，入档位次是100名，那么15年，就需要628分才能入档，由于一分段表在一个分数上有多个考生，因此，在使用等效分时，最好结合省排名来综合判断，使两个数据的偏差得到有效的纠正，因为在同分比较多的情况下，1分之差可能相差百名之外。</p>
                        <p>&nbsp;</p>
                        <p>4.&nbsp;什么是录取线差？专业线差？</p>
                        <p>答：录取线差，是院校专业组当年最低录取分数与该院校专业组在该省所在招生批次录取控制分数线的差值。专业线差，是指院校专业组当年各专业的录取最低分减院校专业组投档线之间的差值。</p>
                        <p>&nbsp;</p>
                        <p>5.&nbsp;什么是录取平均分排行？</p>
                        <p>答：是指各院校近三年的录取分平均分求平均后，根据这个分数的排序。</p>
                        <p>&nbsp;</p>
                        <p>6.&nbsp;高考分数怎么填？</p>
                        <p>答：非志愿填报期间，用户可以输入考生平时的月考、模考或者期末考试成绩，分数修改次数不限；高考成绩公布后，只允许修改一次分数。</p>
                        <p>&nbsp;</p>
                        <p>7.&nbsp;怎么查看院校专业组里的录取分数？</p>
                        <p>答：在历年数据菜单里点击投档线，可以查看院校专业组近三年的投档线专业录取数据。</p>
                        <p>&nbsp;</p>
                        <p>8.&nbsp;网页被运营商劫持偶尔点不动怎么办？</p>
                        <p style="color:black;font-weight:600">答：点击金榜志愿通logo图，进行刷新即可。</p>
                        <p>&nbsp;</p> -->
                        <template v-if="questions.length>0">
                        <div v-for="(item,index) in questions" :key="index">
                            <p>{{index+1}}.&nbsp;{{item.ask}}</p>
                            <!-- <p style="color:black;font-weight:600" v-if="index===questions.length-1">答：{{item.answer}}</p>
                            <p v-if="index!==questions.length-1">答：{{item.answer}}</p> -->
                            <p>答：{{item.answer}}</p>
                            <p>&nbsp;</p>
                        </div>
                        </template>
                    </dd>
                </dl>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        questions:[]
    };
},
methods:{
    // 获取问题列表
    async getQuestionList(){
        const res=await this.$http.getHttp('/cjwt/getCommonProblem');
        if(res.data.code===200){
            console.log(res);
        }else if(res.data){
            this.questions=res.data;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
async mounted(){
    await this.getQuestionList();
},
computed:{
    ...mapState(["userInfo"])
}
};
</script>
<style lang="less" scoped>

</style>
