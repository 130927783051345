<template>
  <div class="card-active-container">
    <div class="card-top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <div class="card-main">
        <div class="panel-lf">
            <div class="head-icon">
                <div class="head-image">
                <img src="../assets/images/1.jpg" width="80%" alt />
                </div>
                <div class="user-info">
                <div class="user-name" v-if="userInfo.yhid">{{userInfo.username || userInfo.sjhm}}</div>
                <div class="user-name" v-else>未登录</div>
                <div class="user-grade" v-if="userInfo.yhid">
                <span v-if="userInfo.yhlb===9">普通咨询师</span>
                <span v-if="userInfo.yhlb===7">高级咨询师</span>
                <span v-if="userInfo.yhlb===2">高级会员</span>
                <span v-if="userInfo.yhlb===4">普通会员</span>
                </div>
                <div class="user-grade" v-else>游客</div>
                </div>
            </div>
            <div class="menu-body">
                <dl>
                <!-- <dt>设置中心</dt> -->
                <dd>
                    <router-link to="/me/updateScore" :class="{current:$route.path.includes('/updateScore')}">信息修改</router-link>
                </dd>
                <dd>
                    <router-link to="/me/updatePwd" :class="{current:$route.path.includes('/updatePwd')}">密码修改</router-link>
                </dd>
                </dl>
                <!-- <dl>
                <dt>我的收藏</dt>
                <dd>
                    <router-link to="/me/collectSchool" :class="{current:$route.path.includes('/collectSchool')}">院校收藏</router-link>
                </dd>
                </dl> -->
            </div>
        </div>
        <div class="panel-rt">
            <router-view></router-view>
        </div>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
props:['sxkmChangeReloadScore'],
data(){
    return {
        reloadTimer:null
    };
},
computed:{
    ...mapState(["userInfo"])
},
mounted(){
    this.reloadTimer=setInterval(() => {
    if(this.sxkmChangeReloadScore&&this.sxkmChangeReloadScore==='true'){
        clearInterval(this.reloadTimer);
        this.$router.push({
            path:'/me/updateScore',
            query:{
                time:(new Date().getMilliseconds())
            }
        });
        location.reload();
    }
}, 1000);
}
};
</script>
<style lang="less" scoped>
.card-active-container{
    min-width:1200px;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .card-top{
        width: 1200px;
        margin: 20px auto;
    }
    .card-main{
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
        .panel-lf{
            width: 240px;
            min-height: 450px;
            background-color: #fff;
            .head-icon{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px solid #ebeef5;
                .head-image{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    height: 80px;
                    border: 1px solid #ddd;
                    border-radius: 5px;
                }
                .user-info{
                    margin-left: 10px;
                    font-size: 14px;
                    .user-name{
                        margin: 10px 0;
                        color: #303133;
                    }
                    .user-grade{
                        color: #606266;
                    }
                }
            }
            .menu-body{
                margin: 0 10%;
                .current{
                    color: #409eff;
                    border-color: #409eff;
                }
                dd{
                    margin: 20px 0;
                }
                a{
                    display: block;
                    width: 100%;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    border: 1px solid #dcdfe6;
                    border-radius: 3px;
                    font-size: 15px;
                    text-decoration: none;
                    color: #303133;
                 }
            }
        }
        .panel-rt{
            width: 960px;
        }
    }
}
</style>
