import Vue from 'vue';
import Vuex from 'vuex';
import state from "./state";
import mutations from "./mutations";
import actions from "./actions";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      key: "token",
      reducer(val) {
        return {
          // 只储存state中的userInfo
          token: val.token,
          userInfo:val.userInfo
        };
      }
    }),
    createPersistedState({
      storage:window.localStorage,
      key:"user",
      reducer(val){
        return {
          userName:val.userName,
          userPwd:val.userPwd
        };
      }
    }),
    createPersistedState({
      storage:window.localStorage,
      key:"collectInfo",
      reducer(val){
        return{
          value:val.collectInfo
        };
      }
    }),
    createPersistedState({
      storage:window.localStorage,
      key:"compareSchool",
      reducer(val){
        return{
          value:val.compareSchool
        };
      }
    })
  ],
  modules: {
  }
});
