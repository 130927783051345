<template>
    <div>
        <div>
            <div style="margin-bottom:20px;">
                <el-button type="primary" @click="allChecked">全选</el-button>
                <el-button type="primary" @click="allNoChecked">取消全选</el-button>
                <el-button type="primary" @click="deleteChecked">删除</el-button>
            </div>
            <template v-if="planList&&planList.length>0">
                <el-table :data="planList" border  :key="updatePlan"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column show-overflow-tooltip prop="checked" width="60" label="选择">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" @change="planCheckChange"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip type="index" label="序号" :resizable="false" width="100"></el-table-column>
                <el-table-column show-overflow-tooltip label="方案名称" prop="plan" width="310" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="保存时间" prop="updatetime" width="240" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="评估报告" width="120" :resizable="false">
                    <template slot-scope="scope">
                        <router-link
                        tag="a"
                        target="_blank"
                        :to="{
                            path: '/getPgResult',
                            query: {id:scope.row.id}
                            }"
                            >查看</router-link>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="风险等级" width="160" prop="fxdj" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="操作" width="200" :resizable="false">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toLrzyfa(scope.row.plan,scope.row.pc,scope.row.id)">修改</el-button>
                        <el-button type="text" @click="deleteMyplan(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            </template>
            <template v-else>
                <el-table :data="tableList" border :key="updatePlan"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column show-overflow-tooltip prop="checked" width="60" label="选择">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" @change="planCheckChange"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip type="index" label="序号" :resizable="false" width="100"></el-table-column>
                <el-table-column show-overflow-tooltip label="方案名称" prop="plan" width="310" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="保存时间" prop="updatetime" width="240" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="评估报告" width="120" :resizable="false">
                    <template slot-scope="scope">
                        <router-link
                        tag="a"
                        target="_blank"
                        :to="{
                            path: '/getPgResult',
                            query: {id:scope.row.id}
                            }"
                            >查看</router-link>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="风险等级" width="160" prop="fxdj" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="操作" width="200" :resizable="false">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toLrzyfa(scope.row.plan,scope.row.pc,scope.row.id)">修改</el-button>
                        <el-button type="text" @click="deleteMyplan(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            </template>
            <el-dialog title="信息" :visible.sync="dialogDeleteVisible" width="20%">
            <div style="width:160px;margin:0 auto;">您确定删除吗？</div>
            <div style="width:200px;margin:20px auto;">
                <el-button type="primary" @click="deleteCode">确定</el-button>
                <el-button @click="closeDelete">取消</el-button>
            </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
props:["planList"],
data(){
    return {
        tableList:[],
        dialogDeleteVisible:false,
        deleteId:"",
        updatePlan:false
    };
},
methods:{
    // 获取我的方案列表
    async getZyfaList(){
        const res=await this.$http.getHttp("/mnzy/getMnzyList");
        if(res.data.code===200){
            this.tableList=[...res.data.obj];
            const len=this.tableList.length;
            for(let i=0;i<len;i++){
                this.tableList[i].checked=false;
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 跳转到录入志愿方案
    toLrzyfa(plan,pc,id){
        const url=this.$router.resolve({
            path:"/zypg",
            query:{
                plan,
                pc,
                id,
                isto:"true"
            }
        });
        window.open(url.href,"_blank");
    },
    // 删除
    deleteMyplan(id){
        this.deleteId=id;
        this.dialogDeleteVisible=true;
    },
    // 关闭删除弹窗
    closeDelete(){
        this.dialogDeleteVisible=false;
    },
    // 全选
    allChecked(){
        const planList=[...this.planList];
        const tableList=[...this.tableList];
        if(planList.length>0){
            for(let i=0;i<planList.length;i++){
                planList[i].checked=true;
            }
            this.planList=planList;
        }else if(tableList.length>0){
            for(let i=0;i<tableList.length;i++){
                tableList[i].checked=true;
            }
            this.tableList=tableList;
        }
    },
    // 取消全选
    allNoChecked(){
        const planList=[...this.planList];
        const tableList=[...this.tableList];
        if(planList.length>0){
            for(let i=0;i<planList.length;i++){
                planList[i].checked=false;
            }
            this.planList=planList;
        }else if(tableList.length>0){
            for(let i=0;i<tableList.length;i++){
                tableList[i].checked=true;
            }
            this.tableList=tableList;
        }
    },
    // 删除选中的方案
    async deleteChecked(){
        const isDelete=await this.$confirm('您确定要删除吗?','信息',{
            type:'warning'
        }).catch(()=>{
            return "你取消了操作";
        });
        if(isDelete==='confirm'){
        const idList=[];
        const planList=[...this.planList];
        const tableList=[...this.tableList];
        if(planList.length>0){
            for(let i=0;i<planList.length;i++){
                if(planList[i].checked){
                    idList.push(planList[i].id);
                }
            }
        }else{
            for(let i=0;i<tableList.length;i++){
                if(tableList[i].checked){
                    idList.push(tableList[i].id);
                }
            }
        }
        if(idList.length===0){
            this.$message({
                message:'请选择您要删除的方案',
                type:'warning',
                showClose:true
            });
            return;
        }
        const res=await this.$http.postHttp('/mnzy/delMnzys',idList);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({
                    message:res.data.message,
                    type:'success',
                    showClose:true
                });
            }else{
                this.$message({
                    message:'操作成功',
                    type:'success',
                    showClose:true
                });
            }
            this.planList=[];
            await this.getZyfaList();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        }else{
            this.$message({
                message:isDelete,
                type:'warning',
                showClose:true
            });
        }
    },
    // 多选框改变刷新表格
    planCheckChange(){
        // this.updatePlan=!this.updatePlan;
        this.$forceUpdate();
    },
    // 删除方案代码
    async deleteCode(){
        const res=await this.$http.getHttp("/mnzy/delMnzyById?id="+this.deleteId);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"删除成功",type:"success",showClose:true});
            }
            await this.getZyfaList();
            this.planList=[];
            this.$forceUpdate();
            this.updatePlan=!this.updatePlan;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.dialogDeleteVisible=false;
    }
},
computed:{
...mapState(["userInfo"])
},
async mounted(){
await this.getZyfaList();
}
};
</script>
<style lang="less">

</style>
