<template>
    <div>
        <div class="major-query-container">
        <div class="major-panel" id="loadZyData">
            <div class="major-types">
                <!-- <div class="major-type-name">
                    <span class="xz-type" :class="{blue:xz === 0}" @click="changeStatus(0)">本科</span>
                    <span class="xz-type" :class="{blue:xz === 1}" @click="changeStatus(1)">专科</span>
                </div> -->
                <template v-if="searchList.length===0||(searchList.size&&searchList.size===0)">
                    <div class="major-type-group" v-for="(item,index) in majorList" :key="index" @click="goAnchor('type'+xz+ index+1)">
                    <a href="javascript:void(0)" class="major-one"><span>{{item.zymc}}</span>
                    <i class="el-icon-arrow-right major-one-right"></i>
                    </a>
                    </div>
                </template>
                <template v-if="searchList.length>0||(searchList.size&&searchList.size>0)">
                    <template v-if="searchList.leftMajor">
                    <div class="major-type-group" v-for="(item,index) in searchList.leftMajor" :key="index" @click="goAnchor('type'+xz+ index+1)">
                    <a href="javascript:void(0)" class="major-one"><span>{{item.zymc}}</span>
                    <i class="el-icon-arrow-right major-one-right"></i>
                    </a>
                    </div>
                    </template>
                </template>
            </div>
            <div>
                <div class="major-search">
                    <input type="text" placeholder="请输入要查询的专业名称" class="search" v-model="zymc"/>
                    <button  class="search-btn" @click="searchZy"><i class="el-icon-search"></i></button>
                    <!-- <button  class="search-btn" @click="noseSearchZy"><i class="el-icon-search"></i></button> -->
                </div>
                <div class="split-line">共找到 <i style="color:#FF0000">
                    <template v-if="searchList.length===0||(searchList.size&&searchList.size===0)">
                        {{size}}
                    </template>
                    <template v-if="searchList.length>0||(searchList.size&&searchList.size>0)">
                        {{searchSize}}
                    </template>
                </i>个{{ xz==0?'本科':'专科' }}专业</div>
                <div class="major-group">
                    <template v-if="searchList.length===0||(searchList.size&&searchList.size===0)">
                        <div class="major-item" ref="typeName" v-for="(item,index) in majorList" :key="index" :id="'type'+xz+index+1">
                        <div class="major-item-title"><div class="line"></div> {{item.zymc}}</div>
                        <div class="pro_msg">
                            <span v-for="(item2,i2) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14
                            ,15,16,17,18,19,20,21,22,23,24,25,
                            26,27,28,29,30,31,32]" :key="i2">
                            <template v-if="item['list'+item2]">
                            <div style="padding:10px 0;"></div>
                            <span
                            v-for="(major,index2) in item['list'+item2]"
                            :key="index2+major.zydm"
                            class="major-name"
                            >
                            <a :zy-zyid="major.zydm" v-if="major.zydm.length===4" class="bluexx"
                            @click="toMajorDetail(major.zydm)">{{major.zymc}}</a>
                            <a :zy-zyid="major.zydm" v-if="major.zydm.length===5" class="puplexx"
                            @click="toMajorDetail(major.zydm)">{{major.zymc}}</a>
                            <a :zy-zyid="major.zydm" v-if="major.zydm.length!==4&&major.zydm.length!==5"
                            @click="toMajorDetail(major.zydm)">{{major.zymc}}</a>
                            </span>
                            </template>
                            </span>
                        </div>
                    </div>
                    </template>
                    <template v-if="searchList.length>0||(searchList.size&&searchList.size>0)">
                        <template v-if="searchList.rightMajor">
                        <div class="major-item" ref="typeName" v-for="(item,index) in searchList.rightMajor" :key="index" :id="'type'+xz+index+1">
                        <div class="major-item-title"><div class="line"></div> {{item.zymc}}</div>
                        <div class="pro_msg">
                            <span v-for="(item2,i2) in
                            [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,
                            20,21,22,23,24,25,26,27,28,29,31,32]" :key="i2">
                            <template v-if="item['list'+item2]">
                            <div style="padding:10px 0;"></div>
                            <span
                            v-for="(major,index2) in item['list'+item2]"
                            :key="index2+major.zydm"
                            class="major-name"
                            >
                            <a :zy-zyid="major.zydm" v-if="major.zydm.length===4" class="bluexx"
                            @click="toMajorDetail(major.zydm)">
                            <span>{{major.zyzd1}}</span>
                            <span style="color:red">{{major.zyzd2}}</span>
                            <span>{{major.zyzd3}}</span>
                            </a>
                            <a :zy-zyid="major.zydm" v-if="major.zydm.length===5" class="puplexx"
                            @click="toMajorDetail(major.zydm)">
                            <span>{{major.zyzd1}}</span>
                            <span style="color:red">{{major.zyzd2}}</span>
                            <span>{{major.zyzd3}}</span>
                            </a>
                            <a :zy-zyid="major.zydm" v-if="major.zydm.length!==4&&major.zydm.length!==5"
                            @click="toMajorDetail(major.zydm)">
                            <span>{{major.zyzd1}}</span>
                            <span style="color:red">{{major.zyzd2}}</span>
                            <span>{{major.zyzd3}}</span>
                            </a>
                            </span>
                            </template>
                            </span>
                        </div>
                        </div>
                        </template>
                        <template v-if="majorInList.length>0">
                            <div class="major-item" ref="typeName" v-for="(item,index) in majorInList" :key="index" :id="'type'+xz+index+1">
                            <div class="major-item-title"><div class="line"></div> {{item.zymc}}</div>
                            <div class="pro_msg">
                                <span v-for="(item2,i2) in [1,2,3,4,5,6,7,8,9,10,11,12,13,14
                                ,15,16,17,18,19,20,21,22,23,24,25,
                                26,27,28,29,30,31,32]" :key="i2">
                                <template v-if="item['list'+item2]">
                                <div style="padding:10px 0;"></div>
                                <span
                                v-for="(major,index2) in item['list'+item2]"
                                :key="index2+major.zydm"
                                class="major-name"
                                >
                                <a :zy-zyid="major.zydm" v-if="major.zydm.length===4" class="bluexx"
                                @click="toMajorDetail(major.zydm)">{{major.zymc}}</a>
                                <a :zy-zyid="major.zydm" v-if="major.zydm.length===5" class="puplexx"
                                @click="toMajorDetail(major.zydm)">{{major.zymc}}</a>
                                <a :zy-zyid="major.zydm" v-if="major.zydm.length!==4&&major.zydm.length!==5"
                                @click="toMajorDetail(major.zydm)">{{major.zymc}}</a>
                                </span>
                                </template>
                                </span>
                            </div>
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
props:["majorList","size","xz"],
data(){
    return{
        majorTypes:[
            {majorType:"哲学"},
            {majorType:"经济学"}
        ],
        zymc:"",
        searchList:[],
        majorInList:[],
        searchSize:0
    };
},
methods:{
        // 分裂锚点导航
        goAnchor(selector){
        // if(this.majorList.length !==13){
        //     return false;
        // }else{
        //     const anchor = document.getElementById(selector);// 参数为要跳转到的元素id
        //     document.body.scrollTop = anchor.offsetTop; // chrome
        //     document.documentElement.scrollTop = anchor.offsetTop; // firefox
        // }
        const anchor = document.getElementById(selector);// 参数为要跳转到的元素id
            document.body.scrollTop = anchor.offsetTop; // chrome
            document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    // 跳转到专业详情
    toMajorDetail(zydm){
        const url=this.$router.resolve({
            path:"/majorDetail",
            query:{zydm}
        });
        window.open(url.href,"_blank");
    },
    // 访问接口查询专业
    async searchZy(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("loadZyData"),text:"正在加载中"});
        const zymc=this.zymc;
        const xz=this.xz;
        let pc=6;
        if(xz===0){
            pc=6;
        }else if(xz===1){
            pc=7;
        }
        const param={pc:pc,zymc,kl:this.userInfo.sxkm,pageNum:1,pageSize:10};
        const res=await this.$http.postHttp("/zycx/zyml",param);
        if(res.data.code===200){
            if(zymc!==''){
                const zys=[...res.data.obj.result];
                const arr={};
                arr.leftMajor=[];
                arr.rightMajor=[];
                const zyList=[...res.data.obj.resultLeft];
                for(let i=0;i<zyList.length;i++){
                    if(zyList[i].zymc){
                        const obj={};
                        obj.zymc=zyList[i].zymc;
                        obj.zydm=zyList[i].zydm;
                        arr.leftMajor.push(obj);
                    }
                }
                let countSize=0;
                let majorCount=0;
                for(let i=0;i<zys.length;i++){
                    const subs=[...zys[i].subs];
                    let count=1;
                    const obj={};
                    obj.zymc=zys[i].zymc;
                    obj.zydm=zys[i].zydm;
                    majorCount+=subs.length;
                    for(let j=0;j<subs.length;j++){
                        if(subs[j].zydm.length===4){
                            if(!obj["list"+count]){
                                obj["list"+count]=[];
                                if(subs[j].zymc.indexOf(zymc)>=0){
                                    const zyzd1=subs[j].zymc.split(zymc)[0];
                                    const zyzd3=subs[j].zymc.split(zymc)[1];
                                    if(subs[j].zymc.indexOf('(')>=0){
                                        const arr1=subs[j].zymc.split('(');
                                        if(arr1[1].indexOf(zymc)>=0&&arr1[0].indexOf(zymc)>=0){
                                            const arr2=arr1[0].split(zymc);
                                            subs[j].zyzd1='';
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=arr2[1]+'('+arr1[1];
                                        }else{
                                            subs[j].zyzd1=zyzd1;
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=zyzd3;
                                        }
                                    }else{
                                        subs[j].zyzd1=zyzd1;
                                        subs[j].zyzd2=zymc;
                                        subs[j].zyzd3=zyzd3;
                                    }
                                }
                                obj["list"+count].push(subs[j]);
                            }else{
                                if(subs[j].zymc.indexOf(zymc)>=0){
                                    const zyzd1=subs[j].zymc.split(zymc)[0];
                                    const zyzd3=subs[j].zymc.split(zymc)[1];
                                    if(subs[j].zymc.indexOf('(')>=0){
                                        const arr1=subs[j].zymc.split('(');
                                        if(arr1[1].indexOf(zymc)>=0&&arr1[0].indexOf(zymc)>=0){
                                            const arr2=arr1[0].split(zymc);
                                            subs[j].zyzd1='';
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=arr2[1]+'('+arr1[1];
                                        }else{
                                            subs[j].zyzd1=zyzd1;
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=zyzd3;
                                        }
                                    }else{
                                        subs[j].zyzd1=zyzd1;
                                        subs[j].zyzd2=zymc;
                                        subs[j].zyzd3=zyzd3;
                                    }
                                }
                                obj["list"+count].push(subs[j]);
                            }
                        }else{
                            if(!obj["list"+count]){
                                obj["list"+count]=[];
                                if(subs[j].zymc.indexOf(zymc)>=0){
                                    const zyzd1=subs[j].zymc.split(zymc)[0];
                                    const zyzd3=subs[j].zymc.split(zymc)[1];
                                    if(subs[j].zymc.indexOf('(')>=0){
                                        const arr1=subs[j].zymc.split('(');
                                        if(arr1[1].indexOf(zymc)>=0&&arr1[0].indexOf(zymc)>=0){
                                            const arr2=arr1[0].split(zymc);
                                            subs[j].zyzd1='';
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=arr2[1]+'('+arr1[1];
                                        }else{
                                            subs[j].zyzd1=zyzd1;
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=zyzd3;
                                        }
                                    }else{
                                        subs[j].zyzd1=zyzd1;
                                        subs[j].zyzd2=zymc;
                                        subs[j].zyzd3=zyzd3;
                                    }
                                }
                                obj["list"+count].push(subs[j]);
                            }else{
                                if(subs[j].zymc.indexOf(zymc)>=0){
                                    const zyzd1=subs[j].zymc.split(zymc)[0];
                                    const zyzd3=subs[j].zymc.split(zymc)[1];
                                    if(subs[j].zymc.indexOf('(')>=0){
                                        const arr1=subs[j].zymc.split('(');
                                        if(arr1[1].indexOf(zymc)>=0&&arr1[0].indexOf(zymc)>=0){
                                            const arr2=arr1[0].split(zymc);
                                            subs[j].zyzd1='';
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=arr2[1]+'('+arr1[1];
                                        }else{
                                            subs[j].zyzd1=zyzd1;
                                            subs[j].zyzd2=zymc;
                                            subs[j].zyzd3=zyzd3;
                                        }
                                    }else{
                                        subs[j].zyzd1=zyzd1;
                                        subs[j].zyzd2=zymc;
                                        subs[j].zyzd3=zyzd3;
                                    }
                                }
                                obj["list"+count].push(subs[j]);
                            }
                        }
                        if(subs[j+1]&&subs[j+1].zydm&&subs[j+1].zydm.length===4){
                            count++;
                        }
                    }
                    countSize++;
                    arr.rightMajor.push(obj);
                }
                arr.size=countSize;
                this.searchList=arr;
                this.majorInList=[];
                if(this.zymc===''){
                    this.searchSize=res.data.obj.size;
                }else{
                    this.searchSize=majorCount;
                }
            }else{
                    const zys=[...res.data.obj.result];
                    const arr=[];
                    for(let i=0;i<zys.length;i++){
                        const subs=zys[i].subs;
                        let count=1;
                        const obj={};
                        obj.zymc=zys[i].zymc;
                        obj.zydm=zys[i].zydm;
                        for(let j=0;j<subs.length;j++){
                            if(subs[j].zydm.length===4){
                                if(!obj["list"+count]){
                                    obj["list"+count]=[];
                                    obj["list"+count].push(subs[j]);
                                }else{
                                    obj["list"+count].push(subs[j]);
                                }
                            }else{
                                if(!obj["list"+count]){
                                    obj["list"+count]=[];
                                    obj["list"+count].push(subs[j]);
                                }else{
                                    obj["list"+count].push(subs[j]);
                                }
                            }
                            if(subs[j+1]&&subs[j+1].zydm&&subs[j+1].zydm.length===4){
                                count++;
                            }
                        }
                        arr.push(obj);
                    }
                    this.majorInList=arr;
                    this.searchList=[];
                    this.searchSize=res.data.obj.size;
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 前端实现搜索专业
    noseSearchZy(){
        const zyList=this.majorList;
        const zymc=this.zymc;
        const searchList=[];
        for(let i=0;i<zyList.length;i++){
            const obj={};
            obj.zymc=zyList[i].zymc;
            obj.zydm=zyList[i].zydm;
            if(zyList[i].list1&&zyList[i].list1.length>0){
                const arr=zyList[i].list1;
                let count=1;
                for(let j=0;j<zyList[i].list1;j++){
                    if(arr[j].zymc.indexOf(zymc)>=0){
                        if(arr[j+1].zydm&&arr[j+1].zydm.length===4){
                            count++;
                        }
                        if(obj['list'+count]){
                            obj['list'+count].push({zymc:arr[j].zymc,zydm:arr[j].zydm});
                        }else{
                            obj['list'+count]=[];
                            obj['list'+count].push({zymc:arr[j].zymc,zydm:arr[j].zydm});
                        }
                    }
                }
            }
            searchList.push(obj);
        }
        console.log(searchList);
    }
},
mounted(){
    this.majorInList=this.majorList;
},
computed:{
    ...mapState(["userInfo"])
}
};
</script>
<style lang="less" scoped>
    .major-query-container{
    min-width:1100px;
    margin:0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .major-panel{
        width: 1100px;
        box-sizing: border-box;
        padding: 16px;
        margin: 0 auto;
        display: flex;
        background-color: #fff;
        .major-types{
            width: 210px;
            height: 800px;
            margin: 0 20px 0;
            box-sizing: border-box;
            border: 1px solid #dcdfe6;
            .major-type-name{
                font-size: 18px;
                height: 40px;
                color: #fff;
                background-color: hsla(60,1%,60%,.471);
                line-height: 40px;
                .xz-type{
                    display: inline-block;
                    width: 101px;
                    height: 100%;
                    text-align: center;
                    cursor: pointer;
                }
                .blue{
                    background-color: #5ba8ff;
                }
            }
            .major-type-group{
                // font-size: 18px;
                // height: 40px;
                // color: #fff;
                // background-color: hsla(60,1%,60%,.471);
                // line-height: 40px;
                .major-one{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 35px;
                    padding: 2px 0;
                    margin: 0 10px;
                    font-size: 14px;
                    color: #303133;
                    border-bottom: 1px solid #dcdfe6;
                }
                .major-one:hover{
                    color:#ff861a;
                }
            }
        }
        .major-search{
            display: flex;
            flex-direction: row;
            width: 810px;
            margin: 0 auto;
            .search{
                box-sizing: border-box;
                width: 710px;
                height: 40px;
                padding-left: 10px;
                border: 2px solid #f4a460;
                border-right: none;
                color: #606266;
            }
            .search-btn{
                width: 100px;
                color: #fff;
                height: 40px;
                border: 1px solid #f4a460;
                padding-left: -1px;
                font-size: 18px;
                cursor: pointer;
                background-color: #f4a460;
            }
        }
        .split-line{
            border: 0;
            width: 810px;
            margin: 20px auto;
            color: #666;
            text-align: right;
            background-image: linear-gradient(90deg,rgba(244,164,96,.1),rgba(244,164,96,.75),rgba(244,164,96,.1));
        }
        .major-group{
            width: 810px;
            margin: 0 auto;
            box-sizing: border-box;
            .major-item{
                display: inline-block;
                width: 100%;
                line-height: 40px;
                font-size: 14px;
                color: #666;
                .major-item-title{
                    display: flex;
                    align-items: center;
                    height: 40px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #ff861a;
                    border-bottom: 1px solid #e4e7ed;
                    background-color: #f8f8f8;
                    .line{
                        width: 0;
                        height: 20px;
                        margin:0;
                        margin-right: 20px;
                        border: 2px solid #ff861a;
                        border-radius: 2px;
                    }
                }
            }
            .major-name{
                display: inline-block;
                padding-right: 20px;
                a:hover{
                    color:#ff861a;
                }
            }
        }
    }
}
.bluexx{
    color: #0081FF;
    font-weight: 600;
}
.puplexx{
    color: #8A49F9;
    font-weight: 600;
}
</style>
