<template>
    <div class="audit-store-container">
        <div class="card-top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        <div class="data-store-content">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="我收藏的院校专业组" name="1">
                <CollectSchool  :reloadGzYx="reloadGzYx"/>
            </el-tab-pane>
            <el-tab-pane label="录入志愿方案" name="2">
                <Lrzyfa  @reloadYhGzYx="reloadYhGzYx"/>
            </el-tab-pane>
            <el-tab-pane label="我的方案" name="3" :disabled="myPlanDisabled">
                <Myplan :planList="tableList"/>
            </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import CollectSchool from './collectSchool/collectSchool.vue';
import Lrzyfa from './lrzyfa/lrzyfa.vue';
import Myplan from './myPlan/plan.vue';
import {mapState} from 'vuex';
export default {
props:['sxkmChangeReload'],
data(){
    return {
        activeName:"1",
        tableList:[],
        reloadGzYx:'',
        reloadTimer:null,
        myPlanDisabled:false
    };
},
methods:{
    // 获取我的方案列表
    async getZyfaList(){
        const res=await this.$http.getHttp("/mnzy/getMnzyList");
        if(res.data.code===200){
            this.tableList=[...res.data.obj];
            for(let i=0;i<this.tableList.length;i++){
                this.tableList[i].checked=false;
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    reloadYhGzYx(reloadFlag){
        this.reloadGzYx=reloadFlag;
    },
    // 切换标签
    async handleClick(){
        const active=this.activeName;
        if(active==="2"){
            this.$router.push({
                path:'/zypg',
                query:{
                    time:(new Date().getMilliseconds()),
                    isto:'true'
                }
            });
            location.reload();
        }else if(active==="1"){
            this.$router.push({
                path:'/zypg',
                query:{
                    time:(new Date().getMilliseconds()),
                    toCollect:'true'
                }
            });
            location.reload();
        }
        await this.getZyfaList();
    }
},
mounted(){
    const isto=this.$route.query.isto;
    const toLrzyfa=this.$route.query.toLrzyfa;
    const toCollect=this.$route.query.toCollect;
    if(isto){
        this.activeName="2";
    }else if(toLrzyfa){
        this.activeName="2";
    }else if(toCollect){
        this.activeName="1";
    }
    // const yhlb=this.userInfo.yhlb;
    // if(yhlb===4){
    //     this.myPlanDisabled=true;
    // }
    this.reloadTimer=setInterval(() => {
        if(this.sxkmChangeReload&&this.sxkmChangeReload==='true'){
            clearInterval(this.reloadTimer);
            this.$router.push({
                path:'/zypg',
                query:{
                    time:(new Date().getMilliseconds())
                }
            });
            location.reload();
        }
    }, 1000);
},
computed:{
    ...mapState(["userInfo"])
},
components:{
    CollectSchool,
    Lrzyfa,
    Myplan
}
};
</script>
<style lang="less">
.audit-store-container{
    min-width: 1200px;
    margin: 0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .data-store-content{
        width: 1200px;
        margin: 0 auto;
    }
}
</style>
