<template>
    <div>
    <div class="plate">
    <div class="header">
    <h3>金榜通系统帮助</h3>
    <div class="get-menu">欢迎使用《金榜志愿通系统使用说明》，为了帮助您快速掌握并使用本系统，请您仔细阅读本使用说明！</div>
    </div>
    </div>

    <div class="container">
    <div class="menu">
    <h3>基本概念<span>▾</span></h3>
    <ul class="ulmenu1">
    <li><a  href="javascript:;" class="selected">关于本系统</a></li>
    <li><a href="javascript:;">名词解释</a></li>
    <li><a href="javascript:;">注意事项</a></li>
    </ul>

    <h3>注册流程<span>▾</span></h3>
    <ul class="ulmenu2">
    <li><a href="javascript:;" class="selected">用户注册</a></li>
    <li><a href="javascript:;">用户登录</a></li>
    <li><a href="javascript:;">忘记密码</a></li>
    </ul>

    <h3>功能说明<span>▾</span></h3>
    <ul class="ulmenu3">
    <li><a  href="javascript:;" class="selected">首页</a></li>
    <li><a href="javascript:;">院校推荐</a></li>
    <li><a href="javascript:;">院校查询</a></li>
    <li><a href="javascript:;">数据筛选查询</a></li>
    <li><a href="javascript:;">院校收藏</a></li>
    <li><a href="javascript:;">院校对比</a></li>
    <li><a href="javascript:;">院校信息查询</a></li>
    <li><a href="javascript:;">专业查询</a></li>
    <li><a href="javascript:;">历年数据</a></li>
    <li><a href="javascript:;">用户信息修改</a></li>
    <li><a href="javascript:;">院校专业收藏</a></li>
    </ul>
    <h3>进阶功能<span>▾</span></h3>
    <ul class="ulmenu4">
    <li><a  href="javascript:;" class="selected">追院校比较表</a></li>
    <li><a href="javascript:;">追专业比较表</a></li>
    <li><a href="javascript:;">院校专业兼顾表</a></li>
    </ul>
    </div>

    <div class="content">
    <div class="menu1 menu_tab">
    <div  id="tab1" class="tab active">
    <p class="tt">1.关于本系统？</p>
    <p>本系统基于各省历年高考招生计划数据和招生录取数据，通过科学合理的汇总、立体交叉的多维分析和数学模型计算，根据考生设置的条件为考生推荐合适院校，并提供了可供参考的院校历年录取信息，以及院校详细资料、专业信息，从而帮助考生为合理填报高考志愿提供全方位多角度的参考依据，提高志愿填报的科学性和合理性。</p>
    <p style="margin-bottom: 50px"></p>
    </div>
    <div  id="tab2" class="tab">
    <p class="tt">1、位次概念</p>
    <p>位次又称“排名”，是指考生综合成绩的排序，综合成绩相同的考生分别按语、数、外科目的成绩高低排序；这样保证每位考生有唯一的位次。本系统的位次排名均是指参加全国普通高考的考生按科类（如理工类、文史类）进行的档案分排名。</p>
    <p class="tt"> 2、批次录取控制分数线</p>
    <p>批次录取控制分数线（简称批次线或省控线）是根据各省（直辖市、自治区）考生高考成绩水平和招生计划，按一定比例确定的录取新生的各批次、各科类最低成绩标准。各批次、各科类的批次线各不相同，科类通常分为文史类、理工类、艺术类和体育类等，文史类和理工类又通常分为第一批次（重点本科批次）、第二批次（一般本科批次）、第三批次（独立院校和民办本科院校）、第四批次（高职专科院校）。批次线是确定考生录取资格、执行招生政策的一个重要指标，院校一般只能录取所在批次线上的考生，只有投档总分达到或超过批次线的考生（通常称“上线考生”）档案，才有可能被投到该批次的高校，由高校选择录取。</p>
    <p class="tt"> 3、等效分</p>
    <p> 依据高考是选拔性考试的特点，根据排名情况可以对历年的高考分数进行换算，将往年考生的成绩换成当年同科类同批次的对应分数，消除了因试题难度、考生人数、招生计划变化所带来的对招生录取结果的影响。 比如：A同学2015年文科第100名，考分为645分，2014年文科第100名的档案分为638，换成等效分就是645，2013年文科第100名的档案分是615分，换成等效分也是645。</p>
    <p class="tt">4、录取线差</p>
    <p>是院校当年最低录取分数与该院校在该省所在招生批次录取控制分数线的差值。</p>
    <p class="tt">5、入档位次</p>
    <p>是指院校当年投档线对应科类（文/理科）的省一分段排名。</p>
    <p class="tt">6、录取平均分排行</p>
    <p> 是指各院校近三年的录取分平均分求平均后，根据这个分数的排序。</p>
    <p class="tt">7、专业线差</p>
    <p>是指院校当年各专业的录取最低分减院校投档线之间的差值。</p>
    <p class="tt"> 8、投档线</p>
    <p>提档线，也叫调档线或投档线。即招生院校按照提档比例提取考生档案的最低分数线，与学校最终的录取线并不完全相符。
    例如规定本科一，二批院校的一志愿按1：1.2的比例确定提档人数，学校按招生计划录取结束后，将把多余的百分之二十考生档案退回省招生考试院。提档的最低分便称为提档线</p>
    <p style="margin-bottom: 50px"></p>
    </div>
    <div  id="tab3" class="tab">
    <p class="tt">1、注意事项</p>

    <p> 1、在本系统中，院校推荐以及各院校专业录取数据，系统仅显示最近3年的高考相关数据。</p>

    <p>2、等效分是本系统中的一个核心概念，主要用于将当年高考成绩与历年高考分数进行等效对比分析。一般情况下，等效分的转换应以当年的高考成绩及排名为依据。比如,在2017年高考分数出来之前，系统中的等效分均是根据2016年的高考成绩及排名为依据进行换算。</p>

    <p>3、由于本系统对信息数据准确性要求高，所以用户登录注册时需注意每个栏目旁边的黑色字或红色字，特别是学生的科类以及提示的必填项的填写。</p>

    <p>4、在系统院校查询功能中，系统默认办学性质是公办，录取批次是本科一批，在用户院校查询时需根据所要查询院校的办学性质和录取批次来设置查询条件，以免出现“未找到匹配数据”的情况。</p>
    <p>5、用户使用本系统时，在高考成绩出来之前分数可以多次修改，但在高考成绩出来之后，该系统允许修改为真实的成绩数据，但只能修改一次。</p>

    <p style="margin-bottom: 50px"></p>
    </div>
    </div>
    <div class="menu2 menu_tab">
    <div id="tab-1" class="tab">
    <p class="tt">1.用户注册</p>
    <p>进入登录界面后，用户点击下方的注册按钮，则进入系统的注册界面进行注册，如下图所示。</p>
    <p class="center"><img src="../assets/img/02.jpg" ></p>
    <p style="margin-bottom: 50px"></p>
    </div>

    <div id="tab-2" class="tab">
    <p class="tt">1.用户登录</p>
    <p>在浏览器网址栏输入 <a href="http://jbt.zy1dt.com" target="_blank">jbt.zy1dt.com</a>，点击进入系统（图1），即可进入到金榜志愿通系统的登陆界面，如下图所示。</p>
    <p class="center"><img src="../assets/img/01.jpg" ></p>
    <p style="margin-bottom: 50px"></p>
    </div>

    <div id="tab-3" class="tab">
    <p class="tt">1.忘记密码</p>
    <p>在用户注册后，在系统登陆界面，输入用户名，密码以及验证码进行登录。如果用户忘记密码，可点击注册下面的<i style="color: #ef392b;font-style: normal">“忘记密码”</i>进行密码重置，也可联系系统客服密码重置。</p>
    <p class="center"><img src="../assets/img/03.jpg" ></p>
    <p style="margin-bottom: 50px"></p>
    </div>
    </div>
    <div class="menu3 menu_tab">
    <div id="tab-3-1" class="tab">
    <p class="tt">1.首页</p>
    <p> 首页提供了本系统的使用流程，为如何利用系统进行高考志愿填报提供了向导，如图5所示,中间黄色圆框的主线流程包含：确定高考成绩、如何填报志愿、如何筛选院校、如何挑选专业、查询历年数据等。主线流程的左边或右边，点击蓝色框，可以进入具体的功能页面。
    比如: 如何填报志愿？点击右边的院校推荐框，可以进入院校推荐的详情页。</p>
    <p class="center"><img src="../assets/img/04.jpg" ></p>
    <p style="margin-bottom: 50px"></p>
    </div>
    <div  id="tab-3-2" class="tab">
    <p class="tt">1.院校推荐</p>
    <p>院校推荐部分，系统根据您输入的高考成绩，设定的院校属地、院校分类、办学性质、录取批次、院校特色等条件，以及注册时选择的文理科类等信息，运用精心设计的数学模型进行科学计算，得出最适合您报考的院校集，这些院校分为冲刺型院校、稳妥型院校和保守型院校三类，其中具体院校又按各批次的录取平均分排行从低到高进行排序。在推荐结果中，可以很直观地查看每所院校近三年的最低录取分、平均录取分、最高录取分、投档线、线差、入档位次，最低等效分、平均等效分、最高等效分，招生计划人数，以及院校的类别、代码、隶属、录取概率、硕士点数和博士点数等信息。</p>
    <p>在这里，我们以某一理科考生为例，其成绩为615分，根据默认条件，直接进行包括院校推荐，在推荐结果中，可以看到院校被分为三大类行：<i style="color: #ef392b;font-style: normal">冲刺型院校</i>、<i style="color: #ef392b;font-style: normal">稳妥型院校</i>、<i style="color: #ef392b;font-style: normal">保守型院校</i>。</p>
    <p class="center"><img src="../assets/img/05.jpg" ></p>
    <p> 在推荐的冲刺型院校里，系统按录取平均分排行从低到高依次显示院校。在图中可以看到，615分接近中南财经政法大学和华中科技大学近三年最低等效分标准。</p>
    <p class="center"><img src="../assets/img/06.jpg" ></p>
    <p>同样，在推荐的稳妥型院校里，系统按录取分排行从低到高依次显示院校。在图中可以看到，615分达到了华东政法大学和北京第二外国语学院近三年等效平均分的接近水平。</p>
    <p class="center"><img src="../assets/img/07.jpg" ></p>
    <p>最后就是保守型院校，同样，在推荐的保守型院校里，系统按录取平均分排行从低到高依次显示院校。在图中可以看到， 615分都超过了北京邮电大学和中国农业大学近三年等效平均分之上。</p>
    <p>在实际应用中，用户可以根据自身的偏好来添加一些条件，从而缩小院校推荐的范围，推荐的院校结果同样被分为冲刺型、稳妥型、保守型三大类型。</p>
    </div>
    <div  id="tab-3-3" class="tab">
    <p class="tt">1.院校查询</p>
    <p>在院校查询部分，系统根据您设定的热门城市、院校属地、院校分类、办学性质、录取批次、院校特色、最低等效分区间和录取线差区间等条件，检索出符合条件的院校集。 在本功能中，系统默认的院校查询条件是办学性质为公办，录取批次为本科一批。 下图就是根据系统设置的默认条件查询的结果。</p>
    <p class="center"><img src="../assets/img/08.jpg" ></p>
        <p>在上图中，系统直接查询出了办学性质是公办，录取批次是本科一批的院校，查询出的院校按照录取平均分排名进行排序，同时也显示了每所院校近三年的最低录取分、平均录取分、最高录取分、投档线、线差、入档位次，最低等效分、平均等效分、最高等效分、招生计划人数，以及院校的类别、代码、隶属、重点学科数、硕士点数和博士点数等信息。</p>
    </div>
    <div  id="tab-3-4" class="tab">
    <p class="tt">1.数据筛选查询</p>
    <p>在本功能中，可以设定一个最低等效分区间或者录取线差区间来进行院校查询。下图显示的是查询近三年最低等效分位于540-610分之间的院校情况。</p>
    <p class="center"><img src="../assets/img/09.jpg" ></p>
    </div>
    <div  id="tab-3-5" class="tab">
    <p class="tt">1.院校收藏</p>
    <p>在本功能中，用户可将有意向的院校加入收藏，可以在用户中心-我收藏的院校中查看。</p>
    <p class="center"><img src="../assets/img/10.jpg" ></p>
    <p>上图使用的是院校推荐功能推荐的院校，从图中可以看到，中国农业大学有个蓝色框，显示“取消收藏”字样，而北京中医药大学下方则是橙色框，显示“加入收藏”字样。在这里，中国农业大学是已经被收藏了，而北京中医药大学则没有。</p>
    </div>
    <div  id="tab-3-6" class="tab">
    <p class="tt">1. 院校对比</p>
    <p>院校对比可以对自己拿不准院校进行深层次的比较分析。下面，我们对院校推荐得到的院校进行对比分析。 首先，进行院校推荐，得到初步需要比较院校的列表。</p>
    <p class="center"><img src="../assets/img/11.jpg" ></p>
    <p>上图是查询需要比较院校的列表，需要比较这两所大学，可以点击北京科技大学的加入对比框，进行院校对比。</p>
    <p class="center"><img src="../assets/img/12.jpg" ></p>
    <p>上图可以看到下方会有一个窗口弹出，显示出要对比的院校，之后用户可以对这两所院校进行基本情况和录取的对比分析，将显示出这两所院校的基本情况对比结果。</p>
    <p class="center"><img src="../assets/img/13.jpg" ></p>
    <p> 上图显示的是点击院校录取分数的对比结果。需要注意的是，每次最多只能进行5所院校的对比分析。</p>
    <p class="center"><img src="../assets/img/14.jpg" ></p>
    <p> 上图是院校录取分数对比分析。</p>
    </div>
    <div  id="tab-3-7" class="tab">
    <p class="tt">1.院校信息查询</p>
    <p>在院校推荐结果及院校查询结果中，点击院校图标，就可以查看院校简介、一流学科、录取详情、当年的招生章程等详细信息，以及近三年该院校在考生所在省招生的各个专业的录取平均分、等效平均分、计划人数等细节信息。</p>
    <p class="center"><img src="../assets/img/15.jpg" ></p>
    <p>上图显示的是华东理工大学的院校简介，其中包括地址，所属城市，招生电话和邮箱等。</p>
    <p class="center"><img src="../assets/img/16.jpg" ></p>
    <p>上图显示的是华东理工大学的一流学科介绍。</p>
    <p class="center"><img src="../assets/img/17.jpg" ></p>
    <p>上图显示的是华东理工大学的录取详情，图中的柱状图显示的是北京交通大学从14年到16年院校录取信息，包括每年的最低等效分、平均等效分、最高等效分以及用户输入的分数，可以直观看出用户的分数与该院校的录取分数以及等效分之间的一个差异状况。</p>
    <p class="center"><img src="../assets/img/18.jpg" ></p>
    <p>上图显示的则是华东理工大学的在考生所在省的专业录取详情，其中包括专业代码，专业名称，一流学科，年份，录取平均分，等效平均分，专业线差、录取批次以及计划人数等。通过这个表格，用户对该校专业的录取情况一目了然，可以根据它来选择拟报考的专业。</p>
    <p class="center"><img src="../assets/img/19.jpg" ></p>
    <p>上图显示的则是华东理工大学招生章程信息(每年同步更新)</p>
    </div>
    <div  id="tab-3-8"  class="tab">
    <p class="tt">1.专业查询</p>
    <p> 在本功能中，可以很方便地查看各专业的详细信息介绍，包括专业解读、开设院校、专业排名。
    首先，选择某一学历类别（分本科和高职专科学历类别），然后在专业层次树或者专业列表中选择某一具体专业，查看该专业详细信息。
    </p>
    <p class="center"><img src="../assets/img/20.jpg" ></p>
    <p>上图为专业查询主页面</p>
    <p class="center"><img src="../assets/img/21.jpg" ></p>
    <p>点击哲学专业进入后，我们可以看到的信息有专业代码，授予学位，修学年限，开设课程，相近专业，专业解读，开设院校以及专业排名。其中，专业解读对哲学的定义，用途，就业形势，就业前景与方向，修学要求等进行了详细解答。比如哲学是稀少且智慧的学科；哲学就业形势没那么灰暗等。</p>
    <p class="center"><img src="../assets/img/22.jpg" ></p>
    <p>对这个专业感兴趣的话，可以点击右上角的加入收藏。加入收藏后，可以在用户中心-我收藏的专业中查看。</p>
    <p class="center"><img src="../assets/img/23.jpg" ></p>
    <p> 点击开设院校，可以查看到开设该专业的院校，比如开设哲学专业的院校有深圳大学、湖南师范大学等，同时也显示了该专业在院校的录取分数情况。</p>
    <p class="center"><img src="../assets/img/24.jpg" ></p>
    <p> 点击专业排名，可以清楚看到专业在各个院校的实力情况，还显示了该专业在各院校的录取平均分和等效平均分。</p>
    </div>
    <div  id="tab-3-9" class="tab">
    <p class="tt">1.历年数据</p>
    <p>本功能可以查询历年的批次控制线、一分段表、投档线、招生计划、双一流、2017年学科排名。</p>
    <p class="center"><img src="../assets/img/25.jpg" ></p>
    </div>
    <div  id="tab-3-10" class="tab">
    <p class="tt">1.用户信息修改</p>
    <p>本系统允许用户在个人中心进行密码，分数修改。(分数在高考成绩出来之前可以输入月考或模拟成绩，平时可以修改，高考成绩公布之后只能修改一次，即输入高考成绩)。</p>
    <p class="center"><img src="../assets/img/26.jpg" ></p>
    <p class="tt">2.修改分数</p>
    <p class="center"><img src="../assets/img/27.jpg" ></p>
    </div>
    <div  id="tab-3-11"  class="tab">
    <p class="tt">1.院校收藏</p>
    <p>在本功能中，用户可以查看收藏的院校和专业，对这些收藏的院校和专业进行管理。</p>
    <p class="center"><img src="../assets/img/28.jpg" ></p>
    <p class="tt">2.专业收藏</p>
    <p class="center"><img src="../assets/img/29.jpg" ></p>
    </div>
    </div>
    <div class="menu4 menu_tab">
    <div id="tab-4-1" class="tab">
    <p class="tt">1.追院校比较表</p>
    <p> 首先需要在系统中把院校收藏好，然后选勾选需要院校，点击查看院校比较表。（勾选的院校不能超过20所）</p>
    <p class="center"><img src="../assets/img/30.jpg" style="max-width: 680px"></p>
    <p class="tt">2.下载追院校比较表</p>
    <p> 生成追院校比较表，点击右下角的付费下载。（支持微信和支付宝付费）</p>
    <p class="center"><img src="../assets/img/31.jpg" ></p>
    <p class="tt">3.选择支付方式</p>
    <p class="center"><img src="../assets/img/32.jpg" ></p>
    <p> 付款成功后，在我的订单中选择需要下载的比较表。</p>
    <p class="center"><img src="../assets/img/33.jpg" ></p>
    <p>点击下载，系统自动生成追院校志愿比较表。</p>
    <p class="center"><img src="../assets/img/34.jpg" ></p>
    </div>
    <div id="tab-4-2" class="tab">
    <p class="tt">1.追专业比较表</p>
    <p>在用户中心，点击我收藏的专业，勾选好收藏的专业后，系统会根据输入的分数和专业自动生成追专业比较表。</p>
    <p class="center"><img src="../assets/img/35.jpg" ></p>
    <p class="tt">2.下载专业比较表</p>
    <p class="center"><img src="../assets/img/36.jpg" ></p>
    <p>付费后，在我的订单中，选择需要下载的比较表进行下载(同追院校比较表下载步骤)。</p>
    </div>
    <div id="tab-4-4" class="tab">
    <p class="tt">1.院校专业兼顾表</p>
    <p> 生成兼顾院校专业比较表前，先收藏院校和专业。（收藏的专业和院校之间有关联，收藏的专业需在收藏的院校中招生，所选院校数量不能超过20所，每个院校的专业数量不能超过10个，否则无法下载）。</p>
    <p class="center"><img src="../assets/img/37.jpg" ></p>
    <p class="tt">2.下载院校专业兼顾表</p>
    <p>选中院校后，点击查看兼顾院校和专业表，系统自动生成，点击付费下载。</p>
    <p class="center"><img src="../assets/img/38.jpg" ></p>
    <p>付费后，在我的订单中，选择需要下载的比较表进行下载(同追院校比较表下载步骤)。</p>
    </div>
    </div>
    </div>
</div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    data(){
        return{};
    },
    methods:{
        // 左侧点击触发
        staticHtmlClick(){
            if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            $("html").css("fontSize","18px");
            }
            $("div.container ul[class^='ulmenu'] > li").click(function(){
            $("div.container ul[class^='ulmenu'] > li a").removeClass("selected");
                $(this).find("a").addClass("selected");
                var index = $(this).index();
                var txt = $(this).closest("ul").attr("class");
                var pindex = parseInt(txt.substring(txt.length-1))-1;
                $(".content div.menu_tab .tab").removeClass("active");
                $(".content div.menu_tab").eq(pindex).find(".tab:eq("+index+")").addClass("active");

                var imgOld =$(".content div.menu_tab").eq(pindex).find(".tab:eq("+index+")").find("img");
                imgOld.each(function(){
                $(this).attr("src",$(this).attr("src"));
                });
            });

            // ul/li的折叠效果
           $(".menu > ul").eq(0).show();
           $(".menu h3").click(function(){
               // 找menu对应的tab
               $(".menu_tab > div").removeClass("active");
               var txt = $(this).next().attr("class"); /* ul class="ulmenu1" */
               var num = txt.substring(txt.length-1); /* 截取最后一位 */

              $(".container .content .menu"+num+" .tab:first-child").addClass("active");
              $(".container .menu .ulmenu"+num+" li>a").removeClass("selected");
              $(".container .menu .ulmenu"+num+" li a").eq(0).addClass("selected"); // 默认设置为被选中状态

            $(this).next().slideToggle();
            $(this).siblings().next("ul").slideUp();

               var Old =$(".container .content .menu"+num+" .tab:first-child").find("p.center");
               Old.each(function(){
                   $(this).html($(this).html().replace('_',''));
            });
            });
        }
    },
    mounted(){
        this.staticHtmlClick();
    }
};
</script>
<style lang="less" scoped>
    html{overflow-y:scroll;font-size:16px}
    body{ margin:0;padding:0;}
    .plate {margin:0 auto; background-color: #eaeaea;}
    .header {width: 900px;margin: 0 auto;  padding:20px 0;  text-align: center;  }
    .header h3 {margin:0;font-size: 24px}
    .header .get-menu {margin-top: 10px;font-size:15px}
    .container{width:900px;  box-sizing: border-box;  font-family: 微软雅黑;  background: #fff;  margin:20px auto;  overflow: hidden;  }
    .container .menu{  box-sizing: border-box;  width: 170px;  float: left;  text-align: center;  }
    .container .menu h3{  font-size: 20px;  cursor: pointer;  }
    .container .menu ul{  display: none;  padding:0;  margin-top: 0;  text-align: center;  }
    .container .menu ul li{  width: 80%;  height: 28px;  line-height: 28px;  margin: 0 auto 10px;  }
    .container .menu ul li a:hover{  background: #9FB6CD;  border-radius: 14px;  -webkit-border-radius: 14px;  -moz-border-radius: 14px;  -moz-border-radius:14px;  color:#fff;  cursor:pointer;  }
    ul{  list-style: none;  }
    .container .menu ul li a{  text-decoration: none;  color: #000;  display: block;  line-height: 28px;  outline: none;  }
    .container .menu .selected{  background: #ccc;  border-radius: 14px;  }
    .container .content{  box-sizing: border-box;  margin-top: 15px;  width: 730px;  float: right;  padding-left: 30px;  border-left: 1px solid #aaa;  }
    .container .content .menu1 .tab {  display: none;  }
    .container .content .menu1 .active{  display: block;  }
    .container .content .menu_tab .tab p{  font-size: 16px;  line-height: 30px;  color: rgba(0,0,0,1);  word-break:break-all;  }
    .container .content .menu_tab .tab .tt{  font-size: 16px;  font-weight: bold;  }
    /*第二个菜单下的文档内容*/
    .container .content .menu2 .tab {  display: none;  }
    .container .content .menu2 .active{  display: block;  }
    .container .content .menu_tab .tab .center {text-align: center}
    /*第三个菜单下的文档内容*/
    .container .content .menu3 .tab {  display: none;  }
    .container .content .menu3 .active{  display: block;  }
    /*第四个菜单下的文档内容*/
    .container .content .menu4 .tab {  display: none;  }
    .container .content .menu4 .active{  display: block;  }
</style>
