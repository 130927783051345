import { render, staticRenderFns } from "./userxx.vue?vue&type=template&id=805988b0&scoped=true&"
import script from "./userxx.vue?vue&type=script&lang=js&"
export * from "./userxx.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805988b0",
  null
  
)

export default component.exports