<template>
    <div class="major-detail">
        <div class="card-top">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div id="load-data" class="out-div">
            <div class="inner-div">
            <div class="top-title">
                <div class="title" style="padding-left:0px;font-size: 22px;">{{detail.zymc}}</div>
                <el-button  type="primary" size="mini" @click="closeWin" plain round>关闭</el-button>
            </div>
            <div class="top-mid">
                <div style="" class="mid-txt">专业代码：<span>{{detail.zydm}}</span></div>
                <div style="margin-left:20px;" class="mid-txt">授予学位：<span>{{detail.syxw}}</span></div>
                <div style="margin-left:20px;" class="mid-txt">修学年限：<span>{{detail.xxnx}}</span></div>
            </div>
            <div class="top-block" style="box-sizing:border-box">
                <h3 class="mid-txt">开设课程：</h3>
                <div v-html="detail.kskc"></div>
            </div>
            <div class="top-block" style="box-sizing:border-box">
                <h3 class="mid-txt">相近专业：</h3>
                <template v-if="detail.xjzys">
                <a href="javascript:void(0)" v-for="(item,index) in detail.xjzys"
                :key="index" :zy_zyid="item.zydm" style="margin-right:20px">{{item.zymc}}</a>
                </template>
            </div>
        </div>
        <div class="content-div" style="padding-left:0px;">
        <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="专业解读" name="1">
                <MajorJd :zyjd="detail.zyjd" :jyqjyfx="detail.jyqjyfx"/>
            </el-tab-pane>
            <el-tab-pane label="院校专业组" name="2">
                <div id="load-yxzyz">
                    <Yxzyz :yxzyz="yxzyz"/>
                    <div class="page-div">
                        <el-pagination
                        @size-change="handleYxzyzSizeChange"
                        @current-change="handleYxzyzCurrentChange"
                        :current-page="yxParam.pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="yxParam.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="yxzyzTotal">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="专业排名" name="3">
                <div id="zy-data">
                    <Ranking :zyxk="zyRanking" :year1="year1" :year2="year2" :year3="year3"/>
                    <div class="page-div">
                        <el-pagination
                        @size-change="handleZySizeChange"
                        @current-change="handleZyCurrentChange"
                        :current-page="zyParam.pageNum"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="zyParam.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="zyTotal">
                        </el-pagination>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        </div>
        </div>
    </div>
</template>
<script>
import MajorJd from './major/majorJd.vue';
import Yxzyz from './major/majorYxzyz.vue';
import Ranking from './major/zyRanking.vue';
import {mapState} from 'vuex';
export default {
data(){
    return{
        detail:{},
        activeName:"1",
        yxzyz:[],
        yxzyzTotal:0,
        yxParam:{
            pageNum:1,
            pageSize:10
        },
        zyRanking:[],
        zyParam:{
            pageNum:1,
            pageSize:10
        },
        year1:'',
        year2:'',
        year3:'',
        zyTotal:0
    };
},
methods:{
            // 关闭当前页面
    closeWin() {
                const userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
                    window.open('','_self').close();
                }else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
    },
    // 获取院校详情数据
    async getZyDetail(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("load-data"),text:"正在加载中"});
        const {zydm}=this.$route.query;
        const res=await this.$http.getHttp("/zycx/zyxq?zydm="+zydm);
        if(res.data.code===200){
            this.detail=res.data.obj;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 获取专业详情院校专业组
    async getYxzyz(pageNum=1,pageSize=10){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("load-yxzyz"),text:"正在加载中"});
        const {zydm}=this.$route.query;
        const res=await this.$http.getHttp("/zycx/zyzsyxzyz?zydm="+zydm+"&pageNum="+pageNum+"&pageSize="+pageSize+"");
        if(res.data.code===200){
            this.yxzyz=res.data.obj.records;
            this.yxzyzTotal=res.data.obj.total;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 获取专业排名数据
    async getZyRanking(pageNum=1,pageSize=10){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("zy-data"),text:"正在加载中"});
        this.zyParam.pageNum=pageNum;
        this.zyParam.pageSize=pageSize;
        const {zydm}=this.$route.query;
        const res=await this.$http.getHttp("/zycx/zypm?zydm="+zydm+"&pageNum="+pageNum+"&pageSize="+pageSize);
        if(res.data.code===200){
            this.zyRanking=res.data.obj.page.records;
            this.zyTotal=res.data.obj.page.total;
            this.year1=res.data.obj.nds[0]+'年';
            this.year2=res.data.obj.nds[1]+'年';
            this.year3=res.data.obj.nds[2]+'年';
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 改变院校专业组size
    async handleYxzyzSizeChange(pageSize){
        this.yxParam.pageSize=pageSize;
        await this.getYxzyz(1,pageSize);
    },
    // 改变院校专业组page
    async handleYxzyzCurrentChange(pageNum){
        this.yxParam.pageNum=pageNum;
        await this.getYxzyz(pageNum,10);
    },
    // 改变专业排名size
    async handleZySizeChange(pageSize){
        await this.getZyRanking(1,pageSize);
    },
    // 改变专业排名page
    async handleZyCurrentChange(pageNum){
        await this.getZyRanking(pageNum,10);
    }
},
computed:{
    ...mapState(['userInfo'])
},
async mounted(){
await this.getZyDetail();
await this.getYxzyz();
await this.getZyRanking();
},
components:{
    MajorJd,
    Yxzyz,
    Ranking
}
};
</script>
<style lang="less" scoped>
.major-detail{
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .top-title{
        width:1100px;
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin:0 auto;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        .title{
            font-weight: normal;
            color: #ff861a;
            padding-left: 25px;
            // background: url() no-repeat 0 -38px;
        }
    }
    .top-mid{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width:1100px;
        margin:10px auto;
    }
    .top-block{
        width:1100px;
        margin:10px auto;
    }
    .mid-txt{
        color: #333;
        font-weight:bold;
        font-size:16px;
    }
    .content-div{
        width:1200px;
        margin:10px auto;
        padding-left:24px;
        box-sizing: border-box;
    }
}
#load-yxzyz{
    .page-div{
        width:600px;
        margin:10px auto;
    }
}
#zy-data{
    .page-div{
        width:600px;
        margin:10px auto;
    }
}
.out-div{
    width:1200px;
    margin:0 auto;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 3px solid #2186DE;
    padding-top: 10px;
    box-sizing: border-box;
    .inner-div{
    width:1200px;
    margin:0 auto;
    }
}
</style>
