<template>
    <div id="container">
        <div>
            <div class="info">
                <div>
                <div class="inputlist">
                    <div class="input-div">
                        考生名：
                    <input class="myinput" v-model="inputObj.xm"/>
                    </div>
                </div>
                <div class="inputlist" style="margin-left:53px;">
                    <div class="input-div">
                        手机号码：
                    <input class="myinput" disabled v-if="userInfo.sjhm" v-model="userInfo.sjhm"/>
                    <input class="myinput" disabled v-else/>
                    </div>
                </div>
                <div class="inputlist" style="margin-left:63px;">
                    <div class="input-div">
                        就读中学：
                    <input class="myinput" style="width:180px;" v-model="inputObj.school"/>
                    </div>
                </div>
                <div v-if="userInfo.yhlb===7||userInfo.yhlb===9" class="inputlist" style="float:right;">
                    备注&nbsp;：&nbsp;
                    <textarea id="dt_bz" rows="4" cols="38"
                    style="resize:none;float:none;border: 1px solid #c2c2c2;height:100px;"></textarea>
                </div>
                <div v-if="userInfo.yhlb===2||userInfo.yhlb===4" class="inputlist" style="float: right;">
                    <img src="../../../assets/images/fontImage/长方形2.jpg" style="width:330px;height:110px;"/>
                </div>
                </div>
                <div>
                    <div class="inputlist">
                        <div class="input-div">
                            高考总分：
                            <input style="width:26px;" class="myinput" disabled v-if="userInfo.gkfs" v-model="userInfo.gkfs"/>
                            <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:15px;">
                        <div class="input-div">
                            语文：
                        <input style="width:26px;" class="myinput" disabled v-if="userInfo.ywcj" v-model="userInfo.ywcj"/>
                        <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:15px;">
                        <div class="input-div">
                            数学：
                        <input style="width:26px;" class="myinput" disabled v-if="userInfo.sxcj" v-model="userInfo.sxcj"/>
                        <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:15px;">
                        <div class="input-div">
                            英语：
                            <input style="width:26px;" class="myinput" disabled v-if="userInfo.wycj" v-model="userInfo.wycj"/>
                            <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:15px;">
                        <div class="input-div">
                        <span v-if="userInfo.sxkm===2">物理：</span>
                        <span v-if="userInfo.sxkm===1">历史：</span>
                        <input style="width:26px;" class="myinput" disabled v-if="userInfo.sxkmcj" v-model="userInfo.sxkmcj"/>
                        <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:15px;">
                        <div class="input-div">
                            <span v-if="userInfo.zxkma===1">政治：</span>
                            <span v-if="userInfo.zxkma===2">地理：</span>
                            <span v-if="userInfo.zxkma===3">化学：</span>
                            <span v-if="userInfo.zxkma===4">生物：</span>
                            <input style="width:26px;" class="myinput" disabled v-if="userInfo.zxkmacj" v-model="userInfo.zxkmacj"/>
                            <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:20px;">
                        <div class="input-div">
                            <span v-if="userInfo.zxkmb===1">政治：</span>
                            <span v-if="userInfo.zxkmb===2">地理：</span>
                            <span v-if="userInfo.zxkmb===3">化学：</span>
                            <span v-if="userInfo.zxkmb===4">生物：</span>
                            <input style="width:26px;" class="myinput" disabled v-if="userInfo.zxkmbcj" v-model="userInfo.zxkmbcj"/>
                            <input style="width:26px;" class="myinput" disabled v-else/>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="inputlist">
                        <div class="input-div">
                            <span>省份：</span>
                            <span v-if="userInfo.sssf===43">湖南省</span>
                            <span v-if="userInfo.sssf===44">广东省</span>
                            <span v-if="userInfo.sssf===51">四川省</span>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:32px;">
                        <div class="input-div">
                            性别：
                            <select class="myinput" style="width:50px;" disabled v-model="userInfo.xb">
                                <option v-for="(item,index) in xbs" :key="index"
                                :value="item.value">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:17px;">
                        <div class="input-div">
                            是否有加分：
                            <input class="myinput" style="width:50px;" disabled v-if="userInfo.yfjf>=0" v-model="userInfo.yfjf"/>
                            <input class="myinput" style="width:50px;" disabled v-else/>
                        </div>
                    </div>
                    <div class="inputlist" style="margin-left:17px;">
                        <div class="input-div">
                            是否有相关资格：
                        <input type="checkbox" checked disabled v-if="userInfo.gjzxzg===1"/><span v-if="userInfo.gjzxzg===1" style="margin-right:5px;">国家专项</span>
                        <input type="checkbox"  disabled v-if="userInfo.gjzxzg===0"/><span v-if="userInfo.gjzxzg===0" style="margin-right:5px;">国家专项</span>
                        <input type="checkbox" checked disabled v-if="userInfo.dfzxzg===1"/><span v-if="userInfo.dfzxzg===1">地方专项</span>
                        <input type="checkbox"  disabled v-if="userInfo.dfzxzg===0"/><span v-if="userInfo.dfzxzg===0">地方专项</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="load-btn">
                <!-- <div class="lrzyfa-btn" style="margin-left:8px;">
                    <div>
                    <el-button type="primary" @click="allNoCheck">取消全选</el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="savePlan">保存</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #ff861a;border: 1px solid white;width:98px;" @click="saveOther"> 另 存 为 </el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #ff861a;border: 1px solid white;width:110px;" @click="saveOther"> 另 存 为 </el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #095A89;border:1px solid white;"
                    @click="openMyZyzList">我的专业组列表</el-button>
                    </div>
                    <div style="margin-left:501px;" v-if="isUser===false||yfFreePg===true">
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveFirst"><i class="el-icon-d-arrow-left" style="color:blue"></i> 移 动 到 第 一 行 </el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="movePrev"><i class="el-icon-arrow-left" style="color:blue"></i> 前 移 一 行 </el-button>
                    </div>
                    <div style="margin-left:350px;" v-if="isUser===true&&yfFreePg===false">
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveFirst"><i class="el-icon-d-arrow-left" style="color:blue"></i> 移 动 到 第 一 行 </el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="movePrev"><i class="el-icon-arrow-left" style="color:blue"></i> 前 移 一 行 </el-button>
                    </div>
                </div>
                <div class="lrzyfa-btn" style="margin-left:8px;">
                    <div>
                    <el-button type="primary" @click="allCheck" style="width:97px;">全选</el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="deleteYx">删除</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #de3b21c9;border: 1px solid white;"
                    @click="submitZypg">志愿评估</el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #de3b21c9;border: 1px solid white;"
                    @click="zypgOne">评估(仅1次)</el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #de3b21c9;border: 1px solid white;"
                    @click="foroverZypg">评估(次数不限)</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #095A89;border:1px solid white;"
                    @click="openMyZyzList">我的专业组列表</el-button>
                    </div>
                    <div v-if="isUser===false||yfFreePg===true" class="user1" style="margin-left:252px;">
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #ff861a;border: 1px solid white;margin-left:99px;"
                    @click="moveEnd"><i class="el-icon-d-arrow-right" style="color:blue"></i> 移 动到最后一行</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #ff861a;border: 1px solid white;margin-left:14px;"
                    @click="moveNext"><i class="el-icon-arrow-right" style="color:blue"></i> 后 移 一 行 </el-button>
                    </div>
                    <div v-if="isUser===true&&yfFreePg===false" class="user2" style="margin-left:351px;">
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveEnd"><i class="el-icon-d-arrow-right" style="color:blue"></i> 移 动到最后一行</el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveNext"><i class="el-icon-arrow-right" style="color:blue"></i> 后 移 一 行 </el-button>
                    </div>
                </div> -->
                <div class="lrzyfa-btn">
                    <div style="float:left;">
                    <el-button type="primary" @click="allNoCheck">取消全选</el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="savePlan">保存</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #ff861a;border: 1px solid white;width:98px;" @click="saveOther"> 另 存 为 </el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #ff861a;border: 1px solid white;width:110px;" @click="saveOther"> 另 存 为 </el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #095A89;border:1px solid white;"
                    @click="openMyZyzList">我的专业组列表</el-button>
                    </div>
                    <div style="float:right;">
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveFirst"><i class="el-icon-d-arrow-left" style="color:blue"></i> 移 动 到 第 一 行 </el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="movePrev"><i class="el-icon-arrow-left" style="color:blue"></i> 前 移 一 行 </el-button>
                    </div>
                </div>
                    <div style="clear:both;"></div>
                    <div class="lrzyfa-btn">
                    <div style="float:left;">
                    <el-button type="primary" @click="allCheck" style="width:97px;">全选</el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="deleteYx">删除</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #de3b21c9;border: 1px solid white;"
                    @click="submitZypg">志愿评估</el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #de3b21c9;border: 1px solid white;"
                    @click="zypgOne">评估(仅1次)</el-button>
                    <el-button type="primary" v-if="isUser===true&&yfFreePg===false" style="background-color: #de3b21c9;border: 1px solid white;"
                    @click="foroverZypg">评估(次数不限)</el-button>
                    <el-button type="primary" v-if="isUser===false||yfFreePg===true" style="background-color: #095A89;border:1px solid white;"
                    @click="openMyZyzList">我的专业组列表</el-button>
                    </div>
                    <div style="float:right;">
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveEnd"><i class="el-icon-d-arrow-right" style="color:blue"></i> 移 动到最后一行</el-button>
                    <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;"
                    @click="moveNext"><i class="el-icon-arrow-right" style="color:blue"></i> 后 移 一 行 </el-button>
                    </div>
                </div>
            </div>
            <el-dialog :visible.sync="dialogProVisble" width="20%" title="提示">
                <div>方案正在提交，请耐心等待</div>
            </el-dialog>
            <el-dialog title="已保存的院校专业组列表" width="60%"
            :visible.sync="dialogTableVisible" :destroy-on-close="true">
                <el-table :data="yxzyzList" border
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778'
                ,'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
            <el-table-column show-overflow-tooltip type="index" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip property="plan" label="姓名" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip property="date" label="保存时间" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip  label="操作" :resizable="false">
                <template slot-scope="scope">
                    <el-button type="text" @click="watchYxzyz(scope.row.id,scope.row.pc)">修改</el-button>
                    <el-button type="text" @click="deleteYxzyz(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
            </el-dialog>
            <el-dialog title="请输入名字" :visible.sync="dialogPlanVisible" width="25%" :destroy-on-close="true">
            <div style="width:200px;margin:0 auto;margin-left:50px;">
                <!-- <el-input v-model.lazy="plan" style="width:200px;"></el-input> -->
                <input class="el-textarea__inner" v-model.lazy="plan" style="width:200px;"/>
            </div>
            <div style="width:200px;margin:20px auto;">
                <el-button type="primary" @click="saveOtherCode">确定</el-button>
                <el-button @click="closeSaveOther">取消</el-button>
            </div>
            </el-dialog>
            <el-dialog  title="金榜志愿通-支付" :visible.sync="dialogBuyVisible"
            width="30%" :destroy-on-close="true" @closed="closePayDialog">
            <el-form>
                <el-form-item label="适用省份">
                    <el-select v-model="buyInfo.sssf" style="width:150px;">
                        <template v-if="buyInfo.sssf===43||buyInfo.sssf==='43'">
                            <el-option value="43" label="湖南"></el-option>
                        </template>
                        <template v-if="buyInfo.sssf===44||buyInfo.sssf==='44'">
                            <el-option value="44" label="广东"></el-option>
                        </template>
                        <template v-if="buyInfo.sssf===51||buyInfo.sssf==='51'">
                            <el-option value="51" label="四川"></el-option>
                        </template>
                        <!-- <el-option value="44" label="广东"></el-option>
                        <el-option value="51" label="四川"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="商品类型">
                    <el-select v-model="buyInfo.type" style="width:150px;">
                        <el-option :value="buyInfo.type" :label="buyInfo.cpmc"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品数量">
                    <span>1份</span>
                </el-form-item>
                <el-form-item label="支付金额">
                    <span class="pay-price">￥{{buyInfo.payPrice}}</span>
                </el-form-item>
                <el-form-item label="付款方式">
                    <el-radio-group v-model="buyInfo.payType">
                        <el-radio :label="1">支付宝</el-radio>
                        <el-radio :label="2">微信</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <div style="margin: 0 auto;width:100px;">
                        <el-button type="primary" @click="payZypg">立即支付</el-button>
                    </div>
                </el-form-item>
                <div class="note">
                    <span style="color: red;">特别提示：</span>
                    <p style="margin:10px 0;">1、手机端下载表格可能存在不兼容问题，请到电脑端下载！</p>
                    <p style="margin:10px 0;">2、本商品一经售出，一经出售，概不退换！如有问题，可与客服联系4000-190-550;本卡不记名、不挂一经出售，概不退换!如有问题，可与我们的 客服联系4000-190-550</p>
                </div>
            </el-form>
            </el-dialog>
            <el-dialog title="提示" width="30%" :visible.sync="dialogZkMsgVisible">
                <div>你有评估历史，是否使用折扣价？</div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="confirmZk">是</el-button>
                    <el-button @click="cancelZk">否</el-button>
                </div>
            </el-dialog>
            <el-dialog
            :title="innerTitle"
            width="30%"
            :visible.sync="innerdialogVisible"
            @closed="closeDialog"
            :destroy-on-close="true"
            >
            <div class="weixin-panel">
            <div id="qrcode" class="qrcode" ref="qrCodeDiv"></div>
            <div class="qrcode-time">
                <i style="color:#ff7e00">{{ qrcodeTime }}s </i>后二维码过期
            </div>
            </div>
            </el-dialog>
            <div class="plan-div" style="border-top:1px solid #1f4778;border-bottom:1px solid #1f4778;">
                <div style="width:400px;margin-left:20px;">方案名：<span>{{planObj.plan}}</span></div>
                <div style="width:200px;">时间：{{planObj.time}}</div>
            </div>
            <div>
                <div id="out-load-tab">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="本科批" name="6">
                    <div id="bk-load">
                        <el-table border highlight-current-row :data="bkYxPlan" :key="updateBk" height="500"
                        @current-change="handleBkCurrentChange"
                        :header-cell-style="{'background-color':'#5287c6',
                        'border': '1px solid #1f4778','color': '#FFF','text-align':'center'}"
                        :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                        <el-table-column show-overflow-tooltip label="选择" prop="checked" width="50">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.checked"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="序号" type="index" width="50"></el-table-column>
                        <el-table-column show-overflow-tooltip label="院校代码" prop="sb" width="90">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.sb" @input="changeSb($event,scope.row)"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="院校名称" prop="yxmc" width="200">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.yxxh" @change="changeYxmc($event,scope.row)" @focus="clickYxmc(scope.row)">
                                    <template v-if="!scope.row.yxmcs||scope.row.yxmcs.length===0">
                                    <el-option :value="scope.row.yxxh" :label="scope.row.yxmc"></el-option>
                                    </template>
                                    <template v-if="scope.row.yxmcs&&scope.row.yxmcs.length>0">
                                    <el-option v-for="(item,index) in scope.row.yxmcs"
                                    :key="index" :value="item.yxxh" :label="item.yxmc"></el-option>
                                    </template>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业组代码" prop="zyz" width="100">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.zyz" @change="changeZyz($event,scope.row)" @focus="clickZyz(scope.row)">
                                    <template v-if="!scope.row.zyzs||scope.row.zyzs.length===0">
                                        <el-option :value="scope.row.zyz" :label="scope.row.zyz"></el-option>
                                    </template>
                                    <template v-if="scope.row.zyzs&&scope.row.zyzs.length>0">
                                    <el-option v-for="(item,index) in scope.row.zyzs"
                                    :key="index" :label="item.zyz" :value="item.zyz"></el-option>
                                    </template>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="开设专业" width="110">
                            <template slot-scope="scope">
                                <!-- <el-popover trigger="click" width="400" placement="right"
                                @show="searchZy(scope.row)">
                                    <div class="zys-div">
                                        <template v-if="scope.row.zys&&scope.row.zys.length>0">
                                            <span v-for="(item,index) in scope.row.zys" :key="index">
                                                <div v-if="item.show" style="margin-right:30px;margin-bottom:10px;">
                                                    <a href="javascript:void(0)" :zydh="item.zydh"
                                                    @click="zyClick(item.zydh,index,scope.row)">{{item.zydh}}&nbsp;{{item.zymc}}</a>
                                                </div>
                                            </span>
                                        </template>
                                    </div>
                                    <el-button  slot="reference" type="primary" style="width:80px;font-size: 14px;" size="mini">开设专业</el-button>
                                </el-popover> -->
                                <el-button type="primary" @click="chooseZy(scope.row)">选择专业</el-button>
                                <!-- <el-button type="primary" @click="toChooseZy(scope.row)">选择专业</el-button> -->
                                <!-- <router-link tag="a" target="_self"
                                :to="{
                                    path:'/chooseZy',
                                    query:{
                                        yxxh:scope.row.yxxh,
                                        zyz:scope.row.zyz,
                                        index:scope.row.index
                                    }
                                }">
                                <span @click="saveTableStorage">选择专业</span>
                                </router-link> -->
                                <!-- <el-button type="text" @click="watchZy(scope.row)" style="margin-left:20px;">预览专业</el-button> -->
                                <!-- <el-button type="primary" @click="watchZy(scope.row)" style="margin-left:20px;">预览专业</el-button> -->
                            </template>
                        </el-table-column>
                         <el-table-column show-overflow-tooltip label="专业(1)" prop="zy1" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy1"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(2)" prop="zy2" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy2"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(3)" prop="zy3" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy3"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(4)" prop="zy4" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy4"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(5)" prop="zy5" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy5"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(6)" prop="zy6" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy6"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业服从" prop="sffc" width="100">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.sffc">
                                    <el-option v-for="(item,index) in sffcs"
                                    :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="专科批" name="7">
                    <div id="zk-load">
                        <el-table border  :data="zkYxPlan" :key="updateZk" height="500"
                        @current-change="handleZkCurrentChange" highlight-current-row
                        :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                        'color': '#FFF','text-align':'center'}"
                        :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                        <el-table-column show-overflow-tooltip label="选择" prop="checked" width="50">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.checked"></el-checkbox>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="序号" type="index" width="50"></el-table-column>
                        <el-table-column show-overflow-tooltip label="院校代码" prop="sb" width="90">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.sb" @input="changeSb($event,scope.row)"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="院校名称" prop="yxmc" width="200">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.yxxh" @change="changeYxmc($event,scope.row)" @focus="clickYxmc(scope.row)">
                                    <template v-if="!scope.row.yxmcs||scope.row.yxmcs.length===0">
                                    <el-option :value="scope.row.yxxh" :label="scope.row.yxmc"></el-option>
                                    </template>
                                    <template v-if="scope.row.yxmcs&&scope.row.yxmcs.length>0">
                                    <el-option v-for="(item,index) in scope.row.yxmcs"
                                    :key="index" :value="item.yxxh" :label="item.yxmc"></el-option>
                                    </template>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业组代码" prop="zyz" width="100">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.zyz" @change="changeZyz($event,scope.row)">
                                    <template v-if="!scope.row.zyzs||scope.row.zyzs.length===0">
                                        <el-option :value="scope.row.zyz" :label="scope.row.zyz"></el-option>
                                    </template>
                                    <template v-if="scope.row.zyzs&&scope.row.zyzs.length>0">
                                    <el-option v-for="(item,index) in scope.row.zyzs"
                                    :key="index" :label="item.zyz" :value="item.zyz"></el-option>
                                    </template>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="开设专业" width="110">
                            <template slot-scope="scope">
                                <!-- <el-popover trigger="click" width="400" placement="right"
                                @show="searchZy(scope.row)">
                                    <div class="zys-div">
                                        <template v-if="scope.row.zys&&scope.row.zys.length>0">
                                            <span v-for="(item,index) in scope.row.zys" :key="index">
                                                <div v-if="item.show" style="margin-right:30px;margin-bottom:10px;">
                                                    <a href="javascript:void(0)" :zydh="item.zydh"
                                                    @click="zyClick(item.zydh,index,scope.row)">{{item.zydh}}&nbsp;{{item.zymc}}</a>
                                                </div>
                                            </span>
                                        </template>
                                    </div>
                                    <el-button  slot="reference" type="primary" style="width:80px;font-size: 14px;" size="mini">开设专业</el-button>
                                </el-popover> -->
                                <el-button type="primary" @click="chooseZy(scope.row)">选择专业</el-button>
                                <!-- <el-button type="primary" @click="toChooseZy(scope.row)">选择专业</el-button> -->
                                <!-- <router-link tag="a" target="_self"
                                :to="{
                                    path:'/chooseZy',
                                    query:{
                                        yxxh:scope.row.yxxh,
                                        zyz:scope.row.zyz,
                                        index:scope.row.index
                                    }
                                }">
                                <span @click="saveTableStorage">选择专业</span>
                                </router-link> -->
                                <!-- <el-button type="text" @click="watchZy(scope.row)" style="margin-left:20px;">预览专业</el-button> -->
                                <!-- <el-button type="primary" @click="watchZy(scope.row)" style="margin-left:20px;">预览专业</el-button> -->
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(1)" prop="zy1" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy1"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(2)" prop="zy2" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy2"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(3)" prop="zy3" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy3"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(4)" prop="zy4" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy4"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(5)" prop="zy5" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy5"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业(6)" prop="zy6" width="80">
                            <template slot-scope="scope">
                                <el-input v-model="scope.row.zy6"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column show-overflow-tooltip label="专业服从" prop="sffc" width="100">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.sffc">
                                    <el-option v-for="(item,index) in sffcs"
                                    :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                    </div>
                </el-tab-pane>
                </el-tabs>
                </div>
            </div>
            <div style="width:1100px;margin:20px auto;display:flex;justify-content:flex-end;">
                <template v-if="userInfo.yhlb===7||userInfo.yhlb===9||userInfo.yhlb===2||yfFreeDown===true">
                <el-button type="primary" @click="freeDownExcel">下载</el-button>
                </template>
                <template v-if="userInfo.yhlb===4&&yfFreeDown===false">
                <el-button type="primary" @click="yhDownload">下载(5元/次)</el-button>
                <el-button type="primary" @click="yjDownload">下载(20元/永久)</el-button>
                </template>
            </div>
            <el-dialog
            title="选择专业"
            width="60%"
            :visible.sync="dialogZyVisible"  :destroy-on-close="true">
            <div style="width:100%;">
            <el-transfer v-model="selectedZys" @change="chooseDialogZy" target-order="unshift"
            :titles="['专业1','专业2']" :data="zyData" @right-check-change="choose"
            @left-check-change="leftChoose" filterable>
            <div slot-scope="{ option }">
            <div class="default-tranfer-item" @mouseenter="indexKey = option.key"
                 @mouseleave="indexKey = null">
              <div style="height:60px;white-space:pre-line;">{{ option.label }}</div>
            </div>
            </div>
            <div class="transer-footer" slot="right-footer">
                <el-button @click="moveUp">上移</el-button>
                <el-button @click="moveDown">下移</el-button>
            </div>
            </el-transfer>
            </div>
            <div style="width:200px;margin:0 auto;margin-top:20px;">
                <el-button type="primary" @click="saveCheckedZy">确定</el-button>
                <el-button @click="closeZyDialog">取消</el-button>
            </div>
            </el-dialog>
            <el-dialog
            title="预览专业"
            width="30%"
            :visible.sync="dialogWatchZyVisible"  :destroy-on-close="true">
            <div style="margin:0 auto;">
                <template v-if="selectedZymcs&&selectedZymcs.length>0">
                <div v-for="(item,index) in selectedZymcs"
                :key="index">{{item}}</div>
                </template>
            </div>
            <div style="width:100px;margin:0 auto;margin-top:10px;">
                <el-button @click="closeWatchZy">关闭</el-button>
            </div>
            </el-dialog>
            <el-dialog
            title="请输入评估的方案名"
            width="15%"
            :visible.sync="dialogPgPlanZyVisible"  :destroy-on-close="true">
            <el-input v-model="pgPlan" style="width:95%;"></el-input>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="runZypg">确定</el-button>
                <el-button @click="closePgPlan">取消</el-button>
            </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2"; // 引入qrcode
import {mapState} from 'vuex';
export default {
data(){
    return{
        xbs:[
            {name:"男",value:1},
            {name:"女",value:2}
        ],
        gjzxzg:true,
        dfzxzg:true,
        pgPlan:'',
        inputObj:{
            xm:"",
            school:"",
            bz:""
        },
        innerTitle:"",
        innerdialogVisible:false,
        dialogZyVisible:false,
        dialogWatchZyVisible:false,
        dialogPgPlanZyVisible:false,
        selectedZys:[],
        selectedZymcs:[],
        zyData:[],
        sffcs:[
            {name:"服从",value:"1"},
            {name:"不服从",value:"0"}
        ],
        buyInfo:{
            type:"1",
            sssf:"43",
            num:"1",
            payPrice:"688",
            payType:1,
            sjhm:"",
            yzm:"",
            dj:"",
            cpmc:"",
            wxPrice:""
        },
        planObj:{
            plan:"",
            time:"",
            id:""
        },
        data: [], // 全部数据
        value: [], // 选中数据
        item: [], // 右侧勾选数据
        indexKey: null, // 高亮显示
        activeName:"6",
        isUser:true,
        bkYxPlan:[],
        zkYxPlan:[],
        dialogTableVisible:false,
        dialogPlanVisible:false,
        dialogBuyVisible:false,
        plan:"",
        updateBk:false,
        updateZk:false,
        yxzyzList:[],
        bkIndex:-1,
        zkIndex:-1,
        qrcodeTime:0,
        zfbTime:0,
        ddTimer:null,
        wxDdh:"",
        intervalQrcodeTime:null,
        wxSuccess:false,
        yfPg:true,
        yfFreePg:false,
        yfFreeDown:false,
        filename:'模拟志愿表',
        searchZyz:'',
        searchYxxh:'',
        searchIndex:-1,
        dialogProVisble:false,
        dialogZkMsgVisible:false,
        payFlag:false,
        yfPay:false,
        percentage:0,
        customColor: '#409eff'
    };
},
methods:{
    // 创建空行数据
    createNullData(){
        for(let i=0;i<45;i++){
            const obj={};
            obj.index=i+1;
            obj.checked=false;
            obj.sb="";
            obj.yxmc="";
            obj.yxmcs=[];
            obj.zyzs=[];
            obj.zys=[];
            obj.pc=6;
            obj.zyz="";
            obj.zy1="";
            obj.zy2="";
            obj.zy3="";
            obj.zy4="";
            obj.zy5="";
            obj.zy6="";
            obj.sffc="1";
            obj.sffcs=[
                {name:"服从",value:"1"},
                {name:"不服从",value:"0"}
            ];
            this.bkYxPlan.push(obj);
        }
        for(let i=0;i<30;i++){
            const obj={};
            obj.checked=false;
            obj.sb="";
            obj.index=i+1;
            obj.pc=7;
            obj.yxmc="";
            obj.yxmcs=[];
            obj.zyzs=[];
            obj.zys=[];
            obj.zyz="";
            obj.zy1="";
            obj.zy2="";
            obj.zy3="";
            obj.zy4="";
            obj.zy5="";
            obj.zy6="";
            obj.sffc="1";
            obj.sffcs=[
                {name:"服从",value:"1"},
                {name:"不服从",value:"0"}
            ];
            this.zkYxPlan.push(obj);
        };
    },
    // 改变省标
    async changeSb(sb,obj){
        const pc=this.activeName;
        if(pc==='6'){
            if(sb.length===4){
                const res=await this.$http.getHttp("/dxk/getYxBySb?sb="+sb);
                const index=obj.index-1;
                if(res.data.code===200){
                    const arr=[...res.data.obj];
                    this.$set(this.bkYxPlan[index],"yxmcs",arr);
                }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
                }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
                }else if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }else if(sb.length===0){
                const index=obj.index-1;
                const obj11={};
                obj11.index=index+1;
                obj11.checked=false;
                obj11.sb="";
                obj11.yxmc="";
                obj11.yxmcs=[];
                obj11.zyzs=[];
                obj11.zys=[];
                obj11.pc=6;
                obj11.zyz="";
                obj11.zy1="";
                obj11.zy2="";
                obj11.zy3="";
                obj11.zy4="";
                obj11.zy5="";
                obj11.zy6="";
                obj11.sffc="1";
                obj11.sffcs=[
                    {name:"服从",value:"1"},
                    {name:"不服从",value:"0"}
                ];
                this.$set(this.bkYxPlan,index,obj11);
            }
        }else{
            if(sb.length===4){
                const res=await this.$http.getHttp("/dxk/getYxBySb?sb="+sb);
                const index=obj.index-1;
                if(res.data.code===200){
                    const arr=res.data.obj;
                    this.$set(this.zkYxPlan[index],"yxmcs",arr);
                }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
                }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
                }else if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }else if(sb.length===0){
                const index=obj.index-1;
                const obj11={};
                obj11.index=index+1;
                obj11.checked=false;
                obj11.sb="";
                obj11.yxmc="";
                obj11.yxmcs=[];
                obj11.zyzs=[];
                obj11.zys=[];
                obj11.pc=7;
                obj11.zyz="";
                obj11.zy1="";
                obj11.zy2="";
                obj11.zy3="";
                obj11.zy4="";
                obj11.zy5="";
                obj11.zy6="";
                obj11.sffc="1";
                obj11.sffcs=[
                    {name:"服从",value:"1"},
                    {name:"不服从",value:"0"}
                ];
                this.$set(this.zkYxPlan,index,obj11);
            }
        }
    },
    // 改变选择的院校
    async changeYxmc(yxxh,obj){
        const pc=this.activeName;
        const kl=this.userInfo.sxkm;
        if(pc==='6'){
            const res=await this.$http.getHttp("/zsjh/getZyzByYxxh?kl="+kl+"&pc="+pc+"&yxxh="+yxxh);
            if(res.data.code===200){
                const arr=res.data.obj;
                const zyzs=[];
                const index=obj.index-1;
                for(let i=0;i<arr.length;i++){
                    if(arr[i]){
                        zyzs.push({zyz:arr[i]});
                    }
                }
                const yxmcObj=this.bkYxPlan[index].yxmcs.find(item=>item.yxxh===yxxh);
                this.$set(this.bkYxPlan[index],'yxmc',yxmcObj.yxmc);
                this.$set(this.bkYxPlan[index],"zyzs",zyzs);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const res=await this.$http.getHttp("/zsjh/getZyzByYxxh?kl="+kl+"&pc="+pc+"&yxxh="+yxxh);
            if(res.data.code===200){
                const arr=res.data.obj;
                const zyzs=[];
                const index=obj.index-1;
                for(let i=0;i<arr.length;i++){
                    if(arr[i]){
                        zyzs.push({zyz:arr[i]});
                    }
                }
                const yxmcObj=this.zkYxPlan[index].yxmcs.find(item=>item.yxxh===yxxh);
                this.$set(this.zkYxPlan[index],'yxmc',yxmcObj.yxmc);
                this.$set(this.zkYxPlan[index],"zyzs",zyzs);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 改变选中的专业组
    async changeZyz(zyz,obj){
        const pc=this.activeName;
        const yxxh=obj.yxxh;
        if(pc==='6'){
            const res=await this.$http.getHttp("/zsjh/getZyByZyz?zyz="+zyz+"&yxxh="+yxxh);
            const index=obj.index-1;
            if(res.data.code===200){
                const arr=res.data.obj;
                for(let i=0;i<arr.length;i++){
                    if(arr[i]){
                        arr[i].show=true;
                    }
                }
                this.$set(this.bkYxPlan[index],"zys",arr);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const res=await this.$http.getHttp("/zsjh/getZyByZyz?zyz="+zyz+"&yxxh="+yxxh);
            const index=obj.index-1;
            if(res.data.code===200){
                const arr=res.data.obj;
                for(let i=0;i<arr.length;i++){
                    if(arr[i]){
                        arr[i].show=true;
                    }
                }
                this.$set(this.zkYxPlan[index],"zys",arr);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 点击专业
    async zyClick(zydh,index,obj){
        const pc=this.activeName;
        if(pc==='6'){
            const index2=obj.index-1;
            const arr=[...this.bkYxPlan];
            const zys=arr[index2].zys;
            zys[index].show=false;
            if(!(arr[index2].zy1)){
                arr[index2].zy1=zydh;
            }else if(!(arr[index2].zy2)){
                arr[index2].zy2=zydh;
            }else if(!(arr[index2].zy3)){
                arr[index2].zy3=zydh;
            }else if(!(arr[index2].zy4)){
                arr[index2].zy4=zydh;
            }else if(!(arr[index2].zy5)){
                arr[index2].zy5=zydh;
            }else{
                arr[index2].zy6=zydh;
            }
        }else{
            const index2=obj.index-1;
            const arr=[...this.zkYxPlan];
            const zys=arr[index2].zys;
            zys[index].show=false;
            if(!(arr[index2].zy1)){
                arr[index2].zy1=zydh;
            }else if(!(arr[index2].zy2)){
                arr[index2].zy2=zydh;
            }else if(!(arr[index2].zy3)){
                arr[index2].zy3=zydh;
            }else if(!(arr[index2].zy4)){
                arr[index2].zy4=zydh;
            }else if(!(arr[index2].zy5)){
                arr[index2].zy5=zydh;
            }else{
                arr[index2].zy6=zydh;
            }
        }
    },
    // 查询专业
    async searchZy(obj){
        const pc=this.activeName;
        if(pc==='6'){
            const index=obj.index-1;
            const yxxh=obj.yxxh;
            const zyz=obj.zyz;
            const arr=[...this.bkYxPlan];
            for(let i=0;i<6;i++){
                arr[index]["zy"+(i+1)]="";
            }
            this.$set(this.bkYxPlan,index,arr[index]);
            const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+yxxh+"&zyz="+zyz);
            if(res.data.code===200){
                const arr2=res.data.obj;
                for(let i=0;i<arr2.length;i++){
                    if(arr2[i]){
                        arr2[i].show=true;
                    }
                }
                this.$set(this.bkYxPlan[index],"zys",arr2);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const index=obj.index-1;
            const yxxh=obj.yxxh;
            const zyz=obj.zyz;
            const arr=[...this.zkYxPlan];
            for(let i=0;i<6;i++){
                arr[index]["zy"+(i+1)]="";
            }
            this.$set(this.zkYxPlan,index,arr[index]);
            const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+yxxh+"&zyz="+zyz);
            if(res.data.code===200){
                const arr2=res.data.obj;
                for(let i=0;i<arr2.length;i++){
                    if(arr2[i]){
                        arr2[i].show=true;
                    }
                }
                this.$set(this.zkYxPlan[index],"zys",arr2);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 点击院校名称下拉框
    async clickYxmc(obj){
        const pc=this.activeName;
        if(pc==='6'){
            const sb=obj.sb;
            const index=obj.index-1;
            const res=await this.$http.getHttp("/dxk/getYxBySb?sb="+sb);
                if(res.data.code===200){
                    const arr=res.data.obj;
                    this.$set(this.bkYxPlan[index],"yxmcs",arr);
                }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
                }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
                }else if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
        }else{
            const sb=obj.sb;
            const index=obj.index-1;
            const res=await this.$http.getHttp("/dxk/getYxBySb?sb="+sb);
                if(res.data.code===200){
                    const arr=res.data.obj;
                    this.$set(this.zkYxPlan[index],"yxmcs",arr);
                }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
                }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
                }else if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
        }
    },
    // 点击专业组下拉框
    async clickZyz(obj){
        const pc=this.activeName;
        if(pc==='6'){
            const kl=this.userInfo.sxkm;
            const yxxh=obj.yxxh;
            const res=await this.$http.getHttp("/zsjh/getZyzByYxxh?kl="+kl+"&pc="+pc+"&yxxh="+yxxh);
            if(res.data.code===200){
                const arr=res.data.obj;
                const zyzs=[];
                const index=obj.index-1;
                for(let i=0;i<arr.length;i++){
                    if(arr[i]){
                        zyzs.push({zyz:arr[i]});
                    }
                }
                this.$set(this.bkYxPlan[index],"zyzs",zyzs);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const kl=this.userInfo.sxkm;
            const yxxh=obj.yxxh;
            const res=await this.$http.getHttp("/zsjh/getZyzByYxxh?kl="+kl+"&pc="+pc+"&yxxh="+yxxh);
            if(res.data.code===200){
                const arr=res.data.obj;
                const zyzs=[];
                const index=obj.index-1;
                for(let i=0;i<arr.length;i++){
                    if(arr[i]){
                        zyzs.push({zyz:arr[i]});
                    }
                }
                this.$set(this.zkYxPlan[index],"zyzs",zyzs);
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 根据方案名获取方案信息
    async getPlanContent(plan,pc,id){
        this.activeName=pc+'';
        if(pc===6||pc==='6'){
            this.activeName="6";
            const loading=this.$loading({fullscreen:true,target:document.getElementById("bk-load"),text:"正在加载中"});
            const res=await this.$http.getHttp("/mnzy/getMnzyByPlan?plan="+plan);
            if(res.data.code===200){
                // const content=res.data.obj.XgkMnzyPojo.param;
                // if(content){
                //     const arr1=content.split(",");
                //     const contentArr=[];
                //     for(let i=0;i<arr1.length;i++){
                //         const arr2=arr1[i].split("-");
                //         const obj={};
                //         obj.sb=arr2[0];
                //         obj.index=(i+1);
                //         obj.yxmc=arr2[1];
                //         obj.yxxh=arr2[2];
                //         obj.pc=arr2[3];
                //         obj.zyz=arr2[4];
                //         obj.sffc=arr2[5];
                //         const zys=arr2[6];
                //         if(zys&&zys!==''){
                //             let arr3=zys.split("_");
                //             arr3=arr3.filter(item=>item!=='');
                //             for(let i=0;i<arr3.length;i++){
                //                 if(arr3[i]){
                //                     obj["zy"+(i+1)]=arr3[i];
                //                 }
                //             }
                //         }
                //         obj.checked=arr2[7]===1;
                //         contentArr.push(obj);
                //     }
                //     const nullLen=45-contentArr.length;
                //     const nullArr=[];
                //     for(let i=0;i<nullLen;i++){
                //         const obj={};
                //         obj.checked=false;
                //         obj.index=contentArr.length+(i+1);
                //         obj.sb="";
                //         obj.yxmc="";
                //         obj.yxmcs=[];
                //         obj.zyzs=[];
                //         obj.zys=[];
                //         obj.pc=6;
                //         obj.zyz="";
                //         obj.zy1="";
                //         obj.zy2="";
                //         obj.zy3="";
                //         obj.zy4="";
                //         obj.zy5="";
                //         obj.zy6="";
                //         obj.sffc="1";
                //         obj.sffcs=[
                //         {name:"服从",value:"1"},
                //         {name:"不服从",value:"0"}
                //         ];
                //         nullArr.push(obj);
                //     }
                //     this.bkYxPlan=contentArr.concat(nullArr);
                // }
                const list=[...res.data.obj.XgkMnzyPojo.list];
                if(list&&list.length>0){
                    const arr=[];
                    for(let i=0;i<list.length;i++){
                        arr.push({
                            sb:list[i].sb,
                            sffc:(list[i].sffc+''),
                            yxmc:list[i].yxmc,
                            yxxh:list[i].yxxh,
                            zyz:list[i].zyz,
                            index:(i+1),
                            checked:list[i].isRed||false,
                            pc:6,
                            zy1:list[i].zydh1,
                            zy2:list[i].zydh2,
                            zy3:list[i].zydh3,
                            zy4:list[i].zydh4,
                            zy5:list[i].zydh5,
                            zy6:list[i].zydh6
                        });
                    }
                    const nullLen=45-arr.length;
                    const nullArr=[];
                    for(let i=0;i<nullLen;i++){
                        const obj={};
                        obj.checked=false;
                        obj.index=arr.length+(i+1);
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.zys=[];
                        obj.pc=6;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                        ];
                        nullArr.push(obj);
                    }
                    this.bkYxPlan=arr.concat(nullArr);
                }else{
                    this.bkYxPlan=[];
                    this.zkYxPlan=[];
                    this.createNullData();
                }
                this.planObj.plan=res.data.obj.XgkMnzyPojo.plan;
                this.planObj.time=res.data.obj.XgkMnzyPojo.updatetime;
                this.planObj.id=id;
                if(res.data.obj.users){
                    this.$store.dispatch('saveUserInfo',res.data.obj.users);
                }
                // const yhlb=this.userInfo.yhlb;
                // if(yhlb===7||yhlb===9){
                //     await this.updateInfo(user);
                // }
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
            loading.close();
        }else{
            this.activeName="7";
            const loading=this.$loading({fullscreen:true,target:document.getElementById("zk-load"),text:"正在加载中"});
            const res=await this.$http.getHttp("/mnzy/getMnzyByPlan?plan="+plan);
            if(res.data.code===200){
                const content=res.data.obj.XgkMnzyPojo.param;
                if(content){
                    const arr1=content.split(",");
                    const contentArr=[];
                    for(let i=0;i<arr1.length;i++){
                        const arr2=arr1[i].split("-");
                        const obj={};
                        obj.index=(i+1);
                        obj.sb=arr2[0];
                        obj.yxmc=arr2[1];
                        obj.yxxh=arr2[2];
                        obj.pc=arr2[3];
                        obj.zyz=arr2[4];
                        obj.sffc=arr2[5];
                        // const zys=arr2[6];
                        obj.checked=arr2[7]===1;
                        contentArr.push(obj);
                    }
                    const nullLen=30-contentArr.length;
                    const nullArr=[];
                    for(let i=0;i<nullLen;i++){
                        const obj={};
                        obj.checked=false;
                        obj.index=contentArr.length+(i+1);
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.zys=[];
                        obj.pc=6;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                        ];
                        nullArr.push(obj);
                    }
                    this.zkYxPlan=contentArr.concat(nullArr);
                }
                const list=[...res.data.obj.XgkMnzyPojo.list];
                if(list){
                    const arr=[];
                    for(let i=0;i<list.length;i++){
                        arr.push({
                            sb:list[i].sb,
                            sffc:(list[i].sffc+''),
                            yxmc:list[i].yxmc,
                            yxxh:list[i].yxxh,
                            zyz:list[i].zyz,
                            index:(i+1),
                            checked:list[i].isRed||false,
                            pc:7,
                            zy1:list[i].zydh1,
                            zy2:list[i].zydh2,
                            zy3:list[i].zydh3,
                            zy4:list[i].zydh4,
                            zy5:list[i].zydh5,
                            zy6:list[i].zydh6
                        });
                    }
                    const nullLen=30-arr.length;
                    const nullArr=[];
                    for(let i=0;i<nullLen;i++){
                        const obj={};
                        obj.checked=false;
                        obj.index=arr.length+(i+1);
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.zys=[];
                        obj.pc=7;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                        ];
                        nullArr.push(obj);
                    }
                    this.zkYxPlan=arr.concat(nullArr);
                }
                this.planObj.plan=res.data.obj.XgkMnzyPojo.plan;
                this.planObj.time=res.data.obj.XgkMnzyPojo.updatetime;
                this.planObj.id=id;
                if(res.data.obj.users){
                this.$store.dispatch('saveUserInfo',res.data.obj.users);
                }
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
            loading.close();
        }
        this.$emit('reloadYhGzYx','true');
    },
    // 改变本科选中的行
    handleBkCurrentChange(val){
        if(val){
            if(val.sb){
                this.bkIndex=val.index;
            }
        }
    },
    // 改变专科选中的行
    handleZkCurrentChange(val){
        if(val){
            if(val.sb){
                this.zkIndex=val.index;
            }
        }
    },
    // 全选
    allCheck(){
        const pc=this.activeName;
        if(pc==='6'){
            const bkArr=[...this.bkYxPlan];
            for(let i=0;i<bkArr.length;i++){
                bkArr[i].checked=true;
            }
            this.bkYxPlan=bkArr;
            this.updateBk=!this.updateBk;
        }else{
            const zkArr=[...this.zkYxPlan];
            for(let i=0;i<zkArr.length;i++){
                zkArr[i].checked=true;
            }
            this.zkYxPlan=zkArr;
            this.updateZk=!this.updateZk;
        }
    },
    // 取消全选
    allNoCheck(){
        const pc=this.activeName;
        if(pc==='6'){
            const bkArr=[...this.bkYxPlan];
            for(let i=0;i<bkArr.length;i++){
                bkArr[i].checked=false;
            }
            this.bkYxPlan=bkArr;
            this.updateBk=!this.updateBk;
        }else{
            const zkArr=[...this.zkYxPlan];
            for(let i=0;i<zkArr.length;i++){
                zkArr[i].checked=false;
            }
            this.zkYxPlan=zkArr;
            this.updateZk=!this.updateZk;
        }
    },
    // 移动到第一行
    moveFirst(){
        const pc=this.activeName;
        if(pc==='6'){
            let bkArr=[...this.bkYxPlan];
            const index=this.bkIndex-1;
            if(this.bkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            if(index===0){
                return;
            }
            const obj=bkArr[index];
            bkArr.unshift(obj);
            bkArr[index+1]=null;
            bkArr=bkArr.filter(item=>item!=null);
            this.bkYxPlan=bkArr;
            this.resetTableIndex();
            this.bkIndex=1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:bkArr,pc:pc});
            }
        }else{
            let zkArr=[...this.zkYxPlan];
            const index=this.zkIndex-1;
            if(this.zkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            if(index===0){
                return;
            }
            const obj=zkArr[index];
            zkArr.unshift(obj);
            zkArr[index+1]=null;
            zkArr=zkArr.filter(item=>item!=null);
            this.zkYxPlan=zkArr;
            this.resetTableIndex();
            this.zkIndex=1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:pc});
            }
        }
    },
    // 重置表格每行的index属性
    resetTableIndex(){
        const pc=this.activeName;
        if(pc==='6'){
            const bkArr=[...this.bkYxPlan];
            bkArr.forEach((item,index)=>{
                item.index=index+1;
            });
        }else{
            const zkArr=[...this.zkYxPlan];
            zkArr.forEach((item,index)=>{
                item.index=index+1;
            });
        }
    },
    // 前移一行
    movePrev(){
        const pc=this.activeName;
        if(pc==='6'){
            const bkArr=[...this.bkYxPlan];
            const index=this.bkIndex-1;
            if(this.bkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            const obj=bkArr[index];
            const prevObj=bkArr[index-1];
            if(index===0){
                return;
            }
            obj.index=index;
            prevObj.index=index+1;
            this.$set(this.bkYxPlan,index,prevObj);
            this.$set(this.bkYxPlan,index-1,obj);
            this.bkIndex=index;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.bkYxPlan,pc:pc});
            }
        }else{
            const zkArr=[...this.zkYxPlan];
            const index=this.zkIndex-1;
            if(this.zkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            const obj=zkArr[index];
            const prevObj=zkArr[index-1];
            if(index===0){
                return;
            }
            obj.index=index;
            prevObj.index=index+1;
            this.$set(this.zkYxPlan,index,prevObj);
            this.$set(this.zkYxPlan,index-1,obj);
            this.zkIndex=index;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.zkYxPlan,pc:pc});
            }
        }
    },
    // 移动到最后一行
    moveEnd(){
        const pc=this.activeName;
        if(pc==='6'){
            const bkArr=[...this.bkYxPlan];
            const index=this.bkIndex-1;
            if(this.bkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            const obj=bkArr[index];
            let arr=[];
            const nullArr=[];
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].sb){
                    arr.push(bkArr[i]);
                }else{
                    nullArr.push(bkArr[i]);
                }
            }
            arr.push(obj);
            arr[index]=null;
            arr=arr.filter(item=>item!=null);
            this.bkYxPlan=arr.concat(nullArr);
            this.resetTableIndex();
            this.bkIndex=arr.length;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.bkYxPlan,pc:pc});
            }
        }else{
            const zkArr=[...this.zkYxPlan];
            const index=this.zkIndex-1;
            if(this.zkIndex===-1){
                this.$message({message:"请选择某一行",showClose:true,type:'warning'});
                return;
            }
            const obj=zkArr[index];
            let arr=[];
            const nullArr=[];
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].sb){
                    arr.push(zkArr[i]);
                }else{
                    nullArr.push(zkArr[i]);
                }
            }
            arr.push(obj);
            arr[index]=null;
            arr=arr.filter(item=>item!=null);
            this.zkYxPlan=arr.concat(nullArr);
            this.resetTableIndex();
            this.zkIndex=arr.length;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.zkYxPlan,pc:pc});
            }
        }
    },
    // 后移一行
    moveNext(){
        const pc=this.activeName;
        if(pc==='6'){
            const bkArr=[...this.bkYxPlan];
            const index=this.bkIndex-1;
            if(this.bkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            const obj=bkArr[index];
            const nextObj=bkArr[index+1];
            let count=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].sb){
                    count++;
                }
            }
            if(index>=count-1){
                return;
            }
            obj.index=index+2;
            nextObj.index=index+1;
            this.$set(this.bkYxPlan,index,nextObj);
            this.$set(this.bkYxPlan,index+1,obj);
            this.bkIndex=index+2;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.bkYxPlan,pc:pc});
            }
        }else{
            const zkArr=[...this.zkYxPlan];
            const index=this.zkIndex-1;
            if(this.zkIndex===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
            }
            const obj=zkArr[index];
            const nextObj=zkArr[index+1];
            let count=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].sb){
                    count++;
                }
            }
            if(index>=count-1){
                return;
            }
            obj.index=index+2;
            nextObj.index=index+1;
            this.$set(this.zkYxPlan,index,nextObj);
            this.$set(this.zkYxPlan,index+1,obj);
            this.zkIndex=index+2;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.zkYxPlan,pc:pc});
            }
        }
    },
    // 关闭输入评估方案名弹窗
    closePgPlan(){
        this.dialogPgPlanZyVisible=false;
    },
    // 打开我的专业组列表
    async openMyZyzList(){
        const yhid=this.userInfo.yhid;
        const res=await this.$http.getHttp("/yhgzfa/selectYxZyzFaByYhid?yhid="+yhid);
        if(res.data.code===200){
            this.yxzyzList=res.data.obj;
            this.dialogTableVisible=true;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 打开院校专业组
    watchYxzyz(id,pc){
        const url=this.$router.resolve({
            path:"/zypg",
            query:{
                id,
                pc,
                isto:"true"
            }
        });
        this.dialogTableVisible=false;
        window.open(url.href,"_blank");
    },
    // 通过id查询关注的院校专业组
    async getYxzyzById(id,pc=6){
        let loadId="bk-load";
        if(pc===6||pc==='6'){
            loadId="bk-load";
        }else{
            loadId="zk-load";
        }
        this.activeName=pc+'';
        const loading=this.$loading({fullscreen:true,target:document.getElementById(loadId),text:"正在加载中"});
        const res=await this.$http.getHttp("/yhgzfa/selectFaById?id="+id);
        if(res.data.code===200){
            this.planObj.plan=res.data.obj.plan;
            this.planObj.time=res.data.obj.date;
            this.planObj.id='';
            if(res.data.obj.users){
                this.$store.dispatch('saveUserInfo',res.data.obj.users);
            }
            const user={};
            user.bz=res.data.obj.users.bz;
            user.gjzxzg=res.data.obj.users.gjzxzg;
            user.dfzxzg=res.data.obj.users.dfzxzg;
            user.gkfs=res.data.obj.users.gkfs;
            user.sxkm=res.data.obj.users.sxkm;
            user.school=res.data.obj.users.school;
            user.sjhm=res.data.obj.users.sjhm;
            user.sssf=res.data.obj.users.sssf;
            user.sxcj=res.data.obj.users.sxcj;
            user.sxkmcj=res.data.obj.users.sxkmcj;
            user.wycj=res.data.obj.users.wycj;
            user.xb=res.data.obj.users.xb;
            user.xm=res.data.obj.users.xm;
            user.yfjf=res.data.obj.users.yfjf;
            user.yhid=res.data.obj.users.yhid;
            user.yhlb=res.data.obj.users.yhlb;
            user.ywcj=res.data.obj.users.ywcj;
            user.zxkma=res.data.obj.users.zxkma;
            user.zxkmacj=res.data.obj.users.zxkmacj;
            user.zxkmb=res.data.obj.users.zxkmb;
            user.zxkmbcj=res.data.obj.users.zxkmbcj;
            // const yhlb=this.userInfo.yhlb;
            // if(yhlb===7||yhlb===9){
            //     await this.updateInfo(user);
            // }
            const arr=res.data.obj.obj;
            if(arr){
                for(let i=0;i<arr.length;i++){
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=(i+1);
                    arr[i].pc=pc;
                    arr[i].sffc="1";
                }
                const nullArr=[];
                const len=arr.length;
                for(let i=0;i<45-len;i++){
                const obj={};
                obj.checked=false;
                obj.index=len+(i+1);
                obj.sb="";
                obj.yxmc="";
                obj.yxmcs=[];
                obj.zyzs=[];
                obj.zys=[];
                obj.pc=6;
                obj.zyz="";
                obj.zy1="";
                obj.zy2="";
                obj.zy3="";
                obj.zy4="";
                obj.zy5="";
                obj.zy6="";
                obj.sffc="1";
                obj.sffcs=[
                    {name:"服从",value:"1"},
                    {name:"不服从",value:"0"}
                ];
                nullArr.push(obj);
                }
                if(pc===6||pc==='6'){
                    this.bkYxPlan=arr.concat(nullArr);
                }else{
                    this.zkYxPlan=arr.concat(nullArr);
                }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
        this.$emit('reloadYhGzYx','true');
    },
        // 修改分数
    async updateInfo(user){
        const obj={};
        obj.bz=user.bz;
        obj.gjzxzg=user.gjzxzg;
        obj.dfzxzg=user.dfzxzg;
        obj.dzyx=null;
        obj.enabled=true;
        obj.gkfs=user.gkfs;
        obj.gkfsxg=this.userInfo.gkfsxg;
        obj.khzcsj=this.userInfo.khzcsj;
        obj.kl=user.sxkm;
        obj.ksxm=this.userInfo.ksxm;
        obj.openid=this.userInfo.openid;
        obj.password=this.userInfo.password;
        obj.school=user.school;
        obj.sjhm=user.sjhm;
        obj.sssf=user.sssf;
        obj.sxcj=user.sxcj;
        obj.sxkm=user.sxkm;
        obj.sxkmcj=user.sxkmcj;
        obj.username=this.userInfo.username;
        obj.wycj=user.wycj;
        obj.xb=user.xb;
        obj.xm=user.xm;
        obj.yfjf=user.yfjf;
        obj.yfjf3=this.userInfo.yfjf3;
        obj.yfjf5=this.userInfo.yfjf5;
        obj.yfjf10=this.userInfo.yfjf10;
        obj.yfjf20=this.userInfo.yfjf20;
        obj.yhid=user.yhid;
        obj.yhlb=user.yhlb;
        obj.yhzcsj=this.userInfo.yhzcsj;
        obj.ywcj=user.ywcj;
        obj.zcjd=this.userInfo.zcjd;
        obj.zckh=this.userInfo.zckh;
        obj.zxkma=user.zxkma;
        obj.zxkmb=user.zxkmb;
        obj.zxkmacj=user.zxkmacj;
        obj.zxkmbcj=user.zxkmbcj;
        const res=await this.$http.postHttp("/users/modifyFs",obj);
        if(res.data.code===200){
            console.log("updateUser");
            // this.$message({message:"修改成功",type:"success",showClose:true});
            await this.getUser();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
        // 获取用户信息
    async getUser(){
        const res=await this.$http.getHttp("/users/getUserInfo?username="+this.userInfo.username);
        if(res.status===200){
            if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else{
                this.$store.dispatch("saveUserInfo",res.data);
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 保存代码
    async savePlan(){
        const obj={};
        if(this.planObj.id){
            obj.id=this.planObj.id;
        }
        obj.yhid=this.userInfo.yhid;
        obj.plan="";
        obj.user=this.userInfo.xm;
        obj.sssf=this.userInfo.sssf;
        obj.kl=this.userInfo.sxkm;
        obj.pc=this.activeName;
        obj.fs=this.userInfo.gkfs;
        obj.school=this.userInfo.school;
        obj.ghs=null;
        obj.xb=this.userInfo.xb;
        obj.bz=this.userInfo.bz;
        obj.lx=null;
        obj.sjhm=this.userInfo.sjhm;
        const pc=this.activeName;
        // if(pc==='6'){
        //     const bkArr=this.bkYxPlan;
        //     const arr=[];
        //     for(let i=0;i<bkArr.length;i++){
        //         if(bkArr[i].zyz){
        //             let strs="";
        //             if(bkArr[i].zy1){
        //                 strs+=bkArr[i].zy1+"_";
        //             }
        //             if(bkArr[i].zy2){
        //                 strs+=bkArr[i].zy2+"_";
        //             }
        //             if(bkArr[i].zy3){
        //                 strs+=bkArr[i].zy3+"_";
        //             }
        //             if(bkArr[i].zy4){
        //                 strs+=bkArr[i].zy4+"_";
        //             }
        //             if(bkArr[i].zy5){
        //                 strs+=bkArr[i].zy5+"_";
        //             }
        //             if(bkArr[i].zy6){
        //                 strs+=bkArr[i].zy6+"_";
        //             }
        //             arr.push(bkArr[i].sb+"-"+bkArr[i].yxmc+"-"+bkArr[i].yxxh+"-"+pc+"-"+bkArr[i].zyz+"-"+bkArr[i].sffc+"-"+strs+"-"+(bkArr[i].checked?1:0));
        //         }
        //     }
        //     obj.param=arr.join(",");
        // }else{
        //     const zkArr=this.zkYxPlan;
        //     const arr=[];
        //     for(let i=0;i<zkArr.length;i++){
        //         if(zkArr[i].zyz){
        //             let strs="";
        //             if(zkArr[i].zy1){
        //                 strs+=zkArr[i].zy1+"_";
        //             }
        //             if(zkArr[i].zy2){
        //                 strs+=zkArr[i].zy2+"_";
        //             }
        //             if(zkArr[i].zy3){
        //                 strs+=zkArr[i].zy3+"_";
        //             }
        //             if(zkArr[i].zy4){
        //                 strs+=zkArr[i].zy4+"_";
        //             }
        //             if(zkArr[i].zy5){
        //                 strs+=zkArr[i].zy5+"_";
        //             }
        //             if(zkArr[i].zy6){
        //                 strs+=zkArr[i].zy6+"_";
        //             }
        //             arr.push(zkArr[i].sb+"-"+zkArr[i].yxmc+"-"+zkArr[i].yxxh+"-"+pc+"-"+zkArr[i].zyz+"-"+zkArr[i].sffc+"-"+strs+"-"+(zkArr[i].checked?1:0));
        //         }
        //     }
        //     obj.param=arr.join(",");
        // }
        if(pc==='6'){
            const list=[];
            const bkArr=[...this.bkYxPlan];
            // let count=0;
            // for(let i=0;i<bkArr.length;i++){
            //     if(bkArr[i].zyz&&bkArr[i].checked){
            //         count++;
            //     }
            // }
            // if(count>45){
            //     this.$message({message:"请选择要保存的45所院校",type:'warning',showClose:true});
            //     return;
            // }else if(bkArr.length>45&&count===0){
            //     this.$message({message:"请选择要保存的45所院校",type:'warning',showClose:true});
            //     return;
            // }
            // for(let i=0;i<bkArr.length;i++){
            //     if(bkArr[i].zyz){
            //         if(count>0){
            //             if(bkArr[i].checked){
            //             let mnzybid='';
            //         if(this.planObj.id){
            //             mnzybid=this.planObj.id;
            //         }
            //         list.push({
            //             id:'',
            //             mnzybid,
            //             sb:bkArr[i].sb,
            //             sffc:bkArr[i].sffc,
            //             xh:i+1,
            //             yxmc:bkArr[i].yxmc,
            //             yxxh:bkArr[i].yxxh,
            //             zyz:bkArr[i].zyz,
            //             zydh1:bkArr[i].zy1,
            //             zydh2:bkArr[i].zy2,
            //             zydh3:bkArr[i].zy3,
            //             zydh4:bkArr[i].zy4,
            //             zydh5:bkArr[i].zy5,
            //             zydh6:bkArr[i].zy6
            //         });
            //             }
            //         }else{
            //             if(i<45){
            //                 let mnzybid='';
            //             if(this.planObj.id){
            //                 mnzybid=this.planObj.id;
            //             }
            //             list.push({
            //                 id:'',
            //                 mnzybid,
            //                 sb:bkArr[i].sb,
            //                 sffc:bkArr[i].sffc,
            //                 xh:i+1,
            //                 yxmc:bkArr[i].yxmc,
            //                 yxxh:bkArr[i].yxxh,
            //                 zyz:bkArr[i].zyz,
            //                 zydh1:bkArr[i].zy1,
            //                 zydh2:bkArr[i].zy2,
            //                 zydh3:bkArr[i].zy3,
            //                 zydh4:bkArr[i].zy4,
            //                 zydh5:bkArr[i].zy5,
            //                 zydh6:bkArr[i].zy6
            //             });
            //             }
            //         }
            //     }
            // }
            let count=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].zyz&&bkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].zyz){
                    if(count>0){
                        if(bkArr[i].checked){
                            let mnzybid='';
                            if(this.planObj.id){
                                mnzybid=this.planObj.id;
                            }
                            list.push({
                                id:'',
                                mnzybid,
                                sb:bkArr[i].sb,
                                sffc:bkArr[i].sffc,
                                xh:i+1,
                                yxmc:bkArr[i].yxmc,
                                yxxh:bkArr[i].yxxh,
                                zyz:bkArr[i].zyz,
                                zydh1:bkArr[i].zy1,
                                zydh2:bkArr[i].zy2,
                                zydh3:bkArr[i].zy3,
                                zydh4:bkArr[i].zy4,
                                zydh5:bkArr[i].zy5,
                                zydh6:bkArr[i].zy6
                            });
                        }
                    }else{
                        let mnzybid='';
                        if(this.planObj.id){
                            mnzybid=this.planObj.id;
                        }
                        list.push({
                            id:'',
                            mnzybid,
                            sb:bkArr[i].sb,
                            sffc:bkArr[i].sffc,
                            xh:i+1,
                            yxmc:bkArr[i].yxmc,
                            yxxh:bkArr[i].yxxh,
                            zyz:bkArr[i].zyz,
                            zydh1:bkArr[i].zy1,
                            zydh2:bkArr[i].zy2,
                            zydh3:bkArr[i].zy3,
                            zydh4:bkArr[i].zy4,
                            zydh5:bkArr[i].zy5,
                            zydh6:bkArr[i].zy6
                        });
                    }
                }
            }
            obj.list=list;
        }else{
            const list=[];
            const zkArr=[...this.zkYxPlan];
            // let count=0;
            // for(let i=0;i<zkArr.length;i++){
            //     if(zkArr[i].zyz&&zkArr[i].checked){
            //         count++;
            //     }
            // }
            // if(count>30){
            //     this.$message({message:"请选择要保存的30所院校",type:'warning',showClose:true});
            //     return;
            // }else if(zkArr.length>30&&count===0){
            //     this.$message({message:"请选择要保存的30所院校",type:'warning',showClose:true});
            //     return;
            // }
            // for(let i=0;i<zkArr.length;i++){
            //     if(zkArr[i].zyz){
            //         if(count>0){
            //             if(zkArr[i].checked){
            //         let mnzybid='';
            //         if(this.planObj.id){
            //             mnzybid=this.planObj.id;
            //         }
            //         list.push({
            //             id:'',
            //             mnzybid,
            //             sb:zkArr[i].sb,
            //             sffc:zkArr[i].sffc,
            //             xh:i+1,
            //             yxmc:zkArr[i].yxmc,
            //             yxxh:zkArr[i].yxxh,
            //             zyz:zkArr[i].zyz,
            //             zydh1:zkArr[i].zy1,
            //             zydh2:zkArr[i].zy2,
            //             zydh3:zkArr[i].zy3,
            //             zydh4:zkArr[i].zy4,
            //             zydh5:zkArr[i].zy5,
            //             zydh6:zkArr[i].zy6
            //         });
            //             }
            //         }else{
            //             if(i<30){
            //         let mnzybid='';
            //         if(this.planObj.id){
            //             mnzybid=this.planObj.id;
            //         }
            //         list.push({
            //             id:'',
            //             mnzybid,
            //             sb:zkArr[i].sb,
            //             sffc:zkArr[i].sffc,
            //             xh:i+1,
            //             yxmc:zkArr[i].yxmc,
            //             yxxh:zkArr[i].yxxh,
            //             zyz:zkArr[i].zyz,
            //             zydh1:zkArr[i].zy1,
            //             zydh2:zkArr[i].zy2,
            //             zydh3:zkArr[i].zy3,
            //             zydh4:zkArr[i].zy4,
            //             zydh5:zkArr[i].zy5,
            //             zydh6:zkArr[i].zy6
            //         });
            //             }
            //         }
            //     }
            // }
            let count=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].zyz&&zkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].zyz){
                    if(count>0){
                        if(zkArr[i].checked){
                            let mnzybid='';
                            if(this.planObj.id){
                                mnzybid=this.planObj.id;
                            }
                            list.push({
                                id:'',
                                mnzybid,
                                sb:zkArr[i].sb,
                                sffc:zkArr[i].sffc,
                                xh:i+1,
                                yxmc:zkArr[i].yxmc,
                                yxxh:zkArr[i].yxxh,
                                zyz:zkArr[i].zyz,
                                zydh1:zkArr[i].zy1,
                                zydh2:zkArr[i].zy2,
                                zydh3:zkArr[i].zy3,
                                zydh4:zkArr[i].zy4,
                                zydh5:zkArr[i].zy5,
                                zydh6:zkArr[i].zy6
                            });
                        }
                    }else{
                         let mnzybid='';
                        if(this.planObj.id){
                            mnzybid=this.planObj.id;
                        }
                        list.push({
                            id:'',
                            mnzybid,
                            sb:zkArr[i].sb,
                            sffc:zkArr[i].sffc,
                            xh:i+1,
                            yxmc:zkArr[i].yxmc,
                            yxxh:zkArr[i].yxxh,
                            zyz:zkArr[i].zyz,
                            zydh1:zkArr[i].zy1,
                            zydh2:zkArr[i].zy2,
                            zydh3:zkArr[i].zy3,
                            zydh4:zkArr[i].zy4,
                            zydh5:zkArr[i].zy5,
                            zydh6:zkArr[i].zy6
                        });
                    }
                }
            }
            obj.list=list;
        }
        obj.updatetime="";
        obj.zxkma=this.userInfo.zxkma;
        obj.zxkmb=this.userInfo.zxkmb;
        obj.yu=this.userInfo.ywcj;
        obj.shu=this.userInfo.sxcj;
        obj.wai=this.userInfo.wycj;
        obj.sxkmfs=this.userInfo.sxkmcj;
        obj.zxkmafs=this.userInfo.zxkmacj;
        obj.zxkmbfs=this.userInfo.zxkmbcj;
        obj.jf=this.userInfo.yfjf;
        obj.gjzxzg=this.userInfo.gjzxzg;
        obj.dfzxzg=this.userInfo.dfzxzg;
        obj.flag=0;
        if(this.planObj.plan){
            obj.plan=this.planObj.plan;
        }else{
            this.saveOther();
            return;
        }
        const res=await this.$http.postHttp("/mnzy/saveOrUpdateMnzy",obj);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"保存成功",type:'success',showClose:true});
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        setTimeout(() => {
            this.$router.push('/zypg?reload=true&&toLrzyfa=true&&time='+new Date().getTime());
            window.location.reload();
        }, 2000);
    },
    // 获取收藏的院校专业组
    async getCollectYx(){
        const res=await this.$http.getHttp("/yhgz/selectYhgzYx");
        if(res.data.code===200){
            if(!(res.data.obj.zk)&&!(res.data.obj.bk)){
                const data=res.data.obj;
            for(let i=0;i<data.length;i++){
                if(data[i]){
                    data[i].checked=data[i].isRed||false;
                    data[i].index=(i+1);
                    data[i].sffc="1";
                    data[i].sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                }
            };
            // const pc=this.activeName;
            const Len=res.data.obj.length;
            const nullData=[];
            const nullLen=45-Len;
            for(let i=0;i<nullLen;i++){
                    const obj={};
                    obj.index=data.length+(i+1);
                    obj.checked=false;
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.pc=6;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                    nullData.push(obj);
            }
                this.bkYxPlan=data.concat(nullData);
            }else{
                const data=res.data.obj.bk;
                for(let i=0;i<data.length;i++){
                    if(data[i]){
                        data[i].checked=data[i].isRed||false;
                        data[i].index=(i+1);
                        data[i].sffc="1";
                        data[i].sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                    }
                };
                const Len1=res.data.obj.bk.length;
                const nullData=[];
                const nullLen=45-Len1;
                for(let i=0;i<nullLen;i++){
                        const obj={};
                        obj.index=data.length+(i+1);
                        obj.checked=false;
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.pc=6;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                        nullData.push(obj);
                }
                this.bkYxPlan=data.concat(nullData);
                const data2=res.data.obj.zk;
                for(let i=0;i<data2.length;i++){
                    if(data2[i]){
                        data2[i].checked=data2[i].isRed||false;
                        data2[i].index=(i+1);
                        data2[i].sffc="1";
                        data2[i].sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                    }
                };
                const Len2=res.data.obj.zk.length;
                const nullData2=[];
                const nullLen2=45-Len2;
                for(let i=0;i<nullLen2;i++){
                        const obj={};
                        obj.index=data.length+(i+1);
                        obj.checked=false;
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.pc=6;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                        nullData2.push(obj);
                }
                this.zkYxPlan=data2.concat(nullData2);
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 打开另存为弹窗
    saveOther(){
        this.dialogPlanVisible=true;
    },
    // 关闭另存为窗口
    closeSaveOther(){
        this.dialogPlanVisible=false;
        this.plan='';
    },
    // 另存为代码
    async saveOtherCode(){
        const obj={};
        obj.id="";
        obj.yhid=this.userInfo.yhid;
        obj.plan=this.plan;
        obj.user=this.userInfo.xm;
        obj.sssf=this.userInfo.sssf;
        obj.kl=this.userInfo.sxkm;
        obj.pc=this.activeName;
        obj.fs=this.userInfo.gkfs;
        obj.school=this.userInfo.school;
        obj.ghs=null;
        obj.xb=this.userInfo.xb;
        obj.bz=this.userInfo.bz;
        obj.lx=null;
        obj.sjhm=this.userInfo.sjhm;
        const pc=this.activeName;
        // if(pc==='6'){
        //     const bkArr=this.bkYxPlan;
        //     const arr=[];
        //     for(let i=0;i<bkArr.length;i++){
        //         if(bkArr[i].zyz){
        //             let strs="";
        //             if(bkArr[i].zy1){
        //                 strs+=bkArr[i].zy1+"_";
        //             }
        //             if(bkArr[i].zy2){
        //                 strs+=bkArr[i].zy2+"_";
        //             }
        //             if(bkArr[i].zy3){
        //                 strs+=bkArr[i].zy3+"_";
        //             }
        //             if(bkArr[i].zy4){
        //                 strs+=bkArr[i].zy4+"_";
        //             }
        //             if(bkArr[i].zy5){
        //                 strs+=bkArr[i].zy5+"_";
        //             }
        //             if(bkArr[i].zy6){
        //                 strs+=bkArr[i].zy6+"_";
        //             }
        //             const status=bkArr[i].checked?1:0;
        //             arr.push(bkArr[i].sb+"-"+bkArr[i].yxmc+"-"+bkArr[i].yxxh+"-"+pc+"-"+bkArr[i].zyz+"-"+bkArr[i].sffc+"-"+strs+"-"+status);
        //         }
        //     }
        //     obj.param=arr.join(",");
        // }else{
        //     const zkArr=this.zkYxPlan;
        //     const arr=[];
        //     for(let i=0;i<zkArr.length;i++){
        //         if(zkArr[i].zyz){
        //             let strs="";
        //             if(zkArr[i].zy1){
        //                 strs+=zkArr[i].zy1+"_";
        //             }
        //             if(zkArr[i].zy2){
        //                 strs+=zkArr[i].zy2+"_";
        //             }
        //             if(zkArr[i].zy3){
        //                 strs+=zkArr[i].zy3+"_";
        //             }
        //             if(zkArr[i].zy4){
        //                 strs+=zkArr[i].zy4+"_";
        //             }
        //             if(zkArr[i].zy5){
        //                 strs+=zkArr[i].zy5+"_";
        //             }
        //             if(zkArr[i].zy6){
        //                 strs+=zkArr[i].zy6+"_";
        //             }
        //             const status=zkArr[i].checked?1:0;
        //             arr.push(zkArr[i].sb+"-"+zkArr[i].yxmc+"-"+zkArr[i].yxxh+"-"+pc+"-"+zkArr[i].zyz+"-"+zkArr[i].sffc+"-"+strs+"-"+status);
        //         }
        //     }
        //     obj.param=arr.join(",");
        // }
        if(pc==='6'){
            const list=[];
            const bkArr=[...this.bkYxPlan];
            // let count=0;
            // for(let i=0;i<bkArr.length;i++){
            //     if(bkArr[i].zyz&&bkArr[i].checked){
            //         count++;
            //     }
            // }
            // if(count>45){
            //     this.$message({message:"请选择要保存的45所院校",type:'warning',showClose:true});
            //     return;
            // }else if(bkArr.length>45&&count===0){
            //     this.$message({message:"请选择要保存的45所院校",type:'warning',showClose:true});
            //     return;
            // }
            // for(let i=0;i<bkArr.length;i++){
            //     if(bkArr[i].zyz){
            //         if(count>0){
            //             if(bkArr[i].checked){
            //             let mnzybid='';
            //         if(this.planObj.id){
            //             mnzybid=this.planObj.id;
            //         }
            //         list.push({
            //             id:'',
            //             mnzybid,
            //             sb:bkArr[i].sb,
            //             sffc:bkArr[i].sffc,
            //             xh:i+1,
            //             yxmc:bkArr[i].yxmc,
            //             yxxh:bkArr[i].yxxh,
            //             zyz:bkArr[i].zyz,
            //             zydh1:bkArr[i].zy1,
            //             zydh2:bkArr[i].zy2,
            //             zydh3:bkArr[i].zy3,
            //             zydh4:bkArr[i].zy4,
            //             zydh5:bkArr[i].zy5,
            //             zydh6:bkArr[i].zy6
            //         });
            //             }
            //         }else{
            //             if(i<45){
            //                 let mnzybid='';
            //             if(this.planObj.id){
            //                 mnzybid=this.planObj.id;
            //             }
            //             list.push({
            //                 id:'',
            //                 mnzybid,
            //                 sb:bkArr[i].sb,
            //                 sffc:bkArr[i].sffc,
            //                 xh:i+1,
            //                 yxmc:bkArr[i].yxmc,
            //                 yxxh:bkArr[i].yxxh,
            //                 zyz:bkArr[i].zyz,
            //                 zydh1:bkArr[i].zy1,
            //                 zydh2:bkArr[i].zy2,
            //                 zydh3:bkArr[i].zy3,
            //                 zydh4:bkArr[i].zy4,
            //                 zydh5:bkArr[i].zy5,
            //                 zydh6:bkArr[i].zy6
            //             });
            //             }
            //         }
            //     }
            // }
            let count=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].zyz&&bkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].zyz){
                    if(count>0){
                        if(bkArr[i].checked){
                            let mnzybid='';
                            if(this.planObj.id){
                                mnzybid=this.planObj.id;
                            }
                            list.push({
                                id:'',
                                mnzybid,
                                sb:bkArr[i].sb,
                                sffc:bkArr[i].sffc,
                                xh:i+1,
                                yxmc:bkArr[i].yxmc,
                                yxxh:bkArr[i].yxxh,
                                zyz:bkArr[i].zyz,
                                zydh1:bkArr[i].zy1,
                                zydh2:bkArr[i].zy2,
                                zydh3:bkArr[i].zy3,
                                zydh4:bkArr[i].zy4,
                                zydh5:bkArr[i].zy5,
                                zydh6:bkArr[i].zy6
                            });
                        }
                    }else{
                         let mnzybid='';
                        if(this.planObj.id){
                            mnzybid=this.planObj.id;
                        }
                        list.push({
                            id:'',
                            mnzybid,
                            sb:bkArr[i].sb,
                            sffc:bkArr[i].sffc,
                            xh:i+1,
                            yxmc:bkArr[i].yxmc,
                            yxxh:bkArr[i].yxxh,
                            zyz:bkArr[i].zyz,
                            zydh1:bkArr[i].zy1,
                            zydh2:bkArr[i].zy2,
                            zydh3:bkArr[i].zy3,
                            zydh4:bkArr[i].zy4,
                            zydh5:bkArr[i].zy5,
                            zydh6:bkArr[i].zy6
                        });
                    }
                }
            }
            obj.list=list;
        }else{
            const list=[];
            const zkArr=[...this.zkYxPlan];
            // let count=0;
            // for(let i=0;i<zkArr.length;i++){
            //     if(zkArr[i].zyz&&zkArr[i].checked){
            //         count++;
            //     }
            // }
            // if(count>30){
            //     this.$message({message:"请选择要保存的30所院校",type:'warning',showClose:true});
            //     return;
            // }else if(zkArr.length>30&&count===0){
            //     this.$message({message:"请选择要保存的30所院校",type:'warning',showClose:true});
            //     return;
            // }
            // for(let i=0;i<zkArr.length;i++){
            //     if(zkArr[i].zyz){
            //         if(count>0){
            //             if(zkArr[i].checked){
            //         let mnzybid='';
            //         if(this.planObj.id){
            //             mnzybid=this.planObj.id;
            //         }
            //         list.push({
            //             id:'',
            //             mnzybid,
            //             sb:zkArr[i].sb,
            //             sffc:zkArr[i].sffc,
            //             xh:i+1,
            //             yxmc:zkArr[i].yxmc,
            //             yxxh:zkArr[i].yxxh,
            //             zyz:zkArr[i].zyz,
            //             zydh1:zkArr[i].zy1,
            //             zydh2:zkArr[i].zy2,
            //             zydh3:zkArr[i].zy3,
            //             zydh4:zkArr[i].zy4,
            //             zydh5:zkArr[i].zy5,
            //             zydh6:zkArr[i].zy6
            //         });
            //             }
            //         }else{
            //             if(i<30){
            //         let mnzybid='';
            //         if(this.planObj.id){
            //             mnzybid=this.planObj.id;
            //         }
            //         list.push({
            //             id:'',
            //             mnzybid,
            //             sb:zkArr[i].sb,
            //             sffc:zkArr[i].sffc,
            //             xh:i+1,
            //             yxmc:zkArr[i].yxmc,
            //             yxxh:zkArr[i].yxxh,
            //             zyz:zkArr[i].zyz,
            //             zydh1:zkArr[i].zy1,
            //             zydh2:zkArr[i].zy2,
            //             zydh3:zkArr[i].zy3,
            //             zydh4:zkArr[i].zy4,
            //             zydh5:zkArr[i].zy5,
            //             zydh6:zkArr[i].zy6
            //         });
            //             }
            //         }
            //     }
            // }
            let count=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].zyz&&zkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].zyz){
                    if(count>0){
                        if(zkArr[i].checked){
                            let mnzybid='';
                            if(this.planObj.id){
                                mnzybid=this.planObj.id;
                            }
                            list.push({
                                id:'',
                                mnzybid,
                                sb:zkArr[i].sb,
                                sffc:zkArr[i].sffc,
                                xh:i+1,
                                yxmc:zkArr[i].yxmc,
                                yxxh:zkArr[i].yxxh,
                                zyz:zkArr[i].zyz,
                                zydh1:zkArr[i].zy1,
                                zydh2:zkArr[i].zy2,
                                zydh3:zkArr[i].zy3,
                                zydh4:zkArr[i].zy4,
                                zydh5:zkArr[i].zy5,
                                zydh6:zkArr[i].zy6
                            });
                        }
                    }else{
                        let mnzybid='';
                        if(this.planObj.id){
                            mnzybid=this.planObj.id;
                        }
                        list.push({
                            id:'',
                            mnzybid,
                            sb:zkArr[i].sb,
                            sffc:zkArr[i].sffc,
                            xh:i+1,
                            yxmc:zkArr[i].yxmc,
                            yxxh:zkArr[i].yxxh,
                            zyz:zkArr[i].zyz,
                            zydh1:zkArr[i].zy1,
                            zydh2:zkArr[i].zy2,
                            zydh3:zkArr[i].zy3,
                            zydh4:zkArr[i].zy4,
                            zydh5:zkArr[i].zy5,
                            zydh6:zkArr[i].zy6
                        });
                    }
                }
            }
            obj.list=list;
        }
        obj.updatetime="";
        obj.zxkma=this.userInfo.zxkma;
        obj.zxkmb=this.userInfo.zxkmb;
        obj.yu=this.userInfo.ywcj;
        obj.shu=this.userInfo.sxcj;
        obj.wai=this.userInfo.wycj;
        obj.sxkmfs=this.userInfo.sxkmcj;
        obj.zxkmafs=this.userInfo.zxkmacj;
        obj.zxkmbfs=this.userInfo.zxkmbcj;
        obj.jf=this.userInfo.yfjf;
        obj.gjzxzg=this.userInfo.gjzxzg;
        obj.dfzxzg=this.userInfo.dfzxzg;
        obj.flag=0;
        if(this.plan===''){
            this.$message({message:"请输入方案名",showClose:true});
            return;
        };
        console.log(obj);
        const res=await this.$http.postHttp("/mnzy/saveOrUpdateMnzy",obj);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"保存成功",type:'success',showClose:true});
            }
            this.plan='';
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.dialogPlanVisible=false;
        setTimeout(() => {
            this.$router.push('/zypg?reload=true&&toLrzyfa=true&&time='+new Date().getTime());
            window.location.reload();
        }, 2000);
    },
    // 删除院校
    deleteYx(){
        const pc=this.activeName;
        if(pc==='6'){
            let bkYxs=[...this.bkYxPlan];
            const checkedIndexArr=[];
            for(let i=0;i<bkYxs.length;i++){
                if(bkYxs[i].checked){
                    checkedIndexArr.push(i);
                    // const obj={};
                    // obj.checked=false;
                    // obj.sb="";
                    // obj.yxmc="";
                    // obj.yxmcs=[];
                    // obj.zyzs=[];
                    // obj.zys=[];
                    // obj.pc=6;
                    // obj.zyz="";
                    // obj.zy1="";
                    // obj.zy2="";
                    // obj.zy3="";
                    // obj.zy4="";
                    // obj.zy5="";
                    // obj.zy6="";
                    // obj.sffc="1";
                    // obj.sffcs=[
                    //     {name:"服从",value:"1"},
                    //     {name:"不服从",value:"0"}
                    // ];
                    // bkYxs[i]=obj;
                }
            }
            for(let i=0;i<checkedIndexArr.length;i++){
                if(checkedIndexArr[i]>=0){
                    bkYxs[checkedIndexArr[i]]=null;
                }
            }
            bkYxs=bkYxs.filter(item=>item!=null);
            const len=checkedIndexArr.length>45?45:checkedIndexArr.length;
            for(let i=0;i<len;i++){
                    const obj={};
                    obj.checked=false;
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.zys=[];
                    obj.pc=6;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                bkYxs.push(obj);
            }
            for(let i=0;i<bkYxs.length;i++){
                if(bkYxs[i]){
                    bkYxs[i].index=i+1;
                }
            }
            this.bkYxPlan=bkYxs;
        }else{
            let zkYxs=[...this.zkYxPlan];
            const checkedIndexArr=[];
            for(let i=0;i<zkYxs.length;i++){
                if(zkYxs[i].checked){
                    checkedIndexArr.push(i);
                    // const obj={};
                    // obj.checked=false;
                    // obj.sb="";
                    // obj.yxmc="";
                    // obj.yxmcs=[];
                    // obj.zyzs=[];
                    // obj.zys=[];
                    // obj.pc=7;
                    // obj.zyz="";
                    // obj.zy1="";
                    // obj.zy2="";
                    // obj.zy3="";
                    // obj.zy4="";
                    // obj.zy5="";
                    // obj.zy6="";
                    // obj.sffc="1";
                    // obj.sffcs=[
                    //     {name:"服从",value:"1"},
                    //     {name:"不服从",value:"0"}
                    // ];
                    // zkYxs[i]=obj;
                }
            }
            for(let i=0;i<checkedIndexArr.length;i++){
                if(checkedIndexArr[i]>=0){
                    zkYxs[checkedIndexArr[i]]=null;
                }
            }
            zkYxs=zkYxs.filter(item=>item!=null);
            const len=checkedIndexArr.length>30?30:checkedIndexArr.length;
            for(let i=0;i<len;i++){
                    const obj={};
                    obj.checked=false;
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.zys=[];
                    obj.pc=7;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                zkYxs.push(obj);
            }
            for(let i=0;i<zkYxs.length;i++){
                if(zkYxs[i]){
                    zkYxs[i].index=i+1;
                }
            }
            this.zkYxPlan=zkYxs;
        }
    },
    // 志愿评估代码
    async submitZypg(){
        const obj={};
        obj.bz=!this.userInfo.bz?null:this.userInfo.bz;
        obj.createTime="";
        obj.dfzxzg=this.userInfo.dfzxzg;
        obj.flag=0;
        obj.fs=this.userInfo.gkfs;
        obj.fxdj=0;
        obj.ghs=null;
        obj.gjzxzg=this.userInfo.gjzxzg;
        obj.jf=this.userInfo.yfjf;
        obj.kl=this.userInfo.sxkm;
        obj.lx=null;
        const pc=this.activeName;
        if(pc==='6'){
            const arr=[...this.bkYxPlan];
            // const arr2=[];
            // for(let i=0;i<arr.length;i++){
            //     if(arr[i].zyz){
            //         let strs="";
            //         for(let j=0;j<6;j++){
            //             if(arr[i]["zy"+(j+1)]){
            //                 if(j===5){
            //                     strs+=arr[i]["zy"+(j+1)];
            //                 }else{
            //                     strs+=arr[i]["zy"+(j+1)]+"_";
            //                 }
            //             }
            //         }
            //         const status=arr[i].checked?1:0;
            //         arr2.push(arr[i].sb+"-"+arr[i].yxmc+"-"+
            //         arr[i].yxxh+"-"+pc+"-"+arr[i].zyz+"-"+arr[i].sffc+"-"+strs+"-"+status);
            //     }
            // }
            // obj.param=arr2.join(",");
            const bkList=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].zyz){
                    let mnzybid=null;
                    if(this.planObj.id){
                        mnzybid=this.planObj.id;
                    }
                    bkList.push({
                        id:null,
                        mnzybid:mnzybid,
                        sb:arr[i].sb,
                        sffc:arr[i].sffc,
                        xh:(i+1),
                        yxmc:arr[i].yxmc,
                        yxxh:arr[i].yxxh,
                        zydh1:arr[i].zy1,
                        zydh2:arr[i].zy2,
                        zydh3:arr[i].zy3,
                        zydh4:arr[i].zy4,
                        zydh5:arr[i].zy5,
                        zydh6:arr[i].zy6,
                        zyz:arr[i].zyz
                    });
                }
            }
            obj.list=bkList;
        }else{
            const arr=[...this.zkYxPlan];
            // const arr2=[];
            // for(let i=0;i<arr.length;i++){
            //     if(arr[i].zyz){
            //         let strs="";
            //         for(let j=0;j<6;j++){
            //             if(arr[i]["zy"+(j+1)]){
            //                 if(j===5){
            //                     strs+=arr[i]["zy"+(j+1)];
            //                 }else{
            //                     strs+=arr[i]["zy"+(j+1)]+"_";
            //                 }
            //             }
            //         }
            //         const status=arr[i].checked?1:0;
            //         arr2.push(arr[i].sb+"-"+arr[i].yxmc+"-"+
            //         arr[i].yxxh+"-"+pc+"-"+arr[i].zyz+"-"+arr[i].sffc+"-"+strs+"-"+status);
            //     }
            // }
            // obj.param=arr2.join(",");
            const zkList=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].zyz){
                    let mnzybid=null;
                    if(this.planObj.id){
                        mnzybid=this.planObj.id;
                    }
                    zkList.push({
                        id:null,
                        mnzybid:mnzybid,
                        sb:arr[i].sb,
                        sffc:arr[i].sffc,
                        xh:(i+1),
                        yxmc:arr[i].yxmc,
                        yxxh:arr[i].yxxh,
                        zydh1:arr[i].zy1,
                        zydh2:arr[i].zy2,
                        zydh3:arr[i].zy3,
                        zydh4:arr[i].zy4,
                        zydh5:arr[i].zy5,
                        zydh6:arr[i].zy6,
                        zyz:arr[i].zyz
                    });
                }
            }
            obj.list=zkList;
        }
        obj.pc=this.activeName;
        if(this.planObj.plan){
            obj.plan=this.planObj.plan;
            if(this.planObj.id){
                obj.id=this.planObj.id;
            }else{
                obj.id="";
            }
        }else{
            this.dialogPgPlanZyVisible=true;
            return;
        }
        obj.result=null;
        obj.school=!this.userInfo.school?null:this.userInfo.school;
        obj.shu=!this.userInfo.sxcj?null:this.userInfo.sxcj+"";
        obj.sjhm=this.userInfo.sjhm;
        obj.sssf=this.userInfo.sssf;
        obj.status=0;
        obj.sxkmfs=!this.userInfo.sxkmcj?null:this.userInfo.sxkmcj+"";
        obj.updatetime="";
        obj.user=this.userInfo.xm;
        obj.wai=!this.userInfo.wycj?null:this.userInfo.wycj+"";
        obj.xb=this.userInfo.xb+"";
        obj.yhid=this.userInfo.yhid;
        obj.yu=!this.userInfo.ywcj?null:this.userInfo.ywcj+"";
        obj.zxkma=this.userInfo.zxkma+"";
        obj.zxkmafs=!this.userInfo.zxkmacj?null:this.userInfo.zxkmacj+"";
        obj.zxkmb=this.userInfo.zxkmb+"";
        obj.zxkmbfs=!this.userInfo.zxkmbcj?null:this.userInfo.zxkmbcj+"";
        this.dialogProVisble=true;
        const obj0={};
        obj0.role=99;
        obj0.type='zypg';
        const res0=await this.$http.postHttp('/enumRole/getFlag',obj0);
        if(res0.data.code===200){
            if(res0.data.obj.length>0){
                const flag=res0.data.obj[0].flag;
                if(flag!==1){
                    this.$message({message:'此功能暂不开放',showClose:true});
                    this.dialogProVisble=false;
                    return;
                }
            }
        }
        const res=await this.$http.postHttp("/zypg/zypgSubmit",obj);
        if(res.data.code===200){
            this.dialogProVisble=false;
            this.$message({message:"请到“我的方案”查看评估结果",type:"success",showClose:true});
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        setTimeout(() => {
            this.$router.push('/zypg?reload=true&&toLrzyfa=true&&time='+new Date().getTime());
            window.location.reload();
        }, 3000);
    },
    // 输入评估方案名进行评估
    async runZypg(){
        const obj={};
        obj.bz=!this.userInfo.bz?null:this.userInfo.bz;
        obj.createTime="";
        obj.dfzxzg=this.userInfo.dfzxzg;
        obj.flag=0;
        obj.fs=this.userInfo.gkfs;
        obj.fxdj=0;
        obj.ghs=null;
        obj.gjzxzg=this.userInfo.gjzxzg;
        obj.jf=this.userInfo.yfjf;
        obj.kl=this.userInfo.sxkm;
        obj.lx=null;
        const pc=this.activeName;
        if(pc==='6'){
            const arr=[...this.bkYxPlan];
            // const arr2=[];
            // for(let i=0;i<arr.length;i++){
            //     if(arr[i].zyz){
            //         let strs="";
            //         for(let j=0;j<6;j++){
            //             if(arr[i]["zy"+(j+1)]){
            //                 if(j===5){
            //                     strs+=arr[i]["zy"+(j+1)];
            //                 }else{
            //                     strs+=arr[i]["zy"+(j+1)]+"_";
            //                 }
            //             }
            //         }
            //         const status=arr[i].checked?1:0;
            //         arr2.push(arr[i].sb+"-"+arr[i].yxmc+"-"+
            //         arr[i].yxxh+"-"+pc+"-"+arr[i].zyz+"-"+arr[i].sffc+"-"+strs+"-"+status);
            //     }
            // }
            // obj.param=arr2.join(",");
            const bkList=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].zyz){
                    let mnzybid=null;
                    if(this.planObj.id){
                        mnzybid=this.planObj.id;
                    }
                    bkList.push({
                        id:null,
                        mnzybid:mnzybid,
                        sb:arr[i].sb,
                        sffc:arr[i].sffc,
                        xh:(i+1),
                        yxmc:arr[i].yxmc,
                        yxxh:arr[i].yxxh,
                        zydh1:arr[i].zy1,
                        zydh2:arr[i].zy2,
                        zydh3:arr[i].zy3,
                        zydh4:arr[i].zy4,
                        zydh5:arr[i].zy5,
                        zydh6:arr[i].zy6,
                        zyz:arr[i].zyz
                    });
                }
            }
            obj.list=bkList;
        }else{
            const arr=[...this.zkYxPlan];
            // const arr2=[];
            // for(let i=0;i<arr.length;i++){
            //     if(arr[i].zyz){
            //         let strs="";
            //         for(let j=0;j<6;j++){
            //             if(arr[i]["zy"+(j+1)]){
            //                 if(j===5){
            //                     strs+=arr[i]["zy"+(j+1)];
            //                 }else{
            //                     strs+=arr[i]["zy"+(j+1)]+"_";
            //                 }
            //             }
            //         }
            //         const status=arr[i].checked?1:0;
            //         arr2.push(arr[i].sb+"-"+arr[i].yxmc+"-"+
            //         arr[i].yxxh+"-"+pc+"-"+arr[i].zyz+"-"+arr[i].sffc+"-"+strs+"-"+status);
            //     }
            // }
            // obj.param=arr2.join(",");
            const zkList=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].zyz){
                    let mnzybid=null;
                    if(this.planObj.id){
                        mnzybid=this.planObj.id;
                    }
                    zkList.push({
                        id:null,
                        mnzybid:mnzybid,
                        sb:arr[i].sb,
                        sffc:arr[i].sffc,
                        xh:(i+1),
                        yxmc:arr[i].yxmc,
                        yxxh:arr[i].yxxh,
                        zydh1:arr[i].zy1,
                        zydh2:arr[i].zy2,
                        zydh3:arr[i].zy3,
                        zydh4:arr[i].zy4,
                        zydh5:arr[i].zy5,
                        zydh6:arr[i].zy6,
                        zyz:arr[i].zyz
                    });
                }
            }
            obj.list=zkList;
        }
        obj.pc=this.activeName;
        if(this.pgPlan===''){
            this.$message({message:'请输入评估的方案名',type:'warning',showClose:true});
            return;
        }else{
            obj.plan=this.pgPlan;
            obj.id=null;
        }
        obj.result=null;
        obj.school=!this.userInfo.school?null:this.userInfo.school;
        obj.shu=!this.userInfo.sxcj?null:this.userInfo.sxcj+"";
        obj.sjhm=this.userInfo.sjhm;
        obj.sssf=this.userInfo.sssf;
        obj.status=0;
        obj.sxkmfs=!this.userInfo.sxkmcj?null:this.userInfo.sxkmcj+"";
        obj.updatetime="";
        obj.user=this.userInfo.xm;
        obj.wai=!this.userInfo.wycj?null:this.userInfo.wycj+"";
        obj.xb=this.userInfo.xb+"";
        obj.yhid=this.userInfo.yhid;
        obj.yu=!this.userInfo.ywcj?null:this.userInfo.ywcj+"";
        obj.zxkma=this.userInfo.zxkma+"";
        obj.zxkmafs=!this.userInfo.zxkmacj?null:this.userInfo.zxkmacj+"";
        obj.zxkmb=this.userInfo.zxkmb+"";
        obj.zxkmbfs=!this.userInfo.zxkmbcj?null:this.userInfo.zxkmbcj+"";
        this.dialogProVisble=true;
        const obj0={};
        obj0.role=99;
        obj0.type='zypg';
        const res0=await this.$http.postHttp('/enumRole/getFlag',obj0);
        if(res0.data.code===200){
            if(res0.data.obj.length>0){
                const flag=res0.data.obj[0].flag;
                if(flag!==1){
                    this.$message({message:'此功能暂不开放',showClose:true});
                    this.dialogProVisble=false;
                    this.dialogPgPlanZyVisible=false;
                    return;
                }
            }
        }
        const res=await this.$http.postHttp("/zypg/zypgSubmit",obj);
        if(res.data.code===200){
            this.dialogProVisble=false;
            this.$message({message:"请到“我的方案”查看评估结果",type:"success",showClose:true});
            this.dialogPgPlanZyVisible=false;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        setTimeout(() => {
            this.$router.push('/zypg?reload=true&&toLrzyfa=true&&time='+new Date().getTime());
            window.location.reload();
        }, 3000);
    },
    // 评估一次代码
    async zypgOne(){
        const obj0={};
        obj0.role=99;
        obj0.type='zypg';
        const res0=await this.$http.postHttp('/enumRole/getFlag',obj0);
        if(res0.data.code===200){
            if(res0.data.obj.length>0){
                const flag=res0.data.obj[0].flag;
                if(flag!==1){
                    this.$message({message:'此功能暂不开放',showClose:true});
                    return;
                }
            }
        }
        this.yfPg=true;
        // await this.getDdxx();
        await this.getPayDdxx();
        await this.getCpxx(14);
        if(this.yfPay){
            this.dialogBuyVisible=false;
        }else{
            this.dialogBuyVisible=true;
        }
    },
    // 评估n次代码
    async foroverZypg(){
        const obj0={};
        obj0.role=99;
        obj0.type='zypg';
        const res0=await this.$http.postHttp('/enumRole/getFlag',obj0);
        if(res0.data.code===200){
            if(res0.data.obj.length>0){
                const flag=res0.data.obj[0].flag;
                if(flag!==1){
                    this.$message({message:'此功能暂不开放',showClose:true});
                    this.dialogProVisble=false;
                    this.dialogPgPlanZyVisible=false;
                    return;
                }
            }
        }
        this.yfPg=true;
        // await this.getDdxx();
        await this.getPayDdxx();
        await this.getCpxx(15);
        if(this.yfPay){
            this.dialogBuyVisible=false;
        }else{
            this.dialogBuyVisible=true;
        }
    },
    // 下载一次
    async yhDownload(){
        // await this.getDdxx();
        await this.getCpxx(10);
        this.yfPg=false;
        this.dialogBuyVisible=true;
    },
    // 永久下载
    async yjDownload(){
        // await this.getDdxx();
        await this.getCpxx(11);
        this.yfPg=false;
        this.dialogBuyVisible=true;
    },
    // 咨询师免费下载
    async freeDownExcel(){
        const pc=this.activeName;
        if(pc==='6'){
            const Yxs=[...this.bkYxPlan];
            const downParams=[];
            const obj0={};
                obj0.sssf=this.userInfo.sssf+"";
                obj0.sxkm=this.userInfo.sxkm+"";
                obj0.zxkma=this.userInfo.zxkma+"";
                obj0.zxkmb=this.userInfo.zxkmb+"";
                obj0.sxkmfs=!this.userInfo.sxkmcj?'':this.userInfo.sxkmcj+"";
                obj0.zxkmafs=!this.userInfo.zxkmacj?'':this.userInfo.zxkmacj+"";
                obj0.zxkmbfs=!this.userInfo.zxkmbcj?'':this.userInfo.zxkmbcj+"";
                obj0.xm=!this.inputObj.xm?'':this.inputObj.xm+"";
                obj0.school=this.inputObj.school+"";
                obj0.gkzf=this.userInfo.gkfs+"";
                obj0.yu=!this.userInfo.ywcj?'':this.userInfo.ywcj+"";
                obj0.shu=!this.userInfo.sxcj?'':this.userInfo.sxcj+"";
                obj0.wai=!this.userInfo.wycj?'':this.userInfo.wycj+"";
                obj0.bz=this.userInfo.bz+"";
                obj0.jf=this.userInfo.yfjf+"";
                obj0.sjhm=this.userInfo.sjhm+"";
                obj0.gjzxzg=this.userInfo.gjzxzg+"";
                obj0.dfzxzg=this.userInfo.dfzxzg+"";
                let gsh=this.userInfo.sjhm+"";
                const yhlb=this.userInfo.yhlb;
                if(yhlb===7||yhlb===9){
                    gsh=this.userInfo.sjhm;
                }
                obj0.ghs=gsh;
                obj0.xb=this.userInfo.xb+"";
                // obj0.pc=pc==='6'?'本科批':'专科批';
                obj0.pc=pc;
                for(let i=0;i<Yxs.length;i++){
                    if(Yxs[i].zyz){
                        const obj={};
                        obj.sb=Yxs[i].sb;
                        obj.sffc=Yxs[i].sffc===1||Yxs[i].sffc==='1'?'服从':'不服从';
                        obj.xh=(i+1);
                        obj.yxmc=Yxs[i].yxmc;
                        obj.yxxh=Yxs[i].yxxh;
                        obj.zyz=Yxs[i].zyz;
                        obj.zy1=!Yxs[i].zy1?'':Yxs[i].zy1;
                        obj.zy2=!Yxs[i].zy2?'':Yxs[i].zy2;
                        obj.zy3=!Yxs[i].zy3?'':Yxs[i].zy3;
                        obj.zy4=!Yxs[i].zy4?'':Yxs[i].zy4;
                        obj.zy5=!Yxs[i].zy5?'':Yxs[i].zy5;
                        obj.zy6=!Yxs[i].zy6?'':Yxs[i].zy6;
                        downParams.push(obj);
                    }
                }
                obj0.list=downParams;
                const res=await this.$http.postHttp("/down/downMnzyb",obj0,{responseType:'blob'});
                if(res.status===200){
                    if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                    }else if(res.data.code===500){
                        if(res.data.message){
                            this.$message({message:res.data.message,type:'error'});
                        }
                    }else{
                        const blob=new Blob([res.data],{type:'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) { // 兼容IE10
                    navigator.msSaveBlob(blob, this.filename);
                    }else{
                        const href=URL.createObjectURL(blob);
                        const a=document.createElement("a");
                        a.style.display="none";
                        a.href=href;
                        a.download=this.filename;
                        a.click();
                        URL.revokeObjectURL(a.href);
                    }
                    }
                }else if(res.status===401){
                    this.$message({message: '登录超时，请重新登录',type:"error"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
        }else{
            const Yxs=[...this.zkYxPlan];
            const downParams=[];
            const obj0={};
            obj0.sssf=this.userInfo.sssf+"";
                obj0.sxkm=this.userInfo.sxkm+"";
                obj0.zxkma=this.userInfo.zxkma+"";
                obj0.zxkmb=this.userInfo.zxkmb+"";
                obj0.sxkmfs=!this.userInfo.sxkmcj?'':this.userInfo.sxkmcj+"";
                obj0.zxkmafs=!this.userInfo.zxkmacj?'':this.userInfo.zxkmacj+"";
                obj0.zxkmbfs=!this.userInfo.zxkmbcj?'':this.userInfo.zxkmbcj+"";
                obj0.xm=!this.inputObj.xm?'':this.inputObj.xm+"";
                obj0.school=this.inputObj.school+"";
                obj0.gkzf=this.userInfo.gkfs+"";
                obj0.yu=!this.userInfo.ywcj?'':this.userInfo.ywcj+"";
                obj0.shu=!this.userInfo.sxcj?'':this.userInfo.sxcj+"";
                obj0.wai=!this.userInfo.wycj?'':this.userInfo.wycj+"";
                obj0.bz=this.userInfo.bz+"";
                obj0.jf=this.userInfo.yfjf+"";
                obj0.sjhm=this.userInfo.sjhm+"";
                obj0.gjzxzg=this.userInfo.gjzxzg+"";
                obj0.dfzxzg=this.userInfo.dfzxzg+"";
                let gsh=this.userInfo.sjhm+"";
                const yhlb=this.userInfo.yhlb;
                if(yhlb===7||yhlb===9){
                    gsh=this.userInfo.sjhm;
                }
                obj0.ghs=gsh;
                obj0.xb=this.userInfo.xb+"";
                // obj0.pc=pc==='6'?'本科批':'专科批';
                obj0.pc=pc;
            for(let i=0;i<Yxs.length;i++){
                if(Yxs[i].zyz){
                    const obj={};
                    obj.sb=Yxs[i].sb;
                    obj.sffc=Yxs[i].sffc===1||Yxs[i].sffc==='1'?'服从':'不服从';
                    obj.xh=(i+1);
                    obj.yxmc=Yxs[i].yxmc;
                    obj.yxxh=Yxs[i].yxxh;
                    obj.zyz=Yxs[i].zyz;
                    obj.zy1=!Yxs[i].zy1?'':Yxs[i].zy1;
                    obj.zy2=!Yxs[i].zy2?'':Yxs[i].zy2;
                    obj.zy3=!Yxs[i].zy3?'':Yxs[i].zy3;
                    obj.zy4=!Yxs[i].zy4?'':Yxs[i].zy4;
                    obj.zy5=!Yxs[i].zy5?'':Yxs[i].zy5;
                    obj.zy6=!Yxs[i].zy6?'':Yxs[i].zy6;
                    downParams.push(obj);
                }
            }
                obj0.list=downParams;
                const res=await this.$http.postHttp("/down/downMnzyb",obj0,{responseType:'blob'});
                if(res.status===200){
                    if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                    }else if(res.data.code===500){
                        if(res.data.message){
                            this.$message({message:res.data.message,type:'error'});
                        }
                    }else{
                        const blob=new Blob([res.data],{type:'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) { // 兼容IE10
                    navigator.msSaveBlob(blob, this.filename);
                    }else{
                        const href=URL.createObjectURL(blob);
                        const a=document.createElement("a");
                        a.style.display="none";
                        a.href=href;
                        a.download=this.filename;
                        a.click();
                        URL.revokeObjectURL(a.href);
                    }
                    }
                }else if(res.status===401){
                    this.$message({message: '登录超时，请重新登录',type:"error"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
        }
    },
    // 查询是否有支付订单
    async getPayDdxx(){
        const res=await this.$http.getHttp("/ddxx/getDdxxByYhid");
        if(res.data.code===200){
            if(res.data.obj===1){
                this.dialogZkMsgVisible=true;
                this.yfPay=true;
            }
        }
    },
    // 确定使用折扣
    async confirmZk(){
        this.payFlag=true;
        this.dialogZkMsgVisible=false;
        await this.getCpxx();
        this.dialogBuyVisible=true;
    },
    cancelZk(){
        this.payFlag=false;
        this.dialogBuyVisible=true;
        this.dialogZkMsgVisible=false;
    },
    // 关闭二维码弹窗
    closeDialog(){
        this.dialogBuyVisible=false;
        this.innerdialogVisible=false;
        clearInterval(this.intervalQrcodeTime);
    },
    // 获取产品信息
    async getCpxx(cpid){
        const payFlag=this.payFlag;
        if(payFlag){
            const res=await this.$http.getHttp("/cpxx/getCpxxById?cpid=14");
            if(res.data.code===200){
                const res2=await this.$http.getHttp("/cpxx/getCpxxById?cpid=15");
                if(res2.data.code===200){
                    this.buyInfo.payPrice=parseFloat((res2.data.obj.xsjg-res.data.obj.xsjg).toFixed(2));
                    this.buyInfo.type=res2.data.obj.id+"";
                    this.buyInfo.cpmc=res2.data.obj.cpmc;
                    this.buyInfo.wxPrice=res2.data.obj.wxjg;
                    this.buyInfo.sssf=this.userInfo.sssf+"";
                }else if(res2.data.code===500){
                if(res2.data.message){
                    this.$message({message:res2.data.message,type:'error'});
                }
                }else if(res2.status===401){
                    this.$message({message: '登录超时，请重新登录',type:"error"});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res2.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res2.status===409||res2.status===410||res2.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const res=await this.$http.getHttp("/cpxx/getCpxxById?cpid="+cpid);
            if(res.data.code===200){
                this.buyInfo.type=res.data.obj.id+"";
                this.buyInfo.payPrice=res.data.obj.xsjg;
                this.buyInfo.cpmc=res.data.obj.cpmc;
                this.buyInfo.wxPrice=res.data.obj.wxjg;
                this.buyInfo.sssf=this.userInfo.sssf+"";
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 志愿评估表单提交
    async payZypg(){
        const obj={};
        obj.code="";
        obj.cpid=this.buyInfo.type;
        obj.ddh="";
        obj.payType=this.buyInfo.payType;
        obj.sf=this.buyInfo.sssf;
        obj.sjhm="";
        obj.sum=this.buyInfo.num;
        obj.codeid="";
        obj.ddje=this.buyInfo.payPrice;
        const res=await this.$http.postHttp("/ddxx/ddSubmit",obj);
        if(res.data.code===200){
            if(this.buyInfo.payType===1){
            // 支付宝支付
            const ddh=res.data.obj.orderId;
            const res2=await this.$http.getHttp("/pay/aliPay?ddh="+ddh);
            localStorage.setItem("aipayDdh",ddh);
            const routerData = this.$router.resolve({
            path: "/payGateWay",
            query: { htmlData: res2.data }
          });
        this.dialogBuyVisible=false;
        await this.getDdxx(1);
        window.open(routerData.href,"_blank");
            }else {
          // 2.微信支付
          this.innerTitle = `微信支付-￥${this.buyInfo.payPrice}`;
          this.innerdialogVisible = true;
          this.dialogBuyVisible = false;
          this.wxDdh=res.data.obj.orderId;
          const obj1={};
          obj1.orderId=res.data.obj.orderId;
          obj1.productName=this.buyInfo.cpmc;
          obj1.orderAmount=this.buyInfo.payPrice;
          obj1.sjhm=this.userInfo.sjhm;
          const result = await this.$http.postHttp("/pay/wxPay",obj1);
            if(result.data.code===200){
                // this.wxDdh=result.data.obj.out_trade_no;
                this.$nextTick(()=>{
                    this.$refs.qrCodeDiv.innerHTML = "";
                    const qrcode = new QRCode("qrcode", {
                        width: 132,
                        height: 132,
                        text: result.data.obj.urlCode, // 二维码地址
                        colorDark: "#000",
                        colorLight: "#fff"
                    });
                    console.log(qrcode);
                });
                this.qrcodeTime=60;
                this.intervalQrcodeTime=setInterval(async () => {
                    this.qrcodeTime--;
                    if (this.qrcodeTime === 0) {
                    clearInterval(this.intervalQrcodeTime);
                    this.innerdialogVisible = false;
                    }
                    await this.getDdxx(2);
                    if(this.wxSuccess){
                        this.innerdialogVisible=false;
                        this.qrcodeTime=0;
                        if(this.yfPg){
                            await this.submitZypg();
                        }else{
                            await this.freeDownExcel();
                        }
                        this.wxSuccess=false;
                        clearInterval(this.intervalQrcodeTime);
                    }
                }, 1000);
            }
        }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 关闭支付弹窗
    closePayDialog(){
        this.yfPay=false;
        this.payFlag=false;
    },
    // 切换本科和专科
    async handleClick(tab,event){
        const plan=this.$route.query.plan;
        const pc=this.$route.query.pc;
        const id=this.$route.query.id;
        const isto=this.$route.query.isto;
        if(plan&&pc){
            await this.getPlanContent(plan,pc,id);
        }
        if(id&&isto){
            if(plan){
            }else{
                await this.getYxzyzById(id,pc);
            }
        }
        // if(!plan&&!pc&&!id&&!isto){
        //     await this.getCollectYx();
        // }
    },
    // 获取订单信息
    async getDdxx(type=1){
        if(type===1){
            this.zfbTime=90;
            this.ddTimer=setInterval(async () => {
                this.zfbTime--;
                const ddh=localStorage.getItem("aipayDdh");
                if(ddh){
                    const res=await this.$http.getHttp("/ddxx/getDdxxByDdh?ddh="+ddh);
                    if(res.data.code===200){
                        const zt=res.data.obj.ddzt;
                        if(zt===2){
                        if(this.yfPg){
                            await this.submitZypg();
                        }else{
                            await this.freeDownExcel();
                        }
                        localStorage.removeItem("aipayDdh");
                        clearInterval(this.ddTimer);
                        }
                    }else if(res.data.code===500){
                        if(res.data.message){
                            this.$message({message:res.data.message,type:'error'});
                        }
                    }else if(res.status===401){
                        clearInterval(this.ddTimer);
                        this.$message({message: '登录超时，请重新登录',type:"error"});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }else if(res.data.code===0){
                        clearInterval(this.ddTimer);
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }else if(res.status===409||res.status===410||res.status===411){
                        clearInterval(this.ddTimer);
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }
                }
                if(this.zfbTime===0){
                    clearInterval(this.ddTimer);
                    this.zfbTime=0;
                    localStorage.removeItem("aipayDdh");
                }
        }, 1000);
        }else{
            const ddh=this.wxDdh;
            if(ddh){
                const res=await this.$http.getHttp("/ddxx/getDdxxByDdh?ddh="+ddh);
                if(res.data.code===200){
                            const zt=res.data.obj.ddzt;
                            if(zt===2){
                                this.wxSuccess=true;
                            }
                }else if(res.data.code===500){
                            if(res.data.message){
                                this.$message({message:res.data.message,type:'error'});
                            }
                }else if(res.status===401){
                            this.$message({message: '登录超时，请重新登录',type:"error"});
                            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                            this.$router.replace({
                                path: '/home',
                                query: {redirect: this.$router.currentRoute.fullPath}
                            });
                }else if(res.data.code===0){
                            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                            this.$router.replace({
                                path: '/home',
                                query: {redirect: this.$router.currentRoute.fullPath}
                            });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }
        }
    },
    // 删除院校专业组方案
    async deleteYxzyz(id){
        const res=await this.$http.getHttp("/yhgzfa/delFaById?id="+id);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"操作成功",type:'success',showClose:true});
            }
            await this.openMyZyzList();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 打开选择专业的弹窗
    async chooseZy(obj){
        this.searchYxxh=obj.yxxh;
        this.searchZyz=obj.zyz;
        this.searchIndex=obj.index;
        if(obj.yxxh&&obj.zyz){
            this.zyData=[];
            this.selectedZys=[];
            const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+obj.yxxh+"&zyz="+obj.zyz);
            if(res.data.code===200){
                const data=res.data.obj;
                const zys=[];
                for(let i=0;i<data.length;i++){
                    if(data[i].zydh){
                        zys.push({
                        key:data[i].zydh,
                        label:data[i].zydh+' '+data[i].zymc,
                        disabled:false
                    });
                    }
                }
                this.zyData=zys;
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            this.zyData=[];
        }
        this.dialogZyVisible=true;
    },
    // 预览专业
    async watchZy(obj){
        const pc=this.activeName;
        if(pc==='6'){
            const index=obj.index-1;
            const Yxs=[...this.bkYxPlan];
            const zymcs=Yxs[index].selectedZymcs;
            const zy1=Yxs[index].zy1;
            const zy2=Yxs[index].zy2;
            const zy3=Yxs[index].zy3;
            const zy4=Yxs[index].zy4;
            const zy5=Yxs[index].zy5;
            const zy6=Yxs[index].zy6;
            if(zymcs&&zymcs.length>0){
                this.selectedZymcs=zymcs;
            }else if(zy1||zy2||zy3||zy4||zy5||zy6){
                const zys=[];
                for(let i=0;i<6;i++){
                    const zy=Yxs[index]['zy'+(i+1)];
                    if(zy){
                        zys.push(zy);
                    }
                }
                const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+obj.yxxh+"&zyz="+obj.zyz);
                if(res.data.code===200){
                    const data=res.data.obj;
                    const zymcs=[];
                    for(let i=0;i<zys.length;i++){
                        for(let j=0;j<data.length;j++){
                            if(data[j].zydh===zys[i]){
                                zymcs.push(data[j].zymc);
                            }
                        }
                    }
                    Yxs[index].selectedZymcs=zymcs;
                    this.bkYxPlan=Yxs;
                    this.selectedZymcs=zymcs;
                }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
                }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
                }else if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }else{
                this.selectedZymcs=[];
            }
        }else{
            const index=obj.index-1;
            const Yxs=[...this.zkYxPlan];
            const zymcs=Yxs[index].selectedZymcs;
            const zy1=Yxs[index].zy1;
            const zy2=Yxs[index].zy2;
            const zy3=Yxs[index].zy3;
            const zy4=Yxs[index].zy4;
            const zy5=Yxs[index].zy5;
            const zy6=Yxs[index].zy6;
            if(zymcs&&zymcs.length>0){
                this.selectedZymcs=zymcs;
            }else if(zy1||zy2||zy3||zy4||zy5||zy6){
                const zys=[];
                for(let i=0;i<6;i++){
                    const zy=Yxs[index]['zy'+(i+1)];
                    if(zy){
                        zys.push(zy);
                    }
                }
                const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+obj.yxxh+"&zyz="+obj.zyz);
                if(res.data.code===200){
                    const data=res.data.obj;
                    const zymcs=[];
                    for(let i=0;i<zys.length;i++){
                        for(let j=0;j<data.length;j++){
                            if(data[j].zydh===zys[i]){
                                zymcs.push(data[j].zymc);
                            }
                        }
                    }
                    Yxs[index].selectedZymcs=zymcs;
                    this.zkYxPlan=Yxs;
                    this.selectedZymcs=zymcs;
                }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
                }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
                }else if(res.data.code===0){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                    await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                    this.$router.replace({
                        path: '/home',
                        query: {redirect: this.$router.currentRoute.fullPath}
                    });
                }
            }else{
                this.selectedZymcs=[];
            }
        }
        this.dialogWatchZyVisible=true;
    },
    // 保存选择的专业
    async saveCheckedZy(){
        const pc=this.activeName;
        if(this.selectedZys.length===0){
            this.$message({message:'请选择好专业',type:'warning',showClose:true});
            return;
        }
        if(pc==='6'){
            const Yxs=[...this.bkYxPlan];
            const index=this.searchIndex-1;
            const zys=this.selectedZys;
            // const yxxh=this.searchYxxh;
            // const zyz=this.searchZyz;
            for(let i=1;i<7;i++){
                Yxs[index]['zy'+i]='';
            }
            for(let i=0;i<zys.length;i++){
                if(zys[i]&&i<=5){
                    Yxs[index]['zy'+(i+1)]=zys[i];
                }
            }
            this.bkYxPlan=Yxs;
            // this.updateBk=!this.updateBk;
            // const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+yxxh+"&zyz="+zyz);
            // if(res.data.code===200){
            //     const data=res.data.obj;
            //     const zymcs=[];
            //     for(let i=0;i<zys.length;i++){
            //         for(let j=0;j<data.length;j++){
            //             if(data[j].zydh===zys[i]&&i<=5){
            //                 zymcs.push(data[j].zymc);
            //             }
            //         }
            //     }
            //     Yxs[index].selectedZymcs=zymcs;
            //     this.bkYxPlan=Yxs;
            //     this.updateBk=!this.updateBk;
            // }else if(res.data.code===500){
            // if(res.data.message){
            //     this.$message({message:res.data.message,type:'error'});
            // }
            // }else if(res.status===401){
            // this.$message({message: '登录超时，请重新登录',type:"error"});
            // await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            // this.$router.replace({
            //     path: '/home',
            //     query: {redirect: this.$router.currentRoute.fullPath}
            // });
            // }else if(res.data.code===0){
            //     this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            //     await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            //     this.$router.replace({
            //         path: '/home',
            //         query: {redirect: this.$router.currentRoute.fullPath}
            //     });
            // }
        }else{
            const Yxs=[...this.zkYxPlan];
            const index=this.searchIndex-1;
            const zys=this.selectedZys;
            // const yxxh=this.searchYxxh;
            // const zyz=this.searchZyz;
            for(let i=1;i<7;i++){
                Yxs[index]['zy'+i]='';
            }
            for(let i=0;i<zys.length;i++){
                if(zys[i]&&i<=5){
                    Yxs[index]['zy'+(i+1)]=zys[i];
                }
            }
            this.zkYxPlan=Yxs;
            // this.updateZk=!this.updateZk;
            // const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+yxxh+"&zyz="+zyz);
            // if(res.data.code===200){
            //     const data=res.data.obj;
            //     const zymcs=[];
            //     for(let i=0;i<zys.length;i++){
            //         for(let j=0;j<data.length;j++){
            //             if(data[j].zydh===zys[i]&&i<=5){
            //                 zymcs.push(data[j].zymc);
            //             }
            //         }
            //     }
            //     Yxs[index].selectedZymcs=zymcs;
            //     this.zkYxPlan=Yxs;
            //     this.updateZk=!this.updateZk;
            // }else if(res.data.code===500){
            // if(res.data.message){
            //     this.$message({message:res.data.message,type:'error'});
            // }
            // }else if(res.status===401){
            // this.$message({message: '登录超时，请重新登录',type:"error"});
            // await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            // this.$router.replace({
            //     path: '/home',
            //     query: {redirect: this.$router.currentRoute.fullPath}
            // });
            // }else if(res.data.code===0){
            //     this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            //     await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            //     this.$router.replace({
            //         path: '/home',
            //         query: {redirect: this.$router.currentRoute.fullPath}
            //     });
            // }
        }
        this.dialogZyVisible=false;
    },
    // 关闭专业选择弹窗
    closeZyDialog(){
        this.dialogZyVisible=false;
    },
    // 关闭预览专业弹窗
    closeWatchZy(){
        this.dialogWatchZyVisible=false;
    },
    // 查询用户是否支付了评估n次
    async getYfFreePg(){
        const yhid=this.userInfo.yhid;
        const res=await this.$http.getHttp("/pay/getPayXz?yhid="+yhid+"&&mnzyb=2");
        if(res.data.code===200){
            this.yfFreePg=true;
        }else if(res.data.code===500){
            if(res.data.message){
                if(res.data.message==='不存在永久支付'){
                    return false;
                }else{
                    this.$message({message:res.data.message,type:'error'});
                }
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 查询用户是否支付了永久下载
    async getYfFreeDown(){
        const yhid=this.userInfo.yhid;
        const res=await this.$http.getHttp("/pay/getPayXz?yhid="+yhid+"&&mnzyb=1");
        if(res.data.code===200){
            this.yfFreeDown=true;
        }else if(res.data.code===500){
            if(res.data.message){
                if(res.data.message==='不存在永久支付'){
                    return false;
                }else{
                    this.$message({message:res.data.message,type:'error'});
                }
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
        /**
     * 监听右侧选中
     */
    choose (value) {
      this.item = value;
    },
        /**
     * 右侧数据点击排序
     */
     publicMobileMethod (direction, key) {
      const self = this;
      let index;
      // 判断是否超出一条
      const item = self.item;
      if (item.length === 1 || item.length === 0) {
        self.selectedZys.forEach((val, indexs) => {
          // 获取需移动数据的下标
          if (val === key) {
            index = indexs;
          }
        });
        if (index === 0 && direction !== 'handleBottom' && direction !== 'handleDown') {
          return self.$message('没有上移的空间了');
        }
        if (index === self.selectedZys.length - 1 && direction !== 'handleUp' && direction !== 'handleTop') {
          return self.$message('没有下移的空间了');
        }
        // 改变的数组
        const changeItem = self.selectedZys[index];
        // 根据下标在数组中删除该数据
        self.selectedZys.splice(index, 1);
        // 判断加入数组位置
        if (direction) {
          // 置顶
          direction === 'handleTop' && self.selectedZys.unshift(changeItem);
          // 置底
          direction === 'handleBottom' && self.selectedZys.push(changeItem);
          // 上移
          direction === 'handleUp' && self.selectedZys.splice(index - 1, 0, changeItem);
          // 下移
          direction === 'handleDown' && self.selectedZys.splice(index + 1, 0, changeItem);
        }
      } else {
        self.$message.error('只能选择一条数据进行上下移动');
      }
    },
    // 上移
    moveUp(){
        const item=[...this.item];
        const selectedZys=[...this.selectedZys];
        const len=item.length;
        if(len>1){
            this.$message({message:'只能移动一个专业',type:'warning',showClose:true});
            return false;
        }
        const zy=item[0];
        let index=-1;
        for(let i=0;i<selectedZys.length;i++){
            if(selectedZys[i]===zy){
                index=i;
            }
        }
        if(index===-1){
            this.$message({message:'请选择某一个专业',type:'warning',showClose:true});
            return false;
        }
        if(index===0){
            return false;
        }
        const obj=selectedZys[index];
        const prevObj=selectedZys[index-1];
        this.$set(this.selectedZys,index,prevObj);
        this.$set(this.selectedZys,index-1,obj);
    },
    // 下移
    moveDown(){
        const item=[...this.item];
        const selectedZys=[...this.selectedZys];
        const len=item.length;
        if(len>1){
            this.$message({message:'只能移动一个专业',type:'warning',showClose:true});
            return false;
        }
        const zy=item[0];
        let index=-1;
        for(let i=0;i<selectedZys.length;i++){
            if(selectedZys[i]===zy){
                index=i;
            }
        }
        if(index===-1){
            this.$message({message:'请选择某一个专业',type:'warning',showClose:true});
            return false;
        }
        if(index===selectedZys.length-1){
            return false;
        }
        const obj=selectedZys[index];
        const nextObj=selectedZys[index+1];
        this.$set(this.selectedZys,index,nextObj);
        this.$set(this.selectedZys,index+1,obj);
    },
    // 跳转到选择专业页面
    toChooseZy(obj){
        const yxxh=obj.yxxh;
        const zyz=obj.zyz;
        // const url=this.$router.resolve({
        //     url:'/chooseZy',
        //     query:{
        //         yxxh,zyz
        //     }
        // });
        // window.open(url.href,'_blank');
        this.$router.push({
            url:'/chooseZy',
            query:{
                yxxh,zyz
            }
        });
    },
    // 把表格数据存在缓存中
    saveTableStorage(){
            localStorage.setItem('pc',(this.activeName+''));
            localStorage.setItem('whBkTableData',JSON.stringify(this.bkYxPlan));
            localStorage.setItem('whZkTableData',JSON.stringify(this.zkYxPlan));
            localStorage.setItem('whPlanObj',JSON.stringify(this.planObj));
    },
    // 选择专业
    chooseDialogZy(value,pos,arr){
        if(pos==='right'&&(value.length>6||arr.length>6)){
            this.$message({message:'只能选择6个专业',type:'warning',showClose:true});
            this.selectedZys=[];
        }else if(pos==='right'){
            this.selectedZys=[...arr];
        }
    },
    leftChoose(leftZyArr,checkedZy){
        const len=leftZyArr.length;
        if(len>0){
            this.$message({
                message:'你已经选中了'+len+'个专业',
                type:'success',
                showClose:true
            });
            if(len===6){
                const zyData=[...this.zyData];
                for(let i=0;i<leftZyArr.length;i++){
                    for(let j=0;j<zyData.length;j++){
                        if(leftZyArr[i]!==zyData[j].key){
                            if(zyData[j].isChecked){
                                zyData[j].disabled=false;
                            }else{
                                zyData[j].disabled=true;
                            }
                        }else{
                            zyData[j].isChecked=true;
                            zyData[j].disabled=false;
                            continue;
                        }
                    }
                }
                this.zyData=zyData;
            }else if(len<6){
                const zyData=[...this.zyData];
                for(let i=0;i<zyData.length;i++){
                    const label=zyData[i].label;
                    if(label){
                        zyData[i].disabled=false;
                        zyData[i].isChecked=undefined;
                    }
                }
                this.zyData=zyData;
            }
        }
    },
    // 获取最后一次保存的方案
    async getLastPlan(){
        // const loading=this.$loading({fullscreen:true,target:document.getElementById('bk-load'),text:'正在加载中'});
        // const loading2=this.$loading({fullscreen:true,target:document.getElementById('zk-load'),text:'正在加载中'});
        const loading=this.$loading({fullscreen:true,target:document.getElementById('out-load-tab'),text:'正在加载中'});
        const res=await this.$http.getHttp("/mnzy/getLastMnzyb");
        if(res.data.code===200){
            if(res.data.obj.mnzyb){
                const list=res.data.obj.mnzyb.list;
                // const param=res.data.obj.mnzyb.param;
                // if(list.length===0&&param&&param.indexOf(',')>=0){
                //     const arr1=param.split(',');
                //     const contentArr=[];
                //     for(let i=0;i<arr1.length;i++){
                //         const arr2=arr1[i].split('-');
                //         const obj={};
                //         obj.sb=arr2[0];
                //         obj.index=(i+1);
                //         obj.yxmc=arr2[1];
                //         obj.yxxh=arr2[2];
                //         obj.pc=arr2[3];
                //         obj.zyz=arr2[4];
                //         obj.sffc=arr2[5];
                //         const zys=arr2[6];
                //         if(zys&&zys!==''){
                //             let arr3=zys.split("_");
                //             arr3=arr3.filter(item=>item!=='');
                //             for(let i=0;i<arr3.length;i++){
                //                 if(arr3[i]){
                //                     obj["zy"+(i+1)]=arr3[i];
                //                 }
                //             }
                //         }
                //         obj.checked=arr2[7]===1;
                //         contentArr.push(obj);
                //     }
                //     const pc=res.data.obj.mnzyb.pc;
                //     if(pc===6){
                //         const nullArr=[];
                //         const nullLen=45-contentArr.length;
                //         for(let i=0;i<nullLen;i++){
                //             const obj={};
                //             obj.checked=false;
                //             obj.sb="";
                //             obj.index=(contentArr.length)+i+1;
                //             obj.yxmc="";
                //             obj.yxmcs=[];
                //             obj.zyzs=[];
                //             obj.zys=[];
                //             obj.pc=6;
                //             obj.zyz="";
                //             obj.zy1="";
                //             obj.zy2="";
                //             obj.zy3="";
                //             obj.zy4="";
                //             obj.zy5="";
                //             obj.zy6="";
                //             obj.sffc="1";
                //             obj.sffcs=[
                //                 {name:"服从",value:"1"},
                //                 {name:"不服从",value:"0"}
                //             ];
                //             nullArr.push(obj);
                //         }
                //         this.bkYxPlan=contentArr.concat(nullArr);
                //     }else{
                //         const nullArr=[];
                //         const nullLen=30-contentArr.length;
                //         for(let i=0;i<nullLen;i++){
                //             const obj={};
                //             obj.checked=false;
                //             obj.sb="";
                //             obj.index=contentArr.length+i+1;
                //             obj.yxmc="";
                //             obj.yxmcs=[];
                //             obj.zyzs=[];
                //             obj.zys=[];
                //             obj.pc=7;
                //             obj.zyz="";
                //             obj.zy1="";
                //             obj.zy2="";
                //             obj.zy3="";
                //             obj.zy4="";
                //             obj.zy5="";
                //             obj.zy6="";
                //             obj.sffc="1";
                //             obj.sffcs=[
                //                 {name:"服从",value:"1"},
                //                 {name:"不服从",value:"0"}
                //             ];
                //             nullArr.push(obj);
                //         }
                //         this.zkYxPlan=contentArr.concat(nullArr);
                //     }
                //     this.planObj.plan=res.data.obj.mnzyb.plan;
                //     this.planObj.time=res.data.obj.mnzyb.updatetime;
                //     this.planObj.id=res.data.obj.mnzyb.id;
                // }
                 if(list.length>0){
                    const pc=res.data.obj.mnzyb.pc;
                    const yxZyzs=[];
                    const data=res.data.obj.mnzyb.list;
                    for(let i=0;i<data.length;i++){
                        yxZyzs.push({
                            index:(i+1),
                            sb:data[i].sb,
                            sffc:(data[i].sffc+''),
                            xh:i+1,
                            checked:data[i].isRed||false,
                            yxmc:data[i].yxmc,
                            yxxh:data[i].yxxh,
                            zyz:data[i].zyz,
                            zy1:data[i].zydh1,
                            zy2:data[i].zydh2,
                            zy3:data[i].zydh3,
                            zy4:data[i].zydh4,
                            zy5:data[i].zydh5,
                            zy6:data[i].zydh6
                        });
                    }
                    if(pc===6){
                        const nullArr=[];
                        const nullLen=45-data.length;
                        for(let i=0;i<nullLen;i++){
                                const obj={};
                                obj.checked=false;
                                obj.sb="";
                                obj.index=data.length+i+1;
                                obj.yxmc="";
                                obj.yxmcs=[];
                                obj.zyzs=[];
                                obj.zys=[];
                                obj.pc=6;
                                obj.zyz="";
                                obj.zy1="";
                                obj.zy2="";
                                obj.zy3="";
                                obj.zy4="";
                                obj.zy5="";
                                obj.zy6="";
                                obj.sffc="1";
                                obj.sffcs=[
                                    {name:"服从",value:"1"},
                                    {name:"不服从",value:"0"}
                                ];
                                nullArr.push(obj);
                        }
                        this.bkYxPlan=yxZyzs.concat(nullArr);
                        // for(let i=0;i<30;i++){
                        //     const obj={};
                        //     obj.checked=false;
                        //     obj.sb="";
                        //     obj.index=i+1;
                        //     obj.pc=7;
                        //     obj.yxmc="";
                        //     obj.yxmcs=[];
                        //     obj.zyzs=[];
                        //     obj.zys=[];
                        //     obj.zyz="";
                        //     obj.zy1="";
                        //     obj.zy2="";
                        //     obj.zy3="";
                        //     obj.zy4="";
                        //     obj.zy5="";
                        //     obj.zy6="";
                        //     obj.sffc="1";
                        //     obj.sffcs=[
                        //         {name:"服从",value:"1"},
                        //         {name:"不服从",value:"0"}
                        //     ];
                        //     this.zkYxPlan.push(obj);
                        // };
                    }else{
                        const nullArr=[];
                        const nullLen=30-data.length;
                        for(let i=0;i<nullLen;i++){
                                const obj={};
                                obj.checked=false;
                                obj.sb="";
                                obj.index=data.length+i+1;
                                obj.yxmc="";
                                obj.yxmcs=[];
                                obj.zyzs=[];
                                obj.zys=[];
                                obj.pc=6;
                                obj.zyz="";
                                obj.zy1="";
                                obj.zy2="";
                                obj.zy3="";
                                obj.zy4="";
                                obj.zy5="";
                                obj.zy6="";
                                obj.sffc="1";
                                obj.sffcs=[
                                    {name:"服从",value:"1"},
                                    {name:"不服从",value:"0"}
                                ];
                                nullArr.push(obj);
                        }
                        this.zkYxPlan=yxZyzs.concat(nullArr);
                    }
                    this.planObj.plan=res.data.obj.mnzyb.plan;
                    this.planObj.time=res.data.obj.mnzyb.updatetime;
                    this.planObj.id=res.data.obj.mnzyb.id;
                 }else{
                    this.bkYxPlan=[];
                    this.zkYxPlan=[];
                    this.createNullData();
                 }
            }else if(res.data.obj.bkList&&res.data.obj.zkList){
                const data=[...res.data.obj.bkList];
                for(let i=0;i<data.length;i++){
                    data[i].index=(i+1);
                    data[i].checked=false;
                    data[i].sffc='1';
                }
                const len=45-data.length;
                const nullArr=[];
                for(let i=0;i<len;i++){
                        const obj={};
                        obj.index=data.length+(i+1);
                        obj.checked=false;
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.pc=6;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                        nullArr.push(obj);
                }
                this.bkYxPlan=data.concat(nullArr);
                const data2=[...res.data.obj.zkList];
                for(let i=0;i<data2.length;i++){
                    data2[i].index=(i+1);
                    data2[i].checked=false;
                    data2[i].sffc='1';
                }
                const len2=30-data2.length;
                const nullArr2=[];
                for(let i=0;i<len2;i++){
                    const obj={};
                        obj.index=data2.length+(i+1);
                        obj.checked=false;
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.pc=7;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                        nullArr2.push(obj);
                }
                this.zkYxPlan=data2.concat(nullArr2);
            }else if(res.data.obj.yxzyzfa){
                const pc=res.data.obj.yxzyzfa.pc;
                this.planObj.plan=res.data.obj.yxzyzfa.plan;
                this.planObj.time=res.data.obj.yxzyzfa.date;
                // this.planObj.id=res.data.obj.yxzyzfa.id;
                if(pc===6||pc==='6'){
                    const bkArr=[...res.data.obj.yxzyzfa.list];
                    for(let i=0;i<bkArr.length;i++){
                        bkArr[i].index=i+1;
                        bkArr[i].checked=false;
                        bkArr[i].sffc='1';
                        bkArr[i].zy1='';
                        bkArr[i].zy2='';
                        bkArr[i].zy3='';
                        bkArr[i].zy4='';
                        bkArr[i].zy5='';
                        bkArr[i].zy6='';
                    }
                    const len=45-bkArr.length;
                    const nullArr=[];
                    for(let i=0;i<len;i++){
                        const obj={};
                        obj.index=bkArr.length+(i+1);
                        obj.checked=false;
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.pc=6;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                        nullArr.push(obj);
                    }
                    this.bkYxPlan=bkArr.concat(nullArr);
                }else{
                    const zkArr=[...res.data.obj.yxzyzfa.list];
                    for(let i=0;i<zkArr.length;i++){
                        zkArr[i].index=i+1;
                        zkArr[i].checked=false;
                        zkArr[i].sffc='1';
                        zkArr[i].zy1='';
                        zkArr[i].zy2='';
                        zkArr[i].zy3='';
                        zkArr[i].zy4='';
                        zkArr[i].zy5='';
                        zkArr[i].zy6='';
                    }
                    const len=30-zkArr.length;
                    const nullArr=[];
                    for(let i=0;i<len;i++){
                        const obj={};
                        obj.index=zkArr.length+(i+1);
                        obj.checked=false;
                        obj.sb="";
                        obj.yxmc="";
                        obj.yxmcs=[];
                        obj.zyzs=[];
                        obj.pc=7;
                        obj.zyz="";
                        obj.zy1="";
                        obj.zy2="";
                        obj.zy3="";
                        obj.zy4="";
                        obj.zy5="";
                        obj.zy6="";
                        obj.sffc="1";
                        obj.sffcs=[
                            {name:"服从",value:"1"},
                            {name:"不服从",value:"0"}
                        ];
                        nullArr.push(obj);
                    }
                    this.zkYxPlan=zkArr.concat(nullArr);
                }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        // loading.close();
        // loading2.close();
        loading.close();
    }
},
async mounted(){
    setTimeout(async () => {
    const readStorage=this.$route.query.readStorage;
    if(readStorage){
        // this.inputObj.xm=!this.userInfo.xm?"":this.userInfo.xm;
        // this.inputObj.school=!this.userInfo.xxmc?"":this.userInfo.xxmc;
        // this.inputObj.bz=!this.userInfo.bz?"":this.userInfo.bz;
        // const yhlb=this.userInfo.yhlb;
        // if(yhlb===7||yhlb===9){
        //     this.isUser=false;
        // }else if(yhlb===2){
        //     this.isUser=true;
        // }
        // const loading1=this.$loading({fullScreen:true,target:document.getElementById('bk-load'),text:'正在加载中'});
        // const loading2=this.$loading({fullScreen:true,target:document.getElementById('zk-load'),text:'正在加载中'});
        // await this.getYfFreeDown();
        // await this.getYfFreePg();
        // const bk=localStorage.getItem("whBkTableData");
        // const zk=localStorage.getItem("whZkTableData");
        // const planObj=localStorage.getItem('whPlanObj');
        // if(bk&&zk&&planObj){
        //     this.bkYxPlan=JSON.parse(bk);
        //     this.zkYxPlan=JSON.parse(zk);
        //     this.planObj=JSON.parse(planObj);
        // }
        // loading1.close();
        // loading2.close();
    }else{
    // const loading1=this.$loading({fullScreen:true,target:document.getElementById('bk-load'),text:'正在加载中'});
    // const loading2=this.$loading({fullScreen:true,target:document.getElementById('zk-load'),text:'正在加载中'});
    // const loading=this.$loading({fullScreen:true,target:document.getElementById('load-btn'),text:'正在加载中'});

    const yhlb=this.userInfo.yhlb;
    if(yhlb===7||yhlb===9||yhlb===2){
        this.isUser=false;
    }else if(yhlb===4){
        this.isUser=true;
    }
    this.createNullData();
    if(yhlb===4){
        await this.getYfFreeDown();
        await this.getYfFreePg();
    }
    await this.getLastPlan();
    const plan=this.$route.query.plan;
    const pc=this.$route.query.pc;
    const id=this.$route.query.id;
    const isto=this.$route.query.isto;
    if(plan&&pc){
        this.bkYxPlan=[];
        this.zkYxPlan=[];
        this.createNullData();
        await this.getPlanContent(plan,pc,id);
    }else if(id&&isto){
        if(plan){
        }else{
            this.bkYxPlan=[];
            this.zkYxPlan=[];
            this.createNullData();
             await this.getYxzyzById(id,pc);
        }
    }else{
        const collectInfo=this.collectInfo;
        const collectInfoStr=localStorage.getItem("collectInfo");
        const collectInfo2=collectInfoStr!==null? JSON.parse(collectInfoStr):{};
        const plan=this.planObj.plan;
        const lrzyfaInfo=this.lrzyfaInfo;
        const lrzyfaInfoStr=localStorage.getItem("lrzyfaInfo");
        const lrzyfaInfo2=lrzyfaInfoStr!==null?JSON.parse(lrzyfaInfoStr):{};
        if(plan){
        }else{
            if(lrzyfaInfo.lrzyfaList.length>0){
                const arr=lrzyfaInfo.lrzyfaList;
                const lrzyfaPc=lrzyfaInfo.pc;
                for(let i=0;i<arr.length;i++){
                    arr[i].pc=lrzyfaInfo.pc;
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                    arr[i].sffc="1";
                }
                const nullArr=[];
                const nullLen=45-arr.length;
                for(let i=0;i<nullLen;i++){
                    const obj={};
                    obj.checked=false;
                    obj.index=arr.length+(i+1);
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.zys=[];
                    obj.pc=lrzyfaPc;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                    nullArr.push(obj);
                }
                if(lrzyfaPc===6||lrzyfaPc==="6"){
                    this.bkYxPlan=arr.concat(nullArr);
                }else{
                    this.zkYxPlan=arr.concat(nullArr);
                }
            }else if(lrzyfaInfo2.value&&lrzyfaInfo2.value.lrzyfaList.length>0){
                const arr=lrzyfaInfo2.value.lrzyfaList;
                const lrzyfaPc=lrzyfaInfo2.value.pc;
                for(let i=0;i<arr.length;i++){
                    arr[i].pc=lrzyfaPc;
                    arr[i].index=i+1;
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].sffc="1";
                }
                const nullArr=[];
                const nullLen=45-arr.length;
                for(let i=0;i<nullLen;i++){
                    const obj={};
                    obj.checked=false;
                    obj.index=arr.length+(i+1);
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.zys=[];
                    obj.pc=lrzyfaPc;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                    nullArr.push(obj);
                }
                if(lrzyfaPc==="6"||lrzyfaPc===6){
                    this.bkYxPlan=arr.concat(nullArr);
                }else{
                    this.zkYxPlan=arr.concat(nullArr);
                }
            }else if(collectInfo.collectSchool.length>0){
                const arr=collectInfo.collectSchool;
                const collectPc=collectInfo.pc;
                for(let i=0;i<arr.length;i++){
                    arr[i].pc=collectInfo.pc;
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                    arr[i].sffc="1";
                }
                const nullArr=[];
                const nullLen=45-arr.length;
                for(let i=0;i<nullLen;i++){
                    const obj={};
                    obj.checked=false;
                    obj.index=arr.length+(i+1);
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.zys=[];
                    obj.pc=collectPc;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                    nullArr.push(obj);
                }
                if(collectPc===6||collectPc==="6"){
                    this.bkYxPlan=arr.concat(nullArr);
                }else{
                    this.zkYxPlan=arr.concat(nullArr);
                }
            }else if(collectInfo2.value&&collectInfo2.value.collectSchool.length>0){
                const arr=collectInfo2.value.collectSchool;
                const collectPc=collectInfo2.value.pc;
                for(let i=0;i<arr.length;i++){
                    arr[i].pc=collectPc;
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                    arr[i].sffc="1";
                }
                const nullArr=[];
                const nullLen=45-arr.length;
                for(let i=0;i<nullLen;i++){
                    const obj={};
                    obj.checked=false;
                    obj.index=arr.length+(i+1);
                    obj.sb="";
                    obj.yxmc="";
                    obj.yxmcs=[];
                    obj.zyzs=[];
                    obj.zys=[];
                    obj.pc=collectPc;
                    obj.zyz="";
                    obj.zy1="";
                    obj.zy2="";
                    obj.zy3="";
                    obj.zy4="";
                    obj.zy5="";
                    obj.zy6="";
                    obj.sffc="1";
                    obj.sffcs=[
                        {name:"服从",value:"1"},
                        {name:"不服从",value:"0"}
                    ];
                    nullArr.push(obj);
                }
                if(collectPc===6||collectPc==="6"){
                    this.bkYxPlan=arr.concat(nullArr);
                }else{
                    this.zkYxPlan=arr.concat(nullArr);
                }
            }
        }
    }
    this.inputObj.xm=!this.userInfo.xm?"":this.userInfo.xm;
    this.inputObj.school=!this.userInfo.xxmc?"":this.userInfo.xxmc;
    this.inputObj.bz=!this.userInfo.bz?"":this.userInfo.bz;
    // loading1.close();
    // loading2.close();
    // loading.close();
    }
    }, 1000);
},
computed:{
    ...mapState(["userInfo","collectInfo","lrzyfaInfo"])
}
};
</script>
<style lang="less" scoped>

.inputlist{
    display: inline-block;
    line-height: 30px;
    margin-left: 10px;
    margin-bottom: 10px;
    .input-div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.info{
    margin-bottom: 10px;
    margin-top: 8px;
    // padding: 10px;
    border-radius: 5px;
    // border: 1px solid #a9c1d057;
    // background-color: #a9c1d012;
    border: none;
    width: 1100px;
    background-color: white;
    .myinput{
        display: inline-block;
        width: 96px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #D9DDE3;
        border-radius: 3px;
        transition: all 0.3s;
        padding-left: 5px;
        color: #666;
        outline: none;
    }
}
.lrzyfa-btn{
    width:1200px;
    height: 42px;
    // display: flex;
    // align-items: center;
    // justify-content: flex-start;
    margin:10px 0;
}
.plan-div{
    width:1200px;
    margin:20px 0;
    padding:10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}
.buy-price{
    color: #0000FF;
    font-size: 16px;
    font-weight: 600;
}
.pay-price{
    font-size: 20px;
    font-weight: 600;
    color: #FF0000;
}
.zys-div{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width:400px;
    flex-wrap:wrap;
}
.el-textarea__inner{
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
}
/deep/ .el-transfer__button:first-child{
    margin-bottom:0px;
}
/deep/ .el-transfer__buttons{
    margin:150px 0;
    width:18%;
}
/deep/ .el-transfer-panel {
    border: 1px solid #EBEEF5;
    border-radius: 4px;
    overflow: hidden;
    background: #FFF;
    display: inline-block;
    vertical-align: top;
    // width: 270px;
    width:36%;
    max-height: 100%;
    box-sizing: border-box;
    position: relative;
}
/deep/ .el-transfer-panel__item{
    height:60px;
}
/deep/ .el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
    background-color:#1f4778;
    color:white;
    font-weight:bold;
}
// /deep/ .el-dialog{
//     margin-left:44%;
// }
.weixin-panel{
                width:100%;
                height:300px;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                .qrcode{
                    width:180px;
                    height:180px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    padding:10px;
                    background:url('../../../assets/images/wxcode-bg.png') no-repeat center;
                }
            }
</style>
