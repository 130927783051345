<template>
    <div>
        <div class="inner clearfix" id="wrap">
        <div style="padding:10px 20px;">
                <div style="width:900px;line-height:2;;margin:0 auto">
                    <p class="MsoNormal" align="center" style="margin-left:0cm;text-align:center;">
                        <b>服务协议</b><b></b>
                    </p>
                    <p>
                        尊敬的用户:
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        欢迎使用《金榜题名高考志愿填报辅助决策系统》！
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        请您在使用本系统前仔细阅读本协议，一旦使用本系统则表明您已明知并完全接受本协议项下的全部条款。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>一、 服务协议的确认</b>
                    </p>
                    <!--<p class="MsoNormal" align="left">
                        <b>1.1 </b><b>域名、标识及专有名称</b>
                    </p>-->
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        《金榜题名高考志愿填报辅助决策系统》（以下简称“金榜志愿通“）是由湖南豪之天信息技术有限公司（以下简称”我司“）自主研发的一个高考志愿填报辅助工具，使用人（以下简称“用户”）要注册成功才能使用我司提供的相关信息。
                    </p>
                    <!--<p class="MsoNormal" align="left">
                        <b>1.2 </b><b>版权</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统包含的所有内容（包括但不限于：文本、图形、图片、视像及声音内容、<span>Logo</span>标识，版面设计，专栏目录与名称、内容分类）的所有权归高考志愿信息网所有。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统受《中华人民共和国著作权法》及其它相关法律的保护。任何单位或个人将本系统提供的内容与服务用于商业、盈利、广告性等目的时，需征得相关权利人的书面许可；将本系统提供的内容与服务用于非商业用途时，应遵守著作权法以及其他相关法律规定，不得侵犯所有者及相关权利人的权益。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>1.3 </b><b>版权异议</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        如权利人认为本系统内容中所涉及的文字作品、图片和音视频资料（以下简称“作品”），侵犯其著作权的，请及时提供书面通知，高考志愿信息网将依法删除被指侵权的作品或断开相应的链接；但权利人不能出示有效身份证明、著作权权属证明及侵权情况证明的，视为未提出异议。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>1.4 </b><b>转载</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统摘录或转载的属于第三方的信息将注明具体来源。任何媒体、网站或个人从本系统下载使用，必须保留本系统注明的信息来源，并自行承担版权等法律责任。
                    </p>-->
                    <p class="MsoNormal" align="left">
                        <b>二、个人信息保护</b>
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>2.1 </b><b>根据《中华人民共和国个人信息保护法》，我司深知个人信息对用户重要性，用户向本系统提供的个人信息将可能用于下列用途：</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）核实用户身份，并提供相应服务；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）执行用户指示、回应用户或以该用户名义提出的查询、建议内容；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）通过发送短信或以其他方式，告知用户相关信息；
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>2.2 </b><b>针对用户信息安全，我司会采取各种制度、安全技术和程序等措施来保护用户隐私和个人数据。</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）我司会使用加密技术确保数据的保密性;
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）我司会使用受信赖的保护机制防止数据遭到恶意攻击;
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）我司会部署访问控制机制，确保只有授权人员才可访问用户信息；我司会举办安全和个人信息保护培训课程，加强员工对于保护用户信息重要性的认识。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>2.3 </b><b>本系统不会将用户的个人信息提供给任何第三方，但发生以下事由的除外：</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）按照中华人民共和国的法律、法规、政策等规范性法律文件规定，按照相关政府主管部门的要求本系统提供的；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）请用户理解，在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我司可能会对外共享用户信息
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）根据法律法规和商业惯例，在合并、收购、资产转让等类似交易中，如涉及到用户信息共享或转让，我司会向用户告知接收方的名称或者姓名和联系方式，告知共享或转让用户信息的目的。并要求新的持有用户信息的公司、组织继续受本用户信息保护政策的约束，否则我司将要求该公司、组织重新向用户征求授权同意。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>4</span>）用户授权同意的以及于法律、行政法规允许的其它用途。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>三、知识产权</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        我司提供的信息技术服务包含（包括但不限于）如下具体内容：
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>3.1 </b><b>域名、标识及专有名称</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统所使用的注册域名（http://www.zy1dt.com）、商标（豪之天--金榜志愿通）、GK金榜志愿通微信公众号，GK金榜志愿通小程序等标识及专有名称等均受相关法律保护，未经我司许可，任何单位及个人均不得将上述域名、商标及专有名称进行容易引起混淆或歧义的使用。
                    </p>
                    <!--<p class="MsoNormal" align="left" style="text-indent:21pt;">
                        当用户浏览本系统时，本系统将自动收集用户的<span>IP</span>地址及系统浏览信息。通过收集上述信息，我们将进行流量统计，从而改进系统的管理和服务。
                    </p>-->
                    <p class="MsoNormal" align="left">
                        <b>3.2 </b><b>版权</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统包含的所有内容（包括但不限于：文本、图形、图片、视像及声音内容、Logo标识，版面设计，专栏目录与名称、内容分类）的所有权归我司所有。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>3.3 </b><b>转载</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统摘录或转载的属于第三方的信息将注明具体来源。任何媒体、网站或个人从本系统下载使用，必须保留本系统注明的信息来源，并自行承担版权等法律责任。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>3.4 </b><b>版权异议</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        如权利人认为本系统内容中所涉及的文字作品、图片和音视频资料（以下简称“作品”），侵犯其著作权的，请及时提供书面通知，www.zy1dt.com将依法删除被指侵权的作品或断开相应的链接；但权利人不能出示有效身份证明、著作权权属证明及侵权情况证明的，视为未提出异议。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>3.5 </b><b>系统使用规定</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）
                        未经我司的书面授权，任何人不得建立www.zy1dt.com的金榜志愿通系统的镜像（包括全部和局部镜像）。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）
                        任何组织和个人不得非法复制、抄袭，或为任何未经允许的商业目的使用本系统及其内容。若我司确定用户行为违法或有损系统利益，则将保留采取相关法律行为的权利。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）
                        本系统可能通过网页上的链接向用户提供来自其它网站的内容及服务。本系统对这些网站及其内容不进行控制，也不负任何责任。请用户在使用该类网站所提供的服务时注意浏览这些网站的相关规定，并自行判断可能带来的结果和风险，以决定是否使用这些网站提供的内容或服务。
                    </p>
                    <!--<p class="MsoNormal" align="left" style="text-indent:21pt;">
                        在未得到用户许可前，本系统不会将用户的个人信息提供给任何第三方，但发生以下事由的除外：
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）
                        按照本系统制订的规则和程序，本系统有充分的理由相信已经获得用户的授权；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）
                        按照中华人民共和国的法律、法规、政策等规范性法律文件规定，要求本系统提供的；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）
                        不能归咎于本系统的客观情势，所导致的个人资料的公开；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>4</span>）
                        因不可抗力，所导致的个人资料的公开；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>5</span>）
                        由于用户自身过错，而导致的个人资料的公开；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>6</span>）
                        超出本系统使用的硬件和软件的技术能力范围，所导致的个人资料的公开；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>7</span>）
                        为维护本系统的所有权及相关权利的。
                    </p>-->
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        综上所述，未经相关权利人和我司的书面许可，上述资料（包括但不限于）均受《中华人民共和国著作权法》、商标权、专利权及其他财产权等相关法律的保护。且上述资料均不得以任何方式（包括但不限于复制、修改、编纂、或创造与内容有关的衍生产品）被任何单位或个人用于第三方平台或用于商业、盈利、广告性等目的，用于非商业用途时，应遵守著作权法以及其他相关法律规定，不得侵犯所有者及相关权利人的权益。
                    </p>
                    <!--<p class="MsoNormal" align="left">
                        <b>3.3 </b><b>信息安全</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        本系统将对用户所提供的资料进行严格的管理及保护，并将使用相应的技术措施，防止用户的个人资料丢失、被盗用或遭篡改。如因不可抗力或计算机病毒感染、黑客攻击等特殊原因，导致用户信息被破坏、泄密并受到损失的，本系统将采取必要措施尽力减少用户的损失，但本系统对此不承担任何责任。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>3.4 </b><b>用户权利</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        用户可通过本系统的相关功能查看、更新提供给本系统的个人信息，也可以要求本系统删除该信息。
                    </p>-->
                    <p class="MsoNormal" align="left">
                        <b>四、用户的权利和义务</b>
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>4.1 </b><b>用户的权利</b>
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>4.1.1 </b><b>用户的权利</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        注册资格要求用户须具有法定的相应权利能力和行为能力的自然人，能够独立承担法律责任。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        用户点击“同意”后，即视为用户已经确认自己具备主体资格，能够独立承担法律责任。若因用户不具备主体资格，则由用户及用户的监护人自行承担。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        在注册时，用户需要向本系统提供一些必要的信息，包括但不限于用户的移动电话号码、首选科目、再选科目，高考分数，孩子高中所读学校等信息。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        用户注册成功后，该用户帐号和密码由用户负责保管，并对帐号所有行为负相关法律责任。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>4.1.2 </b><b>帐户信息的使用</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        用户应谨慎合理的保存、使用自己的用户名和密码，应对通过用户名和密码实施的行为负责。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        建议用户在任何情况下不使用其他用户的帐号或密码。在用户怀疑他人在使用自己的帐号或密码时，请即时通知我司做相关处理。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        建议用户不将自己的帐户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>4.2 </b><b>用户的义务</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        用户在我司的服务过程中，必须遵循以下原则:
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）遵守中国有关的法律和法规；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）得为任何非法目的而使用我司服务；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）遵守所有与网络服务有关的网络协议、规定和程序；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>4</span>）不得利用我司服务进行任何可能对互联网的正常运转造成不利影响的行为；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>5</span>）不得利用我司服务进行任何不利于我司的行为。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>五、湖南豪之天信息技术有限公司的权利与义务</b>
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>5.1 </b><b>湖南豪之天信息技术有限公司的权利</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        当用户在本系统注册时时，本系统将自动收集用户信息，是为方便我司提供服务，从而改进系统的管理和服务。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>5.2 </b><b>湖南豪之天信息技术有限公司的义务</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品的故障或服务;
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        为用户信息保密。
                    </p>
                    <p class="MsoNormal" align="left">
                    <b>六、免责</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        我司为用户仅提供信息服务。
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）“金榜志愿通系统”只适合于普通物理和历史选科考生（不含职高对口）填报本科、高职专科批志愿时参考使用；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）本系统为用户所提供的数据分析服务，仅供用户参考，请用户在实际填报高考志愿时根据自身情况综合考虑，慎重填报！我司不承担用户使用系统后自行做出的选择、判断带来的一切责任和后果；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）由于用户将个人密码告知他人或与他人共享注册账户，由此导致的任何不能正常享用相应服务和用户资料泄露，我司不负任何责任；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>4</span>）当政府司法机关依照法定程序要求本系统披露个人资料时，我们将根据执法单位之要求或为公共安全之目的提供个人资料。在此情况下之任何披露，我司均免责；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>5</span>）请您理解，由于技术水平局限以及可能存在的恶意攻击，计算机病毒侵入或发作、因政府管制、因政策调整等而造成的暂时性关闭等影响系统正常运营的情况，我司免责，因不可抗力而造成的个人资料泄露、丢失、被盗用或被篡改等的意外情况，我司均免责；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>6</span>）为了本系统的正常运行，需要定期或不定期地对系统进行升级或停机维护，因此类情况而造成的正常服务中断，请您予以理解。我司将尽力避免服务中断或将中断时间控制在最短时间内，将尽力减少因此而给用户造成的影响。若因线路及非免责控制范围外的硬件故障或其它不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，我司均免责；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>7</span>）免责将对用户所提供的资料进行严格的管理及保护，并将使用相应的技术措施，防止用户的个人资料丢失、被盗用或遭篡改。如因不可抗力或计算机病毒感染、黑客攻击等特殊原因，导致用户信息被破坏、泄密并受到损失的，免责将采取必要措施尽力减少用户的损失，但本系统对此不承担任何责任；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>8</span>）当服务期限截止时，我司将删除用户信息。谢谢理解和配合。
                    </p>
                    <p class="MsoNormal" align="left">
                    <b>七、其他条款</b>
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>7.1 </b><b>如何联系我司</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        如用户对我司提供的服务有任何疑问、意见或建议，可以通过拨打我司4000-190-550免费服务电话，登录我行官方网站域名，关注“GK金榜志愿通“微信公众号或” GK金榜志愿通“小程序，微信服务群、QQ服务群等多种途径咨询或反映。受理用户的问题后，我司会及时、妥善处。一般情况下，我司将在24小时内给予答复。
                    </p>
                    <p class="MsoNormal" align="left">
                        <b>7.2 </b><b>其他条款</b>
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>1</span>）用户违反本协议的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户同意赔偿我司，使之免受损害；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>2</span>）本《协议》所定的任何条款无论因何种原因部分或全部无效或不具有执行力，本协议的其余条款仍应有效并具备约束力；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>3</span>）本协议的订立、执行和解释及纠纷的解决均应适用中华人民共和国法律。如双方就本协议内容或执行发生任何纠纷或争议，首先应友好协商解决，协商不成的，任何一方均可向我司注册地的有管辖权的人民法院提起诉讼；
                    </p>
                    <p class="MsoNormal" align="left" style="text-indent:21pt;">
                        （<span>4</span>）本协议的版权、各项条款内容的修改权、更新权及最终解释权均属我司所有。
                    </p>
                    <p style="text-align:right">
                        湖南豪之天信息技术有限公司</br>
                        2022年10月20日
                    </p>
                </div>
                <div style="width: 100px;margin: 20px auto">
                    <input type="button" value="关  闭"
                    style="width: 100px;height: 35px;cursor: pointer;background-color:#2186DE;
                    border: 1px solid #2186DE;color: #ffffff;
                    font:600 14px/35px 'Microsoft YaHei;'"
                    @click="closeHtml"
                    />
                </div>
         </div>
    </div>
    </div>
</template>
<script>
export default {
methods:{
    closeHtml(){
        const userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
                    window.open('','_self').close();
                }else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
    }
}
};
</script>
<style lang="less" scoped>

</style>
