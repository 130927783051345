<template>
    <div>
        <div class="home-container">
            <el-carousel :interval="5000" style="height:360px;" indicator-position="none">
      <el-carousel-item v-for="(item, index) in carousels" :key="index"  style="height:360px;">
        <!-- style="height:300px;"-->
        <el-image :src="item.itemUrl" style="height:360px;"></el-image>
      </el-carousel-item>
    </el-carousel>
    <div class="home-login" v-if="!token">
      <div class="login-pane">
        <div class="title"><i class="el-icon-user-solid"></i> 用户登录</div>
        <el-form
         :model="ruleForm"
         :rules="rules"
          ref="ruleForm"
          label-width="60px"
          size="small"
        >
          <el-form-item label="账号" prop="username">
            <el-input
              v-model.trim="ruleForm.username"
              type="text" auto-complete="password"
              autocomplete="off"
              placeholder="账号/手机号"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password" style="margin-bottom: 0">
            <el-input
             v-model.trim="ruleForm.password"
             auto-complete="password"
              type="password"
              autocomplete="off"
              placeholder="账号密码"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <div class="msg">
            <i class="el-icon-warning" v-if="msg"></i> {{ msg }}
          </div>
          <el-button type="primary" @click="submitLogin('ruleForm')" @keyup.enter="keyUpEnter"
            :disabled="loginDisabled">{{loginBtnTxt}}</el-button
          >
          <el-button type="warning" style="margin-top:20px;" @click="$router.push('/regist')"
            >注册</el-button
          >
        </el-form>
        <div class="forgetPwd">
            <span>
                <el-checkbox v-model="rember">记住我!</el-checkbox>
            </span>
          <span
            ><a @click="forgetPwdEvent"
              ><i class="el-icon-lock"></i>&nbsp;忘记密码</a
            ></span
          >
        </div>
      </div>
    </div>
        <div class="banner-tips px1005">
            <div class="tips-right" style="float:left">
                <!-- <div class="gzh">公众号</div> -->
                <!-- <div class="qr-code"></div> -->
                <img src="../assets/img/weixin.450d2b76.jpg" style="width:100%;height:100%;"/>
            </div>
            <div class="tips-left" style="float:left;width:70%">
                <p class="txt">十年寒窗为今朝&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;志愿填报有高招 </p>
                <p>联系人：陈老师&nbsp;&nbsp;手机/微信：13787139119</p>
                <p>齐全的数据，科学的分析，贴心的服务，99.5%推荐成功率，为您的高考保驾护航！</p>
                <!--<p>QQ1群：456266477&nbsp;&nbsp;QQ2群：412081825&nbsp;&nbsp;QQ3群：578121753</p>-->
            </div>
            <div class="tips-right" style="float:right">
            <img src="../assets/images/xiaochengxuma.jpg" style="width:100%;height:100%;"/>
            </div>
        </div>
        <div class="main_box">
            <div class="area cplist" style="margin:0 auto;">
            <!-- style="margin-right:85px;" -->
                <div class="cp_kud" >
                    <div class="cp_ku fl clearfix pr">
                    <!--227-->
                        <div class="cp_kuc pr"> <a href="/#/abouts/zjfw" target="_blank"><img
                                    src="../assets/images/cpimg/cp4.jpg" width="227" height="142" /></a></div>
                    </div>
                </div>
                <!--http://www.zy1dt.com/main-->
                <div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="/#/buyindex">
                            <img src="../assets/images/cpimg/cp1.jpg" width="227"
                                    height="142" /></a></div>
                    </div>
                </div>
                <!--http://www.zy1dt.com/comment-->
                <div class="cp_kud" style="">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="/#/abouts/yhassess" target="_blank"><img
                                    src="../assets/images/cpimg/cp3.jpg" width="227" height="142" /></a></div>
                    </div>
                </div>
                <div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="/#/abouts/about" target="_blank"><img
                                    src="../assets/images/cpimg/cp11.jpg" width="227" height="142" /></a></div>
                    </div>
                </div>
                <div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="/#/abouts/contact" target="_blank"><img
                                    src="../assets/images/cpimg/cp9.jpg" width="227" height="142" /></a></div>
                    </div>
                </div>
                <div class="cp_kud" style="">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="/#/abouts/questions" target="_blank"><img
                                    src="../assets/images/cpimg/cp10.jpg" width="227" height="142" /></a></div>
                    </div>
                </div>
                <!--<div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="/buyindex"><img src="../assets/images/cp1.png" width="227"
                                    height="142" /></a></div>
                    </div>
                </div>-->
                <!--<div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"> <a href="http://www.zy1dt.com/zytb" target="_blank"><img
                                    src="../assets/images/cp5.png" width="227" height="142" /></a></div>
                    </div>
                </div>-->
                <!--<div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="http://www.zy1dt.com/hgmj" target="_blank"><img
                                    src="../assets/images/cp6.png" width="227" height="142" /></a></div>
                    </div>
                </div>-->
                <!--<div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="http://www.zy1dt.com/main" target="_blank"><img
                                    src="../assets/images/cp7.png" width="227" height="142" /></a></div>
                    </div>
                </div>-->
                <!--<div class="cp_kud">
                    <div class="cp_ku fl clearfix pr">
                        <div class="cp_kuc pr"><a href="http://www.zy1dt.com/oto/zysh" target="_blank"><img
                                    src="../assets/images/cp8.png" width="227" height="142" /></a></div>
                    </div>
                </div>-->
            </div>
            <div class="ma-boot04"></div>
        </div>
        <div class="px1005 quanwei">
            <div class="qw-title">权威网址</div>
            <div class="qw-content">
                <!-- <a href="http://www.zy1dt.com/" title="教育部" target="_blank">金榜通官网</a> -->
                <a href="http://www.moe.gov.cn/" title="教育部" target="_blank">教育部</a>
                <a href="http://gaokao.chsi.com.cn/" title="阳光高考" target="_blank">阳光高考</a>
                <a href="http://www.chsi.com.cn/" title="中国高等教育学生信息网" target="_blank">学信网</a>
                <a href="http://www.hneeb.cn/" title="湖南招生考试信息港" target="_blank">湖南招生考试信息港</a>
                <a href="http://eea.gd.gov.cn/" title="广东教育考试院" target="_blank">广东教育考试院</a>
                <a href="http://www.jyb.cn/" title="中国教育报" target="_blank">中国教育报</a>
                <a href="http://www.sceea.cn/" title="四川教育考试院" target="_blank">四川教育考试院</a>
            </div>
            <div class="qw-title">免费学习入口</div>
            <div class="qw-content">
                <a href="https://ykt.eduyun.cn/" title="国家中小学网络云平台" target="_blank">国家中小学网络云平台</a>
                <a href="https://www.eduyun.cn/" title="国家教育资源公共服务平台" target="_blank">国家教育资源公共服务平台</a>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
import {loginAfterReload} from '../common/loginout';
export default {
data(){
    return{
        carousels:[
            {itemUrl:require("../assets/images/slideshow/banner02.jpg")},
            {itemUrl:require("../assets/images/slideshow/banner03.png")}
        ],
      // 登陆表单
      ruleForm: {
        username: "",
        password: ""
      },
      isLogin:false,
      msg:"",
      loginBtnTxt:"登录",
      loginDisabled:false,
      rember:false,
      rules: {
        username: [
          { required: true, message: "账号不能为空", trigger: "blur" }
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" }
        ]
      }
    };
},
methods:{
    // 跳转到忘记密码页面
    forgetPwdEvent(){
        this.$router.push("/findPwd");
    },
    // 登录表单提交
    async submitLogin(formName){
        this.$refs[formName].validate(async (valid)=>{
            if(valid){
                const username=this.ruleForm.username;
                const password=this.$md5(this.ruleForm.password);
                const bjmPwd=this.ruleForm.password;
                // const password=this.ruleForm.password;
                const sf=43;
                this.loginBtnTxt="登录中...";
                this.loginDisabled=true;
                const res=await this.$http.postHttp("/login/login",{username,password,sf});
                if(res.data.code===200){
                    this.isLogin=true;
                    const token=res.data.obj.token;
                    const userInfo=res.data.obj.userInfo;
                    // sessionStorage.setItem("token",token);
                    this.$store.dispatch("saveToken",token);
                    this.$store.dispatch("saveUserInfo",userInfo);
                    const rember=this.rember;
                    if(rember){
                        this.$store.dispatch('saveUserName',username);
                        this.$store.dispatch('saveUserPwd',bjmPwd);
                    }else{
                        this.$store.dispatch('initUserName');
                        this.$store.dispatch('initUserPwd');
                    }
                    this.msg='';
                    loginAfterReload();
                }else if(res.status===409||res.status===410||res.status===411){
                    this.$message({message:'您的账号在其他地点登陆了！',showClose:true,type:'warning'});
                }else{
                    // 登陆失败
                    if(res.data.message){
                        this.msg = `${res.data.message}`;
                    }else{
                        this.msg="账号或密码错误";
                    }
                }
                this.loginBtnTxt="登录";
                this.loginDisabled=false;
            }else{
                return false;
            }
        });
    },
    // 按下回车键触发登录
    async keyUpEnter(e){
        if(e.key==='Enter'){
            await this.submitLogin('ruleForm');
        }
    }
},
mounted(){
    // window.addEventListener('keydown',this.keyUpEnter);
    // const tokenStr=localStorage.getItem('token');
    // if(tokenStr){
    //     const token=JSON.parse(tokenStr);
    //     if(token.userName&&token.userPwd){
    //         this.ruleForm.username=token.userName;
    //         this.ruleForm.password=token.userPwd;
    //         this.rember=true;
    //     }
    // }
    const userStr=localStorage.getItem("user");
    if(userStr){
        const user=JSON.parse(userStr);
        if(user.userName&&user.userPwd){
            this.ruleForm.username=user.userName;
            this.ruleForm.password=user.userPwd;
            this.rember=true;
        }
    }
},
computed:{
    ...mapState(["token"])
}
};
</script>
<style lang="less" scoped>
            .x {
                // background: url("") no-repeat;
            }

            ::-ms-clear,
            ::-ms-reveal {
                display: none;
            }

            .index-banner .g-button-new {
                width: 240px
            }

            body .layui-layer {
                border-radius: 5px;
            }
            #reminder {
                display: none;
            }
            #reminder p {
                box-sizing: border-box;
                padding: 10px;
                margin-top: 20px;
                line-height: 20px;
                font-size: 18px;
                color: #e24242;
            }
            .qy-code{
            width: 80%;
            height: 80%;
            position: absolute;
            left: 10%;
            top: 10%;
            box-sizing: border-box;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            -ms-box-sizing: border-box;
            border: 2px solid #fff;
            }
            .cplist{
            display: flex;
            justify-content: center;
            align-items: center;
            //flex-wrap: wrap;
            }
            .home-container{
                min-width:1200px;
                position:relative;
                background-color:#f5f5f5;
                .home-login{
                position:absolute;
                left:50%;
                top:0;
                width:1200px;
                height:300px;
                margin-left:-600px;
                z-index:888;
                background-color:transparent;
                .login-pane{
                    position: absolute;
                    right: 0;
                    top: 20px;
                    width: 300px;
                    background-color: #fff;
                    border-radius: 5px;
                    .title{
                        margin: 15px 0;
                        font-size: 16px;
                        font-weight: bolder;
                        text-align: center;
                        color: #409eff;
                    }
                    .forgetPwd{
                        width: 85%;
                        padding: 11px;
                        // text-align: right;
                        margin:0 auto;
                        font-size: 14px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a{
                            color: #666;
                        }
                    }
                    .msg{
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 14px;
                        color: #f56c6c;
                    }
                    .el-button{
                        width: 85%;
                        height: 35px;
                        font-size: 16px;
                        margin: 0 0 0 20px;
                    }
                }
            }
            }

</style>
