<template>
    <div class="update-score">
        <div class="title"><i class="el-icon-edit-outline"></i> 修改分数</div>
        <div v-if="userInfo.sssf===43||userInfo.sssf===44">
        <div class="user-prompt">
            <div class="prompt-left" style="background-color: red;">
            <i class="prompt-img"></i>
            </div>
            <div class="prompt-right">
                <p>
                    高考成绩公布后，只允许修改一次分数和再选科目,请谨慎操作！
                    <span style="color:blue;">非志愿填报期间可任意修改。</span>
                </p>
            </div>
        </div>
        <div>
            <div class="inputs">
                <div class="input-div">
                   <div>姓名：</div>
                   <template v-if="userInfo.yhlb===7">
                    <el-autocomplete
                    popper-class="my-autocomplete"
                    v-model.trim="info.xm"
                    :fetch-suggestions="searchScore"
                    placeholder="请输入内容"
                    @select="handleSelect" style="width:90px;">
                    <!-- <i
                        class="el-icon-edit el-input__icon"
                        slot="suffix"
                        @click="handleIconClick">
                    </i> -->
                    <template slot-scope="{ item }">
                        <div class="name">{{ item.xm }}</div>
                        <!-- <span class="addr">{{ item.sjhm }}</span> -->
                    </template>
                    </el-autocomplete>
                   </template>
                   <template v-else>
                   <el-input v-model="info.xm" style="width:90px;"></el-input>
                   </template>
                </div>
                <div class="input-div" style="margin-left:20px;">
                    <div style="font-weight: bold;color: #DF4600;">高考分数：</div>
                    <el-input v-model.trim="info.gkfs" style="width:60px;" @change="gkfsChange"></el-input>
                </div>
                <div class="input-div" style="margin-left:34px;">
                    <div>省排名：</div>
                    <el-input v-model.trim="info.proPm" style="width:100px;"></el-input>
                </div>
                <div class="input-div">
                    <div>语文：</div>
                    <el-input v-model.trim="info.yu" style="width:60px;" @change="yuChange"></el-input>
                </div>
                <div class="input-div" style="margin-left:83px;">
                    <div>数学：</div>
                    <el-input v-model.trim="info.shu" style="width:60px;" @change="shuChange"></el-input>
                </div>
                <div class="input-div" style="margin-left:83px;">
                    <div>英语：</div>
                    <el-input v-model.trim="info.wai" style="width:60px;" @change="yyChange"></el-input>
                </div>
                <div class="input-title" style="margin-right:100px;">首选科目</div>
                <div class="input-div">
                    <el-button :type="wlType" style="margin-right:10px;"
                    @click="changeSxkm(2)">物理</el-button>
                    <el-input style="width:100px;"
                    v-if="wlType==='primary'" v-model.trim="info.sxkmfs" @change="sxkmChange"></el-input>
                    <el-input style="width:100px;"
                    v-if="wlType==='info'" :disabled="true"></el-input>
                </div>
                <div class="input-div" style="margin-left:116px;">
                    <el-button :type="lsType" style="margin-right: 10px;"
                    @click="changeSxkm(1)">历史</el-button>
                    <el-input style="width:100px;"
                    v-if="lsType==='primary'" v-model.trim="info.sxkmfs" @change="sxkmChange"></el-input>
                    <el-input style="width:100px;"
                    v-if="lsType==='info'" :disabled="true"></el-input>
                </div>
                <div class="input-title" style="margin-right:100px;">再选科目</div>
                <div class="input-div">
                    <el-button :type="hxType" style="margin-right:10px;"
                    @click="changeZxkm('化学')">化学</el-button>
                    <el-input v-if="hxType==='primary'"
                    v-model.trim="hxcj" style="width:100px;" @change="zxkmChange($event,'化学')"></el-input>
                    <el-input v-if="hxType==='info'"
                    :disabled="true" style="width:100px;"></el-input>
                </div>
                <div class="input-div" style="margin-left:115px;">
                    <el-button :type="swType" style="margin-right:10px;"
                    @click="changeZxkm('生物')">生物</el-button>
                    <el-input v-if="swType==='primary'"
                    v-model.trim="swcj" style="width:100px;" @change="zxkmChange($event,'生物')"></el-input>
                    <el-input v-if="swType==='info'"
                    :disabled="true" style="width:100px;"></el-input>
                </div>
                <div class="input-div">
                    <el-button :type="dlType" style="margin-right:10px;"
                    @click="changeZxkm('地理')">地理</el-button>
                    <el-input v-if="dlType==='primary'"
                    v-model.trim="dlcj" style="width:100px;" @change="zxkmChange($event,'地理')"></el-input>
                    <el-input v-if="dlType==='info'"
                    :disabled="true" style="width:100px;"></el-input>
                </div>
                <div class="input-div" style="margin-left:115px;">
                    <el-button :type="zzType" style="margin-right: 10px;"
                    @click="changeZxkm('政治')">政治</el-button>
                    <el-input v-if="zzType==='primary'"
                    v-model.trim="zzcj" style="width:100px;" @change="zxkmChange($event,'政治')"></el-input>
                    <el-input v-if="zzType==='info'"
                    :disabled="true" style="width:100px;"></el-input>
                </div>
                <div class="input-div">
                    <div>性别：</div>
                    <el-select style="width:100px;" v-model="info.xb">
                        <el-option v-for="(item,index) in xbs"
                        :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="input-div" style="margin-left:32px;">
                    <div>是否有相关资格：</div>
                    <el-checkbox-group v-model="info.xgzg">
                        <el-checkbox label="1">国家专项</el-checkbox>
                        <el-checkbox label="2">地方专项</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="input-div">
                    <div>手机号码：</div>
                    <el-input style="width:120px;" v-model.trim="info.sjhm" disabled></el-input>
                </div>
                <div class="input-div" style="margin-left:68px;">
                    <div>注册卡号：</div>
                    <el-input style="width:150px;" v-model.trim="info.zckh" disabled></el-input>
                </div>
                <div class="input-div">
                    <div>用户省份：</div>
                    <input v-if="info.sssf==='43'" value="湖南"
                    class="sf-input" style="width:100px;" :disabled="true"/>
                    <input v-if="info.sssf==='44'" value="广东"
                    class="sf-input" style="width:100px;" :disabled="true"/>
                    <input v-if="info.sssf==='51'" value="四川"
                    class="sf-input" style="width:100px;" :disabled="true"/>
                    <input v-if="info.sssf===''" value="湖南"
                    class="sf-input" style="width:100px;" :disabled="true"/>
                </div>
                <div class="input-div" style="margin-left:123px;">
                    <div>是否有加分：</div>
                    <el-select v-model="info.yfjf" style="width: 100px;">
                        <el-option v-for="(item,index) in jfs"
                        :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </div>
                <div class="input-div">
                    <div>备注：</div>
                    <el-input v-model.trim="info.bz" style="width:436px;"></el-input>
                </div>
                <div class="input-btn">
                    <el-button type="primary" @click="submitScore">提交</el-button>
                </div>
            </div>
        </div>
        </div>
        <div v-if="userInfo.sssf===51">
        <div class="user-center-txt">
            <div style="display:inline-block">高考剩余修改次数：</div>
            <div style="display: inline-block;color: red;">无限次</div>
        </div>
        <div class="input-center">
            <div class="input-div">
                <div>高考分数：</div>
                <div><el-input  style="width:100px;" v-model.trim="info.gkfs"></el-input></div>
            </div>
            <div class="input-div" style="margin-top: 10px;">
                <el-button type="primary">提交</el-button>
            </div>
        </div>
        <div class="user-prompt">
            <div class="prompt-left" style="background-color: red;">
            <i class="prompt-img"></i>
            </div>
            <div class="prompt-right">
                <p>
                    高考成绩公布后，只允许修改一次分数和再选科目,请谨慎操作！
                    <span style="color:blue;">非志愿填报期间可任意修改。</span>
                </p>
            </div>
        </div>
        <div class="input-xx-div">
            <div class="center-txt">基本信息</div>
            <div class="input-div">
                <div style="width:60px;">手机  </div>
                <div style="margin-left: 10px;">
                    <el-input v-if="sjhmShow" v-model.trim="info.sjhm" disabled style="width:120px;"></el-input>
                    <el-button v-else @click="sjhmShow=true" style="width:120px;">点击查看</el-button>
                </div>
            </div>
            <div class="input-div" style="margin-top:10px;">
                <div style="width:60px;">注册卡号</div>
                <div style="margin-left: 10px;">
                    <el-input v-if="khShow" v-model.trim="info.zckh" disabled style="width:120px;"></el-input>
                    <el-button v-else @click="khShow=true"  style="width:120px;">点击查看</el-button>
                </div>
            </div>
            <div class="input-div" style="margin-top:10px;">
                <div style="width:60px;">科类</div>
                <div style="margin-left: 10px;">
                    <template v-if="info.sxkm===1">
                    <el-input value="文科" disabled style="width:120px;"></el-input>
                    </template>
                    <template v-if="info.sxkm===2">
                    <el-input value="理科" disabled style="width:120px;"></el-input>
                    </template>
                </div>
            </div>
            <div class="input-div" style="margin-top:10px;">
                <div style="width:60px;">年度</div>
                <div style="margin-left: 10px;"><el-input disabled style="width:120px;"></el-input></div>
            </div>
        </div>
        <div class="input-xx-div">
            <div class="center-txt">详细信息</div>
            <div class="input-div">
                <div style="width:60px;">姓名  </div>
                <div style="margin-left: 10px;"><el-input v-model="info.xm" disabled style="width:120px;"></el-input></div>
            </div>
            <div class="input-div" style="margin-top:10px;">
                <div style="width:60px;">省份</div>
                <div style="margin-left: 10px;">
                    <template v-if="info.sssf==='51'">
                    <el-input value="四川" disabled style="width:120px;"></el-input>
                    </template>
                </div>
            </div>
            <div class="input-div" style="margin-top:10px;">
                <div style="width:60px;">中学</div>
                <div style="margin-left: 10px;"><el-input v-model="userInfo.xxmc" disabled style="width:120px;"></el-input></div>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        info:{
            xm:"",
            proPm:'',
            gkfs:"",
            yu:"",
            shu:"",
            wai:"",
            sxkmfs:"",
            sxkm:"",
            xb:"",
            xgzg:[],
            sjhm:"",
            zckh:"",
            sssf:"43",
            yfjf:"0",
            bz:""
        },
        wlType:"info",
        lsType:"info",
        hxType:"info",
        swType:"info",
        dlType:"info",
        zzType:"info",
        hxcj:"",
        swcj:"",
        dlcj:"",
        zzcj:"",
        count:0,
        sjhmShow:false,
        khShow:false,
        xbs:[
            {name:"男",value:"1"},
            {name:"女",value:"2"}
        ],
        jfs:[
            {name:"无",value:"0"},
            {name:"3分",value:"3"},
            {name:"5分",value:"5"},
            {name:"10分",value:"10"},
            {name:"15分",value:"15"},
            {name:"20分",value:"20"}
        ],
        inputName:'',
        zxsid:'',
        restaurants:[],
        maoshu:""
    };
},
methods:{
    // 改变首选科目
    changeSxkm(kl){
        const yhlb=this.userInfo.yhlb;
        if(yhlb===7||yhlb===9){
            if(kl===1){
            this.lsType="primary";
            this.wlType="info";
            this.info.sxkmfs="";
            }else if(kl===2){
                this.lsType="info";
                this.wlType="primary";
                this.info.sxkmfs="";
            }
            this.info.sxkm=kl;
        }else if(yhlb===2||yhlb===4){
        //     if(kl===1){
        //     this.lsType="primary";
        //     this.wlType="info";
        //     this.info.sxkmfs="";
        // }else if(kl===2){
        //     this.lsType="info";
        //     this.wlType="primary";
        //     this.info.sxkmfs="";
        // }
        // this.info.sxkm=kl;
        }
    },
    // 改变再选科目
    changeZxkm(zxkmName){
        if(zxkmName==='化学'){
            this.changeZx("hxType","count","hxcj");
        }else if(zxkmName==='生物'){
            this.changeZx("swType","count","swcj");
        }else if(zxkmName==='地理'){
            this.changeZx("dlType","count","dlcj");
        }else if(zxkmName==='政治'){
            this.changeZx("zzType","count","zzcj");
        }
    },
    // 改变再选科目代码
    changeZx(typeName,countName,cjName){
            const type=this[typeName];
            let count=this[countName];
            if(type==='primary'){
                this[typeName]="info";
                this[cjName]="";
                count--;
                this.count=count;
            }else if(type==='info'){
                if(count===2){
                    this.$message({message:"再选科目最多选两个",showClose:true,type:'warning'});
                    return;
                }
                this[typeName]='primary';
                count++;
                this[countName]=count;
            }
    },
        // 获取用户信息
    async getUser(){
        const res=await this.$http.getHttp("/users/getUserInfo?username="+this.userInfo.username);
        if(res.status===200){
            if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else{
                this.$store.dispatch("saveUserInfo",res.data);
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    zgChange(){
    },
    // 反显再选科目
    showZxkm(type1,type2,cj1,cj2,zxkm,isShow=false,userInfo={}){
        if(isShow===false){
            const zxkma=this.userInfo.zxkma;
            const zxkmb=this.userInfo.zxkmb;
            this.swType='info';
            this.dlType='info';
            this.hxType='info';
            this.zzType='info';
            this[type1]="primary";
            this[type2]="primary";
            if(zxkma===zxkm){
                this[cj1]=!this.userInfo.zxkmacj?'':this.userInfo.zxkmacj;
                this[cj2]=!this.userInfo.zxkmbcj?'':this.userInfo.zxkmbcj;
            }else if(zxkmb===zxkm){
                this[cj1]=!this.userInfo.zxkmbcj?'':this.userInfo.zxkmbcj;
                this[cj2]=!this.userInfo.zxkmacj?'':this.userInfo.zxkmacj;
            }
        }else{
            const zxkma=userInfo.zxkma;
            const zxkmb=userInfo.zxkmb;
            this.swType='info';
            this.dlType='info';
            this.hxType='info';
            this.zzType='info';
            this[type1]="primary";
            this[type2]="primary";
            if(zxkma===zxkm){
                this[cj1]=!userInfo.zxkmacj?'':userInfo.zxkmacj;
                this[cj2]=!userInfo.zxkmbcj?'':userInfo.zxkmbcj;
            }else if(zxkmb===zxkm){
                this[cj1]=!userInfo.zxkmbcj?'':userInfo.zxkmbcj;
                this[cj2]=!userInfo.zxkmacj?'':userInfo.zxkmacj;
            }
        }
    },
    // 获取修改次数
    async getUpdateNum(){
        const res=await this.$http.getHttp("/users/getModifFsCs");
        if(res.data.code===200){
            this.maoshu=res.data.obj.miaoshu;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 提交修改分数
    async submitScore(){
        const yhlb=this.userInfo.yhlb;
        if(yhlb===2||yhlb===4){
            await this.getUpdateNum();
            const num=this.miaoshu;
            if(num==="分数修改次数已经用完！"){
                this.$message({message:"修改次数已用完！",showClose:true,type:'warning'});
                return false;
            }else{
                await this.updateInfo();
            }
        }else if(yhlb===7||yhlb===9){
            await this.updateInfo();
        }
    },
    // 根据名字查找账号信息
    async searchScore(name,cb){
        const yhlb=this.userInfo.yhlb;
        if(yhlb===7){
            this.inputName=name;
            const res=await this.$http.getHttp('/xsxx/getXsxxByXm?xm='+name);
            if(res.status===200){
                this.restaurants=[...res.data];
                cb(this.restaurants);
            if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:"error"});
                }
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 限制输入框输入数字
    gkfsChange(gkfs){
        const reg=/^\d$/;
        if(!(reg.test(gkfs))){
            this.info.gkfs=gkfs.replaceAll(/\D/gi,'');
        }
    },
    // 限制输入框输入数字
    yuChange(yu){
        const reg=/^\d$/;
        if(!(reg.test(yu))){
            this.info.yu=yu.replaceAll(/\D/gi,'');
        }
    },
    // 限制输入框输入数字
    shuChange(shu){
        const reg=/^\d$/;
        if(!(reg.test(shu))){
            this.info.shu=shu.replaceAll(/\D/gi,'');
        }
    },
    // 限制输入框输入数字
    yyChange(yy){
        const reg=/^\d$/;
        if(!(reg.test(yy))){
            this.info.wai=yy.replaceAll(/\D/gi,'');
        }
    },
    // 限制输入框输入数字
    sxkmChange(sxkmfs){
        const reg=/^\d$/;
        if(!(reg.test(sxkmfs))){
            this.info.sxkmfs=sxkmfs.replaceAll(/\D/gi,'');
        }
    },
    // 限制输入框输入数字
    zxkmChange(zxkmfs,kmmc){
        const reg=/^\d$/;
        if(!(reg.test(zxkmfs))){
            if(kmmc==='化学'){
                this.hxcj=zxkmfs.replaceAll(/\D/gi,'');
            }else if(kmmc==='生物'){
                this.swcj=zxkmfs.replaceAll(/\D/gi,'');
            }else if(kmmc==='地理'){
                this.dlcj=zxkmfs.replaceAll(/\D/gi,'');
            }else if(kmmc==='政治'){
                this.zzcj=zxkmfs.replaceAll(/\D/gi,'');
            }
        }
    },
    handleSelect(item) {
                    const userInfo={...item};
                    this.zxsid=userInfo.yhid;
                    this.info.xm=!userInfo.xm?'':userInfo.xm==='以上都不是'?this.inputName:userInfo.xm;
                    this.info.gkfs=userInfo.gkfs;
                    this.info.yu=!userInfo.ywcj?'':userInfo.ywcj;
                    this.info.shu=!userInfo.sxcj?'':userInfo.sxcj;
                    this.info.wai=!userInfo.wycj?'':userInfo.wycj;
                    this.info.sxkmfs=!userInfo.sxkmcj?'':userInfo.sxkmcj;
                    this.info.proPm=userInfo.spm===''||userInfo.spm===null?'':userInfo.spm;
                    const sxkm=userInfo.sxkm;
                    if(sxkm===1){
                        this.lsType="primary";
                        this.wlType="info";
                    }else if(sxkm===2){
                        this.lsType="info";
                        this.wlType="primary";
                    }
                    this.info.sxkm=sxkm!==null?sxkm:1;
                    const zxkma=userInfo.zxkma;
                    const zxkmb=userInfo.zxkmb;
                    if((zxkma===1&&zxkmb===2)||(zxkmb===1&&zxkma===2)){
                        this.showZxkm("zzType","dlType","zzcj","dlcj",1,true,userInfo);
                    }else if((zxkma===1&&zxkmb===3)||(zxkmb===1&&zxkma===3)){
                        this.showZxkm("zzType","hxType","zzcj","hxcj",1,true,userInfo);
                    }else if((zxkma===1&&zxkmb===4)||(zxkmb===1&&zxkma===4)){
                        this.showZxkm("zzType","swType","zzcj","swcj",1,true,userInfo);
                    }else if((zxkma===2&&zxkmb===3)||(zxkmb===2&&zxkma===3)){
                        this.showZxkm("dlType","hxType","dlcj","hxcj",2,true,userInfo);
                    }else if((zxkma===2&&zxkmb===4)||(zxkmb===2&&zxkma===4)){
                        this.showZxkm("dlType","swType","dlcj","swcj",2,true,userInfo);
                    }else if((zxkma===3&&zxkmb===4)||(zxkmb===3&&zxkma===4)){
                        this.showZxkm("hxType","swType","hxcj","swcj",3,true,userInfo);
                    }
                    this.count=2;
                    this.info.xb=userInfo.xb===null?'1':userInfo.xb+"";
                    const gjzxzg=userInfo.gjzxzg;
                    const dfzxzg=userInfo.dfzxzg;
                    if(gjzxzg===1&&dfzxzg===1){
                        this.info.xgzg=['1','2'];
                    }else if(gjzxzg===1&&dfzxzg===0){
                        this.info.xgzg=['1'];
                    }else if(gjzxzg===0&&dfzxzg===1){
                        this.info.xgzg=['2'];
                    }else if(gjzxzg===0&&dfzxzg===0){
                        this.info.xgzg=[];
                    }
                    // this.info.sjhm=userInfo.sjhm;
                    // this.info.zckh=!userInfo.zckh?'':userInfo.zckh;
                    console.log(this.info);
                    this.info.sssf=userInfo.sssf===null?'43':userInfo.sssf+'';
                    if(userInfo.yfjf>=0){
                        this.info.yfjf=userInfo.yfjf===null?'0':userInfo.yfjf+'';
                    }
                    this.info.bz=!userInfo.bz?'':userInfo.bz;
      },
      handleIconClick(ev) {
        console.log(ev);
      },
    // 修改分数
    async updateInfo(){
        const obj={};
        obj.bz=this.info.bz;
        const xgzg=this.info.xgzg;
        if(xgzg.length===0){
            obj.gjzxzg=0;
            obj.dfzxzg=0;
        }else if(xgzg.length===1){
            if(xgzg[0]==='1'){
                obj.gjzxzg=1;
                obj.dfzxzg=0;
            }else if(xgzg[0]==='2'){
                obj.gjzxzg=0;
                obj.dfzxzg=1;
            }
        }else if(xgzg.length===2){
            obj.gjzxzg=1;
            obj.dfzxzg=1;
        }
        obj.dzyx="";
        obj.enabled=true;
        obj.gkfs=this.info.gkfs;
        obj.gkfsxg=this.userInfo.gkfsxg;
        obj.khzcsj=this.userInfo.khzcsj;
        obj.kl=this.info.sxkm;
        obj.ksxm=this.userInfo.ksxm;
        obj.openid=this.userInfo.openid;
        obj.password=this.userInfo.password;
        obj.school=this.userInfo.school;
        obj.xxmc=this.userInfo.xxmc;
        obj.sjhm=this.info.sjhm;
        obj.sssf=this.info.sssf;
        obj.sxcj=this.info.shu;
        obj.sxkm=this.info.sxkm;
        obj.sxkmcj=this.info.sxkmfs;
        obj.username=this.info.username;
        obj.wycj=this.info.wai;
        obj.xb=this.info.xb;
        obj.xm=this.info.xm;
        obj.yfjf=this.info.yfjf;
        obj.yfjf3=this.info.yfjf3;
        obj.yfjf5=this.info.yfjf5;
        obj.yfjf10=this.info.yfjf10;
        obj.yfjf20=this.info.yfjf20;
        obj.yhid=this.userInfo.yhid;
        obj.yhlb=this.userInfo.yhlb;
        obj.yhzcsj=this.userInfo.yhzcsj;
        obj.ywcj=this.info.yu;
        obj.zcjd=this.userInfo.zcjd;
        obj.zckh=!this.info.zckh?null:this.info.zckh;
        obj.flag='1';
        const spm=this.info.proPm===''?null:this.info.proPm;
        obj.spm=spm;
        const zzType=this.zzType;
        const dlType=this.dlType;
        const swType=this.swType;
        const hxType=this.hxType;
        if(zzType==="primary"&&dlType==="primary"){
            obj.zxkma=1;
            obj.zxkmb=2;
            obj.zxkmacj=this.zzcj;
            obj.zxkmbcj=this.dlcj;
        }else if(zzType==="primary"&&hxType==="primary"){
            obj.zxkma=1;
            obj.zxkmb=3;
            obj.zxkmacj=this.zzcj;
            obj.zxkmbcj=this.hxcj;
        }else if(zzType==="primary"&&swType==="primary"){
            obj.zxkma=1;
            obj.zxkmb=4;
            obj.zxkmacj=this.zzcj;
            obj.zxkmbcj=this.swcj;
        }else if(dlType==="primary"&&hxType==="primary"){
            obj.zxkma=2;
            obj.zxkmb=3;
            obj.zxkmacj=this.dlcj;
            obj.zxkmbcj=this.hxcj;
        }else if(dlType==="primary"&&swType==="primary"){
            obj.zxkma=2;
            obj.zxkmb=4;
            obj.zxkmacj=this.dlcj;
            obj.zxkmbcj=this.swcj;
        }else if(hxType==="primary"&&swType==="primary"){
            obj.zxkma=3;
            obj.zxkmb=4;
            obj.zxkmacj=this.hxcj;
            obj.zxkmbcj=this.swcj;
        }else if(hxType==="info"&&swType==="info"&&dlType==="info"&&zzType==="info"){
            this.$message({message:'请选择再选科目',type:'warning',showClose:true});
            return;
        }
        if(!this.info.gkfs){
            this.$message({message:"请输入高考分数",showClose:true,type:'warning'});
            return;
        }
        const yhlb=this.userInfo.yhlb;
        if(yhlb===7||yhlb===9){
            const zxsid=this.zxsid;
            if(zxsid){
                obj.zxsid=zxsid;
            }else{
                obj.zxsid=this.userInfo.yhid;
            }
        }
        const res=await this.$http.postHttp("/users/modifyFs",obj);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"修改成功",type:"success",showClose:true});
            }
            await this.getUser();
            this.$router.push("/me/updateScore?isload=true&&time="+new Date().getMilliseconds().toString());
            location.reload();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
this.info.xm=!this.userInfo.xm?'':this.userInfo.xm;
this.info.gkfs=this.userInfo.gkfs;
this.info.yu=!this.userInfo.ywcj?'':this.userInfo.ywcj;
this.info.shu=!this.userInfo.sxcj?'':this.userInfo.sxcj;
this.info.wai=!this.userInfo.wycj?'':this.userInfo.wycj;
this.info.sxkmfs=!this.userInfo.sxkmcj?'':this.userInfo.sxkmcj;
this.info.proPm=this.userInfo.spm===''||this.userInfo.spm===null?'':this.userInfo.spm;
const sxkm=this.userInfo.sxkm;
if(sxkm===1){
    this.lsType="primary";
    this.wlType="info";
}else if(sxkm===2){
    this.lsType="info";
    this.wlType="primary";
}
this.info.sxkm=sxkm;
const zxkma=this.userInfo.zxkma;
const zxkmb=this.userInfo.zxkmb;
console.log(zxkma,zxkmb);
if((zxkma!==null&&zxkma!==undefined)&&(zxkmb!==null&&zxkmb!==undefined)){
    this.count=2;
    if((zxkma===1&&zxkmb===2)||(zxkmb===1&&zxkma===2)){
    this.showZxkm("zzType","dlType","zzcj","dlcj",1);
    }else if((zxkma===1&&zxkmb===3)||(zxkmb===1&&zxkma===3)){
        this.showZxkm("zzType","hxType","zzcj","hxcj",1);
    }else if((zxkma===1&&zxkmb===4)||(zxkmb===1&&zxkma===4)){
        this.showZxkm("zzType","swType","zzcj","swcj",1);
    }else if((zxkma===2&&zxkmb===3)||(zxkmb===2&&zxkma===3)){
        this.showZxkm("dlType","hxType","dlcj","hxcj",2);
    }else if((zxkma===2&&zxkmb===4)||(zxkmb===2&&zxkma===4)){
        this.showZxkm("dlType","swType","dlcj","swcj",2);
    }else if((zxkma===3&&zxkmb===4)||(zxkmb===3&&zxkma===4)){
        this.showZxkm("hxType","swType","hxcj","swcj",3);
    }
}else if((zxkma===null&&zxkma===undefined)||(zxkmb===null&&zxkmb===undefined)){
    this.count=1;
    if(zxkma===1){
        this.zzType='primary';
        this.dlType='info';
        this.swType='info';
        this.hxType='info';
    }else if(zxkma===2){
        this.dlType='primary';
        this.swType='info';
        this.hxType='info';
        this.zzType='info';
    }else if(zxkma===3){
        this.hxType='primary';
        this.dlType='info';
        this.swType='info';
        this.zzType='info';
    }else if(zxkma===4){
        this.swType='primary';
        this.dlType='info';
        this.zzType='info';
        this.hxType='info';
    }
    if(zxkmb===1){
        this.zzType='primary';
        this.dlType='info';
        this.swType='info';
        this.hxType='info';
    }else if(zxkmb===2){
        this.dlType='primary';
        this.swType='info';
        this.hxType='info';
        this.zzType='info';
    }else if(zxkmb===3){
        this.hxType='primary';
        this.dlType='info';
        this.swType='info';
        this.zzType='info';
    }else if(zxkmb===4){
        this.swType='primary';
        this.dlType='info';
        this.zzType='info';
        this.hxType='info';
    }
}else{
    this.count=0;
}
this.info.xb=this.userInfo.xb+"";
const gjzxzg=this.userInfo.gjzxzg;
const dfzxzg=this.userInfo.dfzxzg;
if(gjzxzg===1&&dfzxzg===1){
    this.info.xgzg=['1','2'];
}else if(gjzxzg===1&&dfzxzg===0){
    this.info.xgzg=['1'];
}else if(gjzxzg===0&&dfzxzg===1){
    this.info.xgzg=['2'];
}else if(gjzxzg===0&&dfzxzg===0){
    this.info.xgzg=[];
}
this.info.sjhm=this.userInfo.sjhm;
this.info.zckh=!this.userInfo.zckh?'':this.userInfo.zckh;
this.info.sssf=this.userInfo.sssf+'';
if(this.userInfo.yfjf>=0){
    this.info.yfjf=this.userInfo.yfjf+'';
}
this.info.bz=!this.userInfo.bz?'':this.userInfo.bz;
// await this.getUser();
await this.getUpdateNum();
}
};
</script>
<style lang="less" scoped>
.update-score{
    margin-left:30px;
    width:900px;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    background-color:#ffffff;
    .title{
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        padding-left: 10px;
        line-height: 35px;
        font-size: 15px;
        font-weight: 700;
        color: #2f4056;
        background-color: #d3d3d3;
    }
    .user-center-txt{
        width: 200px;
    // background-color: #FFEBCD;
    margin: 20px auto;
    display: table;
    }
    .input-center{
        width:400px;
        margin:20px auto;
        .input-div{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .center-txt{
            width:600px;
            text-align: center;
            margin:5px 0;
        }
    }
    .input-xx-div{
        width:600px;
        margin:10px auto;
        border: 2px dashed skyblue;
        padding: 5px 0;
        box-sizing: border-box;
        .input-div{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .user-prompt{
    width: 400px;
    background-color: #FFEBCD;
    margin: 20px auto;
    display: table;
    .prompt-left{
        background-color: rgba(0, 0, 0, 0.25);
        width: 80px;
        vertical-align: middle;
        text-align: center;
        display: table-cell;
        .prompt-img{
            display: inline-block;
            background: url(../../assets/images/tool/prompt.png) no-repeat center center;
            width: 32px;
            height: 30px;
        }
    }
    .prompt-right{
        display: table-cell;
        vertical-align: middle;
        padding: 20px 10px 20px 10px;
        color: #FF0000;
    }
}
.inputs{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width:600px;
    margin:20px 0;
    margin:0 auto;
    border: 2px dashed #A7D1F7;
    box-sizing: border-box;
    padding-left:40px;
    .sf-input{
        -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    }
    .input-div{
        display: flex;
        justify-content: center;
        align-items: center;
        margin:10px 0;
    }
    .input-title{
        width:600px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #DF4600;
        font-weight: bold;
        margin:10px 0;
        font-size: 16px;
    }
    .input-btn{
        width:600px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:10px 0;
    }
}
}
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}
</style>
