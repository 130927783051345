import axios from "axios";
import router from '../router/index';
import {Message} from 'element-ui';
import store from '../store/index';
const BASE_URL='/api';

export function getHttp(url) {
    let tokenObj=localStorage.getItem("token");
if(tokenObj){
    tokenObj=JSON.parse(tokenObj);
}
const token=tokenObj?"Bearer "+tokenObj.token:"";
const axiosObj={
    BASE_URL: '/api',
    headers: {
       'Content-Type': 'application/json; charset=utf-8',
       Authorization:token
     }
  };
const htp=axios.create(axiosObj);
    return new Promise((resolve,reject)=>{
        htp.get(BASE_URL+url).then((res)=>{
            resolve(res);
        }).catch((err)=>{
            reject(err);
        });
    });
}
export function postHttp(url,data,obj=null){
    let tokenObj=localStorage.getItem("token");
if(tokenObj){
    tokenObj=JSON.parse(tokenObj);
}
const token=tokenObj?"Bearer "+tokenObj.token:"";
const axiosObj={
    BASE_URL: '/api',
    headers: {
       'Content-Type': 'application/json; charset=utf-8',
       Authorization:token
     }
  };
const htp=axios.create(axiosObj);
    if(data){
        return new Promise((resolve,reject)=>{
            if(obj){
                htp.post(BASE_URL+url,data,obj).then(res=>{
                    resolve(res);
                }).catch(err=>{
                    reject(err);
                });
            }else{
                htp.post(BASE_URL+url,data).then(res=>{
                    resolve(res);
                }).catch(err=>{
                    reject(err);
                });
            }
        });
    }else{
        return new Promise((resolve,reject)=>{
            htp.post(BASE_URL+url).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    }
}
export function putHttp(url,data){
    let tokenObj=localStorage.getItem("token");
if(tokenObj){
    tokenObj=JSON.parse(tokenObj);
}
const token=tokenObj?"Bearer "+tokenObj.token:"";
const axiosObj={
    BASE_URL: '/api',
    headers: {
       'Content-Type': 'application/json; charset=utf-8',
       Authorization:token
     }
  };
const htp=axios.create(axiosObj);
    if(data){
        return new Promise((resolve,reject)=>{
            htp.put(BASE_URL+url,data).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    }else{
        return new Promise((resolve,reject)=>{
            htp.put(BASE_URL+url).then(res=>{
                resolve(res);
            }).catch(err=>{
                reject(err);
            });
        });
    }
}
export function deleteHttp(url){
    let tokenObj=localStorage.getItem("token");
if(tokenObj){
    tokenObj=JSON.parse(tokenObj);
}
const token=tokenObj?"Bearer "+tokenObj.token:"";
const axiosObj={
    BASE_URL: '/api',
    headers: {
       'Content-Type': 'application/json; charset=utf-8',
       Authorization:token
     }
  };
const htp=axios.create(axiosObj);
    return new Promise((resolve,reject)=>{
        htp.delete(BASE_URL+url).then(res=>{
            resolve(res);
        }).catch(err=>{
            reject(err);
        });
    });
};
axios.interceptors.response.use((response)=>{
    if(response.status === 200 && response.data.code === 500 && response.data.msg === '未登录'){
        store.commit('initRecommend');
        store.commit('initSchoolFind');
        store.commit('initUserInfo');
        store.commit('initToken');
        Message.error({message: '登录超时，请重新登录'});
        router.replace({
            path: '/home',
            query: {redirect: router.currentRoute.fullPath}
        });
    }
    return response;
},error=>{
    if(error.response.status === 504){
        Message.error({message: '服务器可能未开启,请稍后再试'});
    }
    return Promise.reject(error.response.data);// 返回接口返回的错误信息
});
export default {
    postHttp,
    getHttp,
    putHttp,
    deleteHttp
};
