<template>
    <div>
        <div class="card-top">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="major-div">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="本科" name="1">
            <div id="bk-data">
                <MajorQuery :majorList="bkZys" :xz="0" :size="bkSize"/>
            </div>
        </el-tab-pane>
        <el-tab-pane label="专科" name="2">
            <div id="zk-data">
                <MajorQuery :majorList="zkZys" :xz="1" :size="zkSize"/>
            </div>
        </el-tab-pane>
        <el-tab-pane label="2017年学科排名" name="3">
            <ranking :zyzs="zyzs"/>
        </el-tab-pane>
        </el-tabs>
        </div>
    </div>
</template>
<script>
import MajorQuery from './major/majorQuery.vue';
import Ranking from './ranking/xkRanking.vue';
import {mapState} from 'vuex';
export default {
props:['sxkmChangeReloadMajor'],
data(){
    return{
        activeName:"1",
        zyzs:["世界史","中医史"],
        bkZys:[],
        bkSize:0,
        zkZys:[],
        zkSize:0,
        reloadTimer:null
    };
},
methods:{
    handleClick(){},
    // 获取专业目录
    async getZyList(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("bk-data"),text:"正在加载中"});
        const res=await this.$http.postHttp("/zycx/zyml",{
            kl:this.userInfo.sxkm,
            pc:6,
            pageNum:1,
            pageSize:10,
            zymc:""
        });
        if(res.data.code===200){
            // this.bkZys=res.data.obj.result;
            this.bkSize=res.data.obj.size;
            const zys=res.data.obj.result;
            const arr=[];
            for(let i=0;i<zys.length;i++){
                const subs=zys[i].subs;
                let count=1;
                const obj={};
                obj.zymc=zys[i].zymc;
                obj.zydm=zys[i].zydm;
                for(let j=0;j<subs.length;j++){
                    if(subs[j].zydm.length===4){
                        if(!obj["list"+count]){
                            obj["list"+count]=[];
                            obj["list"+count].push(subs[j]);
                        }else{
                            obj["list"+count].push(subs[j]);
                        }
                    }else{
                        if(!obj["list"+count]){
                            obj["list"+count]=[];
                            obj["list"+count].push(subs[j]);
                        }else{
                            obj["list"+count].push(subs[j]);
                        }
                    }
                    if(subs[j+1]&&subs[j+1].zydm&&subs[j+1].zydm.length===4){
                        count++;
                    }
                }
                arr.push(obj);
            }
            this.bkZys=arr;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
        const loading2=this.$loading({fullscreen:true,target:document.getElementById("zk-data"),text:"正在加载中"});
        const res2=await this.$http.postHttp("/zycx/zyml",{
            kl:this.userInfo.sxkm,
            pc:7,
            pageNum:1,
            pageSize:10,
            zymc:''
        });
        if(res2.data.code===200){
            // this.zkZys=res2.data.obj.result;
            this.zkSize=res2.data.obj.size;
            const zys=res2.data.obj.result;
            const arr=[];
            for(let i=0;i<zys.length;i++){
                const subs=zys[i].subs;
                let count=1;
                const obj={};
                obj.zymc=zys[i].zymc;
                obj.zydm=zys[i].zydm;
                for(let j=0;j<subs.length;j++){
                    if(subs[j].zydm.length===4){
                        if(!obj["list"+count]){
                            obj["list"+count]=[];
                            obj["list"+count].push(subs[j]);
                        }else{
                            obj["list"+count].push(subs[j]);
                        }
                    }else{
                        if(!obj["list"+count]){
                            obj["list"+count]=[];
                            obj["list"+count].push(subs[j]);
                        }else{
                            obj["list"+count].push(subs[j]);
                        }
                    }
                    if(subs[j+1]&&subs[j+1].zydm&&subs[j+1].zydm.length===4){
                        count++;
                    }
                }
                arr.push(obj);
            }
            this.zkZys=arr;
        }else if(res2.data.code===500){
            if(res2.data.message){
                this.$message({message:res2.data.message,type:"error"});
            }
        }else if(res2.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res2.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading2.close();
    },
    // 获取学科排名所有选中的专业
    async getXkAllZy(){
        const res=await this.$http.getHttp("/zycx/getzyList");
        if(res.data.code===200){
            const data=res.data.obj;
            const zys=[];
            for(let i=0;i<data.length;i++){
                if(data[i].zymc){
                    zys.push(data[i].zymc);
                }
            }
            this.zyzs=zys;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
async mounted(){
await this.getZyList();
await this.getXkAllZy();
this.reloadTimer=setInterval(() => {
    if(this.sxkmChangeReloadMajor&&this.sxkmChangeReloadMajor==='true'){
        clearInterval(this.reloadTimer);
        this.$router.push({
            path:'/majorquery',
            query:{
                time:(new Date().getMilliseconds())
            }
        });
        location.reload();
    }
}, 1000);
},
computed:{
    ...mapState(["userInfo"])
},
components:{
    MajorQuery,
    Ranking
}
};
</script>
<style lang="less" scoped>
.major-div{
    width: 1100px;
    margin: 0 auto;
    .major-query-container{
    min-width:1100px;
    margin:0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .major-panel{
        width: 1100px;
        box-sizing: border-box;
        padding: 16px;
        margin: 0 auto;
        display: flex;
        background-color: #fff;
        .major-types{
            width: 210px;
            height: 600px;
            margin: 0 20px 0;
            box-sizing: border-box;
            border: 1px solid #dcdfe6;
            .major-type-name{
                font-size: 18px;
                height: 40px;
                color: #fff;
                background-color: hsla(60,1%,60%,.471);
                line-height: 40px;
                .xz-type{
                    display: inline-block;
                    width: 101px;
                    height: 100%;
                    text-align: center;
                    cursor: pointer;
                }
                .blue{
                    background-color: #5ba8ff;
                }
            }
            .major-type-group{
                // font-size: 18px;
                // height: 40px;
                // color: #fff;
                // background-color: hsla(60,1%,60%,.471);
                // line-height: 40px;
                .major-one{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 35px;
                    padding: 2px 0;
                    margin: 0 10px;
                    font-size: 14px;
                    color: #303133;
                    border-bottom: 1px solid #dcdfe6;
                }
            }
        }
        .major-search{
            display: flex;
            flex-direction: row;
            width: 810px;
            margin: 0 auto;
            .search{
                box-sizing: border-box;
                width: 710px;
                height: 40px;
                padding-left: 10px;
                border: 2px solid #f4a460;
                border-right: none;
                color: #606266;
            }
            .search-btn{
                width: 100px;
                color: #fff;
                height: 40px;
                border: 1px solid #f4a460;
                padding-left: -1px;
                font-size: 18px;
                cursor: pointer;
                background-color: #f4a460;
            }
        }
        .split-line{
            border: 0;
            width: 810px;
            margin: 20px auto;
            color: #666;
            text-align: right;
            background-image: linear-gradient(90deg,rgba(244,164,96,.1),rgba(244,164,96,.75),rgba(244,164,96,.1));
        }
        .major-group{
            width: 810px;
            margin: 0 auto;
            box-sizing: border-box;
            .major-item{
                display: inline-block;
                width: 100%;
                line-height: 40px;
                font-size: 14px;
                color: #666;
                .major-item-title{
                    display: flex;
                    align-items: center;
                    height: 40px;
                    margin-top: 20px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #4593ff;
                    border-bottom: 1px solid #e4e7ed;
                    background-color: #f8f8f8;
                    .line{
                        width: 0;
                        height: 20px;
                        margin:0;
                        margin-right: 10px;
                        border: 2px solid #4593ff;
                        border-radius: 2px;
                    }
                }
            }
            .major-name{
                display: inline-block;
                width: 200px;
                padding-left: 2px;
            }
        }
    }
}
}
.card-top{
        width: 1100px;
        margin: 20px auto;
    }
</style>
