<template>
    <div>
        <div class="collect-title">
            <div style="width:400px;">文件名：<span>{{planObj.plan}}</span></div>
            <div style="width:300px;">时间：<span>{{planObj.time}}</span></div>
        </div>
        <!-- <div class="collect-btn">
            <el-button type="primary" @click="allNoCheck">取消全选</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="deleteSchool">删除</el-button>
            <el-button type="danger" style="background-color: #de3b21c9;border: 1px solid white;" @click="openYxzyz">已保存的院校专业组</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="moveFirst"><i class="el-icon-d-arrow-left" style="color:blue"></i> 移 动 到 第 一 行 </el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="movePrev"><i class="el-icon-arrow-left" style="color:blue"></i> 前 移 一 行 </el-button>
        </div>
        <div class="collect-btn">
            <el-button type="primary" style="width:97px;" @click="allCheck">  全  选  </el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="saveYxzyz">保存</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="ascTableData">升序</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;margin-left:26px;" @click="descTableData">  降  序  </el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="moveEnd"><i class="el-icon-d-arrow-right" style="color:blue"></i> 移 动到最后一行</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="moveNext"><i class="el-icon-arrow-right" style="color:blue"></i> 后 移 一 行 </el-button>
        </div> -->
        <div class="float-btn">
            <div style="float:left">
            <el-button type="primary" @click="allNoCheck">取消全选</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="deleteSchool">删除</el-button>
            <el-button type="danger" style="background-color: #de3b21c9;border: 1px solid white;" @click="openYxzyz">已保存的院校专业组</el-button>
            </div>
            <div style="float:right">
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="moveFirst"><i class="el-icon-d-arrow-left" style="color:blue"></i> 移 动 到 第 一 行 </el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="movePrev"><i class="el-icon-arrow-left" style="color:blue"></i> 前 移 一 行 </el-button>
            </div>
        </div>
        <div style="clear:both;"></div>
        <div class="float-btn">
            <div style="float:left;">
            <el-button type="primary" style="width:97px;" @click="allCheck">  全  选  </el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="saveYxzyz">保存</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="ascTableData">升序</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;margin-left:26px;" @click="descTableData">  降  序  </el-button>
            </div>
            <div style="float:right;">
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="moveEnd"><i class="el-icon-d-arrow-right" style="color:blue"></i> 移 动到最后一行</el-button>
            <el-button type="primary" style="background-color: #ff861a;border: 1px solid white;" @click="moveNext"><i class="el-icon-arrow-right" style="color:blue"></i> 后 移 一 行 </el-button>
            </div>
        </div>
        <div style="clear:both;margin-bottom:10px;"></div>
        <el-dialog title="已保存的院校专业组列表" :visible.sync="dialogTableVisible">
        <el-table :data="yxzyzList" border
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778'
                ,'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
            <el-table-column show-overflow-tooltip type="index" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip property="plan" label="姓名" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip property="date" label="保存时间" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip  label="操作" :resizable="false">
                <template slot-scope="scope">
                    <el-button type="text" @click="watchYxzyz(scope.row.id,scope.row.pc)">修改</el-button>
                    <el-button type="text" @click="deleteYxzyz(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        </el-dialog>
        <el-dialog title="请选择要替换的方案" :visible.sync="dialogPlanVisible" width="25%">
            <div class="dialog-div" style="margin-bottom:20px;">
                <el-select v-model="selectedYxzyz" :filterable="true">
                <el-option v-for="(item,index) in yxzyzList"
                :key="index" :label="item.plan" :value="item.id"></el-option>
            </el-select>
            </div>
            <div class="dialog-div">
            <el-button type="primary" @click="saveYxzyzCode">确定</el-button>
            <el-button @click="openOther">另存为</el-button>
            </div>
        </el-dialog>
        <el-dialog title="请输入名字" :visible.sync="dialogOtherVisible" width="25%">
        <div class="dialog-div" style="margin-bottom:20px;">
            <el-input v-model="otherPlan" style="width:200px;"></el-input>
        </div>
        <div class="dialog-div">
            <el-button type="primary" @click="saveOtherCode">确定</el-button>
            <el-button @click="closeOther">取消</el-button>
        </div>
        </el-dialog>
        <div>
        <div>
            <el-tabs v-model="activePcName" type="card"  @tab-click="handleClick">
            <el-tab-pane label="本科" name="6">
            <div id="bk-data">
                <el-table border highlight-current-row :data="bkCollectYx" height="600"
                @current-change="handleBkCurrentChange" :key="updateBk"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center','font-size':'14px'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column show-overflow-tooltip label="选择" width="40" prop="checked">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" @change="bkCheckChange($event,scope.row)"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip type="index" label="序号" width="40"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校代码" width="60" prop="sb"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校名称" width="210" prop="yxmc">
                    <template slot-scope="scope">
                        <router-link
                        tag="a"
                        target="_blank"
                        :to="{
                            path: '/schoolDetail',
                            query: { gbdm: scope.row.gbdm,pc:scope.row.pc,yxmc:scope.row.yxmc,yxxh:scope.row.yxxh }
                        }"
                        v-if="scope.row.yxmc"
                        >{{scope.row.yxmc}}</router-link>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="专业组" width="50" prop="zyz"></el-table-column>
                <el-table-column show-overflow-tooltip label="所在省市" width="80" prop="szdqmc"></el-table-column>
                <el-table-column show-overflow-tooltip label="批次" width="70" prop="pc">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pc===6">本科批</span>
                        <span v-if="scope.row.pc===7">专科批</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="211" width="50" prop="sf211">
                    <template slot-scope="scope">
                        <img v-if="scope.row.sf211===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="985" width="50" prop="sf985">
                    <template slot-scope="scope">
                        <img v-if="scope.row.sf985===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="招生计划">
                    <el-table-column label="上年" width="60" prop="snjhs"></el-table-column>
                    <el-table-column label="当年" width="60" prop="jnjhs"></el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="入档位次" >
                    <el-table-column label="上年" width="70" prop="snrdwc"></el-table-column>
                    <el-table-column label="当年" width="70" prop="rdwc"></el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="投档线">
                    <el-table-column label="上年" width="70" prop="tdx"></el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="等效分">
                    <el-table-column label="最低" width="70" prop="zdtwf"></el-table-column>
                    <el-table-column label="平均" width="70" prop="pjtwf"></el-table-column>
                    <el-table-column label="最高" width="70" prop="zgtwf"></el-table-column>
                </el-table-column>
            </el-table>
            </div>
            </el-tab-pane>
            <el-tab-pane label="专科" name="7">
                <div id="zk-data">
                <el-table :data="zkCollectYx" height="600" highlight-current-row
                    @current-change="handleZkCurrentChange" :key="updateZk"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center','font-size':'14px'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column show-overflow-tooltip label="选择" width="40" prop="checked">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.checked" @change="zkCheckChange($event,scope.row)"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip type="index" label="序号" width="40"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校代码" width="60" prop="sb"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校名称" width="210" prop="yxmc">
                    <template slot-scope="scope">
                        <router-link
                        tag="a"
                        target="_blank"
                        :to="{
                            path: '/schoolDetail',
                            query: { gbdm: scope.row.gbdm,pc:scope.row.pc,yxmc:scope.row.yxmc,yxxh:scope.row.yxxh }
                        }"
                        v-if="scope.row.yxmc"
                        >{{scope.row.yxmc}}</router-link>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="专业组" width="50" prop="zyz"></el-table-column>
                <el-table-column show-overflow-tooltip label="所在省市" width="80" prop="szdqmc"></el-table-column>
                <el-table-column show-overflow-tooltip label="批次" width="70" prop="pc">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pc===6">本科批</span>
                        <span v-if="scope.row.pc===7">专科批</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="211" width="50" prop="sf211">
                    <template slot-scope="scope">
                        <img v-if="scope.row.sf211===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="985" width="50" prop="sf985">
                    <template slot-scope="scope">
                        <img v-if="scope.row.sf985===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="招生计划">
                    <el-table-column label="上年" width="60" prop="snjhs"></el-table-column>
                    <el-table-column label="当年" width="60" prop="jnjhs"></el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="入档位次">
                    <el-table-column label="上年" width="70" prop="snrdwc"></el-table-column>
                    <el-table-column label="当年" width="70" prop="rdwc"></el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="投档线">
                    <el-table-column label="上年" width="70" prop="tdx"></el-table-column>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="等效分">
                    <el-table-column label="最低" width="70" prop="zdtwf"></el-table-column>
                    <el-table-column label="平均" width="70" prop="pjtwf"></el-table-column>
                    <el-table-column label="最高" width="70" prop="zgtwf"></el-table-column>
                </el-table-column>
            </el-table>
                </div>
            </el-tab-pane>
            </el-tabs>
            <div style="width:1100px;margin:20px auto;display:flex;justify-content:flex-end;">
                <template v-if="userInfo.yhlb===7||userInfo.yhlb===9||userInfo.yhlb===2||yfFreeDown===true">
                <el-button type="primary" @click="freeDownload">下载</el-button>
                </template>
                <template v-if="userInfo.yhlb===4&&yfFreeDown===false">
                <el-button type="primary" @click="yhDownload">下载(5元/次)</el-button>
                <el-button type="primary" @click="yjDownload">下载(20元/永久)</el-button>
                </template>
            </div>
            <el-dialog  title="金榜志愿通-支付" :visible.sync="dialogBuyVisible" width="30%">
                <el-form>
                <el-form-item label="适用省份">
                    <el-select v-model="buyInfo.sssf" style="width:150px;">
                        <template v-if="buyInfo.sssf===43||buyInfo.sssf==='43'">
                            <el-option value="43" label="湖南"></el-option>
                        </template>
                        <template v-if="buyInfo.sssf===44||buyInfo.sssf==='44'">
                            <el-option value="44" label="广东"></el-option>
                        </template>
                        <template v-if="buyInfo.sssf===51||buyInfo.sssf==='51'">
                            <el-option value="51" label="四川"></el-option>
                        </template>
                        <!-- <el-option value="44" label="广东"></el-option>
                        <el-option value="51" label="四川"></el-option> -->
                    </el-select>
                </el-form-item>
                <el-form-item label="商品类型">
                    <el-select v-model="buyInfo.type" style="width:150px;">
                        <el-option :value="buyInfo.type" :label="buyInfo.cpmc"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="商品数量">
                    <span>1份</span>
                </el-form-item>
                <el-form-item label="支付金额">
                    <span class="pay-price">￥{{buyInfo.payPrice}}</span>
                </el-form-item>
                <el-form-item label="付款方式">
                    <el-radio-group v-model="buyInfo.payType">
                        <el-radio :label="1">支付宝</el-radio>
                        <el-radio :label="2">微信</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item>
                    <div style="margin: 0 auto;width:100px;">
                        <el-button type="primary" @click="payDown">立即支付</el-button>
                    </div>
                </el-form-item>
                <div class="note">
                    <span style="color: red;">特别提示：</span>
                    <p style="margin:10px 0;">1、手机端下载表格可能存在不兼容问题，请到电脑端下载！</p>
                    <p style="margin:10px 0;">2、本商品一经售出，一经出售，概不退换！如有问题，可与客服联系4000-190-550;本卡不记名、不挂一经出售，概不退换!如有问题，可与我们的 客服联系4000-190-550</p>
                </div>
            </el-form>
            </el-dialog>
            <el-dialog
            :title="innerTitle"
            width="30%"
            :visible.sync="innerdialogVisible"
            @close="closeDialog"
        >
            <div class="weixin-panel">
            <div id="qrcode" class="qrcode" ref="qrCodeDiv"></div>
            <div class="qrcode-time">
                <i style="color:#ff7e00">{{ qrcodeTime }}s </i>后二维码过期
            </div>
            </div>
            </el-dialog>
            <form action="/yhgz/down" method="post" id="down_form">
            <!-- <input id="down_param" name="param" type="hidden" value="" /> -->
            <input id="down_list" name="list" type="hidden" value="" />
            </form>
        </div>
        </div>
    </div>
</template>
<script>
import QRCode from "qrcodejs2"; // 引入qrcode
import {mapState} from 'vuex';
export default {
props:['reloadGzYx'],
data(){
    return{
        activePcName:"6",
        bkCollectYx:[],
        zkCollectYx:[],
        buyInfo:{
            type:"1",
            sssf:"43",
            num:"1",
            payPrice:"688",
            payType:1,
            sjhm:"",
            yzm:"",
            dj:"",
            cpmc:""
        },
        dialogTableVisible:false,
        dialogPlanVisible:false,
        dialogOtherVisible:false,
        dialogBuyVisible:false,
        yxzyzList:[],
        selectedYxzyz:'',
        otherPlan:'',
        bkIndex:-1,
        zkIndex:-1,
        updateBk:false,
        updateZk:false,
        isOpen:false,
        planObj:{
            plan:"",
            time:""
        },
        qrcodeTime:0,
        innerdialogVisible:false,
        innerTitle:"",
        yfFreeDown:false,
        intervalQrcodeTime:null,
        filename:'院校专业组对比表',
        reloadTimer:null
    };
},
methods:{
    // 查询本科关注院校
    async getBkGzyx(){
        const obj={fs:this.userInfo.gkfs,kl:this.userInfo.sxkm,pc:6,xm:this.userInfo.xm,yhid:this.userInfo.yhid};
        const loading=this.$loading({fullscreen:true,target:document.getElementById("bk-data"),text:"正在加载中"});
        const res=await this.$http.postHttp("/yhgz/selectYhgzYx",obj);
        if(res.data.code===200){
            this.bkCollectYx=res.data.obj;
            const len=res.data.obj.length;
            for(let i=0;i<len;i++){
                this.bkCollectYx[i].checked=this.bkCollectYx[i].isRed||false;
                this.bkCollectYx[i].index=(i+1);
            }
            if(len<10){
                for(let i=0;i<10-len;i++){
                const obj={};
                obj.index=len+(i+1);
                obj.checked=false;
                obj.sb=null;
                obj.yxmc=null;
                obj.zyz=null;
                obj.szdqmc=null;
                obj.pc=null;
                obj.sf985=null;
                obj.sf211=null;
                this.bkCollectYx.push(obj);
            }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 获取专科关注院校
    async getZkGzyx(){
        const obj={fs:this.userInfo.gkfs,kl:this.userInfo.sxkm,pc:7,xm:this.userInfo.xm,yhid:this.userInfo.yhid};
        const loading=this.$loading({fullscreen:true,target:document.getElementById("zk-data"),text:"正在加载中"});
        const res=await this.$http.postHttp("/yhgz/selectYhgzYx",obj);
        if(res.data.code===200){
            this.zkCollectYx=res.data.obj;
            const len=res.data.obj.length;
            for(let i=0;i<len;i++){
                this.zkCollectYx[i].checked=this.zkCollectYx[i].isRed||false;
                this.zkCollectYx[i].index=(i+1);
            }
            if(len<10){
                for(let i=0;i<10-len;i++){
                const obj={};
                obj.index=len+(i+1);
                obj.checked=false;
                obj.sb=null;
                obj.yxmc=null;
                obj.zyz=null;
                obj.szdqmc=null;
                obj.pc=null;
                obj.sf985=null;
                obj.sf211=null;
                this.zkCollectYx.push(obj);
            }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 获取所有的关注院校
    async getYhGzYx(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("bk-data"),text:"正在加载中"});
        const res=await this.$http.getHttp("/yhgz/selectYhgzYx");
        if(res.data.code===200){
            if(!(res.data.obj.zk)&&!(res.data.obj.bk)){
            this.bkCollectYx=[...res.data.obj];
            const len=res.data.obj.length;
            for(let i=0;i<len;i++){
                this.bkCollectYx[i].checked=this.bkCollectYx[i].isRed||false;
                this.bkCollectYx[i].index=(i+1);
                this.bkCollectYx[i].xh=res.data.obj[i].xh||null;
            }
            if(len<10){
                for(let i=0;i<10-len;i++){
                const obj={};
                obj.index=len+(i+1);
                obj.checked=false;
                obj.sb=null;
                obj.yxmc=null;
                obj.zyz=null;
                obj.szdqmc=null;
                obj.pc=null;
                obj.sf985=null;
                obj.sf211=null;
                this.bkCollectYx.push(obj);
            }
            }
            }else{
                this.bkCollectYx=[...res.data.obj.bk];
                const len=res.data.obj.bk.length;
                for(let i=0;i<len;i++){
                this.bkCollectYx[i].checked=this.bkCollectYx[i].isRed||false;
                this.bkCollectYx[i].index=(i+1);
                this.bkCollectYx[i].xh=res.data.obj.bk[i].xh;
                }
                if(len<10){
                    for(let i=0;i<10-len;i++){
                    const obj={};
                    obj.index=len+(i+1);
                    obj.xh=len+(i+1);
                    obj.checked=false;
                    obj.sb=null;
                    obj.yxmc=null;
                    obj.zyz=null;
                    obj.szdqmc=null;
                    obj.pc=null;
                    obj.sf985=null;
                    obj.sf211=null;
                    this.bkCollectYx.push(obj);
                }
                }
                this.zkCollectYx=[...res.data.obj.zk];
                const len2=res.data.obj.zk.length;
                for(let i=0;i<len2;i++){
                this.zkCollectYx[i].checked=this.zkCollectYx[i].isRed||false;
                this.zkCollectYx[i].index=(i+1);
                this.zkCollectYx[i].xh=res.data.obj.zk[i].xh||null;
                }
                if(len2<10){
                    for(let i=0;i<10-len2;i++){
                    const obj={};
                    obj.index=len2+(i+1);
                    obj.xh=len2+(i+1);
                    obj.checked=false;
                    obj.sb=null;
                    obj.yxmc=null;
                    obj.zyz=null;
                    obj.szdqmc=null;
                    obj.pc=null;
                    obj.sf985=null;
                    obj.sf211=null;
                    this.zkCollectYx.push(obj);
                }
                }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 本科选中的行
    handleBkCurrentChange(val) {
        if(val){
            if(val.zyz){
            this.bkIndex=(val.index-1);
        }
        }
    },
    // 专科选中的行
    handleZkCurrentChange(val) {
        if(val){
            if(val.zyz){
            this.zkIndex=(val.index-1);
            console.log(val);
        }
        }
    },
    // 本科和专科的切换
    async handleClick(tab,event){
        // const activePcName=this.activePcName;
        const id=this.$route.query.id;
        const pc=this.$route.query.pc;
        const isto=this.$route.query.isto;
        if(id){
            if(isto){
            }else{
                await this.getYxzyzById(id,pc);
            }
        }else{
            // if(activePcName==='6'){
            // await this.getBkGzyx();
            // }else if(activePcName==='7'){
            //     await this.getZkGzyx();
            // }
        }
    },
    // 获取已保存的院校专业组列表
    async getMyYxzyz(){
        const yhid=this.userInfo.yhid;
        const res=await this.$http.getHttp("/yhgzfa/selectYxZyzFaByYhid?yhid="+yhid);
        if(res.data.code===200){
            this.yxzyzList=res.data.obj;
            this.isOpen=true;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.dataa.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 打开已保存的院校专业组
    async openYxzyz(){
        await this.getMyYxzyz();
        if(this.isOpen){
            this.dialogTableVisible=true;
        }
    },
    // 升序代码
    ascTableData(){
        const activePcName=this.activePcName;
        if(activePcName==='6'){
            const arr=[...this.bkCollectYx];
            const compareArr=[];
            const nullArr=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].pjtwf){
                    compareArr.push(arr[i]);
                }else{
                    nullArr.push(arr[i]);
                }
            }
            for(let i=0;i<compareArr.length;i++){
                for(let j=0;j<compareArr.length-i-1;j++){
                    if(compareArr[j].pjtwf>compareArr[j+1].pjtwf){
                        const temp=compareArr[j];
                        compareArr[j]=compareArr[j+1];
                        compareArr[j+1]=temp;
                    }
                }
            }
            if(nullArr.length>0){
                const bkArr=[...compareArr.concat(nullArr)];
                for(let i=0;i<bkArr.length;i++){
                    if(bkArr[i]){
                        bkArr[i].index=i+1;
                    }
                }
                this.bkCollectYx=[...bkArr];
                this.bkIndex=-1;
                this.$store.dispatch("saveCollectSchool",{collectSchool:bkArr,pc:this.activePcName});
            }else{
                const bkArr=[...compareArr];
                for(let i=0;i<bkArr.length;i++){
                    if(bkArr[i]){
                        bkArr[i].index=i+1;
                    }
                }
                this.bkCollectYx=[...bkArr];
                this.bkIndex=-1;
                this.$store.dispatch("saveCollectSchool",{collectSchool:bkArr,pc:this.activePcName});
            }
        }else{
            const arr=[...this.zkCollectYx];
            const compareArr=[];
            const nullArr=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].pjtwf){
                    compareArr.push(arr[i]);
                }else{
                    nullArr.push(arr[i]);
                }
            }
            for(let i=0;i<compareArr.length;i++){
                for(let j=0;j<compareArr.length-i-1;j++){
                    if(compareArr[j].pjtwf>compareArr[j+1].pjtwf){
                        const temp=compareArr[j];
                        compareArr[j]=compareArr[j+1];
                        compareArr[j+1]=temp;
                    }
                }
            }
            if(nullArr.length>0){
                const zkArr=[...compareArr.concat(nullArr)];
                for(let i=0;i<zkArr.length;i++){
                    if(zkArr[i]){
                        zkArr[i].index=i+1;
                    }
                }
                this.zkCollectYx=[...zkArr];
                this.zkIndex=-1;
                if(!(this.planObj.plan)){
                    this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:this.activePcName});
                }
            }else{
                const zkArr=[...compareArr];
                for(let i=0;i<zkArr.length;i++){
                    if(zkArr[i]){
                        zkArr[i].index=i+1;
                    }
                }
                this.zkCollectYx=[...zkArr];
                this.zkIndex=-1;
                if(!(this.planObj.plan)){
                    this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:this.activePcName});
                }
            }
        }
    },
    // 降序代码
    descTableData(){
        const activePcName=this.activePcName;
        if(activePcName==='6'){
            const arr=[...this.bkCollectYx];
            const compareArr=[];
            const nullArr=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].pjtwf){
                    compareArr.push(arr[i]);
                }else{
                    nullArr.push(arr[i]);
                }
            }
            for(let i=0;i<compareArr.length;i++){
                for(let j=0;j<compareArr.length-i-1;j++){
                    if(compareArr[j].pjtwf<compareArr[j+1].pjtwf){
                        const temp=compareArr[j];
                        compareArr[j]=compareArr[j+1];
                        compareArr[j+1]=temp;
                    }
                }
            }
            if(nullArr.length>0){
                const bkArr=[...compareArr.concat(nullArr)];
                for(let i=0;i<bkArr.length;i++){
                    if(bkArr[i]){
                        bkArr[i].index=i+1;
                    }
                }
                this.bkCollectYx=[...bkArr];
                this.bkIndex=-1;
                if(!(this.planObj.plan)){
                    this.$store.dispatch("saveCollectSchool",{collectSchool:bkArr,pc:this.activePcName});
                }
            }else{
                const bkArr=[...compareArr];
                for(let i=0;i<bkArr.length;i++){
                    if(bkArr[i]){
                        bkArr[i].index=i+1;
                    }
                }
                this.bkCollectYx=[...bkArr];
                this.bkIndex=-1;
                if(!(this.planObj.plan)){
                    this.$store.dispatch("saveCollectSchool",{collectSchool:bkArr,pc:this.activePcName});
                }
            }
        }else{
            const arr=[...this.zkCollectYx];
            const compareArr=[];
            const nullArr=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].pjtwf){
                    compareArr.push(arr[i]);
                }else{
                    nullArr.push(arr[i]);
                }
            }
            for(let i=0;i<compareArr.length;i++){
                for(let j=0;j<compareArr.length-i-1;j++){
                    if(compareArr[j].pjtwf<compareArr[j+1].pjtwf){
                        const temp=compareArr[j];
                        compareArr[j]=compareArr[j+1];
                        compareArr[j+1]=temp;
                    }
                }
            }
            if(nullArr.length>0){
                const zkArr=[...compareArr.concat(nullArr)];
                for(let i=0;i<zkArr.length;i++){
                    if(zkArr[i]){
                        zkArr[i].index=i+1;
                    }
                }
                this.zkCollectYx=[...zkArr];
                this.zkIndex=-1;
                if(!(this.planObj.plan)){
                    this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:this.activePcName});
                }
            }else{
                const zkArr=[...compareArr];
                for(let i=0;i<zkArr.length;i++){
                    if(zkArr[i]){
                        zkArr[i].index=i+1;
                    }
                }
                this.zkCollectYx=[...zkArr];
                this.zkIndex=-1;
                if(!(this.planObj.plan)){
                    this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:this.activePcName});
                }
            }
        }
    },
    // 移动到第一行
    moveFirst(){
        const pc=this.activePcName;
        if(pc==='6'){
            const index=this.bkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
        let bkArr=[...this.bkCollectYx];
        const moveObj=bkArr[index];
        bkArr.unshift(moveObj);
        // this.$set(this.bkCollectYx[index],'index',1);
        // this.$set(this.zkCollectYx[index],'index',index+1);
        bkArr[index+1]=null;
        bkArr=bkArr.filter((item)=>item!=null);
        this.bkCollectYx=bkArr;
        this.resetTableIndex();
        this.bkIndex=0;
        if(!(this.planObj.plan)){
            this.$store.dispatch("saveCollectSchool",{collectSchool:bkArr,pc:pc});
        }
        }else{
        const index=this.zkIndex;
        if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
        }
        let zkArr=[...this.zkCollectYx];
        const moveObj=zkArr[index];
        zkArr.unshift(moveObj);
        console.log(moveObj);
        // this.$set(this.zkCollectYx[index],'index',1);
        // this.$set(this.zkCollectYx[index-1],'index',index+1);
        zkArr[index+1]=null;
        zkArr=zkArr.filter((item)=>item!=null);
        this.zkCollectYx=zkArr;
        this.resetTableIndex();
        this.zkIndex=0;
        if(!(this.planObj.plan)){
            this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:pc});
        }
        }
    },
    // 重置表格内容每行的index属性
    resetTableIndex(){
        const pc=this.activePcName;
        if(pc==='6'){
            const bkArr=[...this.bkCollectYx];
            bkArr.forEach((item,index)=>{
                item.index=index+1;
            });
            this.bkCollectYx=bkArr;
        }else{
            const zkArr=[...this.zkCollectYx];
            zkArr.forEach((item,index)=>{
                item.index=index+1;
            });
            this.zkCollectYx=zkArr;
        }
    },
    // 前移一行
    movePrev(){
        const pc=this.activePcName;
        if(pc==='6'){
            const index=this.bkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }else if(index===0){
                return false;
            }
            const obj=this.bkCollectYx[index];
            const prevObj=this.bkCollectYx[index-1];
            obj.index=index;
            prevObj.index=index+1;
            this.$set(this.bkCollectYx,index,prevObj);
            this.$set(this.bkCollectYx,index-1,obj);
            this.bkIndex=index-1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.bkCollectYx,pc:pc});
            }
        }else{
            const index=this.zkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }else if(index===0){
                return;
            }
            const obj=this.zkCollectYx[index];
            const prevObj=this.zkCollectYx[index-1];
            obj.index=index;
            prevObj.index=index+1;
            this.$set(this.zkCollectYx,index,prevObj);
            this.$set(this.zkCollectYx,index-1,obj);
            this.zkIndex=index-1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.zkCollectYx,pc:pc});
            }
        }
    },
    // 移动到最后一行
    moveEnd(){
        const pc=this.activePcName;
        if(pc==='6'){
            const index=this.bkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            let bkArr=[...this.bkCollectYx];
            let dataCount=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].yxmc){
                    dataCount++;
                }
            }
            let haveData=[];
            const nullData=[];
            for(let i=0;i<bkArr.length;i++){
                if(i<dataCount){
                    haveData.push(bkArr[i]);
                }else{
                    nullData.push(bkArr[i]);
                }
            }
            const obj=haveData[index];
            haveData.push(obj);
            this.$set(this.bkCollectYx[index+1],"index",index+1);
            haveData[index]=null;
            haveData=haveData.filter(item=>item!=null);
            bkArr=haveData.concat(nullData);
            // this.$set(this.bkCollectYx[index],"index",haveData.length);
            // this.$set(this.bkCollectYx[haveData.length-1],"index",haveData.length-1);
            this.bkCollectYx=bkArr;
            this.resetTableIndex();
            this.bkIndex=haveData.length-1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.bkCollectYx,pc:pc});
            }
        }else{
            const index=this.zkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            let zkArr=[...this.zkCollectYx];
            let dataCount=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].yxmc){
                    dataCount++;
                }
            }
            let haveData=[];
            const nullData=[];
            for(let i=0;i<zkArr.length;i++){
                if(i<dataCount){
                    haveData.push(zkArr[i]);
                }else{
                    nullData.push(zkArr[i]);
                }
            }
            const obj=haveData[index];
            haveData.push(obj);
            console.log(this.zkCollectYx[index+1],index);
            this.$set(this.zkCollectYx[index+1],"index",index+1);
            haveData[index]=null;
            haveData=haveData.filter(item=>item!=null);
            zkArr=haveData.concat(nullData);
            console.log(haveData.length);
            // this.$set(this.zkCollectYx[index],"index",haveData.length);
            // this.$set(this.zkCollectYx[haveData.length-1],"index",haveData.length-1);
            this.zkCollectYx=zkArr;
            this.resetTableIndex();
            this.zkIndex=haveData.length-1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:zkArr,pc:pc});
            }
        }
    },
    // 后移一行
    moveNext(){
        const pc=this.activePcName;
        if(pc==='6'){
            const index=this.bkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            const bkArr=[...this.bkCollectYx];
            const obj=bkArr[index];
            const nextObj=bkArr[index+1];
            let count=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].yxmc){
                    count++;
                }
            }
            if(index+1>=count){
                return;
            }
            nextObj.index=index+1;
            obj.index=index+2;
            this.$set(this.bkCollectYx,index,nextObj);
            this.$set(this.bkCollectYx,index+1,obj);
            this.bkIndex=index+1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.bkCollectYx,pc:pc});
            }
        }else{
            const index=this.zkIndex;
            if(index===-1){
                this.$message({message:"请选择某一行",type:'warning',showClose:true});
                return;
            }
            const zkArr=[...this.zkCollectYx];
            const obj=zkArr[index];
            const nextObj=zkArr[index+1];
            let count=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].yxmc){
                    count++;
                }
            }
            if(index+1>=count){
                return;
            }
            nextObj.index=index+1;
            obj.index=index+2;
            this.$set(this.zkCollectYx,index,nextObj);
            this.$set(this.zkCollectYx,index+1,obj);
            this.zkIndex=index+1;
            if(!(this.planObj.plan)){
                this.$store.dispatch("saveCollectSchool",{collectSchool:this.zkCollectYx,pc:pc});
            }
        }
    },
    // 全选
    allCheck(){
        const pc=this.activePcName;
        if(pc==='6'){
            const bkArr=[...this.bkCollectYx];
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i]){
                    bkArr[i].checked=true;
                }
            }
            this.bkCollectYx=bkArr;
            this.updateBk=!this.updateBk;
        }else{
            const zkArr=[...this.zkCollectYx];
            for(let i=0;i<zkArr.length;i++){
                zkArr[i].checked=true;
            }
            this.zkCollectYx=zkArr;
            this.updateZk=!this.updateZk;
        }
    },
    // 取消全选
    allNoCheck(){
        const pc=this.activePcName;
        if(pc==='6'){
            const bkArr=[...this.bkCollectYx];
            for(let i=0;i<bkArr.length;i++){
                bkArr[i].checked=false;
            }
            this.bkCollectYx=bkArr;
            this.updateBk=!this.updateBk;
        }else{
            const zkArr=[...this.zkCollectYx];
            for(let i=0;i<zkArr.length;i++){
                zkArr[i].checked=false;
            }
            this.zkCollectYx=zkArr;
            this.updateZk=!this.updateZk;
        }
    },
    // 改变本科多选框
    bkCheckChange(checked,obj){
        // this.updateBk=!this.updateBk;
        this.$forceUpdate();
    },
    // 改变专科多选框
    zkCheckChange(checked,obj){
        // this.updateZk=!this.updateZk;
        this.$forceUpdate();
    },
    // 保存院校专业组
    async saveYxzyz(){
        this.dialogPlanVisible=true;
        await this.getMyYxzyz();
        const id=this.planObj.id;
        if(id){
            this.selectedYxzyz=parseInt(id);
        }
    },
    // 保存代码
    async saveYxzyzCode(){
        const pc=this.activePcName;
        if(pc==='6'){
            const obj={};
            obj.bz=this.userInfo.bz;
            obj.date=new Date().toLocaleDateString()+new Date().toLocaleTimeString();
            obj.dfzxzg=this.userInfo.dfzxzg;
            obj.flag=0;
            obj.gjzxzg=this.userInfo.gjzxzg;
            obj.gkfs=this.userInfo.gkfs;
            obj.id=this.selectedYxzyz;
            if(this.selectedYxzyz===''){
                this.$message({message:"请选择方案",showClose:true});
                return false;
            }
            obj.kl=this.userInfo.sxkm;
            obj.param="";
            const id=this.selectedYxzyz;
            const planObj=this.yxzyzList.find(item=>item.id===id);
            if(planObj){
                obj.plan=planObj.plan;
            }
            if(planObj.plan.indexOf('(原始)')>=0){
                this.$message({
                    message:'原始方案不允许修改',
                    type:'warning',
                    showClose:true
                });
                return;
            }
            obj.school=this.userInfo.school;
            obj.sjhm=this.userInfo.sjhm;
            obj.sssf=this.userInfo.sssf;
            obj.sxcj=this.userInfo.sxcj;
            obj.sxkm=this.userInfo.sxkm;
            obj.sxkmcj=this.userInfo.sxkmcj;
            obj.wycj=this.userInfo.wycj;
            obj.xb=this.userInfo.xb;
            obj.xm=this.userInfo.xm;
            obj.yfjf=this.userInfo.yfjf;
            obj.yhid=this.userInfo.yhid;
            obj.yhlb=this.userInfo.yhlb;
            obj.ywcj=this.userInfo.ywcj;
            obj.zckh=this.userInfo.zckh;
            obj.zxkma=this.userInfo.zxkma;
            obj.zxkmacj=this.userInfo.zxkmacj;
            obj.zxkmb=this.userInfo.zxkmb;
            obj.zxkmbcj=this.userInfo.zxkmbcj;
            obj.pc=pc;
            const list=[];
            const bkArr=[...this.bkCollectYx];
            let count=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].yxmc&&bkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].yxmc){
                if(count>0){
                    if(bkArr[i].checked){
                        const o={};
                        o.createtime="";
                        o.faid=this.selectedYxzyz;
                        o.id="";
                        // o.fs=this.userInfo.gkfs;
                        // o.xm=this.userInfo.xm;
                        // o.yhid=this.userInfo.yhid;
                        o.kl=this.userInfo.sxkm;
                        o.pc=pc;
                        o.status=bkArr[i].checked?1:0;
                        o.updatetime="";
                        o.xh=i+1;
                        o.yxxh=bkArr[i].yxxh;
                        o.zyz=bkArr[i].zyz;
                        o.yxmc=bkArr[i].yxmc;
                        o.sb=bkArr[i].sb;
                        list.push(o);
                    }
                }else{
                    const o={};
                    o.createtime="";
                    o.faid=this.selectedYxzyz;
                    o.id="";
                    // o.fs=this.userInfo.gkfs;
                    // o.xm=this.userInfo.xm;
                    // o.yhid=this.userInfo.yhid;
                    o.kl=this.userInfo.sxkm;
                    o.pc=pc;
                    o.status=bkArr[i].checked?1:0;
                    o.updatetime="";
                    o.xh=i+1;
                    o.yxxh=bkArr[i].yxxh;
                    o.zyz=bkArr[i].zyz;
                    o.yxmc=bkArr[i].yxmc;
                    o.sb=bkArr[i].sb;
                    list.push(o);
                }
                }
            }
            obj.list=list;
            const res=await this.$http.postHttp("/yhgzfa/saveOrUpdateGzyxFa",obj);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'success',showClose:true});
                }else{
                this.$message({message:"保存成功",type:"success",showClose:true});
                }
                this.dialogPlanVisible=false;
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const obj={};
            obj.bz=this.userInfo.bz;
            obj.date=new Date().toLocaleDateString()+new Date().toLocaleTimeString();
            obj.dfzxzg=this.userInfo.dfzxzg;
            obj.flag=0;
            obj.gjzxzg=this.userInfo.gjzxzg;
            obj.gkfs=this.userInfo.gkfs;
            obj.id=this.selectedYxzyz;
            if(this.selectedYxzyz===''){
                this.$message({message:"请选择方案",showClose:true});
                return false;
            }
            obj.kl=this.userInfo.sxkm;
            obj.param="";
            const id=this.selectedYxzyz;
            const planObj=this.yxzyzList.find(item=>item.id===id);
            if(planObj){
                obj.plan=planObj.plan;
            }
            if(planObj.plan.indexOf('(原始)')>=0){
                this.$message({
                    message:'原始方案不允许修改',
                    type:'warning',
                    showClose:true
                });
                return;
            }
            obj.school=this.userInfo.school;
            obj.sjhm=this.userInfo.sjhm;
            obj.sssf=this.userInfo.sssf;
            obj.sxcj=this.userInfo.sxcj;
            obj.sxkm=this.userInfo.sxkm;
            obj.sxkmcj=this.userInfo.sxkmcj;
            obj.wycj=this.userInfo.wycj;
            obj.xb=this.userInfo.xb;
            obj.xm=this.userInfo.xm;
            obj.yfjf=this.userInfo.yfjf;
            obj.yhid=this.userInfo.yhid;
            obj.yhlb=this.userInfo.yhlb;
            obj.ywcj=this.userInfo.ywcj;
            obj.zckh=this.userInfo.zckh;
            obj.zxkma=this.userInfo.zxkma;
            obj.zxkmacj=this.userInfo.zxkmacj;
            obj.zxkmb=this.userInfo.zxkmb;
            obj.zxkmbcj=this.userInfo.zxkmbcj;
            obj.pc=pc;
            const list=[];
            const zkArr=[...this.zkCollectYx];
            let count=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].yxmc){
                if(count>0){
                    if(zkArr[i].checked){
                        const o={};
                        o.createtime="";
                        o.faid=this.selectedYxzyz;
                        o.id="";
                        o.kl=this.userInfo.sxkm;
                        o.pc=pc;
                        o.status=zkArr[i].checked?1:0;
                        o.updatetime="";
                        o.xh=i+1;
                        o.yxxh=zkArr[i].yxxh;
                        o.zyz=zkArr[i].zyz;
                        o.yxmc=zkArr[i].yxmc;
                        o.sb=zkArr[i].sb;
                        list.push(o);
                    }
                }else{
                    const o={};
                    o.createtime="";
                    o.faid=this.selectedYxzyz;
                    o.id="";
                    o.kl=this.userInfo.sxkm;
                    o.pc=pc;
                    o.status=zkArr[i].checked?1:0;
                    o.updatetime="";
                    o.xh=i+1;
                    o.yxxh=zkArr[i].yxxh;
                    o.zyz=zkArr[i].zyz;
                    o.yxmc=zkArr[i].yxmc;
                    o.sb=zkArr[i].sb;
                    list.push(o);
                }
                }
            }
            obj.list=list;
            const res=await this.$http.postHttp("/yhgzfa/saveOrUpdateGzyxFa",obj);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'success',showClose:true});
                }else{
                    this.$message({message:"保存成功",type:"success",showClose:true});
                }
                this.dialogPlanVisible=false;
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 另存为代码
    async saveOtherCode(){
        const pc=this.activePcName;
        if(pc==='6'){
            const obj={};
            obj.bz=this.userInfo.bz;
            obj.date=new Date().toLocaleDateString()+new Date().toLocaleTimeString();
            obj.dfzxzg=this.userInfo.dfzxzg;
            obj.flag=0;
            obj.gjzxzg=this.userInfo.gjzxzg;
            obj.gkfs=this.userInfo.gkfs;
            obj.id="";
            obj.kl=this.userInfo.sxkm;
            obj.param="";
            if(this.otherPlan===''){
                this.$message({message:"请输入方案名",showClose:true});
                return;
            }
            obj.plan=this.otherPlan;
            obj.school=this.userInfo.school;
            obj.xxmc=this.userInfo.xxmc;
            obj.sjhm=this.userInfo.sjhm;
            obj.sssf=this.userInfo.sssf;
            obj.sxcj=this.userInfo.sxcj;
            obj.sxkm=this.userInfo.sxkm;
            obj.sxkmcj=this.userInfo.sxkmcj;
            obj.wycj=this.userInfo.wycj;
            obj.xb=this.userInfo.xb;
            obj.xm=this.userInfo.xm;
            obj.yfjf=this.userInfo.yfjf;
            obj.yhid=this.userInfo.yhid;
            obj.yhlb=this.userInfo.yhlb;
            obj.ywcj=this.userInfo.ywcj;
            obj.zckh=this.userInfo.zckh;
            obj.zxkma=this.userInfo.zxkma;
            obj.zxkmacj=this.userInfo.zxkmacj;
            obj.zxkmb=this.userInfo.zxkmb;
            obj.zxkmbcj=this.userInfo.zxkmbcj;
            obj.pc=pc;
            const list=[];
            const bkArr=[...this.bkCollectYx];
            let count=0;
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].yxmc){
                if(count>0){
                    if(bkArr[i].checked){
                        const o={};
                        o.createtime="";
                        o.faid=this.selectedYxzyz;
                        o.id="";
                        // o.fs=this.userInfo.gkfs;
                        // o.xm=this.userInfo.xm;
                        // o.yhid=this.userInfo.yhid;
                        o.kl=this.userInfo.sxkm;
                        o.pc=pc;
                        o.status=bkArr[i].checked?1:0;
                        o.updatetime="";
                        o.xh=(i+1);
                        o.yxxh=bkArr[i].yxxh;
                        o.zyz=bkArr[i].zyz;
                        o.yxmc=bkArr[i].yxmc;
                        o.sb=bkArr[i].sb;
                        list.push(o);
                    }
                }else{
                    const o={};
                    o.createtime="";
                    o.faid=this.selectedYxzyz;
                    o.id="";
                    // o.fs=this.userInfo.gkfs;
                    // o.xm=this.userInfo.xm;
                    // o.yhid=this.userInfo.yhid;
                    o.kl=this.userInfo.sxkm;
                    o.pc=pc;
                    o.status=bkArr[i].checked?1:0;
                    o.updatetime="";
                    o.xh=(i+1);
                    o.yxxh=bkArr[i].yxxh;
                    o.zyz=bkArr[i].zyz;
                    o.yxmc=bkArr[i].yxmc;
                    o.sb=bkArr[i].sb;
                    list.push(o);
                }
                }
            }
            obj.list=list;
            const res=await this.$http.postHttp("/yhgzfa/saveOrUpdateGzyxFa",obj);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'success',showClose:true});
                }else{
                    this.$message({message:"保存成功",type:"success",showClose:true});
                }
                this.dialogOtherVisible=false;
                this.dialogPlanVisible=false;
                this.otherPlan='';
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const obj={};
            obj.bz=this.userInfo.bz;
            obj.date=new Date().toLocaleDateString()+new Date().toLocaleTimeString();
            obj.dfzxzg=this.userInfo.dfzxzg;
            obj.flag=0;
            obj.gjzxzg=this.userInfo.gjzxzg;
            obj.gkfs=this.userInfo.gkfs;
            obj.id="";
            obj.kl=this.userInfo.sxkm;
            obj.param="";
            if(this.otherPlan===''){
                this.$message({message:"请输入方案名",showClose:true});
                return;
            }
            obj.plan=this.otherPlan;
            obj.school=this.userInfo.school;
            obj.xxmc=this.userInfo.xxmc;
            obj.sjhm=this.userInfo.sjhm;
            obj.sssf=this.userInfo.sssf;
            obj.sxcj=this.userInfo.sxcj;
            obj.sxkm=this.userInfo.sxkm;
            obj.sxkmcj=this.userInfo.sxkmcj;
            obj.wycj=this.userInfo.wycj;
            obj.xb=this.userInfo.xb;
            obj.xm=this.userInfo.xm;
            obj.yfjf=this.userInfo.yfjf;
            obj.yhid=this.userInfo.yhid;
            obj.yhlb=this.userInfo.yhlb;
            obj.ywcj=this.userInfo.ywcj;
            obj.zckh=this.userInfo.zckh;
            obj.zxkma=this.userInfo.zxkma;
            obj.zxkmacj=this.userInfo.zxkmacj;
            obj.zxkmb=this.userInfo.zxkmb;
            obj.zxkmbcj=this.userInfo.zxkmbcj;
            obj.pc=pc;
            const list=[];
            const zkArr=[...this.zkCollectYx];
            let count=0;
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].checked){
                    count++;
                }
            }
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].yxmc){
                if(count>0){
                    if(zkArr[i].checked){
                        const o={};
                        o.createtime="";
                        o.faid=this.selectedYxzyz;
                        o.id="";
                        o.kl=this.userInfo.sxkm;
                        o.pc=pc;
                        o.status=zkArr[i].checked?1:0;
                        o.updatetime="";
                        o.xh=(i+1);
                        o.yxxh=zkArr[i].yxxh;
                        o.zyz=zkArr[i].zyz;
                        o.yxmc=zkArr[i].yxmc;
                        o.sb=zkArr[i].sb;
                        list.push(o);
                    }
                }else{
                    const o={};
                    o.createtime="";
                    o.faid=this.selectedYxzyz;
                    o.id="";
                    o.kl=this.userInfo.sxkm;
                    o.pc=pc;
                    o.status=zkArr[i].checked?1:0;
                    o.updatetime="";
                    o.xh=(i+1);
                    o.yxxh=zkArr[i].yxxh;
                    o.zyz=zkArr[i].zyz;
                    o.yxmc=zkArr[i].yxmc;
                    o.sb=zkArr[i].sb;
                    list.push(o);
                }
                }
            }
            obj.list=list;
            const res=await this.$http.postHttp("/yhgzfa/saveOrUpdateGzyxFa",obj);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({message:res.data.message,type:"success",showClose:true});
                }else{
                    this.$message({message:"保存成功",type:"success",showClose:true});
                }
                this.dialogOtherVisible=false;
                this.dialogPlanVisible=false;
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 打开另存为弹窗
    async openOther(){
        this.dialogOtherVisible=true;
    },
    // 关闭另存为窗口
    closeOther(){
        this.dialogOtherVisible=false;
        this.dialogPlanVisible=false;
        this.otherPlan='';
    },
    // 删除院校
    async deleteSchool(){
        // const pc=this.activePcName;
        // if(pc==='6'){
        //     const checkedIndexArr=[];
        //     let bkArr=[...this.bkCollectYx];
        //     for(let i=0;i<bkArr.length;i++){
        //         if(bkArr[i].checked){
        //             checkedIndexArr.push(i);
        //         }
        //     }
        //     for(let i=0;i<checkedIndexArr.length;i++){
        //             if(checkedIndexArr[i]>=0){
        //                 bkArr[checkedIndexArr[i]]=null;
        //             }
        //     }
        //     bkArr=bkArr.filter(item=>item!=null);
        //     const len=checkedIndexArr.length>45?45:checkedIndexArr.length;
        //     for(let i=0;i<len;i++){
        //             const obj={};
        //             obj.index=bkArr.length;
        //             obj.checked=false;
        //             obj.sb=null;
        //             obj.yxmc=null;
        //             obj.zyz=null;
        //             obj.szdqmc=null;
        //             obj.pc=null;
        //             obj.sf985=null;
        //             obj.sf211=null;
        //             bkArr.push(obj);
        //     }
        //     this.bkCollectYx=bkArr;
        // }else{
        //     const checkedIndexArr=[];
        //     let zkArr=[...this.zkCollectYx];
        //     for(let i=0;i<zkArr.length;i++){
        //         if(zkArr[i].checked){
        //             checkedIndexArr.push(i);
        //         }
        //     }
        //     for(let i=0;i<checkedIndexArr.length;i++){
        //         if(checkedIndexArr[i]>=0){
        //             zkArr[checkedIndexArr[i]]=null;
        //         }
        //     }
        //     zkArr=zkArr.filter(item=>item!=null);
        //     const len=checkedIndexArr.length>45?45:checkedIndexArr.length;
        //     for(let i=0;i<len;i++){
        //             const obj={};
        //             obj.index=zkArr.length;
        //             obj.checked=false;
        //             obj.sb=null;
        //             obj.yxmc=null;
        //             obj.zyz=null;
        //             obj.szdqmc=null;
        //             obj.pc=null;
        //             obj.sf985=null;
        //             obj.sf211=null;
        //             zkArr.push(obj);
        //     }
        //     this.zkCollectYx=zkArr;
        // }
        const pc=this.activePcName;
        if(pc==='6'){
            const bkArr=[...this.bkCollectYx];
            const delArr=[];
            for(let i=0;i<bkArr.length;i++){
                if(bkArr[i].checked&&bkArr[i].yxmc){
                    const obj={};
                    obj.fs=this.userInfo.gkfs;
                    obj.gzsj=bkArr[i].gzsj;
                    obj.kl=this.userInfo.kl;
                    obj.pc=bkArr[i].pc;
                    obj.status=bkArr[i].checked?'1':'0';
                    obj.xh=bkArr[i].xh;
                    obj.xm=this.userInfo.xm;
                    obj.yhid=this.userInfo.yhid;
                    obj.yxmc=bkArr[i].yxmc;
                    obj.yxxh=bkArr[i].yxxh;
                    obj.zyz=bkArr[i].zyz;
                    delArr.push(obj);
                }
            }
            const res=await this.$http.postHttp('/yhgz/delYhgzYx',delArr);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({
                        message:res.data.message,
                        type:'success',
                        showClose:true
                    });
                }else{
                    this.$message({
                        message:'操作成功',
                        type:'success',
                        showClose:true
                    });
                }
                const id=this.$route.query.id;
                // const pc=this.$route.query.pc;
                const isto=this.$route.query.isto;
                if(id){
                    if(isto){
                        // await this.getYhGzYx();
                        this.reloadTimer=setInterval(async () => {
                            if(this.reloadGzYx&&this.reloadGzYx==='true'){
                                clearInterval(this.reloadTimer);
                                await this.getYhGzYx();
                            }
                        }, 1000);
                    }else{
                        // await this.getYxzyzById(id,pc);
                        await this.getYhGzYx();
                    }
                }else{
                    // await this.getBkGzyx();
                    await this.getYhGzYx();
                }
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const zkArr=[...this.zkCollectYx];
            const delArr=[];
            for(let i=0;i<zkArr.length;i++){
                if(zkArr[i].checked&&zkArr[i].yxmc){
                    const obj={};
                    obj.fs=this.userInfo.gkfs;
                    obj.gzsj=zkArr[i].gzsj;
                    obj.kl=this.userInfo.kl;
                    obj.pc=zkArr[i].pc;
                    obj.status=zkArr[i].checked?'1':'0';
                    obj.xh=zkArr[i].xh;
                    obj.xm=this.userInfo.xm;
                    obj.yhid=this.userInfo.yhid;
                    obj.yxmc=zkArr[i].yxmc;
                    obj.yxxh=zkArr[i].yxxh;
                    obj.zyz=zkArr[i].zyz;
                    delArr.push(obj);
                }
            }
            const res=await this.$http.postHttp('/yhgz/delYhgzYx',delArr);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({
                        message:res.data.message,
                        type:'success',
                        showClose:true
                    });
                }else{
                    this.$message({
                        message:'操作成功',
                        type:'success',
                        showClose:true
                    });
                }
                const id=this.$route.query.id;
                // const pc=this.$route.query.pc;
                const isto=this.$route.query.isto;
                if(id){
                    if(isto){
                        // await this.getYhGzYx();
                        this.reloadTimer=setInterval(async () => {
                            if(this.reloadGzYx&&this.reloadGzYx==='true'){
                                clearInterval(this.reloadTimer);
                                await this.getYhGzYx();
                            }
                        }, 1000);
                    }else{
                        // await this.getYxzyzById(id,pc);
                        await this.getYhGzYx();
                    }
                }else{
                    // await this.getBkGzyx();
                    await this.getYhGzYx();
                }
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 重新设置index属性
    setIndex(arr){
        for(let i=0;i<arr.length;i++){
            arr[i].index=(i+1);
        }
        return arr;
    },
    // 打开已保存的院校专业组
    watchYxzyz(id,pc){
        this.dialogTableVisible=false;
        const url=this.$router.resolve({
            path:"/zypg",
            query:{id,pc}
        });
        window.open(url.href,"_blank");
    },
    // 通过id查询关注的院校专业组
    async getYxzyzById(id,pc=6){
        let loadId="bk-data";
        if(pc===6||pc==='6'){
            loadId="bk-data";
            const arr=[];
            for(let i=0;i<10;i++){
                    const obj={};
                    obj.index=(i+1);
                    obj.xh=i+1;
                    obj.checked=false;
                    obj.sb=null;
                    obj.yxmc=null;
                    obj.zyz=null;
                    obj.szdqmc=null;
                    obj.pc=null;
                    obj.sf985=null;
                    obj.sf211=null;
                    arr.push(obj);
                }
                this.zkCollectYx=arr;
        }else{
            loadId="zk-data";
            const arr=[];
            for(let i=0;i<10;i++){
                    const obj={};
                    obj.index=(i+1);
                    obj.xh=i+1;
                    obj.checked=false;
                    obj.sb=null;
                    obj.yxmc=null;
                    obj.zyz=null;
                    obj.szdqmc=null;
                    obj.pc=null;
                    obj.sf985=null;
                    obj.sf211=null;
                    arr.push(obj);
                }
                this.bkCollectYx=arr;
        }
        this.activePcName=pc+'';
        const loading=this.$loading({fullscreen:true,target:document.getElementById(loadId),text:"正在加载中"});
        const res=await this.$http.getHttp("/yhgzfa/selectFaById?id="+id);
        if(res.data.code===200){
            this.planObj.plan=res.data.obj.plan;
            this.planObj.time=res.data.obj.date;
            this.planObj.id=id;
            if(res.data.obj.users){
                this.$store.dispatch('saveUserInfo',res.data.obj.users);
            }
            const user={};
            user.bz=res.data.obj.users.bz;
            user.gjzxzg=res.data.obj.users.gjzxzg;
            user.dfzxzg=res.data.obj.users.dfzxzg;
            user.gkfs=res.data.obj.users.gkfs;
            user.sxkm=res.data.obj.users.sxkm;
            user.school=res.data.obj.users.school;
            user.sjhm=res.data.obj.users.sjhm;
            user.sssf=res.data.obj.users.sssf;
            user.sxcj=res.data.obj.users.sxcj;
            user.sxkmcj=res.data.obj.users.sxkmcj;
            user.wycj=res.data.obj.users.wycj;
            user.xb=res.data.obj.users.xb;
            user.xm=res.data.obj.users.xm;
            user.yfjf=res.data.obj.users.yfjf;
            user.yhid=res.data.obj.users.yhid;
            user.yhlb=res.data.obj.users.yhlb;
            user.ywcj=res.data.obj.users.ywcj;
            user.zxkma=res.data.obj.users.zxkma;
            user.zxkmacj=res.data.obj.users.zxkmacj;
            user.zxkmb=res.data.obj.users.zxkmb;
            user.zxkmbcj=res.data.obj.users.zxkmbcj;
            // const yhlb=this.userInfo.yhlb;
            // if(yhlb===7||yhlb===9){
            //     await this.updateInfo(user);
            // }
            const arr=[...res.data.obj.obj];
            if(arr){
                for(let i=0;i<arr.length;i++){
                arr[i].checked=arr[i].isRed||false;
                arr[i].index=i+1;
                arr[i].xh=res.data.obj.obj[i].xh||null;
            }
            const len=arr.length;
            if(len<10){
                for(let i=0;i<10-len;i++){
                    const obj={};
                    obj.index=len+(i+1);
                    obj.xh=len+(i+1);
                    obj.checked=false;
                    obj.sb=null;
                    obj.yxmc=null;
                    obj.zyz=null;
                    obj.szdqmc=null;
                    obj.pc=null;
                    obj.sf985=null;
                    obj.sf211=null;
                    arr.push(obj);
                }
                if(pc===6||pc==='6'){
                    this.bkCollectYx=arr;
                }else{
                    this.zkCollectYx=arr;
                }
            }
            if(pc===6||pc==='6'){
                    this.bkCollectYx=arr;
            }else{
                    this.zkCollectYx=arr;
            }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
        // 修改分数
    async updateInfo(user){
        const obj={};
        obj.bz=user.bz;
        obj.gjzxzg=user.gjzxzg;
        obj.dfzxzg=user.dfzxzg;
        obj.dzyx="";
        obj.enabled=true;
        obj.gkfs=user.gkfs;
        obj.gkfsxg=this.userInfo.gkfsxg;
        obj.khzcsj=this.userInfo.khzcsj;
        obj.kl=user.sxkm;
        obj.ksxm=this.userInfo.ksxm;
        obj.openid=this.userInfo.openid;
        obj.password=this.userInfo.password;
        obj.school=user.school;
        obj.sjhm=user.sjhm;
        obj.sssf=user.sssf;
        obj.sxcj=user.sxcj;
        obj.sxkm=user.sxkm;
        obj.sxkmcj=user.sxkmcj;
        obj.username=this.userInfo.username;
        obj.wycj=user.wycj;
        obj.xb=user.xb;
        obj.xm=user.xm;
        obj.yfjf=user.yfjf;
        obj.yfjf3=this.userInfo.yfjf3;
        obj.yfjf5=this.userInfo.yfjf5;
        obj.yfjf10=this.userInfo.yfjf10;
        obj.yfjf20=this.userInfo.yfjf20;
        obj.yhid=user.yhid;
        obj.yhlb=user.yhlb;
        obj.yhzcsj=this.userInfo.yhzcsj;
        obj.ywcj=user.ywcj;
        obj.zcjd=this.userInfo.zcjd;
        obj.zckh=this.userInfo.zckh;
        obj.zxkma=user.zxkma;
        obj.zxkmb=user.zxkmb;
        obj.zxkmacj=user.zxkmacj;
        obj.zxkmbcj=user.zxkmbcj;
        const res=await this.$http.postHttp("/users/modifyFs",obj);
        if(res.data.code===200){
            console.log("updateUser");
            // this.$message({message:"修改成功",type:"success",showClose:true});
            await this.getUser();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
        // 获取用户信息
    async getUser(){
        const res=await this.$http.getHttp("/users/getUserInfo?username="+this.userInfo.username);
        if(res.status===200){
            if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else{
                this.$store.dispatch("saveUserInfo",res.data);
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 获取产品信息
    async getCpxx(cpid){
        const res=await this.$http.getHttp("/cpxx/getCpxxById?cpid="+cpid);
        if(res.data.code===200){
            this.buyInfo.type=res.data.obj.id+"";
            this.buyInfo.payPrice=res.data.obj.xsjg;
            this.buyInfo.cpmc=res.data.obj.cpmc;
            this.buyInfo.sssf=this.userInfo.sssf+"";
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 获取订单信息
    async getDdxx(type=1){
        if(type===1){
            this.zfbTime=90;
            this.ddTimer=setInterval(async () => {
                this.zfbTime--;
                const ddh=localStorage.getItem("aipayDdh");
                if(ddh){
                    const res=await this.$http.getHttp("/ddxx/getDdxxByDdh?ddh="+ddh);
                    if(res.data.code===200){
                        const zt=res.data.obj.ddzt;
                        if(zt===2){
                        this.freeDownload();
                        localStorage.removeItem("aipayDdh");
                        clearInterval(this.ddTimer);
                        }
                    }else if(res.data.code===500){
                        if(res.data.message){
                            this.$message({message:res.data.message,type:'error'});
                        }
                    }else if(res.status===401){
                        clearInterval(this.ddTimer);
                        this.$message({message: '登录超时，请重新登录',type:"error"});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }else if(res.data.code===0){
                        clearInterval(this.ddTimer);
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }else if(res.status===409||res.status===410||res.status===411){
                        clearInterval(this.ddTimer);
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                    }
                }
                if(this.zfbTime===0){
                    clearInterval(this.ddTimer);
                    this.zfbTime=0;
                    localStorage.removeItem("aipayDdh");
                }
        }, 1000);
        }else{
            const ddh=this.wxDdh;
            const res=await this.$http.getHttp("/ddxx/getDdxxByDdh?ddh="+ddh);
            if(res.data.code===200){
                        const zt=res.data.obj.ddzt;
                        if(zt===2){
                            this.wxSuccess=true;
                        }
            }else if(res.data.code===500){
                        if(res.data.message){
                            this.$message({message:res.data.message,type:'error'});
                        }
            }else if(res.status===401){
                        this.$message({message: '登录超时，请重新登录',type:"error"});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
            }else if(res.data.code===0){
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 下载一次
    async yhDownload(){
        await this.getCpxx(12);
        this.dialogBuyVisible=true;
    },
    // 永久下载
    async yjDownload(){
        await this.getCpxx(13);
        this.dialogBuyVisible=true;
    },
    // 关闭二维码弹窗
    closeDialog(){
        this.dialogBuyVisible=false;
        this.innerdialogVisible=false;
        clearInterval(this.intervalQrcodeTime);
    },
    // 支付下载
    async payDown(){
        const obj={};
        obj.code="";
        obj.cpid=this.buyInfo.type;
        obj.ddh="";
        obj.payType=this.buyInfo.payType;
        obj.sf=this.buyInfo.sssf;
        obj.sjhm="";
        obj.sum=this.buyInfo.num;
        obj.codeid="";
        const res=await this.$http.postHttp("/ddxx/ddSubmit",obj);
        if(res.data.code===200){
            if(this.buyInfo.payType===1){
            // 支付宝支付
            const ddh=res.data.obj.orderId;
            const res2=await this.$http.getHttp("/pay/aliPay?ddh="+ddh);
            localStorage.setItem("aipayDdh",ddh);
            const routerData = this.$router.resolve({
            path: "/payGateWay",
            query: { htmlData: res2.data }
          });
            await this.getDdxx(1);
            this.dialogBuyVisible=false;
            window.open(routerData.href,"_blank");
            }else {
          // 2.微信支付
          this.innerTitle = `微信支付-￥${this.buyInfo.payPrice}`;
          this.innerdialogVisible = true;
          this.dialogBuyVisible = false;
          this.wxDdh=res.data.obj.orderId;
          const obj={};
          obj.orderId=res.data.obj.orderId;
          obj.productName=this.buyInfo.cpmc;
          obj.orderAmount=this.buyInfo.payPrice;
          const result = await this.$http.postHttp("/pay/wxPay",obj);
          console.log(result,QRCode);
        //   if (result.code === 200) {
        //     this.out_trade_no = result.data.out_trade_no;
        //     this.$refs.qrCodeDiv.innerHTML = "";
        //     let qrcode = new QRCode("qrcode", {
        //       width: 132,
        //       height: 132,
        //       text: result.data.urlCode, // 二维码地址
        //       colorDark: "#000",
        //       colorLight: "#fff"
        //     });
        //     //设置定时器
        //     this.qrcodeTime = 60;
        //     this.intervalQrcodeTime = setInterval(async () => {
        //       this.qrcodeTime--;
        //       if (this.qrcodeTime === 0) {
        //         clearInterval(this.intervalQrcodeTime);
        //         this.innerdialogVisible = false;
        //       }
        //     })
        //     }
            if(result.data.code===200){
                this.qrcodeTime=60;
                        this.$nextTick(()=>{
                            this.$refs.qrCodeDiv.innerHTML = "";
                            const qrcode = new QRCode("qrcode", {
                                width: 132,
                                height: 132,
                                text: result.data.obj.urlCode, // 二维码地址
                                colorDark: "#000",
                                colorLight: "#fff"
                            });
                            console.log(qrcode);
                        });
                this.intervalQrcodeTime=setInterval(async () => {
                    this.qrcodeTime--;
                    if (this.qrcodeTime === 0) {
                    clearInterval(this.intervalQrcodeTime);
                    this.innerdialogVisible = false;
                    }
                    await this.getDdxx(2);
                    if(this.wxSuccess){
                        this.innerdialogVisible=false;
                        this.qrcodeTime=0;
                        this.freeDownload();
                        this.wxSuccess=false;
                        clearInterval(this.intervalQrcodeTime);
                    }
                }, 1000);
            }
        }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 咨询师免费下载
    async freeDownload(){
        const pc=this.activePcName;
        if(pc==='6'||pc===6){
            const collectYx=this.bkCollectYx;
            const arr=[];
            const list=[];
            for(let i=0;i<collectYx.length;i++){
                if(collectYx[i].yxmc){
                    const pcmc=collectYx[i].pc===6?'本科批':'专科批';
                    const sb=!collectYx[i].sb?0:collectYx[i].sb+"";
                    const yxmc=!collectYx[i].yxmc?0:collectYx[i].yxmc;
                    const zyz=!collectYx[i].zyz?0:collectYx[i].zyz+"";
                    const szdqmc=!collectYx[i].szdqmc?0:collectYx[i].szdqmc;
                    const sf211=collectYx[i].sf211===1?'是':'否';
                    const sf985=collectYx[i].sf985===1?'是':'否';
                    const snjhs=!collectYx[i].snjhs?0:collectYx[i].snjhs+"";
                    const jnjhs=!collectYx[i].jnjhs?0:collectYx[i].jnjhs+"";
                    const rdwc=!collectYx[i].rdwc?0:collectYx[i].rdwc+"";
                    const snrdwc=!collectYx[i].snrdwc?0:collectYx[i].snrdwc+"";
                    const tdx=!collectYx[i].tdx?0:collectYx[i].tdx+"";
                    const zdtwf=!collectYx[i].zdtwf?0:collectYx[i].zdtwf+"";
                    const pjtwf=!collectYx[i].pjtwf?0:collectYx[i].pjtwf+"";
                    const zgtwf=!collectYx[i].zgtwf?0:collectYx[i].zgtwf+"";
                    arr.push(sb+'-'+yxmc+'-'+zyz+'-'+pcmc+'-'+szdqmc+'-'+sf211+'-'+sf985+'-'+
                    snjhs+'-'+jnjhs+'-'+rdwc+'-'+snrdwc+'-'+tdx+'-'+zdtwf+'-'+
                    pjtwf+'-'+zgtwf);
                    list.push({
                        xh:i+1,
                        dnjhs:jnjhs,
                        pc:pcmc,
                        pjRdwc:rdwc,
                        pjtwf,
                        sb,
                        sf211,
                        sf985,
                        sheng:szdqmc,
                        snRdwc:snrdwc,
                        snjhs,
                        tdx,
                        yxmc,
                        zdtwf,
                        zgtwf,
                        zyz
                    });
                }
            }
            // const param=arr.join(",");
            // $("#down_param").val(param);
            // $("#down_list").val(JSON.stringify(list));
            // $("#down_form").submit();
            const res=await this.$http.postHttp("/down/downYhgzYxZyz",list,{responseType:'blob'});
            if(res.status===200){
                if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
                }else if(res.data.code===500){
                    if(res.data.message){
                        this.$message({message:res.data.message,type:'error'});
                    }
                }else{
                    if(res.data){
                    const blob=new Blob([res.data],{type:'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) { // 兼容IE10
                    navigator.msSaveBlob(blob, this.filename);
                    } else {
                        const href = URL.createObjectURL(blob); // 创建新的URL表示指定的blob对象
                        const a = document.createElement('a'); // 创建a标签
                        a.style.display = 'none';
                        a.href = href; // 指定下载链接
                        a.download = this.filename; // 指定下载文件名
                        a.click(); // 触发下载
                        URL.revokeObjectURL(a.href); // 释放URL对象
                    }
                }
                }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message: '您的账号在其他地点登陆了',type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const collectYx=this.zkCollectYx;
            const arr=[];
            const list=[];
            for(let i=0;i<collectYx.length;i++){
                if(collectYx[i].yxmc){
                    const pcmc=collectYx[i].pc===6?'本科批':'专科批';
                    const sb=!collectYx[i].sb?0:collectYx[i].sb+"";
                    const yxmc=!collectYx[i].yxmc?0:collectYx[i].yxmc;
                    const zyz=!collectYx[i].zyz?0:collectYx[i].zyz+"";
                    const szdqmc=!collectYx[i].szdqmc?0:collectYx[i].szdqmc;
                    const sf211=collectYx[i].sf211===1?'是':'否';
                    const sf985=collectYx[i].sf985===1?'是':'否';
                    const snjhs=!collectYx[i].snjhs?0:collectYx[i].snjhs+"";
                    const jnjhs=!collectYx[i].jnjhs?0:collectYx[i].jnjhs+"";
                    const rdwc=!collectYx[i].rdwc?0:collectYx[i].rdwc+"";
                    const snrdwc=!collectYx[i].snrdwc?0:collectYx[i].snrdwc+"";
                    const tdx=!collectYx[i].tdx?0:collectYx[i].tdx+"";
                    const zdtwf=!collectYx[i].zdtwf?0:collectYx[i].zdtwf+"";
                    const pjtwf=!collectYx[i].pjtwf?0:collectYx[i].pjtwf+"";
                    const zgtwf=!collectYx[i].zgtwf?0:collectYx[i].zgtwf+"";
                    arr.push(sb+'-'+yxmc+'-'+zyz+'-'+pcmc+'-'+szdqmc+'-'+sf211+'-'+sf985+'-'+
                    snjhs+'-'+jnjhs+'-'+rdwc+'-'+snrdwc+'-'+tdx+'-'+zdtwf+'-'+
                    pjtwf+'-'+zgtwf);
                    list.push({
                        xh:i+1,
                        dnjhs:jnjhs,
                        pc:pcmc,
                        pjRdwc:rdwc,
                        pjtwf,
                        sb,
                        sf211,
                        sf985,
                        sheng:szdqmc,
                        snRdwc:snrdwc,
                        snjhs,
                        tdx,
                        yxmc,
                        zdtwf,
                        zgtwf,
                        zyz
                    });
                }
            }
            // const param=arr.join(",");
            // $("#down_param").val(param);
            // $("#down_list").val(JSON.stringify(list));
            // $("#down_form").submit();
            const res=await this.$http.postHttp("/down/downYhgzYxZyz",list,{responseType:'blob'});
            if(res.status===200){
                if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
                }else if(res.data.code===500){
                    if(res.data.message){
                        this.$message({message:res.data.message,type:'error'});
                    }
                }else{
                    if(res.data){
                    const blob=new Blob([res.data],{type:'application/vnd.ms-excel'});
                    if (window.navigator.msSaveOrOpenBlob) { // 兼容IE10
                    navigator.msSaveBlob(blob, this.filename);
                    } else {
                        const href = URL.createObjectURL(blob); // 创建新的URL表示指定的blob对象
                        const a = document.createElement('a'); // 创建a标签
                        a.style.display = 'none';
                        a.href = href; // 指定下载链接
                        a.download = this.filename; // 指定下载文件名
                        a.click(); // 触发下载
                        URL.revokeObjectURL(a.href); // 释放URL对象
                    }
                }
                }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message: '您的账号在其他地点登陆了',type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    },
    // 查询用户是否支付了永久下载
    async getYfFreeDown(){
        const yhid=this.userInfo.yhid;
        const res=await this.$http.getHttp("/pay/getPayXz?yhid="+yhid+"&&mnzyb=1");
        if(res.data.code===200){
            this.yfFreeDown=true;
        }else if(res.data.code===500){
            if(res.data.message){
                if(res.data.message==='不存在永久支付'){
                    return false;
                }else{
                    this.$message({message:res.data.message,type:'error'});
                }
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 删除院校专业组方案
    async deleteYxzyz(id){
        const res=await this.$http.getHttp("/yhgzfa/delFaById?id="+id);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"操作成功",type:'success',showClose:true});
            }
            await this.getMyYxzyz();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
async mounted(){
const id=this.$route.query.id;
const pc=this.$route.query.pc;
const isto=this.$route.query.isto;
if(id){
    if(isto){
        // await this.getYhGzYx();
        this.reloadTimer=setInterval(async () => {
            if(this.reloadGzYx&&this.reloadGzYx==='true'){
                clearInterval(this.reloadTimer);
                await this.getYhGzYx();
            }
        }, 1000);
    }else{
        await this.getYxzyzById(id,pc);
    }
}else{
    // await this.getBkGzyx();
    await this.getYhGzYx();
        const collectInfo=this.collectInfo;
        const collectInfoStr=localStorage.getItem("collectInfo");
        const collectInfo2=collectInfoStr!==null? JSON.parse(collectInfoStr):{};
        const plan=this.planObj.plan;
        const lrzyfaInfo=this.lrzyfaInfo;
        const lrzyfaInfoStr=localStorage.getItem("lrzyfaInfo");
        const lrzyfaInfo2=lrzyfaInfoStr!==null?JSON.parse(lrzyfaInfoStr):{};
        if(!plan){
            if(lrzyfaInfo.lrzyfaList.length>0){
                const lrzyfaPc=lrzyfaInfo.pc;
                const arr=lrzyfaInfo.lrzyfaList;
                for(let i=0;i<arr.length;i++){
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                }
                const nullArr=[];
                if(lrzyfaInfo.lrzyfaList.length<10){
                    const len=lrzyfaInfo.lrzyfaList.length;
                    for(let i=0;i<10-len;i++){
                            const obj={};
                            obj.index=len+(i+1);
                            obj.checked=false;
                            obj.sb=null;
                            obj.yxmc=null;
                            obj.zyz=null;
                            obj.szdqmc=null;
                            obj.pc=null;
                            obj.sf985=null;
                            obj.sf211=null;
                            nullArr.push(obj);
                            if(lrzyfaPc===6||lrzyfaPc==="6"){
                                this.bkCollectYx=arr.concat(nullArr);
                            }else{
                                this.zkCollectYx=arr.concat(nullArr);
                            }
                    }
                }else{
                    if(lrzyfaPc===6||lrzyfaPc==="6"){
                        this.bkCollectYx=[...arr];
                    }else{
                        this.zkCollectYx=[...arr];
                    }
                }
            }else if(lrzyfaInfo2.value&&lrzyfaInfo2.value.lrzyfaList.length>0){
                const lrzyfaPc=lrzyfaInfo2.value.pc;
                const arr=lrzyfaInfo2.value.lrzyfaList;
                for(let i=0;i<arr.length;i++){
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                }
                const nullArr=[];
                if(lrzyfaInfo2.value.lrzyfaList.length<10){
                    const len=lrzyfaInfo2.value.lrzyfaList.length;
                    for(let i=0;i<10-len;i++){
                            const obj={};
                            obj.index=len+(i+1);
                            obj.checked=false;
                            obj.sb=null;
                            obj.yxmc=null;
                            obj.zyz=null;
                            obj.szdqmc=null;
                            obj.pc=null;
                            obj.sf985=null;
                            obj.sf211=null;
                            nullArr.push(obj);
                            if(lrzyfaPc===6||lrzyfaPc==="6"){
                                this.bkCollectYx=arr.concat(nullArr);
                            }else{
                                this.zkCollectYx=arr.concat(nullArr);
                            }
                    }
                }else{
                    if(lrzyfaPc===6||lrzyfaPc==="6"){
                        this.bkCollectYx=[...arr];
                    }else{
                        this.zkCollectYx=[...arr];
                    }
                }
            }else if(collectInfo.collectSchool.length>0){
                const collectPc=collectInfo.pc;
                const arr=collectInfo.collectSchool;
                let count=0;
                for(let i=0;i<arr.length;i++){
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                    if(arr[i].yxmc){
                        count++;
                    }
                }
                const nullArr=[];
                if(collectInfo.collectSchool.length<10){
                    const len=collectInfo.collectSchool.length;
                    for(let i=0;i<10-len;i++){
                            const obj={};
                            obj.index=len+(i+1);
                            obj.checked=false;
                            obj.sb=null;
                            obj.yxmc=null;
                            obj.zyz=null;
                            obj.szdqmc=null;
                            obj.pc=null;
                            obj.sf985=null;
                            obj.sf211=null;
                            nullArr.push(obj);
                            if(collectPc===6||collectPc==="6"){
                                this.bkCollectYx=arr.concat(nullArr);
                            }else{
                                this.zkCollectYx=arr.concat(nullArr);
                            }
                    }
                }else{
                    const arr2=[];
                    for(let i=0;i<count;i++){
                        arr2.push(arr[i]);
                    }
                    if(collectPc===6||collectPc==="6"){
                        this.bkCollectYx=[...arr2];
                    }else{
                        this.zkCollectYx=[...arr2];
                    }
                }
            }else if(collectInfo2.value&&collectInfo2.value.collectSchool.length>0){
                const collectPc=collectInfo2.value.pc;
                const arr=collectInfo2.value.collectSchool;
                let count=0;
                for(let i=0;i<arr.length;i++){
                    arr[i].checked=arr[i].isRed||false;
                    arr[i].index=i+1;
                    if(arr[i].yxmc){
                        count++;
                    }
                }
                const nullArr=[];
                if(collectInfo2.value.collectSchool.length<10){
                    const len=collectInfo2.value.collectSchool.length;
                    for(let i=0;i<10-len;i++){
                            const obj={};
                            obj.index=len+(i+1);
                            obj.checked=false;
                            obj.sb=null;
                            obj.yxmc=null;
                            obj.zyz=null;
                            obj.szdqmc=null;
                            obj.pc=null;
                            obj.sf985=null;
                            obj.sf211=null;
                            nullArr.push(obj);
                            if(collectPc===6||collectPc==="6"){
                                this.bkCollectYx=arr.concat(nullArr);
                            }else{
                                this.zkCollectYx=arr.concat(nullArr);
                            }
                    }
                }else{
                    const arr2=[];
                    for(let i=0;i<count;i++){
                        arr2.push(arr[i]);
                    }
                    if(collectPc===6||collectPc==="6"){
                        this.bkCollectYx=[...arr2];
                    }else{
                        this.zkCollectYx=[...arr2];
                    }
                }
            }
        }
}
const yhlb=this.userInfo.yhlb;
if(yhlb===4){
    await this.getYfFreeDown();
}
},
computed:{
    ...mapState(["userInfo","collectInfo","lrzyfaInfo"])
}
};
</script>
<style lang="less" scoped>
.collect-title{
    width:800px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin:20px auto;
}
.collect-btn{
    width:700px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin:10px auto;
}
.float-btn{
    width:1200px;
    margin:10px auto;
}
.dialog-div{
    margin:10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.weixin-panel{
                width:100%;
                height:300px;
                display:flex;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                .qrcode{
                    width:180px;
                    height:180px;
                    display:flex;
                    justify-content:center;
                    align-items:center;
                    padding:10px;
                    background:url('../../../assets/images/wxcode-bg.png') no-repeat center;
                }
            }
</style>
