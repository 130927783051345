<template>
    <div>
        <div class="majorSearch">
        <div class="biaoji1">
        <div class="informationTdx-rt">
            <label>选择专业：</label>
            <div class="informationTdx-zy_click">
            <span id="choose_zy" @click="showAllZy">{{xkParam.zymc}}</span>
            <input id="choose_input" name="zymc" v-model="xkParam.zymc" ref="choose_input" value="" type="hidden">
            <div class="informationTdx-chooseZy" v-if="zysShow==true">
                <ul>
                <li style="color:#006BCF" @click="checkZy('全部专业')">全部专业</li>
                <li v-for="(item,index) in zyzs"
                :key="index" @click="checkZy(item)">{{item}}</li>
                </ul>
                </div>
                </div>
        </div>
        <div class="informationTdx-rt">
            <label>院校名称：</label>
            <input type="text" v-model="xkParam.yxmc" placeholder="请输入院校名称" name="search">
            <button type="submit" id="yxzypm_tijiao" @click="getRanking(1,10)">搜索</button>
        </div>
        </div>
        </div>
        <div class="xk-content" id="xk-data">
            <el-table border :data="xkList" :header-cell-style="{'background-color':'#B0E2FF',color:'#3B3B3B',
            border:'1px solid #0099FF'}" :cell-style="{border:'1px solid #0099FF'}">
    <el-table-column show-overflow-tooltip
        label="院校名称"
        width="190" prop="yxmc" :resizable="false">
        <template slot-scope="scope">
            <span>{{!scope.row.yxmc?'-':scope.row.yxmc}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        label="专业名称"
        width="190" prop="zymc" :resizable="false">
        <template slot-scope="scope">
            <span>{{!scope.row.zymc?'-':scope.row.zymc}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        label="国际代码"
        width="190" prop="gbdm" :resizable="false">
        <template slot-scope="scope">
            <span>{{!scope.row.gbdm?'-':scope.row.gbdm}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        label="学科代码"
        width="190" prop="zydm" :resizable="false">
        <template slot-scope="scope">
            <span>{{!scope.row.zydm?'-':scope.row.zydm}}</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip
        label="排名"
        width="190" prop="pm" :resizable="false">
        <template slot-scope="scope">
            <span>{{!scope.row.pm?'-':scope.row.pm}}</span>
        </template>
      </el-table-column>
            </el-table>
            <div
        class="pagination"
      >
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="xkParam.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="xkParam.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
      </div>
        </div>
    </div>
</template>
<script>
// import $ from 'jquery';
import {mapState} from 'vuex';
export default {
props:["zyzs"],
data(){
    return{
        xkParam:{
            zymc:"全部专业",
            yxmc:'',
            pageNum:1,
            pageSize:10
        },
        total:0,
        zysShow:false,
        xkList:[]
    };
},
methods:{
    // 用jQuery写的代码
    chooseZy(){
        // $(".biaoji1 #choose_zy").click(function () {
        //                 $(".biaoji1 .informationTdx-chooseZy").toggle();
        //             });
                    // $(".biaoji1 .informationTdx-chooseZy ul li").click(function () {
                    //     var defStr = "全部专业";
                    //     var s = $(this).text();
                    //     $(".biaoji1 #choose_zy").text(s);
                    //     if (s === defStr) {
                    //         $(".biaoji1 #choose_input").val("");
                    //     } else {
                    //         $(".biaoji1 #choose_input").val(s);
                    //     }
                    //     $(".biaoji1  .informationTdx-chooseZy").hide();
                    // });
    },
    // 显示专业选择框
    showAllZy(){
        this.zysShow=!this.zysShow;
    },
    // 选中专业
    checkZy(zymc){
        this.xkParam.zymc=zymc;
        this.zysShow=false;
    },
    // 获取2017年学科排名
    async getRanking(pageNum=1,pageSize=10){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("xk-data"),text:"正在加载中"});
        this.xkParam.pageNum=pageNum;
        this.xkParam.pageSize=pageSize;
        let zymc=this.xkParam.zymc;
        if(zymc==='全部专业'){
            zymc='';
        }
        const res=await this.$http.getHttp("/zycx/xkpm?zymc="+zymc+"&yxmc="+
        this.xkParam.yxmc+"&pageNum="+pageNum+"&pageSize="+pageSize);
        if(res.data.code===200){
            this.xkList=res.data.obj.records;
            this.total=res.data.obj.total;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 改变pagesize
    async handleSizeChange(pageSize){
        await this.getRanking(1,pageSize);
    },
    // 改变pagenum
    async handleCurrentChange(pageNum){
        await this.getRanking(pageNum,10);
    }
},
computed:{
    ...mapState(['userInfo'])
},
async mounted(){
    this.chooseZy();
    await this.getRanking();
}
};
</script>
<style lang="less" scoped>
.majorSearch{
    height: 45px;
    margin-bottom: 10px;
    background-color: #E5E5E5;
    border: 1px solid #E5E5E5;
}
.biaoji1{
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
}
.informationTdx-rt{
    float: left;
    padding-left: 20px;
    input{
        width: 200px;
        height: 30px;
        border: 1px solid #C1C1C1;
        padding-left: 5px;
        border-right: none;
        color: #3B3B3B;
    }
    button{
        width: 60px;
        height: 32px;
        color: #FFFFFF;
        background-color: #FF4040;
        border: none;
        margin-left: -5px;
    }
}
.informationTdx-zy_click{
    display: inline-block;
    width: 160px;
    height: 30px;
    border: 1px solid #C1C1C1;
    padding-left: 10px;
    position: relative;
    background: url(../../../assets/images/tool/arrow.png) no-repeat scroll right center transparent;
    font: 400 14px/30px 'Microsoft YaHei';
    cursor: pointer;
}
.informationTdx-chooseZy{
    width: 680px;
    height: auto;
    border: 1px solid #FFC125;
    margin: 8px auto;
    // display: none;
    z-index: 999999;
    background-color: #FFFFFF;
    position: absolute;
    opacity: 1;
    left: 0;
    padding: 5px;
    ul{
        // display: flex;
        // justify-content: flex-start;
        // align-items: center;
    }
    ul li{
        float: left;
        width: 125px;
        font: 400 12px/15px 'Microsoft YaHei';
        padding: 5px 5px;
        color: #383838;
    }
}
#choose_zy{
    display: inline-block;
    width: 150px;
    height: 30px;
}
.xk-content{
    width:960px;
    margin:0 auto;
    .pagination{
        width:700px;
        margin:20px auto;
    }
}
</style>
