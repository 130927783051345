<template>
  <div id="app">
    <Header   v-if="userInfo.yhid" @reloadZypg="reloadZypg" />
    <noHeader v-if="!userInfo.yhid"/>
    <keep-alive  v-if="$route.meta.keepAlive">
      <router-view v-if="$route.meta.keepAlive" :sxkmChangeReload="sxkmChangeReload"
      :sxkmChangeReloadYxcx="sxkmChangeReload" :sxkmChangeReloadYxtj="sxkmChangeReload"
      :sxkmChangeReloadScore="sxkmChangeReload" :sxkmChangeReloadMajor="sxkmChangeReload"
      :sxkmChangeReloadDateHome="sxkmChangeReload" />
    </keep-alive>
    <!--  v-if="RouteState && !$route.meta.keepAlive" -->
    <router-view v-if="RouteState && !$route.meta.keepAlive" :sxkmChangeReload="sxkmChangeReload"
    :sxkmChangeReloadYxcx="sxkmChangeReload" :sxkmChangeReloadYxtj="sxkmChangeReload"
    :sxkmChangeReloadScore="sxkmChangeReload" :sxkmChangeReloadMajor="sxkmChangeReload"
    :sxkmChangeReloadDateHome="sxkmChangeReload" />
    <Footer />
  </div>
</template>
<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import noHeader from './components/noHeader.vue';
import {mapState} from 'vuex';
// import $ from 'jquery';
export default {
  name: "App",
  data() {
    return {
      RouteState: true,
      sxkmChangeReload:'',
      gapTime:'',
      beforeUnloadTime:''
    };
  },
  provide() {
    return {
      reload: this.reload
    };
  },
  methods: {
    reload() {
      this.RouteState = false;
      this.$nextTick(() => {
        this.RouteState = true;
      });
    },
    reloadZypg(sxkmChangeLoad){
      if(sxkmChangeLoad){
        this.sxkmChangeReload=sxkmChangeLoad;
      }
    }
  },
  computed:{
    ...mapState(["userInfo"])
  },
  components: {
    Header,
    Footer,
    noHeader
  }
};
</script>
<style>
#app{
    width: 100%;
    height: 100%;
    position:relative;
}
</style>
