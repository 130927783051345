<template>
    <div class="yxzyz-container">
        <div id="data-div">
            <ul class="slist" id="zycx_warp">
            <li class="clearfix" v-for="(item,index) in yxzyz" :key="index">
                <div class="pic newExam">
                    <router-link
                    tag="a"
                    target="_blank"
                    :to="{
                        path: '/schoolDetail',
                        query: { gbdm: item.gbdm, zyz: item.zyz,pc:item.pc,yxmc:item.yxmc,yxxh:item.yxxh }
                    }"
                    >
                    <img :alt="item.yxmc" :src="'http://img.gkzyydt.com/upload/img/logo/'+item.gbdm+'.jpg'"/>
                    </router-link>
                </div>
                <div class="con">
                    <div class="tit">
                        <h3 class="newExam">
                            <router-link
                            tag="a"
                            target="_blank"
                            :to="{
                                path: '/schoolDetail',
                                query: { gbdm: item.gbdm, zyz: item.zyz,pc:item.pc,yxmc:item.yxmc,yxxh:item.yxxh }
                            }"
                            >{{item.yxmc}}</router-link>
                        </h3>
                        <div class="tab" style="display: inline-block;"></div>
                        <div style="display: inline-block;color: #2186de">（点击院校名称/logo进入院校详情页可加入收藏）</div>
                    </div>
                    <div style="width:300px;height:30px;line-height: 30px;">
                    <el-tooltip placement="top">
                        <div slot="content">
                            是指各院校近三年的录取分平均分求平均后，根据这个分数的
排序。
                        </div>
                        <span class="tip_a tip_pjf">录取平均分排行
                            (
                                <span v-if="item.pc===6">本科批</span>
                                <span v-if="item.pc===7">专科批</span>
                            )
                            ：</span>
                    </el-tooltip>
                    </div>
                    <div class="s" style="width:170px;">
                    <div class="ss2">院校代号：{{item.sb}}</div>
                    <div class="ss1">隶属：{{item.ls}}</div>
                    <div class="ss1">院校类型：{{item.xxlbmc}}</div>
                    <div class="ss2">硕士点数：{{item.ssdgs}}</div>
                    <div class="ss2">博士点数：{{item.bsdgs}}</div>
                    </div>
                    <div class="s s3">
                        <table class="lqTable">
                            <tr>
                                <th rowspan="2">专业名称</th>
                                <th rowspan="2">所属专业组</th>
                                <th rowspan="2">批次</th>
                                <th rowspan="2">一流学科</th>
                                <th rowspan="2" style="min-width: 30px;">年度</th>
                                <th colspan="2">平均分</th>
                                <th rowspan="2" style="min-width: 45px;">控制线</th>
                                <th rowspan="2" style="min-width: 45px;">投档线</th>
                                <th rowspan="2" style="min-width: 45px;">专业线差</th>
                                <th colspan="2">
                                    招生
                                    <br/>
                                    计划人数
                                </th>
                            </tr>
                            <tr>
                                <th style="min-width: 40px;">录取分</th>
                                <el-tooltip placement="top">
                                <div slot="content" style="width:400px;">
                                    等效分就是今年的分数相当于往年的多少分数，它使得不同年分的分数能直接进行对比分析。比如说2015理科100名是628分，等效于2014年理科100名的623分、2013年理科100名的588分，就是说按照试题难度系数、考生分数分布情况等，如果2015理科100名是628分的成绩，那放在2014年的高考中相当于考了623分，要是在2013年比较难的情况下，那相当于是考了588分。等效分是基于考生成绩分布情况，系统通过科学的数学模型自动换算得来的。具体到使用时，比如某学校14年在湖南的投档线是623分，入档位次是100名，那么15年就需要628分才能入档，由于一分段表在一个分数上有多个考生，因此，在使用等效分时，最好结合省排名来综合判断，使两个数据的偏差得到有效的纠正，因为在同分比较多的情况下，1分之差可能相差百名之外。
                                </div>
                                <th>等效分</th>
                            </el-tooltip>
                            <th style="min-width: 30px;">往年</th>
                            <th style="min-width: 30px;">今年</th>
                            </tr>
                            <tr v-for="(item2,index2) in item.zyxqs" :key="index2">
                            <td v-if="index2===0" rowspan="4">{{item2.zymc}}</td>
                            <td v-if="index2===0" rowspan="4">
                                <span v-if="!item.zyz">-</span>
                                <router-link
                                tag="a"
                                target="_blank"
                                :to="{
                                    path: '/zyzDetail',
                                    query: { gbdm: item.gbdm, zyz: item.zyz,pc:item.pc,yxmc:item.yxmc,yxxh:item.yxxh }
                                }"
                                 v-if="item.zyz">{{item2.zyz}}</router-link>
                            </td>
                            <td v-if="index2===0" rowspan="4">
                            <template v-if="item2.pc===6">本科批</template>
                            <template v-if="item2.pc===7">专科批</template>
                            </td>
                            <td v-if="index2===0" rowspan="4">
                                <template v-if="item2.zdzy===1">
                                    <img src="../../../assets/images/gou.png" width="20" height="20"/>
                                </template>
                            </td>
                            <td>
                                {{item2.nd===0?'平均':item2.nd}}
                            </td>
                            <td>{{!item2.pjf?'-':item2.pjf}}</td>
                            <td>{{!item2.pjtwf?'-':item2.pjtwf}}</td>
                            <td>{{!item2.pckzx?'-':item2.pckzx}}</td>
                            <td>{{!item2.tdx?'-':item2.tdx}}</td>
                            <td>{{!item2.xc?'-':item2.xc}}</td>
                            <td>{{!item2.jhs?'-':item2.jhs}}</td>
                            <td v-if="index2===0" rowspan="4">{{!item2.jnjhrs?'-':item2.jnjhrs}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </li>
        </ul>
        </div>
    </div>
</template>
<script>
export default {
props:["yxzyz"],
data(){
    return{
        pageNum:1,
        pageSize:10
    };
},
methods:{

    // 跳转到院校详情
    toSchoolDetail(yxmc,yxxh,pc,gbdm){
        const obj={
            url:"/schoolDetail",
            query:{
                yxmc,
                yxxh,
                pc,
                gbdm
            }
        };
        this.$router.push(obj);
    }
}
};
</script>
<style lang="less">
.yxzyz-container{
    width:1100px;
    margin:0 auto;
    .slist{
        // margin-top:-20px;
        li{
            padding: 25px 0;
            border-bottom: 1px solid #E5E5E5;
            line-height: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
        .pic{
            margin-right: 10px;
            height:120px;
            overflow: hidden;
            a{
                // background: url() no-repeat scroll center center #E8E8E8;
                width: 110px;
                height: 110px;
                display: block;
                img{
                    // background: url() no-repeat scroll center center #E8E8E8;
                    width: 110px;
                    height: 110px;
                    display: block;
                }
            }
        }
        .con{
        }
        .tit{
            overflow: hidden;
            zoom: 1;
            margin: 6px 0;
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
        }
        .s{
            float: left;
            color: #666;
            display: inline;
        }
        .s3{
            width:652px;
            a{
                background-color: #51C3B7;
                color: #FFF;
                display: inline-block;
                line-height: 20px;
                padding: 0 5px;
                font-size: 12px;
                border-radius: 3px;
            }
        }
        h3{
            font-size: 20px;
            font-weight: normal;
            line-height: 22px;
            // float: left;
            max-width: 620px;
            a{
                color: #ff861a;
            }
        }
        .lqTable{
            width:100%;
            th{
                background-color: #e4f5ff;
                line-height: 18px;
                padding: 1px;
                color: #545655;
                font-size: 12px;
                font-weight: normal;
                border: 1px solid #09F;
                border-bottom: 0;
                text-align: center;
            }
            td{
                line-height: 15px;
                padding: 5px 0;
                border: 1px solid #09F;
                text-align: center;
                font-size: 13px;
            }
        }
    }
    .page-div{
        width:600px;
        margin:10px auto;
    }
}
</style>
