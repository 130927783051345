<template>
    <div class="ranking-container">
        <div class="ranking-title">备注: 排名规则按三年平均等效分从高至低排序，部分院校此专业由于录取信息不全，暂未加入排名。</div>
        <el-table border :data="zyxk"
        :header-cell-style="{'background-color': '#5287c6',color:'#FFF',border: '1px solid #1f4778',
        'text-align':'center'}"
        :cell-style="{'border':'1px solid #1f4778','text-align':'center'}">
            <el-table-column show-overflow-tooltip type="index" width="50" label="序号" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip label="院校名称" prop="yxmc" width="120" :resizable="false"></el-table-column>
            <el-table-column show-overflow-tooltip label="所属专业组" prop="zyz" width="80" :resizable="false">
                <template slot-scope="scope">
                    <router-link
                    tag="a"
                    target="_blank"
                    :to="{
                        path: '/zyzDetail',
                        query: { gbdm: scope.row.gbdm, zyz: scope.row.zyz,pc:scope.row.pc,yxmc:scope.row.yxmc,yxxh:scope.row.yxxh }
                    }"
                    v-if="scope.row.zyz">【{{scope.row.zyz}}】</router-link>
                    <span v-if="!scope.row.zyz">【{{scope.row.zyz}}】</span>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="211" prop="sf211" width="50" :resizable="false">
                <template slot-scope="scope">
                    <img v-if="scope.row.sf211===1" src="../../../assets/images/gou.png" width="20" height="20"/>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="985" prop="sf985" width="50" :resizable="false">
                <template slot-scope="scope">
                    <img v-if="scope.row.sf985===1" src="../../../assets/images/gou.png" width="20" height="20"/>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="批次" prop="pc" width="70" :resizable="false">
                <template slot-scope="scope">
                    <span v-if="scope.row.pc===6">本科批</span>
                    <span v-if="scope.row.pc===7">专科批</span>
                </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="year1" :resizable="false">
                <el-table-column show-overflow-tooltip label="录取平均分" prop="pjf1" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjf1?'-':scope.row.pjf1}}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="等效平均分" prop="pjtwf1" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjtwf1?'-':scope.row.pjtwf1}}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="year2" :resizable="false">
                <el-table-column show-overflow-tooltip label="录取平均分" prop="pjf2" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjf2?'-':scope.row.pjf2}}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="等效平均分" prop="pjtwf2" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjtwf2?'-':scope.row.pjtwf2}}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column show-overflow-tooltip :label="year3" :resizable="false">
                <el-table-column label="录取平均分" prop="pjf3" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjf3?'-':scope.row.pjf3}}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="等效平均分" prop="pjtwf3" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjtwf3?'-':scope.row.pjtwf3}}</span>
                    </template>
                </el-table-column>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="三年平均" :resizable="false">
                <el-table-column show-overflow-tooltip label="录取平均分" prop="pjf" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjf?'-':scope.row.pjf}}</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="等效平均分" prop="pjtwf" width="92" :resizable="false">
                    <template slot-scope="scope">
                        <span>{{!scope.row.pjtwf?'-':scope.row.pjtwf}}</span>
                    </template>
                </el-table-column>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
props:["zyxk","year1","year2","year3"],
data(){
    return{
    };
}
};
</script>
<style lang="less">
.ranking-container{
    width:1160px;
    margin:0 auto;
    .ranking-title{
        width:1160px;
        margin:10px auto;
    }
}
</style>
