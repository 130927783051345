<template>
    <div class="zy-container">
        <div class="card-top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        <div class="zys-div">
            <el-transfer v-model="selectedZys" @change="chooseDialogZy" target-order="unshift"
            :titles="['专业1','专业2']" :data="zyData" @right-check-change="choose">
            <div slot-scope="{ option }">
            <div class="default-tranfer-item" @mouseenter="indexKey = option.key"
                 @mouseleave="indexKey = null">
              <div>{{ option.label }}</div>
              <!-- <div v-show="selectedZys.includes(option.key) && indexKey === option.key" class="button-group"
              style="display:inline-block;margin-left:10px;font-size:15px;">
                <em class="el-icon-top" @click.stop.prevent="publicMobileMethod('handleUp', option.key)"></em>
                <em class="el-icon-arrow-up"
                    @click.stop.prevent="publicMobileMethod('handleTop', option.key)"></em>
                <em class="el-icon-bottom" @click.stop.prevent="publicMobileMethod('handleDown', option.key)"></em>
                <em class="el-icon-arrow-down" @click.stop.prevent="publicMobileMethod('handleBottom', option.key)"></em>
              </div> -->
            </div>
            </div>
            <div class="transer-footer" slot="right-footer">
                <el-button @click="moveUp">上移</el-button>
                <el-button @click="moveDown">下移</el-button>
            </div>
            </el-transfer>
            </div>
            <div style="width:200px;margin:0 auto;margin-top:20px;">
                <el-button type="primary" @click="updateZy">保存</el-button>
            </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        indexKey:null,
        zyData:[],
        selectedZys:[],
        tabelData:[],
        searchZy:[]
    };
},
methods:{
    // 上移
    moveUp(){
        const item=[...this.item];
        const selectedZys=[...this.selectedZys];
        const len=item.length;
        if(len>1){
            this.$message({message:'只能移动一个专业',type:'warning',showClose:true});
            return false;
        }
        const zy=item[0];
        let index=-1;
        for(let i=0;i<selectedZys.length;i++){
            if(selectedZys[i]===zy){
                index=i;
            }
        }
        if(index===-1){
            this.$message({message:'请选择某一个专业',type:'warning',showClose:true});
            return false;
        }
        if(index===0){
            return false;
        }
        const obj=selectedZys[index];
        const prevObj=selectedZys[index-1];
        this.$set(this.selectedZys,index,prevObj);
        this.$set(this.selectedZys,index-1,obj);
    },
    // 下移
    moveDown(){
        const item=[...this.item];
        const selectedZys=[...this.selectedZys];
        const len=item.length;
        if(len>1){
            this.$message({message:'只能移动一个专业',type:'warning',showClose:true});
            return false;
        }
        const zy=item[0];
        let index=-1;
        for(let i=0;i<selectedZys.length;i++){
            if(selectedZys[i]===zy){
                index=i;
            }
        }
        if(index===-1){
            this.$message({message:'请选择某一个专业',type:'warning',showClose:true});
            return false;
        }
        if(index===selectedZys.length-1){
            return false;
        }
        const obj=selectedZys[index];
        const nextObj=selectedZys[index+1];
        this.$set(this.selectedZys,index,nextObj);
        this.$set(this.selectedZys,index+1,obj);
    },
     /**
     * 监听右侧选中
     */
     choose (value) {
      this.item = value;
    },
    // 选择专业
    chooseDialogZy(value,pos,arr){
        if(pos==='right'&&(value.length>6||arr.length>6)){
            this.$message({message:'只能选择6个专业',type:'warning',showClose:true});
            this.selectedZys=[];
        }
    },
    // 获取专业数据
    async getZyData(obj){
        if(obj.yxxh&&obj.zyz){
            this.zyData=[];
            this.selectedZys=[];
            const res=await this.$http.getHttp("/zsjh/getZyByZyz?yxxh="+obj.yxxh+"&zyz="+obj.zyz);
            if(res.data.code===200){
                const data=res.data.obj;
                const zys=[];
                const searchZy=[];
                for(let i=0;i<data.length;i++){
                    if(data[i].zydh){
                        zys.push({
                        key:data[i].zydh,
                        label:data[i].zydh+' '+data[i].zymc
                    });
                    searchZy.push({
                        zydh:data[i].zydh,
                        zymc:data[i].zymc
                    });
                    }
                }
                this.zyData=zys;
                this.searchZy=searchZy;
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            this.zyData=[];
        }
    },
    // 保存专业
    async updateZy(){
        let index=this.$route.query.index;
        if(index){
            index=index-1;
        }
        const zys=this.selectedZys;
        if(zys.length===0){
            this.$message({message:'请选择好专业',type:'warning',showClose:true});
            return;
        }
        const zymcs=[];
        const searchZy=this.searchZy;
        for(let i=0;i<zys.length;i++){
            for(let j=0;j<searchZy.length;j++){
                if(zys[i]===searchZy[j].zydh&&i<=5){
                    zymcs.push(searchZy[j].zymc);
                }
            }
        }
        const tabelData=[...this.tabelData];
        tabelData[index].selectedZymcs=zymcs;
        tabelData[index].zy1='';
        tabelData[index].zy2='';
        tabelData[index].zy3='';
        tabelData[index].zy4='';
        tabelData[index].zy5='';
        tabelData[index].zy6='';
        for(let i=0;i<this.selectedZys.length;i++){
            if(i<=5){
                tabelData[index]['zy'+(i+1)]=this.selectedZys[i];
            }
        }
        const pc=localStorage.getItem('pc');
        if(pc&&pc==='6'){
            localStorage.setItem('whBkTableData',JSON.stringify(tabelData));
        }else if(pc&&pc==='7'){
            localStorage.setItem('whZkTableData',JSON.stringify(tabelData));
        }
        this.$router.push({
            path:'/zypg',
            query:{
                isto:'true',
                readStorage:'true'
            }
        });
    }
},
async mounted(){
    const yxxh=this.$route.query.yxxh;
    const zyz=this.$route.query.zyz;
    if(yxxh&&zyz){
        const obj={};
        obj.yxxh=yxxh;
        obj.zyz=zyz;
        await this.getZyData(obj);
    }
    const tabelData=localStorage.getItem("whBkTableData");
    const tabelData2=localStorage.getItem("whZkTableData");
    const pc=localStorage.getItem('pc');
    if(pc&&pc==='6'){
        this.tabelData=JSON.parse(tabelData);
    }else if(pc&&pc==='7'){
        this.tabelData=JSON.parse(tabelData2);
    }
},
computed:{
    ...mapState(['userInfo'])
}
};
</script>
<style lang="less" scoped>
.zy-container{
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .zys-div{
        width:1100px;
        margin:0 auto;
    }
}
</style>
