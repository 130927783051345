<template>
    <div>
        <div class="header-container">
      <div class="header-top">
        <h2 class="logo">
            <i href="javascript:;" title="金榜志愿通" @click="refreshHtml">
            <img src="../assets/images/logo.jpg"/>
            </i>
        </h2>
        <div class="header-md">
            <div class="md-change"  v-if="token">
                <el-form :model="ruleForm"  :inline="true" style="width:100%" size="mini">
                <el-form-item label="省份:" prop="selectedSf" v-if="userInfo.yhlb===9||userInfo.yhlb===7">
                    <el-select v-if="sfs.length>0" v-model="userInfo.sssf" style="width:70px;" @change="changeSf">
                        <el-option v-if="sfs.length===0" disabled value="-1">
                            <i class="el-icon-loading" style="font-size: 16px;"></i>
                        </el-option>
                        <template v-if="sfs.length>0">
                        <el-option v-for="(item,index) in sfs"
                        :key="index" :label="item.name" :value="item.value"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="首选科目:" prop="sxkm" v-if="userInfo.yhlb===7||userInfo.yhlb===9">
                    <el-select style="width:70px;" v-if="sxkms.length>0" v-model="userInfo.sxkm" @change="changeSxkm">
                        <el-option v-if="sxkms.length===0" disabled value="-1">
                            <i class="el-icon-loading" style="font-size: 16px;"></i>
                        </el-option>
                        <template v-if="sxkms.length>0">
                        <el-option v-for="(item,index) in sxkms"
                        :key="index" :label="item.name" :value="item.value"></el-option>
                        </template>
                    </el-select>
                </el-form-item>
            </el-form>
            </div>
            <div class="header-user" v-if="token">
                <span>科目： </span>
                <template v-if="userInfo.sssf===43||userInfo.sssf===44">
                <span v-if="userInfo.sxkm===2">物理/</span>
                <span v-if="userInfo.sxkm===1">历史/</span>
                <span v-if="(userInfo.zxkma===1&&userInfo.zxkmb===2)
                ||(userInfo.zxkmb===1&&userInfo.zxkma===2)">政治/地理 </span>
                <span v-if="(userInfo.zxkma===1&&userInfo.zxkmb===3)
                ||(userInfo.zxkmb===1&&userInfo.zxkma===3)">政治/化学</span>
                <span v-if="(userInfo.zxkma===1&&userInfo.zxkmb===4)
                ||(userInfo.zxkmb===1&&userInfo.zxkma==4)">政治/生物</span>
                <span v-if="(userInfo.zxkma===2&&userInfo.zxkmb===3)
                ||(userInfo.zxkmb===2&&userInfo.zxkma==3)">化学/地理 </span>
                <span v-if="(userInfo.zxkma===2&&userInfo.zxkmb===4)
                ||(userInfo.zxkmb===2&&userInfo.zxkma==4)">地理/生物</span>
                <span v-if="(userInfo.zxkma===3&&userInfo.zxkmb===4)
                ||(userInfo.zxkmb===3&&userInfo.zxkma==4)">化学/生物</span>
                </template>
                <template v-if="userInfo.sssf===51">
                <span v-if="userInfo.sxkm===1">文科</span>
                <span v-if="userInfo.sxkm===2">理科</span>
                </template>
                <span>
                    <strong class="score">{{userInfo.gkfs}}</strong>分
                </span>
            </div>
            <div class="header-rt">
                <template v-if="token">
                    <el-button type="primary" size="small" round @click="loginOut"><i class="el-icon-switch-button"></i> 退出 </el-button>
                </template>
                <template v-else>
                    <el-button type="primary" size="small" round @click="toLogin"><i class="el-icon-user-solid"></i> 登录 </el-button>
                </template>
                <el-button type="success" size="small" round @click="toHelp"><i class="el-icon-question"></i>帮助</el-button>
            </div>
        </div>
      </div>
      <div class="header-nav">
        <ul class="nav-item">
          <li :class="{current: $route.path.includes('/home')}"><router-link  to="/home" >首页</router-link></li>
          <li :class="{current: $route.path.includes('/zypg')}">
            <router-link to="/zypg" >志愿评估</router-link>
            <!-- <span @click="toZypg">志愿评估</span> -->
        </li>
          <li :class="{current: $route.path.includes('/schoolRecommend')}"><router-link to="/schoolRecommend" ><span @click="clearYxtj">院校推荐</span></router-link></li>
          <li :class="{current: $route.path.includes('/schoolfinder')}"><router-link to="/schoolfinder"><span @click="clearYxcx">院校查询</span></router-link></li>
          <li :class="{current: $route.path.includes('/majorquery')}"><router-link to="/majorquery">专业查询</router-link></li>
          <li :class="{current: $route.path.includes('/datahome')}"><router-link to="/datahome">数据查询</router-link></li>
          <li :class="{current: $route.path.includes('/me')}"><router-link to="/me">用户中心</router-link></li>
          <li :class="{current: $route.path.includes('/buyindex')}" ><router-link to="/buyindex" >在线购买</router-link></li>
          <li :class="{current: $route.path.includes('/qjPlan')}"><router-link to="/qjPlan"> 强基计划</router-link></li>
          <li><a href="http://www.zy1dt.cn" target="_blank"><i class="el-icon-s-promotion"></i>艺考系统入口</a></li>
        </ul>
      </div>
  </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        sfs:[
            // {name:"湖南",value:43},
            // {name:"四川",value:51},
            // {name:"广东",value:44}
        ],
        sxkms:[],
        ruleForm:{
            selectedSf:"",
            sxkm:""
        }
    };
},
methods:{
    refreshHtml(){
        let href="";
        if (location.href.indexOf("#") !== -1) {
            href = location.href.substring(0, location.href.indexOf("#"));
        }
        location.href =href;
    },
    // 跳转到帮助页面
    toHelp(){
        this.$router.push("/help");
    },
    // 跳转到登录界面
    toLogin(){
        this.$router.push("/home");
    },
    toZypg(){
        const url=this.$router.resolve({
            path:'/zypg'
        });
        window.open(url.href,'_blank');
    },
    // 退出登录
    loginOut(){
        this.$confirm('确认退出登录吗？', '提示',{type: 'warning'}).then((action) => {
          if(action === 'confirm'){
             const sjhm=this.userInfo.sjhm;
             this.$store.dispatch('loginOut',{sjhm});
             this.$router.replace('/home');
          }
        }).catch((error) => {
          return error;
        });
    },
    // 获取省份
    async getProvince(){
        const res=await this.$http.getHttp("/sf/findSfOk");
        if(res.data.code===200){
            const sfs=[];
            const data=res.data.obj;
            for(let i=0;i<data.length;i++){
                if(data[i].sfxh){
                    sfs.push({name:data[i].sfmc,value:data[i].sfxh});
                }
            }
            this.sfs=sfs;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.ruleForm.selectedSf=this.userInfo.sssf;
    },
    // 获取首选科目
    async getSxkm(){
        const res=await this.$http.getHttp("/zsjh/getSxkms");
        if(res.data.code===200){
            const sxkms=[];
            const data=res.data.obj;
            for(let i=0;i<data.length;i++){
                if(data[i].sxkmmc){
                    sxkms.push({name:data[i].sxkmmc,value:data[i].sxkm});
                }
            }
            this.sxkms=sxkms;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.ruleForm.sxkm=this.userInfo.sxkm;
    },
    // 切换首选科目
    async changeSxkm(sxkm){
        const res=await this.$http.getHttp("/users/updateKl?kl="+sxkm);
        if(res.data.code===200){
            await this.getUserInfo();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.$emit('reloadZypg', 'true');
    },
    // 切换省份
    async changeSf(sfxh){
        const res=await this.$http.getHttp("/users/updateSf?sf="+sfxh);
        if(res.data.code===200){
            await this.getUserInfo();
            await this.getSxkm();
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 获取用户信息
    async getUserInfo(){
        const res=await this.$http.getHttp("/users/getUserInfo?username="+this.userInfo.username);
        if(res.status===200){
            if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else{
                this.$store.dispatch("saveUserInfo",res.data);
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 清除院校查询缓存
    clearYxcx(){
        this.$store.commit("initSchoolFind");
        this.$store.commit("initRecommend");
    },
    // 清除院校推荐缓存
    clearYxtj(){
        this.$store.commit("initRecommend");
        this.$store.commit("initSchoolFind");
    }
},
async mounted(){
    await this.getProvince();
    await this.getSxkm();
},
computed:{
    ...mapState(["token","userInfo"])
}
};
</script>
<style lang="less" scoped>
.header-container
{
    .header-top{
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:1100px;
        height:130px;
        margin:0 auto;
        position:relative;
    }
    .header-md{
        flex:1;
        display:flex;
        justify-content:flex-end;
        align-items:center;
        .md-change{
            flex: 1;
            // margin-left: 65px;
            margin-left:20px;
            padding-top: 12px;
            box-sizing: border-box;
        }
        .header-user{
            flex: 1;
            margin-right: 10px;
            text-align: right;
            font-size: 14px;
        }
        .score{
            color:#f56c6c;
        }
    }
    .header-rt{
        width: 160px;
        text-align: left;
    }
    .header-nav{
        width:100%;
        height:40px;
        background-color:#409EFF;
        .nav-item{
            min-width:1200px;
            display:flex;
            justify-content:center;
            align-items:center;
            flex-flow:nowrap;
            color:#ffffff;
            li{
                flex-basis: 120px;
                height:40px;
                line-height:40px;
                cursor:pointer;
                text-align:center;
                list-style:none;
                a{
                    display:block;
                    color:#ffffff;
                    text-decoration:none;
                    &:-webkit-any-link{
                        text-decoration:none;
                    }
                    .router-link-active{
                        text-decoration:none;
                    }
                }
            }
            li.current{
                    background-color:#0479f5;
                }
        }
    }
}
</style>
