<template>
    <div class="yxdb-container">
        <div class="card-top">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
            <div id="yxdb-data">
                <div class="close-div">
                    <el-button  type="primary" size="mini" @click="closeWin" plain round>关闭</el-button>
                </div>
                <table class="compareTable">
                <tr style="background-color: #FFF;">
                <th></th>
                <td v-for="(item,index) in logoList" :key="index">
                    <template v-if="item.yxmc">
                        <a href="javascript:void(0)"
                        @click="toSchoolDetail(item.yxxh,item.yxmc,item.pc,item.gbdm)">
                        <img :alt="item.yxmc" width="80px" height="80px" :src="'http://img.gkzyydt.com/upload/img/logo/'+item.gbdm+'.jpg'"/>
                        </a>
                    </template>
                </td>
                </tr>
                <tr>
                    <th>学校名称</th>
                    <td v-for="(item,index) in logoList" :key="index">
                        <template v-if="item.yxmc">
                        <a href="javascript:void(0)" @click="toSchoolDetail(item.yxxh,item.yxmc,item.pc,item.gbdm)">
                        {{item.yxmc}}
                        </a>
                    </template>
                </td>
                </tr>
                <tr>
                    <th>学校标签</th>
                    <td v-for="(item,index) in yxbqList" :key="index">
                    <template v-if="item.sf211>0">
                    <template v-if="item.sf985===1">
                    <img src="../assets/images/ico2.png"/>
                    </template>
                    <template v-if="item.sf211===1">
                    <img src="../assets/images/ico1.png"/>
                    </template>
                    <template v-if="item.sfsyl===1">
                    <img src="../assets/images/ico8.png"/>
                    </template>
                    <template v-if="item.sf101===1">
                    <img src="../assets/images/ico9.png"/>
                    </template>
                    <template v-if="item.yfyjsy===1">
                    <img src="../assets/images/ico3.png"/>
                    </template>
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>学校类型</th>
                    <td v-for="(item,index) in yxlbList" :key="index">
                    <template v-if="item.xxlbmc">
                        {{item.xxlbmc}}
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>博士点个数</th>
                    <td v-for="(item,index) in bsdList" :key="index">
                    <template v-if="item.bsdgs">
                        {{item.bsdgs}}
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>硕士点个数</th>
                    <td v-for="(item,index) in ssdList" :key="index">
                    <template v-if="item.ssdgs">
                        {{item.ssdgs}}
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>一流学科数量</th>
                    <td v-for="(item,index) in ylxkList" :key="index">
                    <template v-if="item.zdxk">
                        {{item.zdxk}}
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>录取批次</th>
                    <td v-for="(item,index) in pcList" :key="index">
                    <template v-if="item.pc>0">
                    <template v-if="item.pc===6">
                        本科批
                    </template>
                    <template v-if="item.pc===7">
                        专科批
                    </template>
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>录取平均分排名</th>
                    <td v-for="(item,index) in pmList" :key="index">
                    <template v-if="item.pm>0">
                        {{item.pm}}
                    </template>
                    </td>
                </tr>
                <tr>
                    <th>一流学科</th>
                    <td v-for="(item,index) in xkList" :key="index" style="padding: 8px;">
                    <template v-if="item.zdzys.length>0">
                    <p v-for="(item2,index2) in item.zdzys" :key="index2">
                        {{!item2.zymc?'':item2.zymc}}
                    </p>
                    </template>
                    </td>
                </tr>
            </table>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        yxdbParam:{
            kl:0,
            pc:"",
            yxxhs:""
        },
        yxdbList:[],
        logoList:[],
        yxbqList:[],
        yxlbList:[],
        bsdList:[],
        ssdList:[],
        ylxkList:[],
        pcList:[],
        pmList:[],
        xkList:[]
    };
},
methods:{
    // 获取院校对比数据
    async getYxdbData(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("yxdb-data"),text:"正在加载中"});
        this.yxdbParam.yxxhs=this.$route.query.yxxhs;
        this.yxdbParam.pc=this.$route.query.pc;
        this.yxdbParam.kl=this.userInfo.sxkm;
        const res=await this.$http.postHttp("/yxcx/yxdb",this.yxdbParam);
        if(res.data.code===200){
            let yxdbs=res.data.obj;
            yxdbs=yxdbs.filter((item)=>item.yxxh!==null);
            this.yxdbList=yxdbs;
            let logoArr=[];
            let yxbqArr=[];
            let yxlbArr=[];
            let bsdArr=[];
            let ssdArr=[];
            let ylxkArr=[];
            let pcArr=[];
            let pmArr=[];
            let xkArr=[];
            for(let i=0;i<yxdbs.length;i++){
                if(yxdbs[i].yxmc){
                    logoArr.push(
                        {
                        yxmc:yxdbs[i].yxmc,
                        logo:yxdbs[i].logo,
                        yxxh:yxdbs[i].yxxh,
                        pc:yxdbs[i].pc,
                        gbdm:yxdbs[i].gbdm
                        }
                        );
                    yxbqArr.push(
                        {
                            sf211:yxdbs[i].sf211,
                            sf985:yxdbs[i].sf985,
                            sfsyl:yxdbs[i].sfsyl,
                            yfyjsy:yxdbs[i].yfyjsy
                        }
                        );
                        yxlbArr.push({xxlbmc:yxdbs[i].xxlbmc});
                        bsdArr.push({bsdgs:yxdbs[i].bsdgs});
                        ssdArr.push({ssdgs:yxdbs[i].ssdgs});
                        ylxkArr.push({zdxk:yxdbs[i].zdxk});
                        pcArr.push({pc:yxdbs[i].pc});
                        pmArr.push({pm:yxdbs[i].pm});
                        xkArr.push({zdzys:yxdbs[i].zdzys});
                }
            }
            if(logoArr.length<5){
                const nullData=this.createNullData(5-logoArr.length,
                {
                    yxmc:"",
                    logo:"",
                    yxxh:"",
                    gbdm:"",
                    pc:0
                });
                logoArr=logoArr.concat(nullData);
            }
            if(yxbqArr.length<5){
                const nullData=this.createNullData(5-yxbqArr.length,
                {
                    sf985:0,
                    sf211:0,
                    sfsyl:0,
                    yfyjsy:0
                }
                );
                yxbqArr=yxbqArr.concat(nullData);
            }
            if(yxlbArr.length<5){
                const nullData=this.createNullData(5-yxlbArr.length,{xxlbmc:""});
                yxlbArr=yxlbArr.concat(nullData);
            }
            if(bsdArr.length<5){
                const nullData=this.createNullData(5-bsdArr.length,{bsdgs:""});
                bsdArr=bsdArr.concat(nullData);
            }
            if(ssdArr.length<5){
                const nullData=this.createNullData(5-ssdArr.length,{ssdgs:""});
                ssdArr=ssdArr.concat(nullData);
            }
            if(ylxkArr.length<5){
                const nullData=this.createNullData(5-ylxkArr.length,{zdxk:""});
                ylxkArr=ylxkArr.concat(nullData);
            }
            if(pcArr.length<5){
                const nullData=this.createNullData(5-pcArr.length,{pc:0});
                pcArr=pcArr.concat(nullData);
            }
            if(pmArr.length<5){
                const nullData=this.createNullData(5-pmArr.length,{pm:0});
                pmArr=pmArr.concat(nullData);
            }
            if(xkArr.length<5){
                const nullData=this.createNullData(5-xkArr.length,{zdzys:[]});
                xkArr=xkArr.concat(nullData);
            }
            this.logoList=logoArr;
            this.yxbqList=yxbqArr;
            this.yxlbList=yxlbArr;
            this.bsdList=bsdArr;
            this.ssdList=ssdArr;
            this.ylxkList=ylxkArr;
            this.pcList=pcArr;
            this.pmList=pmArr;
            this.xkList=xkArr;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 创建空行数据
    createNullData(nullLen,obj){
        const arr=[];
        for(let i=0;i<nullLen;i++){
            arr.push(obj);
        }
        return arr;
    },
            // 关闭当前页面
            closeWin() {
                const userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
                    window.open('','_self').close();
                }else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
    },
    // 跳转到院校详情
    toSchoolDetail(yxxh,yxmc,pc,gbdm){
        const url=this.$router.resolve(
            {
                path:"/schoolDetail",
                query:{
                    yxxh:yxxh,
                    zyz:"",
                    yxmc:yxmc,
                    pc:pc,
                    gbdm:gbdm
                }
            }
        );
            window.open(url.href,"_blank");
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
await this.getYxdbData();
}
};
</script>
<style lang="less" scoped>
.yxdb-container{
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
}
.compareTable {
    border-top: 3px solid #2186de;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    th{
        width: 150px;
        border: 1px solid #09F;
        text-align: center;
        padding: 10px 0;
        font-weight: normal;
    }
    td{
        width: 150px;
        border: 1px solid #09F;
        text-align: center;
        padding: 10px 0;
    }
}
.close-div{
    width:1100px;
    margin:20px auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
</style>
