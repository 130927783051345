<template>
    <div>
        <div class="main_box px1005 box950" style="padding-left:30px;">
               <nav>
                   <a class="maincolor" href="/">首页</a> <span class="c-666 en">&gt;</span>用户评价
               </nav>
           </div>
           <div class="clear_div center_box">
               <!-- 左边 -->
               <!--<div class="i_left">
                <dl class="left_nav th clear_div">
                    <dt></dt>
                    <dd>
                        <a href="about"><span class="l_3"></span>关于我们</a>
                    </dd>
                    <dd class="light">
                        <a href="http://www.zy1dt.com/comment"><span class="l_6"></span>用户评价</a>
                    </dd>
                    <dd>
                        <a href="contact"><span class="l_5"></span>联系我们</a>
                    </dd>
                </dl>
                </div>-->
                <!--右边-->
                <div class="i_right" style="width: 985px;text-align:center;">
                    <div class="intro comment" id="comment1"  style="width: 970px;text-align:center;">
                        <h2 class="titleh2">公益讲座</h2>
                        <div style="width: 970px;">
                            <div class="scroll">
                                <div class="scroll_left" id="LeftArr"></div>
                                <div class="pic" id="scrollPic" style="width: 910px;">
                                    <ul style="width: 910px;display:block;">
                                        <li style="width: 910px;display:block;" imga="1" class="imgli">
                                            <a href="javascript:void(0)" title="公益讲座">
                                            <img src="../../assets/images/pinjia02.jpg" width="910" height="672"></a>
                                        </li>
                                        <li style="width: 910px;display:none;" imga="2" class="imgli">
                                            <a href="javascript:void(0)" title="公益讲座">
                                            <img src="../../assets/images/pinjia01.jpg" width="910" height="672"></a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="scroll_right" id="RightArr"></div>
                            </div>
                        </div>
                    </div>

                    <div class="intro comment" id="comment2"  style="width: 970px;text-align:center;clear:both;">
                        <h2 class="titleh2">用户评论</h2>
                        <div style="width: 970px;">
                            <div class="scroll" style="width:985px;height:700px;">
                                <div class="pic" id="scrollPic" style="height:672px;overflow-y: scroll;">
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0)" title="用户评价">
                                                <img src="../../assets/images/comment01.png" width="980" height="672">
                                                <img src="../../assets/images/pingjia.jpg" width="980" height="672">
                                                <img src="../../assets/images/pingjia2.png" width="980" height="672">
                                                <img src="../../assets/images/pingjia3.png" width="980" height="672">
                                                <img src="../../assets/images/pingjia4.png" width="980" height="672">
                                                <img src="../../assets/images/pingjia5.png" width="980" height="672">
                                                <img src="../../assets/images/pingjia6.png" width="980" height="672">
                                                <img src="../../assets/images/pingjia7.png" width="980" height="196">
                                                <img src="../../assets/images/pingjia8.png" width="980" height="196">
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
data(){
    return{
        carousels:[
            {itemUrl:require("../../assets/images/pinjia01.jpg")},
            {itemUrl:require("../../assets/images/pinjia02.jpg")}
        ]
    };
},
methods:{
    // 轮播图左右点击事件
    leftAndRightClick(){
        // var imglen=2;
var showimg=1;
$("#LeftArr").on("click",function(e){
    if(showimg===1){
        var imgs=$(".imgli");
        for(let i=0;i<imgs.length;i++){
            var imga=imgs.eq(i).attr("imga");
            if(imga===showimg+""){
                if(i===0){
                break;
                }else if(i===1){
                imgs[i-1].style.display="block";
                imgs[i].style.display="none";
                }
            }
        }
    }else if(showimg===2){
        var imgs2=$(".imgli");
        for(let i=0;i<imgs2.length;i++){
            var imga2=imgs2.eq(i).attr("imga");
            if(imga2===showimg+""){
                if(i===0){
                break;
                }else if(i===1){
                imgs2[i-1].style.display="block";
                imgs2[i].style.display="none";
                }
            }
        }
        showimg=1;
    }
});
$("#RightArr").on("click",function(e){
    if(showimg===1){
        var imgs=$(".imgli");
        for(let i=0;i<imgs.length;i++){
            var imga=imgs.eq(i).attr("imga");
            if(imga===showimg+""){
                if(i===0){
                imgs[i+1].style.display="block";
                imgs[i].style.display="none";
                }else if(i===1){
                    break;
                }
            }
        }
        showimg=2;
    }else if(showimg===2){
        var imgs2=$(".imgli");
        for(let i=0;i<imgs2.length;i++){
            var imga2=imgs2.eq(i).attr("imga");
            if(imga2===showimg+""){
                if(i===0){
                imgs2[i+1].style.display="block";
                imgs2[i].style.display="none";
                }else if(i===1){
                    break;
                }
            }
        }
    }
});
    }
},
mounted(){
    this.leftAndRightClick();
}
};
</script>
<style lang="less" scoped>
    .scroll_left{
            width: 23px;
            height: 63px;
            background: url(../../assets/images/btn_left.jpg) no-repeat;
            float: left;
            margin: 280px 5px 0 0;
            cursor: pointer;
    }
    .scroll_right{
        width: 23px;
        height: 63px;
        background: url(../../assets/images/btn_right.jpg) left no-repeat;
        float: left;
        margin: 280px 0px 0 5px;
        cursor: pointer;
    }
    .pic{
    height: 672px;
    float: left;
    overflow-y: hidden;
    }
    .mytabs{
    padding: 0;
    margin: 0 0 10px 25px;
    margin-bottom: 5px;
    margin-left: 0;
    list-style: none;
    width: 850px;
    position: relative;
    float: left;
    height: 50px;
    line-height: 50px;
    z-index: 1;
    border-bottom: 1px solid #ddd;
    }
    .titleh2{
    margin-bottom: 10px;
    text-align: center;
    line-height: 2;
    font-size: 30px;
    border-bottom:1px solid black;
    margin-bottom: 10px;
    }
</style>
