import router from '../router/index.js';
import store from '../store/index.js';
export default function(){
    let seteInterVal=null;// 定时器
    const timeOut=30*60*1000;
    localStorage.setItem("lastTime",new Date().getTime());
    window.onload=function(){
        document.onmousedown=function(){
            localStorage.setItem("lastTime",new Date().getTime());
        };
    };
    async function checkTimeout(){
        const currentTime=new Date().getTime();
        const lastTime=window.localStorage.getItem("lastTime");
        const tokenObj=localStorage.getItem('token');
        if(tokenObj){
            const token=JSON.parse(tokenObj).token;
            if(token){
                if(currentTime-lastTime>=timeOut){
                    clearInterval(seteInterVal);
                    localStorage.clear("lastTime");
                    if(router.history.current.fullpath!=="/"){
                        router.push("/home?overtime="+"loginout&&time="+new Date().getSeconds());
                        await store.dispatch('loginOut',{sjhm:store.state.userInfo.sjhm});
                        store.commit('initUserInfo');
                        store.commit('initToken');
                        store.commit('initRecommend');
                        store.commit('initSchoolFind');
                        localStorage.removeItem('whBkTableData');
                        localStorage.removeItem('whZkTableData');
                        localStorage.removeItem('pc');
                        window.location.reload();
                    }
                }
            }
        }
    }
    seteInterVal=setInterval(checkTimeout,2000);
}
