<template>
    <div>
        <div class="main_box px1005 box950" style="padding-left:30px;">
            <nav>
                <a class="maincolor" href="/">首页</a> <span class="c-666 en">&gt;</span>关于我们
            </nav>
        </div>
        <div class="clear_div center_box">
            <!--左边-->
            <!--<div class="i_left">
                <dl class="left_nav th clear_div">
                    <dt></dt>
                    <dd class="light">
                        <a href="about"><span class="l_3"></span>关于我们</a>
                    </dd>
                    <dd>
                        <a href="http://www.zy1dt.com/comment"><span class="l_6"></span>用户评价</a>
                    </dd>
                    <dd>
                        <a href="contact"><span class="l_5"></span>联系我们</a>
                    </dd>
                </dl>
            </div>-->
            <!--右边-->
            <div class="i_right" style="width:984px;float:none;padding-left:10px;padding-right:5px;box-sizing: border-box;">
                <dl class="r_th" style="display:flex;justify-content:center;align-items:center;margin-right: 13px;">
                    <dd class="th">
                        <span style="font-weight:bold;font-size:28px;">关于我们</span>
                    </dd>
                </dl>
                <div class="clear_div r_contact th cYre6va" style="font-size: 18px;margin-top:10px;">
                    <div xid="div13_3">
                        <p xid="default24_3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;“金榜题名高考志愿辅助决策系统”（简称“金榜志愿通”）是一个专注于大数据服务高考志愿填报“一站式”信息服务平台。公司核心技术骨干来自国防科技大学大数据技术研究团队，曾获得发明专利军队科技进步一、二、三等奖多项，以承接湖南省某教育考试院相关重点研究课题为契机，自2008年湖南省实行平行志愿录取规则以来，一直专注于大数据服务高考志愿填报的相关研究。</p>
                    </div>
                    <div  xid="div13_3" style="margin-top:10px;">
                    <p xid="default24_3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;该系统基于历年高考招生录取数据，借助等效分分析方法和科学的数学模型，运用大数据分析手段，不仅能准确预测稳中有降大院校专业组投档线，根据考生情况进行个性化志愿推荐，还能提供真实可靠的历年院校专业组录取信息，院校详细信息、学科专业信息等数据资料。</p>
                    </div>
                    <div  xid="div13_3" style="margin-top:10px;">
                    <p xid="default24_3">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;截止2023年，“金榜题名高考志愿填报辅助决策系统”系列产品已经平稳服务了湖南纯文化考生13届，已经平稳服务了湖南省艺术9大专业类的艺考生6届。“金榜志愿通”系列产品为考生合理填报高考志愿提供全方位多角度的参考依据，提高志愿填报的科学性和合理性，是广大考生高考志愿填报的两大利器。</p>
                    </div>
                    <!--<div xid="div14_3" >
                        <p style="height: 10px;" ></p>
                        <p style="font-weight: bold; margin-bottom: 0px;" xid="default27_3"
                            >系统具有如下七大优势：</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;一、首创等效分分析法。系统首创“等效分分析法”，将本不具有可比性的不同年份的高考成绩转化为可以直接进行对比分析的等效分，考生只需将自己当年的真实高考成绩与等效分进行比对，就能直观地判断出大概能就读什么院校，以及该校的何种专业。</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;二、准确预测院校投档线。系统基于历史招考数据，结合团队在行为决策理论方面多年研究得出的数学模型，运用大数据分析技术，能准确地预测各大院校的投档线，85%以上院校的投档线预测偏差在5分范围内，为考生选择报考院校指明了方向。</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;三、报考院校智能推荐。考生只要输入自己的高考成绩，系统就能自动得出多种院校组合供考生选择，还能根据考生的其他偏好进行自动调整，不仅保证了考生成绩得到充分利用，又能很好地避免因志愿导致、梯度不合理等填报错误带来的落榜风险，真正实现了科学、智能的志愿填报。</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;四、精心剔除特殊数据。系统中的数据不仅真实可靠，而且还精心剔除了自主招生、音体美艺术生、综合评价录取、定向生、民族班、预科班、单独招生、对口招生等录取数据，确保数据具有最高的参考价值。</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;五、一站式的查询服务。系统汇集了全国2500多所高等院校，本科13个学科大类（700多个专业）和专科19个学科大类（900多个专业）中所有学科专业的相关信息，所见即所得的操作方式，使得用户无需拥有高超的计算机操作技能，就能快速定位到意向的院校和专业。</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;六、利用模拟成绩评估。考生可根据月考成绩或者模拟考试成绩来提前锁定目标院校和专业，避免正式填报志愿时搞得手忙脚乱；也能及时把握与心仪院校的差距，增强学习的动力。</p>
                        <p >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;七、多途径全方位指导。高考志愿信息网、公众微信号提供全面可靠的资讯信息，手机版便于随时随地进行查看，400电话、在线客服提供及时贴心服务，售后服务QQ群毫无保留地讲解高考招生录取相关知识，知名专家现场讲座面对面地给您传授最权威的志愿填报知识，志愿在线审核和一对一咨询服务为您进行全方位把关。</p>
                        <p ></p>
                    </div>-->
                    <div xid="div11_1" style="font-size: 18px;">
                        <p style="height: 10px;" xid="p10_1" __cid="cYre6va"
                            class="cYre6va"></p>
                        <p style="font-weight: bold; margin-bottom: 0px;" xid="p81_1"
                            >系统主要特点：</p>
                        <p xid="p91_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong __cid="cYre6va"
                                class="cYre6va">面向中学：</strong>提高学校整体录取率，提升录取院校档次。
                        </p>
                        <p xid="p71_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong __cid="cYre6va"
                                class="cYre6va">面向考生：</strong>提高志愿录取概率，确保分数不浪费。
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong __cid="cYre6va"
                                class="cYre6va">面向家长：</strong>让家长懂规则、有数据、有系统、有服务，自主分析、自主填报。
                        </p>
                    </div>
                    <div xid="div11_1"  style="font-size: 18px;">
                        <p style="height: 10px;" xid="p10_1" __cid="cYre6va"
                            class="cYre6va"></p>
                        <p style="font-weight: bold; margin-bottom: 0px;" xid="p81_1"
                            >公司提供的产品与服务有：</p>
                        <p xid="p91_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 金榜志愿通文化考生系统
                        </p>
                        <p xid="p71_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 金榜志愿通艺考系统
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 一对一个性化咨询服务
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 学业规划
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 升学规划
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 强基计划
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 综合评价录取
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 港澳升学
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 考研服务
                        </p>
                        <p xid="p51_1" >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 留学、移民
                        </p>
                    </div>
                    <div xid="div13_3"  style="font-size: 18px;">
                        <p xid="default24_3" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;服务热线：4000-190-550&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13787139119（微信同号）</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {

};
</script>
<style lang="less" scoped>
            .r_contact p{
                line-height:28px;
            }
</style>
