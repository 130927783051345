<template>
    <div>
        <div class="header-container">
      <div class="header-top">
        <h2 class="logo">
            <i href="javascript:;" title="金榜志愿通" @click="refreshHtml">
            <img src="../assets/images/logo.jpg"/>
            </i>
        </h2>
        <div class="header-md">
            <div class="md-change"  v-if="token">
                <el-form :model="ruleForm"  :inline="true" style="width:100%" size="mini">
                <el-form-item label="省份:" prop="selectedSf" v-if="userInfo.yhlb===9||userInfo.yhlb===7">
                    <el-select v-model="ruleForm.selectedSf" style="width:70px;">
                    <el-option v-for="(item,index) in sfs" :key="index" :label="item.name" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="首选科目:" prop="sxkm" v-if="userInfo.yhlb===7||userInfo.yhlb===9">
                    <el-select style="width:70px;" v-model="ruleForm.sxkm">
                        <el-option label="历史" value="1"></el-option>
                        <el-option label="物理" value="2"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            </div>
            <div class="header-user" v-if="token">
                <span>科目： </span>
                <span v-if="userInfo.sxkm===2">物理/</span>
                <span v-if="userInfo.sxkm===1">历史/</span>
                <span v-if="(userInfo.zxkma===1&&userInfo.zxkmb===2)
                ||(userInfo.zxkmb===1&&userInfo.zxkma===2)">政治/地理 </span>
                <span v-if="(userInfo.zxkma===1&&userInfo.zxkmb===3)
                ||(userInfo.zxkmb===1&&userInfo.zxkma===3)">政治/化学</span>
                <span v-if="(userInfo.zxkma===1&&userInfo.zxkmb===4)
                ||(userInfo.zxkmb===1&&userInfo.zxkma==4)">政治/生物</span>
                <span v-if="(userInfo.zxkma===2&&userInfo.zxkmb===3)
                ||(userInfo.zxkmb===2&&userInfo.zxkma==3)">化学/地理 </span>
                <span v-if="(userInfo.zxkma===2&&userInfo.zxkmb===4)
                ||(userInfo.zxkmb===2&&userInfo.zxkma==4)">地理/生物</span>
                <span>
                <span v-if="(userInfo.zxkma===3&&userInfo.zxkmb===4)
                ||(userInfo.zxkmb===3&&userInfo.zxkma==4)">化学/生物</span>
                    <strong class="score">{{userInfo.gkfs}}</strong>分
                </span>
            </div>
            <div class="header-rt">
                <template v-if="token">
                    <el-button type="primary" size="small" round @click="loginOut"><i class="el-icon-switch-button"></i> 退出 </el-button>
                </template>
                <template v-else>
                    <el-button type="primary" size="small" round @click="toLogin"><i class="el-icon-user-solid"></i> 登录 </el-button>
                </template>
                <el-button type="success" size="small" round @click="toHelp"><i class="el-icon-question"></i>帮助</el-button>
            </div>
        </div>
      </div>
      <div class="header-nav">
        <ul class="nav-item">
          <li :class="{current: $route.path.includes('/home')}"><router-link  to="/home" >首页</router-link></li>
          <li :class="{current: $route.path.includes('/zypg')}"><router-link to="/zypg" >志愿评估</router-link></li>
          <li :class="{current: $route.path.includes('/schoolRecommend')}"><router-link to="/schoolRecommend" >智能推荐</router-link></li>
          <li :class="{current: $route.path.includes('/schoolfinder')}"><router-link to="/schoolfinder">院校查询 </router-link></li>
          <li :class="{current: $route.path.includes('/majorquery')}"><router-link to="/majorquery">专业查询</router-link></li>
          <li :class="{current: $route.path.includes('/datahome')}"><router-link to="/datahome">数据查询</router-link></li>
          <li :class="{current: $route.path.includes('/me')}"><router-link to="/me">用户中心</router-link></li>
          <li :class="{current: $route.path.includes('/buyindex')}" ><router-link to="/buyindex" >在线购买</router-link></li>
          <li :class="{current: $route.path.includes('/qjPlan')}"><router-link to="/qjPlan"> 强基计划</router-link></li>
          <li><a href="http://www.zy1dt.cn" target="_blank"><i class="el-icon-s-promotion"></i>艺考系统入口</a></li>
        </ul>
      </div>
  </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        sfs:[
            {name:"湖南",value:43},
            {name:"四川",value:51},
            {name:"广东",value:44}
        ],
        ruleForm:{
            selectedSf:"",
            sxkm:""
        }
    };
},
methods:{
    refreshHtml(){
        let href="";
        if (location.href.indexOf("#") !== -1) {
            href = location.href.substring(0, location.href.indexOf("#"));
        }
        location.href =href;
    },
    // 跳转到帮助页面
    toHelp(){
        this.$router.push("/help");
    },
    // 跳转到登录界面
    toLogin(){
        this.$router.push("/home");
    },
    // 退出登录
    loginOut(){
        this.$confirm('确认退出登录吗？', '提示',{type: 'warning'}).then((action) => {
          if(action === 'confirm'){
             const sjhm=this.userInfo.sjhm;
             this.$store.dispatch('loginOut',{sjhm});
             this.$router.replace('/home');
          }
        }).catch((error) => {
          return error;
        });
    }
},
computed:{
    ...mapState(["token","userInfo"])
}
};
</script>
<style lang="less" scoped>
.header-container
{
    .header-top{
        display:flex;
        justify-content:space-between;
        align-items:center;
        width:1100px;
        height:130px;
        margin:0 auto;
        position:relative;
    }
    .header-md{
        flex:1;
        display:flex;
        justify-content:flex-end;
        align-items:center;
        .md-change{
            flex: 1;
            // margin-left: 65px;
            margin-left:20px;
            padding-top: 12px;
            box-sizing: border-box;
        }
        .header-user{
            flex: 1;
            margin-right: 10px;
            text-align: right;
            font-size: 14px;
        }
        .score{
            color:#f56c6c;
        }
    }
    .header-rt{
        width: 160px;
        text-align: left;
    }
    .header-nav{
        width:100%;
        height:40px;
        background-color:#409EFF;
        .nav-item{
            min-width:1200px;
            display:flex;
            justify-content:center;
            align-items:center;
            flex-flow:nowrap;
            color:#ffffff;
            li{
                flex-basis: 120px;
                height:40px;
                line-height:40px;
                cursor:pointer;
                text-align:center;
                list-style:none;
                a{
                    display:block;
                    color:#ffffff;
                    text-decoration:none;
                    &:-webkit-any-link{
                        text-decoration:none;
                    }
                    .router-link-active{
                        text-decoration:none;
                    }
                }
            }
            li.current{
                    background-color:#0479f5;
                }
        }
    }
}
</style>
