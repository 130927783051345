<template>
    <div>
        <div class="main_box px1005 box950" style="padding-left:30px;">
               <nav>
                   <a class="maincolor" href="/">首页</a> <span class="c-666 en">&gt;</span>联系我们
               </nav>
           </div>
    <div id="banner-test" style="position: relative;height: 600px;overflow: hidden;margin: 1px auto">
        <a href="javascript:void(0)"><img src="../../assets/images/zsg-01.png" alt="立即加入" style="position: absolute;left: 50%;margin-left: -960px;"></a>
    </div>
    <div style="width: 1200px;height: auto;margin: 0px auto;">
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-1.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-2.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-3.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-4.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-5.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-6.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 0 auto;width: 1100px;">
            <img src="../../assets/images/zsgg-7.png" alt="" style="width: 1100px;">
        </div>
        <div style="margin: 50px auto;width: 1100px;">
            <div style="margin-left:45px">
                <div style="width: 450px;float: left">
                    <h4 style="font-size:20px;font-weight: 600;font-family: 'Microsoft YaHei';letter-spacing: 1px;text-align: center;color: #FDAC05">合作条件</h4>
                    <p style="font-size: 14px;;color: #464646;line-height: 25px;margin-top: 33px;padding-top:10px">1、对本地市场有科学客观的调查和认识，有较合理的营销计划和目标。</p>
                    <p style="font-size: 14px;color: #464646;line-height: 25px;margin-top: 20px">2、认可金榜志愿通的发展和经营管理模式。</p>
                    <p style="font-size: 14px;color: #464646;line-height: 25px;margin-top: 20px">3、能遵守加盟合约中的各项规定。</p>
                    <p style="font-size: 14px;color: #464646;line-height: 30px;margin-top: 20px;margin-bottom: 20px;">4、具备基本的经营管理能力的个人，团队或机构。</p>
                </div>
                <div style="width: 500px;margin-left: 450px;padding-left: 60px">
                    <h4 style="font-size:20px;font-weight: 600;font-family: 'Microsoft YaHei';letter-spacing: 1px;color:#FDAC05;margin-left: 110px;">合作流程</h4>
                    <!-- <div style="height: 80px;position: relative;margin-top: 40px">
                        <img src="../../assets/images/person1.png" alt="" ><span style="font-size: 13px;color: #464646;">普通代理商</span>
                        <div>
                            <div style="background:#C0DA7C ;width: 125px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block">关注金榜志愿通服务号</div>
                            <div style="display: inline-block;color:#C0DA7C;font-size: 20px ;font-weight: 600;height: 30px;;line-height: 30px"> &gt; </div>
                            <div style="background:#C0DA7C ;width: 80px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block">成为分销商</div>
                            <div style="display: inline-block;color:#C0DA7C;font-size: 20px ;font-weight: 600;height: 30px;;line-height: 30px"> &gt; </div>
                            <div style="background:#C0DA7C ;width: 80px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block">开始推广</div>
                        </div>
                         <img src="../../assets/images/erweima.png" alt="扫码" style="position: absolute; top:-40px;right: 50px">
                    </div> -->
                    <div>
                        <!-- <img src="../../assets/images/person2.png" alt="" ><span style="font-size: 13px;color: #464646;">合作商</span> -->
                        <div style="margin-top:101px;">
                            <div style="background:#7FCEF4 ;width: 90px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block;font-size: 20px;">咨询申请</div>
                            <!-- <div style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"> &rarr; </div> -->
                            <i class="el-icon-arrow-right" style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"></i>
                            <div style="background:#7FCEF4 ;width: 90px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block;font-size: 20px;">签订合同</div>
                            <!-- <div style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"> &rarr; </div> -->
                            <i class="el-icon-arrow-right" style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"></i>
                            <div style="background:#7FCEF4 ;width: 90px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block;font-size: 20px;">支付货款</div>
                            <!-- <div style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"> &uarr; </div> -->
                            <i class="el-icon-arrow-down" style="display: block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin-left:308px;"></i>
                            <div style="background:#7FCEF4 ;width: 90px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block;font-size: 20px;">宣传推广</div>
                            <!-- <div style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"> &gt; </div> -->
                            <i class="el-icon-arrow-left" style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"></i>
                            <div style="background:#7FCEF4 ;width: 90px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block;font-size: 20px;">培训交流</div>
                            <!-- <div style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"> &gt; </div> -->
                            <i class="el-icon-arrow-left" style="display: inline-block;color:#7FCEF4;font-size: 30px ;font-weight: 600;height: 30px;;line-height: 30px;margin:0 10px;"></i>
                            <div style="background:#7FCEF4 ;width: 90px;height: 35px;color: #fff;font:400 11px/35px 'Microsoft YaHei';text-align: center;border-radius: 2px;display: inline-block;font-size: 20px;">交付货物</div>
                        </div>
                        <div style="font-size:12px;font-family: 'Microsoft YaHei';color:#464646;margin-top: 20px">
                        <!-- <span>联系人：陈老师：13787139119(微信)&emsp;</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="banner-last" style="margin: 0 auto;width: 1100px;">
            <a href="javascript:void(0)"><img src="../../assets/images/zsgg-12.png" alt="" style="width: 1100px;"></a>
        </div>

    </div>
           <div class="clear_div center_box">
               <!-- 左边 -->
               <!--<div class="i_left">
                <dl class="left_nav th clear_div">
                    <dt></dt>
                    <dd>
                        <a href="about"><span class="l_3"></span>关于我们</a>
                    </dd>
                    <dd>
                        <a href="http://www.zy1dt.com/comment"><span class="l_6"></span>用户评价</a>
                    </dd>
                    <dd class="light">
                        <a href="contact"><span class="l_5"></span>联系我们</a>
                    </dd>
                </dl>
               </div>-->
               <!--右边-->
               <div class="i_right" style="width:984px;float:none;box-sizing: border-box;">
                   <dl class="r_th" style="display:flex;justify-content:center;align-items:center;margin-right: 13px;">
                   <dd class="th">
                   <span style="font-weight:bold;font-size:28px;">联系我们</span>
                   </dd>
                   </dl>
                    <dl class="clear_div r_contact th">
                       <dd>
                           <table width="100%" border="0" cellspacing="0">
                               <tr>
                                   <td width="40%">免费热线：4000-190-550<br>
                                    联系电话：0731-89722148&nbsp;&nbsp;&nbsp;&nbsp;13787139119(微信同号)<br>
                                    售前/售后服务邮箱：44394435@qq.com<br>
                                    网址：
                                       <a href="http://www.zy1dt.com">http://www.zy1dt.com</a><br>
                                    公司地址：湖南省长沙市开福区永通商邸B栋东单元1405室<br>
                                    <div style="margin:10px 0;"></div>
                                    QQ群号：<br>
                                    <!-- 湖南高考金榜通一群：426566477<br> -->
                                    湖南高考金榜通二群：275463431<br>
                                    湖南高考金榜通三群：578121753<br>
                                    湖南高考金榜通四群：661758353<br>
                                    湖南高考金榜通五群：201492381（美术艺考群）<br>
                                       <span style="color:#CD2626;font-size: 16px;letter-spacing: 1px">湖南正在招商加盟中，期待您的加入......</span><br>
                                       <span style="color:#CD2626;font-size: 16px;letter-spacing: 1px">广东正在招商加盟中，期待您的加入......</span><br>
                                       <span style="color:#CD2626;font-size: 16px;letter-spacing: 1px">四川正在招商加盟中，期待您的加入......</span><br>
                                       <!-- 微信公众号：GK金榜志愿通 (欢迎扫一扫)<br>
                                       微信小程序：GK金榜志愿通 (欢迎扫一扫) -->
                                   </td>
                                   <td width="20%" align="center">
                                       <h4 style="color:#EE4000;margin-top: 186px;">GK金榜志愿通</h4>
                                       <img src="../../assets/images/weixin.jpg" width="140" height="140">
                                       <h4 style="color: #EE4000">微信公众号</h4>
                                   </td>
                                   <td width="20%" align="center">
                                    <div style="color:black;font-size: 30px;font-weight: bold;margin-top: 186px;">欢迎扫一扫</div>
                                   </td>
                                   <td width="20%" align="center">
                                       <h4 style="color:#EE4000;margin-top:186px;">GK金榜志愿通</h4>
                                       <!-- <img src="../../assets/images/fuwuhao.jpg" width="140" height="140"> -->
                                       <img src="../../assets/images/xiaochengxuma.jpg" width="140" height="140">
                                       <h4 style="color:#EE4000">微信小程序</h4>
                                   </td>
                               </tr>
                           </table>
                           </dd>
                           </dl>
                   <!-- <dl class="r_th">
                       <dd class="th"><h2><strong>售前/售后服务</strong></h2></dd>
                   </dl> -->
                   <!-- <table class="contact_table">
                       <tr class="th">
                           <th width="20%">客服销售</th>
                           <th width="29%">QQ号码</th>
                           <th width="22%">联系QQ</th>
                       </tr>
                       <tr>
                           <td>金榜志愿通-湖南客服1</td>
                           <td>QQ：2675338431</td>
                           <td><a target="_blank"
                                  href="http://wpa.qq.com/msgrd?v=3&uin=2675338431&site=qq&menu=yes"><img
                                   border="0" src="http://wpa.qq.com/pa?p=2:2675338431:41"
                                   alt="点击这里给我发消息" title="点击这里给我发消息"></a></td>
                       </tr>
                       <tr>
                           <td>金榜志愿通-湖南客服2</td>
                           <td>QQ：3484198650</td>
                           <td><a target="_blank"
                                  href="http://wpa.qq.com/msgrd?v=3&uin=3484198650&site=qq&menu=yes"><img
                                   border="0" src="http://wpa.qq.com/pa?p=2:3484198650:41"
                                   alt="点击这里给我发消息" title="点击这里给我发消息"></a></td>
                       </tr>
                       <tr>
                           <td>金榜志愿通-湖南客服3</td>
                           <td>QQ：3471332782</td>
                           <td><a target="_blank"
                                  href="http://wpa.qq.com/msgrd?v=3&uin=3471332782&site=qq&menu=yes"><img
                                   border="0" src="http://wpa.qq.com/pa?p=2:3471332782:41"
                                   alt="点击这里给我发消息" title="点击这里给我发消息"></a></td>
                       </tr>
                       <tr>
                           <td>金榜志愿通-湖南客服4</td>
                           <td>QQ：226853310</td>
                           <td><a target="_blank"
                                  href="http://wpa.qq.com/msgrd?v=3&uin=226853310&site=qq&menu=yes"><img
                                   border="0" src="http://wpa.qq.com/pa?p=2:226853310:41"
                                   alt="点击这里给我发消息" title="点击这里给我发消息"></a></td>
                       </tr>
                   </table> -->
                   <!-- QQ群号：<br> -->
                <!-- 湖南高考金榜通一群：426566477<br> -->
                <!-- 湖南高考金榜通二群：275463431<br>
                湖南高考金榜通三群：578121753<br>
                湖南高考金榜通四群：661758353<br>
                湖南高考金榜通五群：201492381（美术艺考群）<br> -->

                   <!-- <br> 联系电话：0731-89722148&nbsp;&nbsp;&nbsp;&nbsp;13787139119(微信同号)
                   <br> 售前/售后服务邮箱：44394435@qq.com
                   <br> 地址：湖南省长沙市开福区三一大道303号永通商邸B栋东单元1405室
                   <br>
                   <br> -->
                    <!--<dl class="r_th">
                       <dd class="th">
                           <h2>
                               <strong>河南团队联系方式</strong>
                           </h2>
                       </dd>
                   </dl>-->
                    <!-- <dd style="color:#CD2626;font-size: 16px;letter-spacing: 1px">湖南正在招商加盟中，期待您的加入......</dd> -->
                   <!--<dl class="r_th">
                       <dd class="th">
                           <h2>
                               <strong>湖北团队联系方式</strong>
                           </h2>
                       </dd>
                   </dl>-->
                     <!-- <dd style="color:#CD2626;font-size: 16px;letter-spacing: 1px">广东正在招商加盟中，期待您的加入......</dd>
                     <dd style="color:#CD2626;font-size: 16px;letter-spacing: 1px">四川正在招商加盟中，期待您的加入......</dd> -->
                     <!-- <dd style="color:#CD2626;font-size: 16px;letter-spacing: 1px">湖北正在招商加盟中，期待您的加入......</dd> -->
               </div>
           </div>
    </div>
</template>
<script>
import '../../assets/css/g.css';
import '../../assets/css/g-headerfooter.css';
import '../../assets/css/index.css';
export default {

};
</script>
<style lang="less" scoped>
</style>
