<!-- <template>
    <div class="school-detail-wraper">
        <div class="card-top">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-top">
            <div class="top-logo">
                <img :src="detail.logo" alt="院校logo" width="100%">
            </div>
            <div class="top-mid">
                <div class="mid-school-name">
                    <h3>
                        {{ detail.yxmc }}
                    <template v-if="detail.sf211===1">
                        <img src="../assets/images/ico1.png" width="28" height="26" title="211">
                    </template>
                    <template v-if="detail.sf985===1">
                        <img src="../assets/images/ico2.png" width="28" height="26" title="211">
                    </template>
                    <template v-if="detail.yfyjsy===1">
                        <img src="../assets/images/ico3.png" width="28" height="26" title="211">
                    </template>
                    <template v-if="detail.sfsyl===1">
                        <img src="../assets/images/ico8.png" width="28" height="26" title="211">
                    </template>
                    </h3>
                    <el-button  type="primary" size="mini" @click="closeWin" plain round>关闭</el-button>
                </div>
                <div class="mid-school-nature">
                    <div class="nature-item">
                        <span>{{detail.yymc}}</span>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
        <div class="detail-main">
            <el-tabs v-model="activeName" type="card">
            <el-tab-pane label="专业组详情" name="1">
                <div class="schoolIntro_con2">
                    <div class="catTitle2">
                        <h2>专业组历年数据对比</h2>
                    </div>
                    <div class="txt"  id="lqTab">
                        <table class="info-data">
                            <tr>
                                <th rowspan="2">专业组</th>
                                <th rowspan="2">年度</th>
                                <th colspan="6">录取分</th>
                                <th colspan="3">等效分</th>
                                <th>计划人数</th>
                            </tr>
                            <tr>
                                <th>最低分</th>
                                <th>平均分</th>
                                <th>最高分</th>
                                <th>投档线</th>
                                <th>线差</th>
                                <th>入档位次</th>
                                <th>最低分</th>
                                <th>平均分</th>
                                <th>最高分</th>
                                <th>今年</th>
                            </tr>
                            <tr v-for="(item,index) in zyzTotals.sub" :key="index">
                                <td :rowspan="zyzRowspan" v-if="index===0">
                                    <a href="javascript:void(0)" style="color:#2186de;" @click="toZyzDetail">[{{zyzTotals.zyz}}]</a>
                                    <span style="display:block;width:41px;height:5px"></span>
                                    <div class="collectBtn" v-if="zyzTotals.sfsc===0" @click="addCollect(zyzTotals.zyz,zyzTotals.sfsc)">加入收藏</div>
                                    <div class="collectBtn" v-if="zyzTotals.sfsc===1" @click="addCollect(zyzTotals.zyz,zyzTotals.sfsc)">取消收藏</div>
                                </td>
                                <td>{{item.nd?item.nd:item.nd===0?'平均分':'-'}}</td>
                                <td>{{!item.zdf?'-':item.zdf}}</td>
                                <td>{{!item.pjf?'-':item.pjf}}</td>
                                <td>{{!item.zgf?'-':item.zgf}}</td>
                                <td>{{!item.tdx?'-':item.tdx}}</td>
                                <td>{{!item.xc?'-':item.xc}}</td>
                                <td>{{!item.rdwc?'-':item.rdwc}}</td>
                                <td v-if="item.nd===0" class="yuceTdx">{{!item.zdtwf?'-':item.zdtwf}}</td>
                                <td v-if="item.nd!==0">{{!item.zdtwf?'-':item.zdtwf}}</td>
                                <td>{{!item.pjtwf?'-':item.pjtwf}}</td>
                                <td>{{!item.zgtwf?'-':item.zgtwf}}</td>
                                <td :rowspan="zyzRowspan" v-if="index===0">{{zyzTotals.jnjhs}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="院校简介" name="2">
                <div class="schoolIntro_con">
                    <div class="intro"  style="text-indent: 28px;" id="jjDiv">{{ detail.jj }}</div>
                    <div class="read-more" style="text-align: right;">
                    <a href="javascript:void(0)" @click="watchAndStop">{{detailTxt}}</a>
                    <div class="baseInfo clearfix">
                        <ul class="baseInfo_left">
                            <li class="biItem">
                                <span class="t">创建时间</span>
                                <div class="c" id="cjsj">{{detail.jxsj}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">隶属于</span>
                                <div class="c" id="ls">{{detail.ls}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">学生人数</span>
                                <div class="c" id="xsrs">{{detail.xsrs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">院士人数</span>
                                <div class="c" id="ysrs">{{detail.ysrs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">男生比例</span>
                                <div class="c" id="nsbl">{{detail.nsbl}}</div>
                            </li>
                        </ul>
                        <ul class="baseInfo_right">
                            <li class="biItem">
                                <span class="t">一流学科</span>
                                <div class="c" id="zdxk">
                                </div>
                            </li>
                            <li class="biItem">
                                <span class="t">学校类型</span>
                                <div class="c" id="xxlx">
                                    <template v-if="detail.xxlb===1">
                                    <span>综合类</span>
                                    </template>
                                    <template v-if="detail.xxlb===2">
                                    <span>理工类</span>
                                    </template>
                                    <template v-if="detail.xxlb===3">
                                    <span>财经类</span>
                                    </template>
                                    <template v-if="detail.xxlb===4">
                                    <span>农林类</span>
                                    </template>
                                    <template v-if="detail.xxlb===5">
                                    <span>林业类</span>
                                    </template>
                                    <template v-if="detail.xxlb===6">
                                    <span>医药类</span>
                                    </template>
                                    <template v-if="detail.xxlb===7">
                                    <span>师范类</span>
                                    </template>
                                    <template v-if="detail.xxlb===8">
                                    <span>体育类</span>
                                    </template>
                                    <template v-if="detail.xxlb===9">
                                    <span>语言类</span>
                                    </template>
                                    <template v-if="detail.xxlb===10">
                                    <span>政法类</span>
                                    </template>
                                    <template v-if="detail.xxlb===11">
                                    <span>艺术类</span>
                                    </template>
                                    <template v-if="detail.xxlb===12">
                                    <span>民族类</span>
                                    </template>
                                    <template v-if="detail.xxlb===13">
                                    <span>军事类</span>
                                    </template>
                                    <template v-if="detail.xxlb===14">
                                    <span>交叉学科类</span>
                                    </template>
                                </div>
                            </li>
                            <li class="biItem">
                                <span class="t">博士点个数</span>
                                <div class="c" id="bsdgs">{{detail.bsdgs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">硕士点个数</span>
                                <div class="c" id="ssdgs">{{detail.ssdgs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">女生比例</span>
                                <div class="c" id="nvsbl">{{detail.nvsbl}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="catTitle">
                        <h2>学费信息</h2>
                    </div>
                    <div class="txt" style="text-indent:28px;" id="xfxx" v-html="detail.xfxx">
                    </div>
                    <div class="catTitle">
                        <h2>就业情况</h2>
                    </div>
                    <div class="txt" style="text-indent:28px;" id="jyqk" v-html="detail.jyqk">
                    </div>
                    <div class="txt">
                        以上数据由金榜志愿通<a href></a>汇总整理。
                    </div>
                    </div>
                </div>
                <div class="schoolIntro_side">
                    <div class="schoolIntro_sideBd">
                        <div class="pics">
                            <div class="schoolPic_slide">
                                <ul>
                                    <li>
                                        <img id="yxtb_xgt" :src="detail.logo" :alt="detail.yxmc"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="infos">
                            <ul>
                                <li>
                                    <label>所在城市：</label>
                                    <font id="ss">{{detail.szdqmc}}</font>
                                </li>
                                <li>
                                    <label>学校地址：</label>
                                    <font id="xxdz">{{detail.dz}}</font>
                                </li>
                                <li>
                                    <label>招生电话：</label>
                                    <font id="zsdh">{{detail.dh}}</font>
                                </li>
                                <li>
                                    <label>电子邮箱：</label>
                                    <font id="dzyx">{{detail.dzyx}}</font>
                                </li>
                            </ul>
                            <div class="website">
                                <a :href="detail.wz" target="_blank" class="a1">进入官网</a>
                                <a :href="detail.zswz" target="_blank" class="a2">进入招生网站</a>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="一流学科" name="3">
                <div class="schoolIntro_con22">
                    <div class="majorCon2">
                        <h3>一流学科</h3>
                    </div>
                    <div class="majorCon1" id="gjppzyDiv">
                        <ul class="list clearfix" id="gjppzy">
                            <li v-for="(item,index) in detail.ylxks" :key="index">
                                <a href="javascript:void(0)" :zy_zyid="item.zydm">{{item.zymc}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="招生章程" name="4">
                <div class="schoolIntro_con23">
                    <div class="majorCon2">
                        <h3>招生章程</h3>
                    </div>
                    <div class="txt" id="zsjz" v-html="detail.zszc"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
// import $ from 'jquery';
export default {
data(){
    return{
        activeName:"1",
        detail:{
            logo:"",
            dz:"",
            yxmc:"",
            wz:"",
            jxsj:"",
            ls:"",
            xsrs:"",
            bsdgs:"",
            ssdgs:"",
            ysrs:"",
            nsbl:"",
            nvsbl:"",
            jj:"",
            lsjj:"",
            xfxx:"",
            jyqk:"",
            szdqmc:"",
            dh:"",
            dzyx:"",
            ylxks:[],
            zswz:"",
            zszc:"",
            yymc:"",
            sf985:0,
            sf211:0,
            yfyjsy:0,
            sfsyl:0,
            gbdm:"",
            xxlbmc:"",
            xxlb:""
        },
        zyzTotals:{},
        zyzRowspan:"",
        maxwidth:300,
        detailTxt:"查看详情 >>"
    };
},
methods:{
    // 获取院校详情数据
    async getSchoolDeatil(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("lqTab"),text:"正在加载中"});
        const { gbdm, zyz = null,pc,yxmc,yxxh } = this.$route.query;
        const res=await this.$http.postHttp("/yxcx/getYxDetail",{
            gbdm,
            zyz,
            pc,
            yxmc,
            yxxh
        });
        if(res.data.code===200){
            this.detail.logo=res.data.obj.yxml.logo;
            this.detail.dz=res.data.obj.yxml.dz;
            this.detail.wz=res.data.obj.yxml.xxwz;
            this.detail.yxmc=res.data.obj.yxml.yxmc;
            this.detail.jj=res.data.obj.yxml.jj;
            this.detail.jxsj=res.data.obj.yxml.jxsj;
            this.detail.ls=res.data.obj.yxml.ls;
            this.detail.xsrs=res.data.obj.yxml.xsrs;
            this.detail.bsdgs=res.data.obj.yxml.bsdgs;
            this.detail.ysrs=res.data.obj.yxml.ysrs;
            this.detail.ssdgs=res.data.obj.yxml.ssdgs;
            this.detail.nsbl=res.data.obj.yxml.nsbfb;
            this.detail.nvsbl=res.data.obj.yxml.nvsbfb;
            this.detail.xfxx=res.data.obj.yxml.xfxx;
            this.detail.jyqk=res.data.obj.yxml.jyqk;
            this.detail.ylxks=res.data.obj.zdzys;
            this.detail.szdqmc=res.data.obj.yxml.szdqmc;
            this.detail.dh=res.data.obj.yxml.dh;
            this.detail.dzyx=res.data.obj.yxml.dzyj;
            this.detail.zswz=res.data.obj.yxml.zswz;
            this.detail.zszc=res.data.obj.yxml.zszc;
            this.detail.yymc=res.data.obj.yxml.yxywmc;
            this.detail.sf985=res.data.obj.yxml.sf985;
            this.detail.sf211=res.data.obj.yxml.sf211;
            this.detail.sfsyl=res.data.obj.yxml.sfsyl;
            this.detail.yfyjsy=res.data.obj.yxml.yfyjsy;
            this.detail.gbdm=res.data.obj.yxml.gbdm;
            this.detail.xxlb=res.data.obj.yxml.xxlb;
            this.detail.xxlbmc=res.data.obj.yxml.xxlbmc;
        }
        const res2=await this.$http.postHttp("/yxcx/getLqxq",{
            kl:this.userInfo.kl,
            pc,
            yxxh,
            zxkma:this.userInfo.zxkma,
            zxkmb:this.userInfo.zxkmb,
            zyz
        });
        if(res2.data.code===200){
            this.zyzTotals=res2.data.obj.zyzTotals[0];
            this.zyzRowspan=res2.data.obj.zyzTotals[0].sub.length;
            this.zyzTotals.jnjhs=res2.data.obj.zyzTotals[0].sub[0].jnjhs;
            this.zyzTotals.sfsc=res2.data.obj.sfsc;
        }else if(res2.data.code===500){
            if(res2.data.message){
                this.$message({message:res2.data.message,type:"error"});
            }
        }
        loading.close();
    },
            // 关闭当前页面
    closeWin() {
                const userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
                    window.open('','_self').close();
                }else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
    },
    // 去除HTML标签，提取其中的文字
    highlight(item){
        return item.replace(/<[^>]+>/g, '');
    },
    // 跳转到专业组详情
    toZyzDetail(gbdm,yxmc,yxxh,pc,zyz){
        const url=this.$router.resolve({
            path:"/zyzDetail",
            gbdm,
            yxmc,
            yxxh,
            pc,
            zyz
        });
        window.open(url.href,"_blank");
    },
    // 查看简介和收起简介
    watchAndStop(){
        const maxwidth=this.maxwidth;
        const txt=this.detail.jj;
        if(txt.length-7<=maxwidth){
            this.detail.jj=this.detail.lsjj;
            this.detailTxt="<< 收起详情";
        }else{
            this.detail.jj=txt.substring(0,maxwidth)+".......";
            this.detailTxt="查看详情 >>";
        }
    },
    // 用jQuery实现查看详情和收起详情
    // watchAndStop(){
    //     $('.inner .intro').each(function () {
    //                             var maxwidth = 300; // 设置最多显示的字数
    //                             var textTemp = $(this).text(); // 临时记录
    //                             if ($(this).text().length > maxwidth) {
    //                                 $(this).text($(this).text().substring(0, maxwidth) + ".......");
    //                                 $(".read-more a").text("查看详情 >>");
    //                             }
    //                             $(".read-more a").click(function () {
    //                                 var temp = $('.inner .intro');
    //                                 if (temp.text().length - 7 <= maxwidth) {
    //                                     temp.text(textTemp);
    //                                     $(this).text("<< 收起详情");
    //                                 } else {
    //                                     temp.text(temp.text().substring(0, maxwidth) + ".......");
    //                                     $(this).text("查看详情 >>");
    //                                 }
    //                             });
    //                         });
    // },
            // 加入收藏
    async addCollect(zyz,status){
        const { pc,yxxh } = this.$route.query;
        const res=await this.$http.postHttp("/yhgz/addGzYxZyz",{
            yhid:this.userInfo.yhid,
            yxxh,
            pc,
            kl:this.userInfo.sxkm,
            zyz,
            xm:this.userInfo.xm,
            fs:this.userInfo.gkfs+"",
            status
        });
        if(res.data.code===200){
            this.$message({type:"success",message:res.data.message,showClose:true});
        }else if(res.data.code===500){
            this.$message({message:res.data.message,type:"error"});
        }
        await this.getSchoolDeatil();
    }
},
computed:{
...mapState(["userInfo"])
},
async mounted(){
    await this.getSchoolDeatil();
        this.detail.jj=this.highlight(this.detail.jj);
        this.detail.lsjj=this.highlight(this.detail.jj);
        const maxwidth=this.maxwidth;
        let txt=this.detail.jj;
        if(txt.length>maxwidth){
            txt=txt.substring(0,maxwidth)+".......";
        }
        this.detail.jj=txt;
}
};
</script>
<style lang="less" scoped>
.school-detail-wraper{
    min-width:1100px;
    margin:0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .detail-top{
        display: flex;
        align-items: center;
        width: 1100px;
        margin: 0 auto;
        padding: 20px 0;
        background-color: #fff;
        .top-logo{
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            margin: 0 30px;
        }
        .top-mid{
            width: 80%;
            box-sizing: border-box;
            color: #666;
        }
        .mid-school-name{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
        }
        .mid-school-nature{
            display: flex;
            font-size: 14px;
            .nature-item{
                flex: 1;
                .school-link{
                    cursor: pointer;
                }
            }
        }
    }
    .detail-main{
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 20px 30px;
        background-color: #fff;
    }
}
.schoolIntro_con2 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    margin: 0 20px 50px 190px;
    min-height: 500px;
    // padding-top: 130px;
}
.catTitle2 {
    height: 35px;
    border-left: 5px solid #5287c6;
    padding-left: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    h2{
        font-size: 26px;
        line-height: 35px;
        font-weight: normal;
        color: #333;
    }
}
.info-data{
    width: 100%;
    color: #666;
    th{
        border: 1px solid #09f;
        padding: 1px 0;
        text-align: center;
        background-color: #e4f5ff;
    }
    td{
        padding: 1px 5px;
        border: 1px solid #09f;
        text-align: center;
    }
}
.schoolIntro_con {
    margin: 30px 0 30px 180px;
    min-height: 500px;
    width: 510px;
    float: left;
    display: inline;
    .intro{
        text-align: justify;
        word-wrap: break-word;
        margin-bottom: 15px;
    }
    .txt{
        text-align: justify;
        word-wrap: break-word;
        margin-bottom: 35px;
    }
    a{
        color: #2186de;
    }
}
.baseInfo{
    border-top: 2px solid #efaf49;
    // background: url(school.css);
    line-height: 33px;
    margin-bottom: 20px;
    height: 170px;
    li{
        height: 33px;
        vertical-align: top;
    }
    .t{
        float: left;
      }
      .c{
        float: right;
        text-align: right;
        color: #999;
      }
}
.clearfix {
    *zoom: 1;
}
.baseInfo_left {
    float: left;
    width: 47%;
}
.baseInfo_right {
    float: right;
    width: 47%;
}
.catTitle {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    margin-bottom: 10px;
    h2{
        font-size: 26px;
        line-height: 35px;
        font-weight: normal;
        color: #efaf49;
        text-align: left;
    }
}
.schoolIntro_side {
    float: right;
    width: 260px;
    margin-top: 30px;
    .infos{
        border: 1px solid #E5E5E5;
        border-top: 0;
        ul{
            padding: 5px 12px 12px 12px;
        }
        li{
            padding: 10px 0 10px 75px;
            border-bottom: 1px dashed #DDD;
            line-height: 18px;
            word-wrap: break-word;
            overflow: hidden;
        }
        label{
            float: left;
            margin-left: -75px;
        }
        .website{
            height: 30px;
            margin-left: 15px;
            margin-bottom: 10px;
            a{
                line-height: 28px;
                float: left;
                border: 1px solid #D6D6D6;
                border-radius: 5px;
                text-align: center;
            }
            .a1{
                width: 90px;
                margin-right: 13px;
            }
            .a2{
                width: 120px;
            }
        }
    }
}
.schoolIntro_sideBd {
    width: 260px;
}
.schoolPic_slide {
    width: 260px;
    height: 240px;
    overflow: hidden;
    position: relative;
    ul{
        width: 260px;
        height: 240px;
        overflow: hidden;
    }
    li{
        float: left;
    }
    img{
        width: 260px;
        height: 240px;
    }
}
.collectBtn{
    width: 36px;
    padding: 3px;
    margin: 0 auto;
    font-size: 13px;
    color: #fff;
    border: 1px solid #f17070;
    border-radius: 3px;
    background: #f17070;
    cursor: pointer;
}
.schoolIntro_con22 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
}
h3{
    font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
}
.majorCon1 {
    margin-bottom: 25px;
    .list{
        margin-bottom: 15px;
    // background: url(school.css);
    li{
        // background: url(school.css) no-repeat 28px 20px;
        text-indent: 18px;
        height: 45px;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        float: left;
        width: 235px;
        padding-left: 15px;
        list-style: square;
    }
    }
}
}
.schoolIntro_con23 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
    h3{
        font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
    }
}
.txt{
    margin-bottom: 20px;
}
}
.yuceTdx{
    color: #ED4A09;
}
</style> -->
<template>
    <div>
        <Detail type="1"/>
    </div>
</template>
<script>
import Detail from '../components/detail.vue';
export default {
components:{
    Detail
}
};
</script>
<style lang="less">

</style>
