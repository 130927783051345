<template>
    <div>
        <div class="block">
            <h3 class="title">专业解读：</h3>
            <div v-html="zyjd"></div>
        </div>
        <div class="block">
            <h3 class="title">就业前景与方向：</h3>
            <div v-html="jyqjyfx"></div>
        </div>
    </div>
</template>
<script>
export default {
props:["zyjd","jyqjyfx"]
};
</script>
<style lang="less" scoped>
.block{
    width:1100px;
    margin:10px auto;
    .title{
        color: #333;
        font-weight:bold;
        font-size:16px;
        margin:20px 0;
    }
}
</style>
