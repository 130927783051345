<template>
    <div>
        <div class="clear_div2 foot">
      <dl class="clear_div2 foot">
            <div class="foot2">
            <div class="icp" style="width:500px;margin-left:-10px;">技术支持：湖南豪之天信息技术有限公司      <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备16013382号</a></div>
            <div style="margin-left:200px;"><div class="time" style="width:100%;padding-top:17px;"><p>金榜志愿通团队将竭力为您提供服务</p></div></div>

            </div>
            <div class="foot1">
            <div style="margin-left:-10px;">经营许可证编号：<a href="https://beian.miit.gov.cn" target="_blank">湘B2-20220719</a>
                <img src="../assets/images/footerimg/beianimg.png" style="width:20px;height:20px;"/>
                <a href="https://beian.mps.gov.cn/#/query/webSearch"  target="_blank" style="margin-left:10px;">湘公网安备43010502001696</a>
            </div>
            <!-- margin-left:460px; -->
                             <div style="margin-left:188px;">招商/客服热线：<i class="fuwurexian">4000-190-550</i></div>
            </div>
        </dl>
    </div>
    <!-- 在线咨询 start -->
    <div class="yb_conct">
    <div class="yb_bar">
    <ul>
    <li class="yb_ercode" style="height: 53px;">微信二维码 <br><img class="hd_qr" src="../assets/images/weixin.jpg" width="125" alt="关注你附近"></li>
    <li class="yb_QQ"><a  href="/#/abouts/contact" title="在线客服">在线客服</a><br></li>
    <li class="yb_top">返回顶部</li>
    </ul>
    </div>
</div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
data(){
    return{};
},
methods:{
    rightHtmlHover(){
        if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
                    $(".yb_conct").css("display", "none");
                }else{
                    $(".yb_ercode").hover(function() {
                        $(".yb_conct").css("right", "5px");
                        $(".yb_bar .yb_ercode").css('height', '200px');
                    }, function() {
                        $(".yb_conct").css("right", "-127px");
                        $(".yb_bar .yb_ercode").css('height', '53px');
                    });
                    $(".yb_top").click(function() {
                        $("html,body").animate({
                            scrollTop : '0px'
                        }, 300);
                    });
                }
    }
},
mounted(){
    this.rightHtmlHover();
}
};
</script>
<style lang="less" scoped>
.foot1{
    width:1200px;
    display:flex;
    justify-content:left;
    align-items:center;
    margin-top:10px;
}
.foot2{
    width:1200px;
    display:flex;
    justify-content:left;
    align-items:center;
}
div.foot {
    margin-top: 20px;
    clear: both;
    background: #4a4a4a;
    padding: 20px 0 45px 0;
}
dl.foot {
    clear: both;
    color: #fff;
    width: 960px;
    margin: 0 auto;
    background: #4a4a4a;
    padding: 25px 0;
}
dl.foot .icp {
    float: left;
    width: 60%;
    padding-top: 20px;
    line-height: 24px;
}
dl.foot a {
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}
.yb_conct {
    position: fixed;
    z-index: 9999999;
    top: 200px;
    right: -127px;
    cursor: pointer;
    transition: all .3s ease;
}
.yb_bar .yb_ercode {
    background: #0081ff url(../assets/images/fixCont.png) no-repeat 0 -169px;
}
.yb_bar ul li {
    width: 175px;
    height: 53px;
    font: 16px/53px 'Microsoft YaHei';
    color: #fff;
    text-indent: 54px;
    margin-bottom: 3px;
    border-radius: 3px;
    transition: all .5s ease;
    overflow: hidden;
}
li{
    list-style: none;
}
.hd_qr {
    padding: 0 29px 25px 29px;
}
.yb_bar .yb_QQ {
    background: #0081ff url(../assets/images/fixCont.png) no-repeat 0 -113px;
}
.yb_QQ a {
    display: block;
    text-indent: 54px;
    width: 100%;
    height: 100%;
    color: #fff;
}
a{
    text-decoration: none;
}
.yb_bar .yb_top {
    background: #0081ff url(../assets/images/fixCont.png) no-repeat 0 0;
}
</style>
