export default{
    userInfo: {}, // 用户信息
    token:"",
    userName:'',
    userPwd:'',
    collectInfo:{
        collectSchool: [], // 用户收藏院校
        pc:null
    },
    schoolFindData: {}, // 院校查询结果
    recommendData: {}, // 推荐结果
    lrzyfaInfo:{
        lrzyfaList:[],
        pc:null
    },
    compareSchool:[]
};
