<template>
    <div>
        <div class="tdx-title">历年院校投档线</div>
        <div class="tdx-condition">
            <el-form :inline="true">
                <el-form-item label="选择批次">
                    <el-select style="width:150px;" v-model="tdxParam.pc" clearable>
                    <el-option v-if="pcList.length===0" disabled value="-1">
                        <i class="el-icon-loading"  style="font-size: 16px;"></i>
                    </el-option>
                    <template v-if="pcList.length>0">
                        <el-option v-for="(item,index) in pcList"
                    :key="index" :label="item.name" :value="item.value">
                        </el-option>
                    </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择年度">
                    <el-select style="width:150px;" v-model="tdxParam.nd" clearable>
                        <el-option v-if="ndList.length===0" disabled value="-1">
                            <i class="el-icon-loading"  style="font-size: 16px;"></i>
                        </el-option>
                        <template v-if="ndList.length>0">
                        <el-option v-for="(item,index) in ndList"
                        :key="index" :label="item.name" :value="item.value">
                    </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="院校名称">
                    <el-input style="width:150px;" v-model.trim="tdxParam.yxmc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="getTdx">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div id="tdx-data">
            <el-table border :data="tdxList" height="560"
            :header-cell-style="{'background-color':'#B0E2FF',
            'color': '#3B3B3B','border':'1px solid #0099FF','text-align':'center'}"
            :cell-style="{'border':'1px solid #0099FF','text-align':'center'}">
                <el-table-column show-overflow-tooltip label="年度" prop="nd" width="100" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校代号" prop="sb" width="100" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校名称" width="280" prop="yxmc" :resizable="false"></el-table-column>
                <el-table-column label="首选科目" prop="kl" width="100" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="scope.row.kl===1">历史</span>
                        <span v-if="scope.row.kl===2">物理</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="批次" prop="pc" width="90" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="scope.row.pc===6">本科批</span>
                        <span v-if="scope.row.pc===7">专科批</span>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip label="专业组" prop="zyz" width="100" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="投档线" prop="tdx" width="100" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="控制线" prop="kzfs" width="100" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="线差" width="100" :resizable="false">
                    <template slot-scope="scope">
                        <span v-if="scope.row.tdx>0&&scope.row.kzfs>0">{{scope.row.tdx-scope.row.kzfs}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div v-if="tdxFlag" class="tdx-page">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="tdxParam.pageNum"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="tdxParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tdxTotal">
            </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        tdxList:[],
        tdxParam:{
            nd:"",
            pc:"",
            yxmc:"",
            kl:"",
            pageNum:1,
            pageSize:10
        },
        pcList:[
        ],
        tdxFlag:true,
        tdxTotal:0,
        ndList:[]
    };
},
methods:{
    async getTdx(){
        this.loadData();
    },
   async loadData(pageNum=1,pageSize=10){
        this.tdxFlag=false;
        const loading=this.$loading({fullscreen:true,target:document.getElementById("tdx-data"),text:"正在加载中"});
        this.tdxParam.pageNum=pageNum;
        this.tdxParam.pageSize=pageSize;
        const res=await this.$http.postHttp("/lnsj/tdx",this.tdxParam);
        if(res.data.code===200){
            this.tdxList=res.data.obj.records;
            this.tdxTotal=res.data.obj.total;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
        this.tdxFlag=true;
    },
    async getPcCondition(){
        const res=await this.$http.getHttp("/lnsj/tdxpcs");
        if(res.data.code===200){
            const pcs=res.data.obj;
            for(let i=0;i<pcs.length;i++){
                if(pcs[i].pcmc){
                    this.pcList[i]={};
                    this.pcList[i].name=pcs[i].pcmc;
                    this.pcList[i].value=pcs[i].pc+"";
                }
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    async handleSizeChange(pageSize){
        await this.loadData(1,pageSize);
    },
    async handleCurrentChange(pageNum){
        await this.loadData(pageNum,10);
    },
    async getNdConditon(){
        const res=await this.$http.getHttp("/lnsj/tdxNds");
        if(res.data.code===200){
            const nds=res.data.obj;
            const ndArr=[];
            for(let i=0;i<nds.length;i++){
                ndArr.push({name:nds[i],value:nds[i]+""});
            }
            this.ndList=ndArr;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
    this.tdxParam.kl=this.userInfo.sxkm;
    await this.getTdx();
    await this.getPcCondition();
    await this.getNdConditon();
}
};
</script>
<style lang="less" scoped>
.tdx-title{
    width:1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    font-size: 18px;
    font-weight: bold;
}
.tdx-page{
    width:800px;
    margin:20px auto;
}
</style>
