import { render, staticRenderFns } from "./jbxx_db.vue?vue&type=template&id=43837962&scoped=true&"
import script from "./jbxx_db.vue?vue&type=script&lang=js&"
export * from "./jbxx_db.vue?vue&type=script&lang=js&"
import style0 from "./jbxx_db.vue?vue&type=style&index=0&id=43837962&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43837962",
  null
  
)

export default component.exports