<template>
    <div class="register-container">
        <div class="card-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="member">
        <el-form ref="registForm" :model="ruleForm" :rules="rules" label-width="100px" size="small">
            <el-form-item label="手机号" prop="sjhm">
                <el-input v-model.trim="ruleForm.sjhm" style="width:360px"
                placeholder="请输入手机号" auto-complete="password" @blur="changeSjhm(ruleForm.sjhm)"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input type="password" auto-complete="password"
                v-model.trim="ruleForm.password" style="width:360px"
                placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
                <el-input type="password" auto-complete="password"
                v-model.trim="ruleForm.confirmPassword" style="width:360px"
                placeholder="请输入密码" show-password></el-input>
            </el-form-item>
            <el-form-item label="注册省份" prop="province">
                <el-select style="width:360px;" :popper-append-to-body="false"
                  filterable
                  v-model="ruleForm.province" @change="changeProvince">
                    <el-option v-for="(item,index) in sfs" :key="index"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="中学" prop="school">
                <!-- <el-cascader
                v-model="ruleForm.school"
                style="width:360px;"
                :options="options"></el-cascader> -->
                <el-select v-model="ruleForm.shi" style="width:100px;margin-right:10px;"
                @change="changeShi" :popper-append-to-body="false">
                <template v-if="citys.length>0">
                <el-option v-for="(item,index) in citys"
                :key="index" :label="item.name" :value="item.value"></el-option>
                </template>
                </el-select>
                <el-select v-model="ruleForm.qu" style="width:100px;margin-right:10px;"
                @change="changeQu" :popper-append-to-body="false">
                <template v-if="areas.length>0">
                <el-option v-for="(item,index) in areas"
                :key="index" :label="item.name" :value="item.value"></el-option>
                </template>
                </el-select>
                <el-select v-model="ruleForm.school" style="width:140px;"
                :popper-append-to-body="false">
                <template v-if="schools.length>0">
                <el-option v-for="(item,index) in schools"
                :key="index" :label="item.name" :value="item.value"></el-option>
                </template>
                </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="xm">
              <el-input v-model.trim="ruleForm.xm" style="width:360px;"
              placeholder="请输入考生姓名" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="xb">
                <el-select style="width:360px;" v-model="ruleForm.xb" :popper-append-to-body="false">
                <el-option v-for="(item,index) in xbs" :key="index"
                :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="首选科目" prop="sxkm">
                <el-select style="width:360px;" v-model="ruleForm.sxkm"
                :popper-append-to-body="false">
                <el-option v-for="(item,index) in sxkms" :key="index"
                :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="再选科目" prop="zxkms">
                <el-checkbox-group :max="2" v-model="ruleForm.zxkms">
                <el-checkbox v-for="item in zxkms" :key="item.value"
                :label="item.value" :value="item.value">{{item.name}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="分数" prop="score">
              <el-input v-model.trim="ruleForm.score"
              @change="gkfsChange" style="width:360px;"
              placeholder="高考出分前无限改" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="省排名" prop="proPm">
              <el-input v-model.trim="ruleForm.proPm"
              @change="proPmChange" style="width:360px;"
              placeholder="请输入省排名" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="高考年度" prop="nd">
              <el-select style="width:360px;" v-model="ruleForm.nd" :popper-append-to-body="false">
                <el-option v-for="(item,index) in nds" :key="index"
                :label="item.name" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="会员卡号" prop="cardNum">
              <el-input style="width:360px;" @change="cardChange" v-model.trim="ruleForm.cardNum"
              placeholder="请输入金榜通卡号" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="卡号密码" prop="cardPwd">
              <el-input style="width:360px;" @change="cardPwdChange" v-model.trim="ruleForm.cardPwd"
              placeholder="请输入卡号密码" autocomplete="off" type="password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="checked">我已阅读并接受<a href="/#/userxx" target="_blank"
              style="text-decoration: none;color: #5BC0DE;">《用户使用协议》</a></el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="saveRegist('registForm')">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
    <el-dialog
    title="是否升级" width="30%" :visible.sync="dialogMsgVisible">
    <div style="margin:0 auto;">该手机号已注册，身份是游客，请升级为会员</div>
    <div style="width:200px;margin:20px auto;" class="flex-div">
      <el-button type="primary" @click="PromoteHy">升级</el-button>
      <el-button @click="closeMsgDialog">取消</el-button>
    </div>
    </el-dialog>
    <el-dialog
    title="发送验证码" width="30%" :visible.sync="dialogYzmVisible">
    <el-form>
      <el-form-item label="手机号：">
        <el-input style="width:120px;" v-model="yzmForm.sjhm" disabled></el-input>
        <el-button type="primary" :disabled="yzmDisabled" @click="sendCode" style="width:120px;">{{yzmBtnTxt}}</el-button>
      </el-form-item>
      <el-form-item label="验证码：">
        <el-input style="width:120px;" v-model.trim="yzmForm.code"></el-input>
      </el-form-item>
    </el-form>
    <div style="width:200px;margin:20px auto;" class="flex-div">
      <el-button type="primary" @click="submitYzm">提交</el-button>
      <el-button @click="closeYzmDialog">取消</el-button>
    </div>
    </el-dialog>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        checked:false,
        // sfs:[
        //     {name:"湖南",value:43},
        //     {name:"四川",value:51},
        //     {name:"广东",value:44}
        // ],
        sfs:[],
        citys:[],
        areas:[],
        schools:[],
        xbs:[
            {name:"男",value:"1"},
            {name:"女",value:"2"}
        ],
        sxkms:[
            {name:"物理",value:"2"},
            {name:"历史",value:"1"}
        ],
        zxkms:[
            {name:"政治",value:"1"},
            {name:"地理",value:"2"},
            {name:"化学",value:"3"},
            {name:"生物",value:"4"}
        ],
        // nds:[
        //   {name:"2023",value:"2023"},
        //   {name:"2024",value:"2024"}
        // ],
        nds:[],
        ruleForm:{
          sjhm:"",
          password:"",
          confirmPassword:"",
          province:"",
          shi:"",
          qu:"",
          school:"",
          xb:"",
          sxkm:"",
          zxkms:[],
          proPm:'',
          score:"",
          nd:"",
          cardNum:"",
          cardPwd:"",
          xm:""
        },
        rules: {
        password: [{ required: true, message: "请输入系统登录密码", trigger: "change" }],
        confirmPassword: [{ required: true, message: "请输入系统登录密码", trigger: "change" }],
        sjhm: [
          { required: true, message: "请输入您的手机号码", trigger: "change" },
          { pattern: /^1[345789]\d{9}$/, message: "请输入正确的手机号码" }
        ],
        province: [{ required: true, message: "请选择省份", trigger: "change" }],
        shi: [{ required: true, message: "请选择地区", trigger: "blur" }],
        school: [{ required: true, message: "请选择学校", trigger: "change" }],
        sxkm: [{ required: true, message: "请选择科目", trigger: "change" }],
        xm: [{ required: true, message: "请输入姓名", trigger: "change" }],
        xb: [{ required: true, message: "请选择性别", trigger: "change" }],
        zxkms: [{ required: true, message: "请选择再选科目", trigger: "change" }],
        score: [{ required: true, message: "请输入您的高考成绩", trigger: "change" }],
        proPm: [{ required: true, message: "请输入您的省排名", trigger: "change" }],
        nd: [{ required: true, message: "请选择您的高考年度", trigger: "change" }],
        cardNum: [
          { required: true, message: "请输入您的会员卡号", trigger: "change" },
          { min: 10, message: "卡号格式错误", trigger: "change" }
          ],
        cardPwd: [{required: true, message: "请输入您的卡号密码", trigger: "change"}]
      },
      yzmForm:{
        sjhm:'',
        code:""
      },
      codeId:'',
      dialogMsgVisible:false,
      dialogYzmVisible:false,
      yzmDisabled:false,
      yzmBtnTxt:"发送验证码"
    };
},
methods:{
  // 改变省份
  async changeProvince(sfId){
    const res=await this.$http.getHttp("/xzqh/shixian?dqbm="+sfId);
    if(res.data.code===200){
      const arr=res.data.obj;
      const citys=[];
      for(let i=0;i<arr.length;i++){
        if(arr[i].dqbm){
          citys.push({name:arr[i].dqmc,value:arr[i].dqbm});
        }
      }
      this.citys=citys;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  },
  // 改变城市
  async changeShi(cityId){
    const res=await this.$http.getHttp("/xzqh/shixian?dqbm="+cityId);
    if(res.data.code===200){
      const arr=res.data.obj;
      const areas=[];
      for(let i=0;i<arr.length;i++){
        if(arr[i].dqbm){
          areas.push({name:arr[i].dqmc,value:arr[i].dqbm});
        }
      }
      this.areas=areas;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  },
  // 改变地区
  async changeQu(dqId){
    const res=await this.$http.getHttp("/zxml/getShools?dqbm="+dqId);
    if(res.data.code===200){
      const arr=res.data.obj;
      const schools=[];
      for(let i=0;i<arr.length;i++){
        if(arr[i].szdq){
          schools.push({name:arr[i].zxmc,value:arr[i].zxxh});
        }
      }
      this.schools=schools;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  },
  // 获取年度和省份
  async getNdAndPro(){
    const res=await this.$http.getHttp("/users/getRegisterData");
    if(res.data.code===200){
      const nds=res.data.obj.ndList;
      const sfs=res.data.obj.sfList;
      const arr1=[];
      const arr2=[];
      for(let i=0;i<nds.length;i++){
        if(nds[i]){
          arr1.push({name:nds[i],value:nds[i]});
        }
      }
      for(let i=0;i<sfs.length;i++){
        if(sfs[i].sfxh){
          arr2.push({name:sfs[i].sfmc,value:sfs[i].sfxh});
        }
      }
      this.nds=arr1;
      this.sfs=arr2;
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  },
  // 注册表单提交
  saveRegist(form){
    this.$refs[form].validate(async validate=>{
      if(validate){
        const obj={};
        obj.yhid=null;
        obj.username=null;
        obj.password=this.$md5(this.ruleForm.password);
        const pwd1=this.ruleForm.password;
        const pwd2=this.ruleForm.confirmPassword;
        if(pwd1!==pwd2){
          this.$message({message:"两次输入的密码不一致",showClose:true,type:'warning'});
          return false;
        }
        obj.XM=null;
        obj.xm=this.ruleForm.xm;
        obj.xb=this.ruleForm.xb;
        obj.sjhm=this.ruleForm.sjhm;
        obj.DZYX=null;
        obj.zckh=this.ruleForm.cardNum;
        obj.KHZCSJ=null;
        obj.sssf=this.ruleForm.province;
        obj.kl=this.ruleForm.sxkm;
        const fs=this.ruleForm.score;
        if(fs<200||fs>720){
          this.$message({message:'请输入正确的高考分数',type:'warning',showClose:true});
          return;
        }
        obj.gkfs=this.ruleForm.score;
        const zckh=this.ruleForm.cardNum;
        if(zckh.indexOf('86')===0){
          obj.yhlb='2';
        }else if((zckh.indexOf('985211')===0)||(zckh.indexOf('43985')===0)){
          obj.yhlb='4';
        }
        obj.ZCJD=null;
        obj.GKFSXG=null;
        obj.YHZCSJ=null;
        obj.OPENID=null;
        obj.sxkm=this.ruleForm.sxkm;
        if(this.zxkms<2){
          this.$message({message:"请选择两个再选科目",showClose:true,type:'warning'});
          return;
        }
        obj.zxkma=this.ruleForm.zxkms[0];
        obj.zxkmb=this.ruleForm.zxkms[1];
        obj.spm=this.ruleForm.proPm;
        obj.SXKMCJ=null;
        obj.ZXKMACJ=null;
        obj.ZXKMBCJ=null;
        obj.YWCJ=null;
        obj.SXCJ=null;
        obj.WYCJ=null;
        obj.YFJF=null;
        obj.YFJF3=null;
        obj.YFJF5=null;
        obj.YFJF10=null;
        obj.YFJF20=null;
        obj.KSXM=null;
        obj.GJZXZG=null;
        obj.DFZXZG=null;
        const zxid=this.ruleForm.school;
        const schoolObj=this.schools.find(item=>item.value===zxid);
        if(schoolObj){
          obj.xxmc=schoolObj.name;
        }
        obj.school=zxid;
        obj.BZ=null;
        obj.password1=this.$md5(this.ruleForm.confirmPassword);
        obj.nd=this.ruleForm.nd;
        obj.khmm=this.ruleForm.cardPwd;
        if(!(this.checked)){
          this.$message({message:'请勾选好协议再注册',showClose:true,type:'warning'});
          return;
        }
        const res=await this.$http.postHttp("/users/register",obj);
        if(res.data.code===200){
          if(res.data.message){
            this.$message({message:res.data.message,type:'success',showClose:true});
          }else{
            this.$message({message:"注册成功",showClose:true,type:'success'});
          }
          this.$router.push("/home");
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
      }
    });
  },
  // 改变高考分数
  gkfsChange(fs){
    const reg=/^\d$/;
    if(!reg.test(fs)){
      this.ruleForm.score=fs.replaceAll(/\D/gi,'');
    }
  },
    // 改变省排名
    proPmChange(pm){
    const reg=/^\d$/;
    if(!reg.test(pm)){
      this.ruleForm.proPm=pm.replaceAll(/\D/gi,'');
    }
  },
  // 改变卡号
  cardChange(card){
    const reg=/^\d$/;
    if(!reg.test(card)){
      this.ruleForm.cardNum=card.replaceAll(/\D/gi,'');
    }
  },
  // 改变卡号密码
  cardPwdChange(cardPwd){
    const reg=/^\d$/;
    if(!reg.test(cardPwd)){
      this.ruleForm.cardPwd=cardPwd.replaceAll(/\D/gi,'');
    }
  },
  // 输入手机号码
  async changeSjhm(sjhm){
    const reg=/^1[3-9][0-9]{9}$/;
    if(sjhm.length<11||sjhm.length>11||!(reg.test(sjhm))){
      this.$message({message:"请输入正确的手机号",type:'warning',showClose:true});
      return false;
    }
    const res=await this.$http.getHttp("/users/getUserInfo?username="+sjhm);
      if(res.status===200){
            if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
            }else{
              const yhlb=res.data.yhlb;
              if(yhlb===1){
                this.dialogMsgVisible=true;
                this.yzmForm.sjhm=sjhm;
              }
            }
      }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
      }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
      }
  },
  // 关闭升级会员提示
  closeMsgDialog(){
    this.dialogMsgVisible=false;
  },
  // 关闭发送验证码弹窗
  closeYzmDialog(){
    this.dialogMsgVisible=false;
    this.dialogYzmVisible=true;
  },
  // 省内各级会员
  PromoteHy(){
    this.dialogYzmVisible=true;
  },
  // 发送验证码
  async sendCode(){
        this.yzmDisabled=true;
        let t=60;
        let timer=null;
        const sjhm=this.yzmForm.sjhm;
        if(sjhm===''){
            this.$message({message:"请输入手机号",type:'warning',showClose:true});
            return;
        }
        if(sjhm.length<11||sjhm.length>11){
            this.$message({message:"请输入正确的手机号",type:'warning',showClose:true});
            return;
        }
        const res=await this.$http.getHttp("/msg/sendMsg?sjhm="+sjhm);
        if(res.data.code===200){
            this.codeId=res.data.obj;
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"发送成功",type:"success",showClose:true});
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        timer=setInterval(() => {
            t--;
            this.yzmBtnTxt=t+"秒";
            if(t===0){
                clearInterval(timer);
                this.yzmBtnTxt="发送验证码";
                this.yzmDisabled=false;
            }
        }, 1000);
  },
  // 比对验证码
  async submitYzm(){
    const code=this.yzmForm.code;
    if(code.length<7||code.length>7){
      this.$message({message:'请输入正确的验证码',type:'warning',showClose:true});
      return false;
    }
    const res=await this.$http.getHttp("/msg/verification?codeid="+this.codeId+"&versioncation_code="+
    this.yzmForm.code+"&sjhm="+this.yzmForm.sjhm);
    if(res.data.code===200){
      this.$router.push("/promote?sjhm="+this.yzmForm.sjhm);
    }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
    }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
    }
  }
},
computed:{
  ...mapState(['userInfo'])
},
async mounted(){
  await this.getNdAndPro();
}
};
</script>
<style lang="less" scoped>
.register-container{
    width:900px;
    min-height:calc(100vh - 410px);
    min-height:-moz-calc(100vh - 410px);
    min-height:-webkit-calc(100vh - 410px);
    margin:30px auto;
    .card-top{
        width: 1200px;
        margin: 20px auto;
    }
    .member{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: nowrap;
        width: 100%;
    }
    .el-button{
        width: 85%;
        height: 35px;
        font-size: 16px;
        margin: 0 0 0 20px;
    }
}
.flex-div{
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
