<template>
    <div class="zs-container">
    <div class="card-top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="margin:20px auto;" id="load-data">
            <table class="qjschool">
                <tr>
                    <td v-for="(item,index) in schoolObj.list1" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.name,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list2" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.name,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list3" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.yxmc,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list4" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.yxmc,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list5" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.yxmc,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list6" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.yxmc,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list7" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.yxmc,item.gbdm)">{{item.name}}</a>
                    </td>
                </tr>
                <tr>
                    <td v-for="(item,index) in schoolObj.list8" :key="index+item.value">
                    <a href="javascript:void(0)" @click="toQjDetail(item.yxmc,item.gbdm)">{{item.name}}</a>
                    </td>
                    <td v-if="schoolObj.list8&&schoolObj.list8.length>0">
                        <a href="javascript:void(0)"></a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        schools:[
        ],
        schoolObj:{}
    };
},
methods:{
    // 获取强基计划
    async getQjPlan(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("load-data"),text:"正在加载中"});
        const obj={};
        obj.is=985;
        obj.jx=["0"];
        obj.nowPage=1;
        obj.yxmc="";
        const res=await this.$http.postHttp("/qjjh/getQjYx",obj);
        if(res.data.code===200){
            const arr=res.data.obj;
            const yxmcs=[];
            for(let i=0;i<arr.length;i++){
                if(arr[i].yxml){
                    yxmcs.push({yxmc:arr[i].yxml.yxmc,gbdm:arr[i].yxml.gbdm});
                }
            }
            const obj={};
            let count=0;
            for(let i=0;i<yxmcs.length;i++){
                if(i%5===0){
                    count++;
                    if(obj['list'+count]){
                        obj['list'+count].push({name:yxmcs[i].yxmc,value:i+1,gbdm:yxmcs[i].gbdm});
                    }else{
                        obj['list'+count]=[];
                        obj['list'+count].push({name:yxmcs[i].yxmc,value:i+1,gbdm:yxmcs[i].gbdm});
                    }
                }else{
                    if(obj['list'+count]){
                        obj['list'+count].push({name:yxmcs[i].yxmc,value:i+1,gbdm:yxmcs[i].gbdm});
                    }else{
                        obj['list'+count]=[];
                        obj['list'+count].push({name:yxmcs[i].yxmc,value:i+1,gbdm:yxmcs[i].gbdm});
                    }
                }
            }
            this.schoolObj=obj;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 跳转到强基计划院校的详情页
    toQjDetail(yxmc,gbdm){
        const url=this.$router.resolve({
            path:'/qjPlan/detail',
            query:{
                yxmc,
                gbdm
            }
        });
        window.open(url.href,"_blank");
    },
    // 获取虚拟数据
    getMockData(){
    for(let i=0;i<39;i++){
        this.schools.push({name:'北大'+(i+1)});
    }
    const obj={};
    let count=0;
    const schools=[...this.schools];
    for(let i=0;i<39;i++){
        if(i%5===0){
            count++;
            if(obj['list'+count]){
                obj['list'+count].push({name:schools[i].name,value:i+1});
            }else{
                obj['list'+count]=[];
                obj['list'+count].push({name:schools[i].name,value:i+1});
            }
        }else{
            if(obj['list'+count]){
                obj['list'+count].push({name:schools[i].name,value:i+1});
            }else{
                obj['list'+count]=[];
                obj['list'+count].push({name:schools[i].name,value:i+1});
            }
        }
    }
        this.schoolObj=obj;
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
    await this.getQjPlan();
    // this.getMockData();
}
};
</script>
<style lang="less" scoped>
.zs-container{
    .card-top{
        width:1100px;
        margin:20px auto;
    }
    .qjschool{
        tr{
            td{
                height: 50px;
                border: 1px solid #09F;
                width: 200px;
                text-align: center;
                a{
                    display: inline-block;
                    width: 112px;
                }
            }
        }
    }
}
</style>
