<template>
    <div>
        <div class="main_box px1005 box950" style="padding-left:30px;">
               <nav>
                   <a class="maincolor" href="/">首页</a> <span class="c-666 en">&gt;</span>专家服务
               </nav>
           </div>
        <div class="g-carousel" id="J-index-banner-slide" style="min-width: 1200px;">
            <div class="g-carousel-viewport">
                <ul class="g-carousel-inner">
                    <img src="../../assets/images/yiduiyi-banner.jpg" width="1920" height="280">
                </ul>
            </div>
        </div>
        <div class="" style="background:#fffdc5; padding-top:30px;">
            <div class="conent_ydy">
            <img src="../../assets/images/yiduiyi01.jpg" width="1198" height="392">
            <img src="../../assets/images/yiduiyi02.jpg" width="1198" height="275">
            <img src="../../assets/images/yiduiyi03.jpg" width="1198" height="366">
            <img src="../../assets/images/yiduiyi04.jpg" width="1198" height="469">
            <img src="../../assets/images/yiduiyi05.jpg" width="1198" height="1127">
            <img src="../../assets/images/yiduiyi06.jpg" width="1198" height="501">
            </div>
            <div class="conent_zj">
                    <ul>
                        <li v-for="(item,index) in expert_list" :key="index">
                        <div class="con1" style="height:260px;">
                            <div class="left_wen">
                            <h1>专家姓名：{{item.name}} &nbsp;&nbsp;指导领域：{{item.area_name}}</h1>
                            <div style="height:160px; overflow:hidden;">
                                <p style="height:160px;">简介：{{item.description}}</p>
                            </div>
                            <div class="bottom_wh"><a style="cursor: default;">服务类型：{{item.type_code==1?"权威专家服务":item.type_code==2?"一般专家服务":""}}</a><a style="cursor: default;">指导名额：{{item.server_num}}</a></div>
                            <div></div>
                            </div>
                            <div class="right_tu">
                                <!-- <img src="../../assets/attached/image/20170227/20170227153509_969.jpg" width="141" height="141"> -->
                                <img :src=item.pic width="180" height="220">
                            </div>
                            <!-- <div class="right_tu2">
                                <a class="btn btn-primary" target="_blank"
                                :href="'http://wpa.qq.com/msgrd?v=3&uin='+item.qq_num+'&site=qq&menu=yes&from=message&isappinstalled=0'">点击预约服务</a>
                            </div> -->
                        </div>
                        </li>
                    </ul>

            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        expert_list:[]
        // obj:{
        //     name:"蒋老师",
        //     area_name:"湖南",
        //     description:"重点大学某高校一线教师，从事招生工作十余年，对专业的解读和把握具有丰富经验。",
        //     type_code:1,
        //     server_num:"20",
        //     qq_num:"2675338431"
        // }
    };
},
methods:{
    zjServiceClick(paramObj){
    // 点击调用qq服务
                var qqUrl = 'http://wpa.qq.com/msgrd?v=3&uin='+paramObj+'&site=qq&menu=yes&from=message&isappinstalled=0';
                window.open(qqUrl,'');
    },
    // 获取专家的信息
    async getZjxx(){
        const res=await this.$http.getHttp("/zjxx/queryZjxx");
        if(res.data.code===200){
            const arr=res.data.obj;
            for(let i=0;i<arr.length;i++){
                if(arr[i].name){
                    const pic=arr[i].pic;
                    arr[i].pic='../../assets'+pic;
                }
            }
            this.expert_list=arr;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
        }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:"warning"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
async mounted(){
await this.getZjxx();
},
computed:{
    ...mapState(["userInfo"])
}
};
</script>
<style lang="less" scoped>
    .g-carousel{
        border: none;
        position: relative;
        width: 100%;
    }
    .g-carousel-viewport {
         position: relative;
         overflow: hidden;
         border: none;
         width: 100%;
     }
     ul.g-carousel-inner {
          list-style: none;
          position: relative;
          overflow: hidden;
      }
    img {
        margin: 0px;
        padding: 0px;
        list-style-type: none;
    }
    .conent_ydy {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        padding: 10px 0 0 0;
        background: #fffdc5;
    }
    .conent_zj {
        width: 1185px;
        height: auto;
        margin: 0 auto;
        padding: 10px 0 25px 25px;
        background: #fffdc5;
        overflow: hidden;
    }
    .con1 {
        width: 529px;
        height: 243px;
        float: left;
        margin-left: 25px;
        background: #FFF;
        border: 2px solid #a0a0a0;
        margin-top: 25px;
    }
    .con1 .left_wen {
        width: 328px;
        height: 220px;
        float: right;
        margin-top: 25px;
        margin-left: 10px;
    }
    .con1 .left_wen h1 {
        font-size: 16px;
        font-family: microsoft yahei;
        background: #F00;
        color: #fff;
        font-weight: normal;
        height: 25px;
        line-height: 25px;
        margin: 0px;
        text-indent: 15px;
        padding: 0px;
    }
    .con1 .left_wen p {
        color: #615f5f;
        margin-top: 10px;
    }
    .con1 .bottom_wh {
        width: 328px;
        height: 35px;
        line-height: 35px;
        margin-top: 10px;
    }
    .con1 .right_tu {
        width: 154px;
        height: 154px;
        float: left;
        margin-top: 25px;
        margin-left: 10px;
    }
    .con1 .right_tu2 {
        width: 154px;
        height: 35px;
        float: left;
        margin-top: 15px;
        margin-left: 10px;
    }
    .btn:first-child {
        *margin-left: 0;
        width: 141px;
    }
    button, html input[type="button"], input[type="reset"], input[type="submit"] {
        cursor: pointer;
        -webkit-appearance: button;
    }
    .btn-primary {
        color: #fff;
        background-color: #19ca9e;
        border-color: #19ca9e;
        width: 141px;
    }
    .btn {
        display: inline-block;
        text-align: center;
        font-weight: 600;
        white-space: nowrap;
        vertical-align: middle;
        -webkit-transition: background-color .1s linear;
        -moz-transition: background-color .1s linear;
        -o-transition: background-color .1s linear;
        transition: background-color .1s linear;
        border-radius: 0px !important;
        border: 0px currentColor;
        border-image: none;
        color: rgb(255, 255, 255) !important;
        box-shadow: none;
        text-shadow: none;
        background-image: none;
        background-repeat: no-repeat;
        user-select: none;
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 1.4285;
        padding: 6px 12px;
    }
    input{
    outline: none;
    font-family: "Microsoft YaHei", SimSun, Arial, sans-serif !important;
    margin-left: 0px;
    margin: 0;
    }
    .con1 .bottom_wh a {
        display: block;
        width: 150px;
        float: left;
        height: 35px;
        line-height: 35px;
        // background: url(../assets/images/listc.jpg) no-repeat 0px;
        font-family: microsoft yahei;
        font-size: 12px;
        color: #c91e18;
        text-indent: 15px;
    }
</style>
