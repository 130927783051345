<template>
    <div>
        <div class="syl-title">2022年双一流</div>
        <div class="syl-content" id="syl-data">
            <el-table border :data="sylList" height="560"
            :header-cell-style="{'background-color':'#B0E2FF','color': '#3B3B3B',
            'border':'1px solid #0099FF','text-align':'center'}"
            :cell-style="{'border':'1px solid #0099FF','text-align':'center'}">
                <el-table-column show-overflow-tooltip label="院校名称" width="200" prop="yxmc" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业名称" width="280" prop="zymc" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="一流学校" width="100" prop="ylxx" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="一流学科数量" width="120" prop="ylxksl" :resizable="false"></el-table-column>
            </el-table>
            <div class="syl-page">
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="sylParam.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="sylParam.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="sylTotal">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        sylParam:{
            pageNum:1,
            pageSize:10
        },
        sylList:[],
        sylTotal:0
    };
},
methods:{
    async getSyl(){
       await this.loadData();
    },
    async loadData(pageNum=1,pageSize=10){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("syl-data"),text:"正在加载中"});
        this.sylParam.pageNum=pageNum;
        this.sylParam.pageSize=pageSize;
        const res=await this.$http.getHttp("/lnsj/syl?pageNum="+pageNum+"&pageSize="+pageSize);
        if(res.data.code===200){
            this.sylList=res.data.obj.records;
            this.sylTotal=res.data.obj.total;
        }else if(res.data.code===500){
            if(res.data.message){
            this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    async handleSizeChange(pageSize){
        await this.loadData(1,pageSize);
    },
    async handleCurrentChange(pageNum){
        await this.loadData(pageNum,10);
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
    await this.getSyl();
}
};
</script>
<style lang="less" scoped>
.syl-title{
    width:1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px auto;
    font-size: 18px;
    font-weight: bold;
}
.syl-content{
    width:730px;
    margin:0 auto;
}
.syl-page{
    width:800px;
    margin:20px auto;
}
</style>
