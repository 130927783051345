<template>
    <div>
        <div class="kzx-title">历年批次控制线</div>
        <div class="kzx-content" id="kzx-data">
            <el-table border :data="kzxList" height="660" :span-method="objectSpanMethod"
            :header-cell-style="{'background-color':'#B0E2FF','color': '#3B3B3B',
            'border':'1px solid #0099FF','text-align':'center'}"
            :cell-style="{'border':'1px solid #0099FF','text-align':'center'}">
                <el-table-column show-overflow-tooltip
                label="年度"
                width="199" prop="nd" :resizable="false">
                </el-table-column>
                <el-table-column show-overflow-tooltip
                label="科目"
                width="199" prop="kl" :resizable="false">
                <template slot-scope="scope">
                    <span v-if="scope.row.kl===1">历史</span>
                    <span v-if="scope.row.kl===2">物理</span>
                </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip
                label="批次"
                width="199" prop="pc" :resizable="false">
                <template slot-scope="scope">
                    <span v-if="scope.row.pc===6">本科批</span>
                    <span v-if="scope.row.pc===7">专科批</span>
                </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip
                label="分数"
                width="199" prop="fs" :resizable="false">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        kzxList:[]
    };
},
methods:{
    async getKzx(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("kzx-data"),text:"正在加载中"});
        const res=await this.$http.getHttp("/lnsj/pckzx");
        if(res.data.code===200){
            this.kzxList=res.data.obj;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }){
        if(columnIndex===0||columnIndex===1){
            if(rowIndex%2===0){
                    return {
                    rowspan: 2,
                    colspan: 1
                };
            }else{
                return {
              rowspan: 0,
              colspan: 0
            };
            }
        }
    }
},
async mounted(){
    await this.getKzx();
},
computed:{
    ...mapState(["userInfo"])
}
};
</script>
<style lang="less" scoped>
.kzx-title{
    width:1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin:10px 0;
    font-weight: bold;
}
.kzx-content{
    width:820px;
    margin:0 auto;
}
</style>
