import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugin/element';
import http from './request/http';
import {Message,MessageBox,Loading} from 'element-ui';
import axios from 'axios';
import loginout from './checkLogin/auto_loginout';
import md5 from 'js-md5';
import {mount} from './common/loginout';

Vue.config.productionTip = false;
Vue.prototype.$http=http;
Vue.prototype.$message=Message;
Vue.prototype.$confirm=MessageBox.confirm;
Vue.prototype.$loading=Loading.service;
Vue.prototype.$axios=axios;
Vue.prototype.$md5=md5;
Vue.use(loginout);
Vue.use(mount);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

// 全局配置
// router.beforeEach((to, from, next) => {
//   if (to.meta.requiresAuth) {
//     if (store.state.userInfo.yhid) {
//       // 需要登录后查看的页面判断user是否登录
//       next();
//     } else {
//       Vue.prototype.$message({
//         type: "warning",
//         message: "您还没有登录，请先登录系统",
//         duration: "1000"
//       });
//       next("/");
//     }
//   } else {
//     next();
//   }
// });
// router.afterEach((to, from) => {
//   /* 路由发生变化修改页面title */
//   if (to.meta.title) {
//     document.title = to.meta.title;
//   }
// });
