<template>
    <div>
        <div>
            <div class="plan-container">
                <div class="plan-title">提前批招生计划</div>
                <div class="plan-condition">
                    <el-form :inline="true" style="width:900px;">
                    <el-form-item label="选择批次">
                        <el-select style="width:120px;" v-model="planParam.pc"
                        @change="getLxCondition">
                        <el-option v-if="pcList.length===0" disabled value="-1">
                            <i class="el-icon-loading" style="font-size: 16px;"></i>
                        </el-option>
                        <!-- <el-option label="全部" value=""></el-option> -->
                        <template v-if="pcList.length>0">
                        <el-option v-for="(item,index) in pcList"
                        :key="index" :label="item.name" :value="item.value">
                        </el-option>
                        </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="招生类型">
                        <el-select v-model="planParam.schoolType" style="width:210px;"
                        @change="getLxCondition">
                            <el-option label="采用平行志愿的院校(专业)" value="1"></el-option>
                            <el-option label="采用单个志愿的院校(专业)" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择类型">
                        <el-select style="width:250px;" v-model="planParam.lxid"
                        clearable @change="changeLx">
                        <el-option v-if="lxList.length===0" disabled value="-1">
                                <i class="el-icon-loading" style="font-size: 16px;"></i>
                        </el-option>
                            <el-option v-for="(item,index) in lxList"
                            :key="index" :label="item" :value="index"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择年度">
                        <el-select style="width:120px;" v-model="planParam.nd"
                        @change="getLxCondition">
                            <el-option v-if="ndList.length===0" disabled value="-1">
                                <i class="el-icon-loading" style="font-size: 16px;"></i>
                            </el-option>
                            <template v-if="ndList.length>0">
                            <el-option label="全部" value=""></el-option>
                                <el-option v-for="(item,index) in ndList"
                                :key="index" :label="item.name" :value="item.value">
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专业名称">
                        <el-input style="width:210px;" v-model="planParam.zymc"></el-input>
                    </el-form-item>
                    <el-form-item label="院校名称">
                        <el-input style="width:172px;" v-model="planParam.yxmc"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getPlan">搜索</el-button>
                    </el-form-item>
                </el-form>
                </div>
                <div>
                    <!-- <div>计划共招人</div> -->
                    <div id="planDiv">
                        <el-table :data="planList.list" border max-height="560"
                        :header-cell-style="{'background-color':'#B0E2FF','color': '#3B3B3B','border':'1px solid #0099FF','text-align':'center'}" :cell-style="{'border':'1px solid #0099FF','text-align':'center'}">
                            <el-table-column show-overflow-tooltip label="年度" prop="nd" width="60" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="院校名称" prop="yxmc" width="180" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="批次" prop="pc" width="100" :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.pc===0">本科提前批</span>
                                    <span v-if="scope.row.pc===6">本科提前批</span>
                                    <span v-if="scope.row.pc===7">专科提前批</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="专业组" prop="zyz" width="70" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="科目要求" prop="zxkmyq" width="190" :resizable="false">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.kl==='1'"><span style="color: #ff6900;">首选</span> 历史</span>
                                    <span v-if="scope.row.kl==='2'"><span style="color: #ff6900;">首选</span> 物理</span>
                                    &nbsp;<span>{{scope.row.zxkmyq}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="开设专业" prop="zymc" width="300" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="计划数" prop="jhs" width="70" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="代号" prop="zydh" width="60" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="收费" prop="sfbz" width="70" :resizable="false"></el-table-column>
                            <el-table-column show-overflow-tooltip label="专业备注" prop="zybz" width="100" :resizable="false"></el-table-column>
                        </el-table>
                        <el-pagination v-if="false"></el-pagination>
                    </div>
                    <div v-if="planFlag" class="plan-page-div">
                        <el-pagination
                        @size-change="handlePlanSizeChange"
                        @current-change="handlePlanCurrentChange"
                        :current-page="planList.currnet"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="planList.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="planList.total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        planList:{
            list:[],
            currnet:1,
            pageSize:10
        },
        pcList:[
        ],
        planParam:{
            schoolType:'1',
            nd:'2023',
            pc:"6",
            yxmc:"",
            zymc:"",
            kl:"",
            lxid:'',
            lxmc:'',
            pageNum:1,
            pageSize:10
        },
        ndList:[],
        lxList:[],
        planFlag:true
    };
},
methods:{
    async getPlan(){
       await this.loadData();
    },
    async loadData(pageNum=1,pageSize=10){
        this.planFlag=false;
        const loading=this.$loading({fullscreen:true,target:document.getElementById("planDiv"),text:"正在加载中"});
        this.planParam.pageNum=pageNum;
        this.planParam.pageSize=pageSize;
        this.planParam.zylx=this.planParam.schoolType;
        // const schoolType=this.planParam.schoolType;
        // if(schoolType==='1'){
        //     this.planParam.pxzy=1;
        //     this.planParam.dgzy=0;
        // }else if(schoolType==='2'){
        //     this.planParam.pxzy=0;
        //     this.planParam.dgzy=1;
        // }else{
        //     this.planParam.pxzy=0;
        //     this.planParam.dgzy=0;
        // }
        const res=await this.$http.postHttp("/lnsj/zsjh2",this.planParam);
        if(res.data.code===200){
            this.planList.list=[...res.data.obj.records];
            this.planList.currnet=pageNum;
            this.planList.total=res.data.obj.total;
            this.planList.pageSize=pageSize;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
        this.planFlag=true;
    },
   async handlePlanSizeChange(pageSize){
        await this.loadData(1,pageSize);
    },
   async handlePlanCurrentChange(pageNum){
        await this.loadData(pageNum,10);
    },
    async getPcCondition(){
        const res=await this.$http.getHttp("/lnsj/zsjhpcs2");
        if(res.data.code===200){
            const pcs=[...res.data.obj];
            for(let i=0;i<pcs.length;i++){
                if(pcs[i].pcmc){
                    this.pcList[i]={};
                    this.pcList[i].name=pcs[i].pcmc;
                    this.pcList[i].value=pcs[i].pc+"";
                }
            };
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 获取类型条件
    async getLxCondition(){
        const sjtype=this.planParam.schoolType;
        const pc=this.planParam.pc;
        const nd=this.planParam.nd;
        const res=await this.$http.getHttp('/lnsj/getTqpQuery?pc='+pc+'&sjtype='+sjtype+'&nd='+nd);
        if(res.data.code===200){
            this.lxList=[...res.data.obj];
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 改变类型
    changeLx(index){
        if(index>=0){
            const lxmc=this.lxList[index];
            this.planParam.lxmc=lxmc;
            this.planParam.tqptype=lxmc;
        }
    },
    async getNdCondition(){
        const res=await this.$http.getHttp("/lnsj/zsjhNds2");
        if(res.data.code===200){
            const nds=res.data.obj;
            const ndArr=[];
            for(let i=0;i<nds.length;i++){
                if(nds[i]){
                    ndArr.push({name:nds[i]+'',value:nds[i]+''});
                }
            }
            this.ndList=ndArr;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
    this.planParam.kl=this.userInfo.sxkm;
    const nd=new Date().getFullYear();
    this.planParam.nd=nd;
    await this.getPcCondition();
    await this.getNdCondition();
    await this.getLxCondition();
    // await this.getPlan();
}
};
</script>
<style lang="less" scoped>
.plan-container{
    width:1100px;
    margin:0 auto;
    .plan-title{
        width:1100px;
        display: flex;
        justify-content: center;
        align-content: center;
        margin:10px 0;
        font-size: 18px;
        font-weight: bold;
    }
    .plan-page-div{
        width:800px;
        margin:20px auto;
    }
}
</style>
