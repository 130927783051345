<template>
    <div>
        <Detail type="2"/>
    </div>
</template>
<script>
import Detail from '../components/detail.vue';
export default {
data(){},
components:{Detail}
};
</script>
<style lang="less">

</style>
