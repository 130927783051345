<template>
    <div>
        <div v-html="htmlData">
            {{htmlData}}
        </div>
    </div>
</template>

<script>
    export default {
        name:'AiPay',
        data() {
            return {
                htmlData: ''
            };
        },
        mounted () {
            this.htmlData = this.$route.query.htmlData;
            this.$nextTick(()=> {
                document.forms[0].submit();
                document.forms[1].submit();
            });
        }
    };
</script>
