export default{
saveToken(state,{token}){
    state.token=token;
},
saveUserInfo(state,{userInfo}){
    state.userInfo=userInfo;
},
saveUserName(state,{userName}){
    state.userName=userName;
},
saveUserPwd(state,{userPwd}){
    state.userPwd=userPwd;
},
getSchoolFind(state,{schoolFindData}){
    state.schoolFindData=schoolFindData;
},
getRecommend(state,{recommendData}){
    state.recommendData=recommendData;
},
initUserName(state){
    state.userName='';
},
initUserPwd(state){
    state.userPwd='';
},
initSchoolFind(state){
    state.schoolFindData={};
},
initRecommend(state){
    state.recommendData={};
},
initUserInfo(state){
    state.userInfo={};
},
initToken(state){
    state.token="";
},
saveCollectSchool(state,{collectSchool,pc}){
    state.collectInfo.collectSchool=collectSchool;
    state.collectInfo.pc=pc;
},
initCollectSchool(state){
    state.collectInfo={collectSchool:[],pc:null};
},
saveLrzyfaList(state,{lrzyfaList,pc}){
    state.lrzyfaInfo.lrzyfaList=lrzyfaList;
    state.lrzyfaInfo.pc=pc;
},
initLrzyfaList(state){
    state.lrzyfaInfo={lrzyfaList:[],pc:null};
},
saveCompareSchool(state,{compareSchool}){
    state.compareSchool=compareSchool;
},
initCompareSchool(state){
    state.compareSchool=[];
}
};
