<template>
    <div class="qj-container">
        <div class="card-top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        <div class="detail-top">
            <div class="top-logo">
                <img v-if="detail.gbdm"
                :src="'http://img.gkzyydt.com/upload/img/logo/'+detail.gbdm+'.jpg'" :alt="detail.yxmc" width="100%">
            </div>
            <div class="top-mid">
                <div class="mid-school-name">
                    <h3>
                        {{ detail.yxmc }}
                    <template v-if="detail.sf985===1">
                        <img src="../../assets/images/ico2.png" title="985">
                    </template>
                    <template v-if="detail.sf211===1">
                        <img src="../../assets/images/ico1.png" title="211">
                    </template>
                    <template v-if="detail.sfsyl===1">
                        <img src="../../assets/images/ico8.png" title="双一流">
                    </template>
                    <template v-if="detail.sf101===1">
                        <img src="../../assets/images/ico9.png" title="101">
                    </template>
                    <template v-if="detail.yfyjsy===1">
                        <img src="../../assets/images/ico3.png" title="研究生院">
                    </template>
                    </h3>
                    <el-button  type="primary" size="mini" @click="closeWin" plain round>关闭</el-button>
                </div>
                <div class="mid-school-nature">
                    <div class="nature-item">
                        <span>{{detail.yymc}}</span>
                    </div>
                    <!-- <div class="nature-item">
                        <span class="school-link">网址：{{detail.wz}}</span>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="detail-main" id="xq-load">
            <el-tabs v-model="activeName" tab-position="left" type="card">
            <el-tab-pane label="院校简介" name="1">
                <div style="margin-left:15px;">
                <div class="schoolIntro_con">
                    <div class="intro"  style="text-indent: 28px;" id="jjDiv">{{ detail.jj }}</div>
                    <div class="read-more" style="text-align: right;">
                    <a href="javascript:void(0)" @click="watchAndStop">{{detailTxt}}</a>
                    <div class="baseInfo clearfix">
                        <ul class="baseInfo_left">
                            <li class="biItem">
                                <span class="t">创建时间</span>
                                <div class="c" id="cjsj">{{detail.jxsj}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">隶属于</span>
                                <div class="c" id="ls">{{detail.ls}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">学生人数</span>
                                <div class="c" id="xsrs">{{detail.xsrs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">院士人数</span>
                                <div class="c" id="ysrs">{{detail.ysrs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">男生比例</span>
                                <div class="c" id="nsbl">{{detail.nsbl}}</div>
                            </li>
                        </ul>
                        <ul class="baseInfo_right">
                            <li class="biItem">
                                <span class="t">一流学科</span>
                                <div class="c" id="zdxk">
                                    {{detail.ylxkSize}}
                                </div>
                            </li>
                            <li class="biItem">
                                <span class="t">学校类型</span>
                                <div class="c" id="xxlx">
                                    {{detail.xxlbmc}}
                                </div>
                            </li>
                            <li class="biItem">
                                <span class="t">博士点个数</span>
                                <div class="c" id="bsdgs">{{detail.bsdgs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">硕士点个数</span>
                                <div class="c" id="ssdgs">{{detail.ssdgs}}</div>
                            </li>
                            <li class="biItem">
                                <span class="t">女生比例</span>
                                <div class="c" id="nvsbl">{{detail.nvsbl}}</div>
                            </li>
                        </ul>
                    </div>
                    <div class="catTitle">
                        <h2>学费信息</h2>
                    </div>
                    <div class="txt" style="text-indent:28px;" id="xfxx" v-html="detail.xfxx">
                    </div>
                    <div class="catTitle">
                        <h2>就业情况</h2>
                    </div>
                    <div class="txt" style="text-indent:28px;" id="jyqk" v-html="detail.jyqk">
                    </div>
                    <div class="txt">
                        以上数据由金榜志愿通<a href></a>汇总整理。
                    </div>
                    </div>
                </div>
                <div class="schoolIntro_side">
                    <div class="schoolIntro_sideBd">
                        <div class="pics">
                            <div class="schoolPic_slide">
                                <ul>
                                    <li>
                                        <img id="yxtb_xgt" :src="'http://img.gkzyydt.com/upload/img/logo/'+detail.gbdm+'.jpg'" :alt="detail.yxmc"/>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="infos">
                            <ul>
                                <li>
                                    <label>所在城市：</label>
                                    <font id="ss">{{detail.szdqmc}}</font>
                                </li>
                                <li>
                                    <label>学校地址：</label>
                                    <font id="xxdz">{{detail.dz}}</font>
                                </li>
                                <li>
                                    <label>招生电话：</label>
                                    <font id="zsdh">{{detail.dh}}</font>
                                </li>
                                <li>
                                    <label>电子邮箱：</label>
                                    <font id="dzyx">{{detail.dzyx}}</font>
                                </li>
                            </ul>
                            <div class="website">
                                <a :href="detail.wz" target="_blank" class="a1">进入官网</a>
                                <a :href="detail.zswz" target="_blank" class="a2">进入招生网站</a>
                            </div>
                        </div>
                        <!-- <div class="hotschool">
                            <div class="hd"></div>
                            <div class="hd"></div>
                        </div> -->
                    </div>
                </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="一流学科" name="2">
                <div class="schoolIntro_con22">
                    <div class="majorCon2">
                        <h3>一流学科</h3>
                    </div>
                    <div class="majorCon1" id="gjppzyDiv">
                        <ul class="list clearfix" id="gjppzy">
                            <li v-for="(item,index) in detail.ylxks" :key="index">
                                ● <a href="javascript:void(0)" :zy_zyid="item.zydm" @click="toZyDetail(item.zydm)">{{item.zymc}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="专业计划" name="3">
                <div class="zy-plan">
                    <div class="majorCon2" style="margin-bottom: 30px;">
                        <h3>学校专业计划表</h3>
                    </div>
                    <div class="txt">
                        <el-table :data="jhList" border
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778'
                ,'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                            <el-table-column show-overflow-tooltip label="专业名称" prop="zymc" width="670" :resizable="false">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.zymc?'-':scope.row.zymc}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="总计划数" prop="jhs" width="120" :resizable="false">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.jhs?'-':scope.row.jhs}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="年度" prop="nd" width="120" :resizable="false">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.nd?'-':scope.row.nd}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <p>
                            <span id="remarks">备注：</span>
                            以上数据由工作人员采集某些专业可能出现些许误差，具体详情请参考院校自主招生简章，以院校招生简章为准!!
                        </p>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="招生简章" name="4">
                <div class="schoolIntro_con23">
                    <div class="majorCon2">
                        <h3>招生简章</h3>
                    </div>
                    <div class="txt" id="zsjz" v-html="detail.zsjz"></div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="录取数据" name="5">
                <div class="lq-data">
                    <div class="majorCon2" style="margin-bottom: 30px;">
                        <h3>录取数据</h3>
                    </div>
                    <div class="txt">
                        <el-table border :data="lqData"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778','color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                            <el-table-column show-overflow-tooltip label="报名时间" prop="bmsj" width="125">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.bmsj?'-':scope.row.bmsj}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="考试时间" prop="kssj" width="125">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.kssj?'-':scope.row.kssj}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="计划数" prop="jhs" width="50">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.jhs?'-':scope.row.jhs}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="限报院校" prop="xbyx" width="50">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.xbyx?'-':scope.row.xbyx}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="限报专业" prop="xbzy" width="50">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.xbzy?'-':scope.row.xbzy}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="入围比例" prop="rwbl" width="50">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.rwbl?'-':scope.row.rwbl}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="复审方式" prop="fsfs" width="220">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.fsfs?'-':scope.row.fsfs}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="录取方法" prop="lqff" width="160">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.lqff?'-':scope.row.lqff}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column show-overflow-tooltip label="年度" prop="nd" width="80">
                                <template slot-scope="scope">
                                    <span>{{!scope.row.nd?'-':scope.row.nd}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <p>
                            <span id="remarks">备注：</span>
                            以上数据由工作人员采集某些专业可能出现些许误差，具体详情请参考院校自主招生简章，以院校招生简章为准!!
                        </p>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        activeName:'1',
        maxwidth:300,
        detail:{
            logo:"",
            dz:"",
            yxmc:"",
            wz:"",
            jxsj:"",
            ls:"",
            xsrs:"",
            bsdgs:"",
            ssdgs:"",
            ysrs:"",
            nsbl:"",
            nvsbl:"",
            jj:"",
            lsjj:"",
            xfxx:"",
            jyqk:"",
            szdqmc:"",
            dh:"",
            dzyx:"",
            ylxks:[],
            zswz:"",
            zszc:"",
            zsjz:'',
            yymc:"",
            sf985:0,
            sf211:0,
            yfyjsy:0,
            sfsyl:0,
            gbdm:"",
            xxlbmc:"",
            xxlb:"",
            ylxkSize:"",
            yxxh:"",
            pc:""
        },
        jhList:[],
        lqData:[],
        detailTxt:"查看详情 >>"
    };
},
methods:{
        // 关闭当前页面
    closeWin() {
        const userAgent = navigator.userAgent;
        if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
            window.open('','_self').close();
        }else {
            window.opener = null;
            window.open("about:blank", "_self");
            window.close();
        }
    },
    // 查看简介和收起简介
    watchAndStop(){
        const maxwidth=this.maxwidth;
        const txt=this.detail.jj;
        if(txt.length-7<=maxwidth){
            this.detail.jj=this.detail.lsjj;
            this.detailTxt="<< 收起详情";
        }else{
            this.detail.jj=txt.substring(0,maxwidth)+".......";
            this.detailTxt="查看详情 >>";
        }
    },
    // 获取页面数据
    async getYxxq(){
        const loading=this.$loading({fullscreen:true,target:document.getElementById("xq-load"),text:"正在加载中"});
        const {yxmc,gbdm}=this.$route.query;
        const res=await this.$http.getHttp("/qjjh/getYxxqByGb?gbdm="+gbdm);
        if(res.data.code===200){
            this.detail.logo=res.data.obj.logo;
            this.detail.dz=res.data.obj.dz;
            this.detail.wz=res.data.obj.xxwz;
            this.detail.yxmc=res.data.obj.yxmc;
            this.detail.jj=res.data.obj.jj;
            this.detail.jxsj=res.data.obj.jxsj;
            this.detail.ls=res.data.obj.ls;
            this.detail.xsrs=res.data.obj.xsrs;
            this.detail.bsdgs=res.data.obj.bsdgs;
            this.detail.ysrs=res.data.obj.ysrs;
            this.detail.ssdgs=res.data.obj.ssdgs;
            this.detail.nsbl=res.data.obj.nsbfb;
            this.detail.nvsbl=res.data.obj.nvsbfb;
            this.detail.xfxx=res.data.obj.xfxx;
            this.detail.jyqk=res.data.obj.jyqk;
            this.detail.ylxks=res.data.obj.zdzylist;
            this.detail.szdqmc=res.data.obj.szdqmc;
            this.detail.dh=res.data.obj.dh;
            this.detail.dzyx=res.data.obj.dzyj;
            this.detail.zswz=res.data.obj.zswz;
            this.detail.zszc=res.data.obj.zszc;
            this.detail.zsjz=res.data.obj.zsjz;
            this.detail.yymc=res.data.obj.yxywmc;
            this.detail.sf985=res.data.obj.sf985;
            this.detail.sf211=res.data.obj.sf211;
            this.detail.sfsyl=res.data.obj.sfsyl;
            this.detail.yfyjsy=res.data.obj.yfyjsy;
            this.detail.sf101=res.data.obj.sf101;
            this.detail.gbdm=res.data.obj.gbdm;
            this.detail.xxlb=res.data.obj.xxlb;
            this.detail.xxlbmc=res.data.obj.xxlbmc;
            this.jhList=res.data.obj.zyjhlist;
            this.lqData=res.data.obj.lqinfo;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginout",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        const res2=await this.$http.getHttp("/qjjh/queryByYxmc?yxmc="+yxmc);
        if(res2.data.code===200){
        }else if(res2.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res2.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginout",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res2.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        loading.close();
    },
    // 获取招生章程
    async getZszc(){
        const res=await this.$http.getHttp("/yxcx/getZszc?gbdm="+this.detail.gbdm);
        if(res.data.code===200){
            if(res.data.obj){
                this.detail.zszc=res.data.obj;
            }else {
                this.detail.zszc="暂无招生章程!";
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:"error"});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginout",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 跳转到专业详情
    toZyDetail(zydm){
        const url=this.$router.resolve({
            path:"/majorDetail",
            query:{zydm}
        });
        window.open(url.href,"_blank");
    },
    // 去除HTML标签，提取其中的文字
    highlight(item){
        return item.replace(/<[^>]+>/g, '');
    }
},
computed:{
    ...mapState(['userInfo'])
},
async mounted(){
        await this.getYxxq();
        // await this.getZszc();
        this.detail.jj=this.highlight(this.detail.jj);
        this.detail.lsjj=this.highlight(this.detail.jj);
        const maxwidth=this.maxwidth;
        let txt=this.detail.jj;
        if(txt.length>maxwidth){
            txt=txt.substring(0,maxwidth)+".......";
        }
        this.detail.jj=txt;
}
};
</script>
<style lang="less" scoped>
.qj-container{
    min-width:1100px;
    margin:0 auto;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .detail-top{
        display: flex;
        align-items: center;
        width: 1100px;
        margin: 0 auto;
        padding: 20px 0;
        background-color: #fff;
        .top-logo{
            width: 80px;
            height: 80px;
            box-sizing: border-box;
            margin: 0 30px;
        }
        .top-mid{
            width: 80%;
            box-sizing: border-box;
            color: #666;
        }
        .mid-school-name{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            font-size: 18px;
            color: #333;
        }
        .mid-school-nature{
            display: flex;
            font-size: 14px;
            .nature-item{
                flex: 1;
                .school-link{
                    cursor: pointer;
                }
            }
        }
    }
    .detail-main{
        box-sizing: border-box;
        width: 1100px;
        margin: 0 auto;
        padding: 20px 30px;
        background-color: #fff;
    }
}
.zy-plan{
    // margin: 0 20px 50px 190px;
    margin:0 auto;
    min-height: 500px;
    margin-left:15px;
    // padding-top: 130px;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
    h3{
        font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
    }
    }
    .txt{
        text-align: justify;
        word-wrap: break-word;
        font-size: 14px;
        p{
            margin-top:20px;
            padding: 0.6em 0;
            #remarks {
            font-size: 16px;
            font-weight: 900;
            color: #EFAF49;
            }
        }
    }
}
.lq-data{
        // margin: 0 20px 50px 190px;
        margin:0 auto;
    min-height: 500px;
    margin-left:15px;
    // padding-top: 130px;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
    h3{
        font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
    }
    }
    .txt{
        text-align: justify;
        word-wrap: break-word;
        font-size: 14px;
        p{
            margin-top:20px;
            padding: 0.6em 0;
            #remarks {
            font-size: 16px;
            font-weight: 900;
            color: #EFAF49;
            }
        }
    }
}
.schoolIntro_con2 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    margin: 0 20px 50px 190px;
    min-height: 500px;
    // padding-top: 130px;
}
.schoolIntro_con {
    // margin: 30px 0 30px 180px;
    min-height: 500px;
    width: 510px;
    float: left;
    display: inline;
    .intro{
        text-align: justify;
        word-wrap: break-word;
        margin-bottom: 15px;
    }
    .txt{
        text-align: justify;
        word-wrap: break-word;
        margin-bottom: 35px;
    }
    a{
        color: #2186de;
    }
}
.baseInfo{
    border-top: 2px solid #efaf49;
    // background: url(school.css);
    line-height: 33px;
    margin-bottom: 20px;
    height: 170px;
    li{
        height: 33px;
        vertical-align: top;
    }
    .t{
        float: left;
      }
      .c{
        float: right;
        text-align: right;
        color: #999;
      }
}
.clearfix {
    *zoom: 1;
}
.baseInfo_left {
    float: left;
    width: 47%;
}
.baseInfo_right {
    float: right;
    width: 47%;
}
.catTitle {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    margin-bottom: 10px;
    h2{
        font-size: 26px;
        line-height: 35px;
        font-weight: normal;
        color: #efaf49;
        text-align: left;
    }
}
.schoolIntro_side {
    float: right;
    width: 260px;
    margin-top: 30px;
    .infos{
        border: 1px solid #E5E5E5;
        border-top: 0;
        ul{
            padding: 5px 12px 12px 12px;
        }
        li{
            padding: 10px 0 10px 75px;
            border-bottom: 1px dashed #DDD;
            line-height: 18px;
            word-wrap: break-word;
            overflow: hidden;
        }
        label{
            float: left;
            margin-left: -75px;
        }
        .website{
            height: 30px;
            margin-left: 15px;
            margin-bottom: 10px;
            a{
                line-height: 28px;
                float: left;
                border: 1px solid #D6D6D6;
                border-radius: 5px;
                text-align: center;
            }
            .a1{
                width: 90px;
                margin-right: 13px;
            }
            .a2{
                width: 120px;
            }
        }
    }
}
.schoolIntro_sideBd {
    width: 260px;
}
.schoolPic_slide {
    width: 260px;
    height: 240px;
    overflow: hidden;
    position: relative;
    ul{
        width: 260px;
        height: 240px;
        overflow: hidden;
    }
    li{
        float: left;
    }
    img{
        width: 260px;
        height: 240px;
    }
}
.schoolIntro_con22 {
    width: 790px;
    padding-top: 0;
    margin-left:15px;
    overflow: hidden;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
}
h3{
    font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
}
.majorCon1 {
    margin-bottom: 25px;
    .list{
        margin-bottom: 15px;
    // background: url(school.css);
    li{
        // background: url(school.css) no-repeat 28px 20px;
        text-indent: 18px;
        height: 45px;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        float: left;
        width: 235px;
        padding-left: 15px;
        list-style: square;
    }
    }
}
}
.schoolIntro_con23 {
    width: 790px;
    padding-top: 0;
    overflow: hidden;
    margin-left:15px;
    .majorCon2 {
    height: 35px;
    border-left: 5px solid #efaf49;
    padding-left: 20px;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid #efaf49;
    clear: left;
    h3{
        font-size: 26px;
    line-height: 35px;
    font-weight: normal;
    color: #efaf49;
    }
}
.txt{
    margin-bottom: 20px;
}
}
</style>
