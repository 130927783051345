<template>
    <div class="container">
        <div class="card-top">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        <div class="plan-div">
            <div style="width:400px;">方案名：<span>{{planObj.plan}}</span></div>
            <div style="width:300px;">时间：<span>{{planObj.time}}</span></div>
            <div>
                <el-button  type="primary" size="mini" @click="closeWin" plain round>关闭</el-button>
            </div>
        </div>
        <div class="content-div" id="load-data">
            <el-tabs type="border-card"  v-model="activeName">
            <el-tab-pane label="表1"  name="first">
            <el-table :data="tableData" border max-height="400"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column show-overflow-tooltip label="序号" type="index" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校代码" prop="sb" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="院校名称" prop="yxmc" width="300" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业组代码" prop="zyz" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业代码(1)" prop="zy1" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业代码(2)" prop="zy2" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业代码(3)" prop="zy3" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业代码(4)" prop="zy4" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业代码(5)" prop="zy5" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="专业代码(6)" prop="zy6" :resizable="false"></el-table-column>
                <el-table-column show-overflow-tooltip label="服从专业调剂" prop="sffc" width="" :resizable="false">
                    <template slot-scope="scope">
                        <img v-if="scope.row.sffc==='1'"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </template>
                </el-table-column>
            </el-table>
            </el-tab-pane>
            <el-tab-pane label="表2" name="second">
                <!-- <el-table :data="tableData2" border max-height="400"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column label="序号" width="50" prop="xh" :resizable="false"></el-table-column>
                <el-table-column
                prop="yxdm"
                label="院校代码"
                width="100" :resizable="false">
                </el-table-column>
                <el-table-column
                prop="yxmc"
                label="院校名称"
                width="200" :resizable="false">
                </el-table-column>
                <el-table-column
                prop="zyz"
                label="专业组"
                width="100" :resizable="false">
                </el-table-column>
                <el-table-column
                label="专业"
                width="200" :resizable="false">
                <template slot-scope="scope">
                    {{scope.row.th1}}
                </template>
                </el-table-column>
                 <el-table-column
                label="专业(1)"
                width="600">
                <el-table-column label="专业代码" width="100" prop="zydh1"></el-table-column>
                <el-table-column label="专业名称" width="200" prop="zymc1"></el-table-column>
                <el-table-column label="最低等效分" width="100" prop="zdtwf1"></el-table-column>
                <el-table-column label="投档线" width="100">
                    <el-table-column label="上年" prop="sntdx1"></el-table-column>
                    <el-table-column label="当年" prop="dntdx1"></el-table-column>
                </el-table-column>
                <el-table-column label="计划数" width="100">
                    <el-table-column label="上年" prop="snjhs1"></el-table-column>
                    <el-table-column label="当年" prop="dnjhs1"></el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column
                label="专业(2)"
                width="600">
                <el-table-column label="专业代码" width="100" prop="zydh2"></el-table-column>
                <el-table-column label="专业名称" width="200" prop="zymc2"></el-table-column>
                <el-table-column label="最低等效分" width="100" prop="zdtwf2"></el-table-column>
                <el-table-column label="投档线" width="100">
                    <el-table-column label="上年" prop="sntdx2"></el-table-column>
                    <el-table-column label="当年" prop="dntdx2"></el-table-column>
                </el-table-column>
                <el-table-column label="计划数" width="100">
                    <el-table-column label="上年" prop="snjhs2"></el-table-column>
                    <el-table-column label="当年" prop="dnjhs2"></el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column
                label="专业(3)"
                width="600">
                <el-table-column label="专业代码" width="100" prop="zydh3"></el-table-column>
                <el-table-column label="专业名称" width="200" prop="zymc3"></el-table-column>
                <el-table-column label="最低等效分" width="100" prop="zdtwf3"></el-table-column>
                <el-table-column label="投档线" width="100">
                    <el-table-column label="上年" prop="sntdx3"></el-table-column>
                    <el-table-column label="当年" prop="dntdx3"></el-table-column>
                </el-table-column>
                <el-table-column label="计划数" width="100">
                    <el-table-column label="上年" prop="snjhs3"></el-table-column>
                    <el-table-column label="当年" prop="dnjhs3"></el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column
                label="专业(4)"
                width="600">
                <el-table-column label="专业代码" width="100" prop="zydh4"></el-table-column>
                <el-table-column label="专业名称" width="200" prop="zymc4"></el-table-column>
                <el-table-column label="最低等效分" width="100" prop="zdtwf4"></el-table-column>
                <el-table-column label="投档线" width="100">
                    <el-table-column label="上年" prop="sntdx4"></el-table-column>
                    <el-table-column label="当年" prop="dntdx4"></el-table-column>
                </el-table-column>
                <el-table-column label="计划数" width="100">
                    <el-table-column label="上年" prop="snjhs4"></el-table-column>
                    <el-table-column label="当年" prop="dnjhs4"></el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column
                label="专业(5)"
                width="600">
                <el-table-column label="专业代码" width="100" prop="zydh5"></el-table-column>
                <el-table-column label="专业名称" width="200" prop="zymc5"></el-table-column>
                <el-table-column label="最低等效分" width="100" prop="zdtwf5"></el-table-column>
                <el-table-column label="投档线" width="100">
                    <el-table-column label="上年" prop="sntdx5"></el-table-column>
                    <el-table-column label="当年" prop="dntdx5"></el-table-column>
                </el-table-column>
                <el-table-column label="计划数" width="100">
                    <el-table-column label="上年" prop="snjhs5"></el-table-column>
                    <el-table-column label="当年" prop="dnjhs5"></el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column
                label="专业(6)"
                width="600">
                <el-table-column label="专业代码" width="100" prop="zydh6"></el-table-column>
                <el-table-column label="专业名称" width="200" prop="zymc6"></el-table-column>
                <el-table-column label="最低等效分" width="100" prop="zdtwf6"></el-table-column>
                <el-table-column label="投档线" width="100">
                    <el-table-column label="上年" prop="sntdx6"></el-table-column>
                    <el-table-column label="当年" prop="dntdx6"></el-table-column>
                </el-table-column>
                <el-table-column label="计划数" width="100">
                    <el-table-column label="上年" prop="snjhs6"></el-table-column>
                    <el-table-column label="当年" prop="dnjhs6"></el-table-column>
                </el-table-column>
                </el-table-column>
                <el-table-column
                prop="sffc"
                label="服从专业调剂"
                width="120" :resizable="false">
                <template slot-scope="scope">
                        <img v-if="scope.row.sffc===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                </template>
                </el-table-column>
                </el-table> -->

                <div>
                    <table class="compareTable">
                        <tr>
                        <th style="width:50px;">序号</th>
                        <th style="width:50px;">院校代码</th>
                        <th style="width:50px;">院校名称</th>
                        <th style="width:50px;">专业组</th>
                        <th>专业</th>
                        <th style="width:50px;">服从专业调剂</th>
                        <th style="width:4.5px;"></th>
                        </tr>
                        <tr>
                            <td colspan="7" style="padding:0px;">
                            <div style="max-height: 500px;overflow-y: auto;">
                                <table class="compareTable" style="margin:0px;">
                    <tr v-for="(item,index) in tableData2" :key="index">
                        <td style="width:50px;">{{index+1}}</td>
                        <td style="width:50px;">{{item.yxdm}}</td>
                        <td style="width:50px;">{{item.yxmc}}</td>
                        <td style="width:50px;">{{item.zyz}}</td>
                        <td style="padding:0px;">
                            <table>
                                <tr>
                                    <th rowspan="7">专业(1)</th>
                                    <th>代码</th>
                                    <td>{{ item.zydh1 }}</td>
                                </tr>
                                <tr>
                                    <th>名称</th>
                                    <td>{{ item.zymc1 }}</td>
                                </tr>
                                <tr>
                                    <th>最低等效分</th>
                                    <td>{{ item.zdtwf1 }}</td>
                                </tr>
                                <tr>
                                    <th>上年投档线</th>
                                    <td>{{item.sntdx1}}</td>
                                </tr>
                                <tr>
                                    <th>当年投档线</th>
                                    <td>{{item.dntdx1}}</td>
                                </tr>
                                <tr>
                                    <th>计划数</th>
                                    <td>{{ item.snjhs1 }}</td>
                                </tr>
                                <tr>
                                    <th>当年计划数</th>
                                    <td>{{ item.dnjhs1 }}</td>
                                </tr>

                                <tr>
                                    <th rowspan="7">专业(2)</th>
                                    <th>代码</th>
                                    <td>{{ item.zydh2 }}</td>
                                </tr>
                                <tr>
                                    <th>名称</th>
                                    <td>{{ item.zymc2 }}</td>
                                </tr>
                                <tr>
                                    <th>最低等效分</th>
                                    <td>{{ item.zdtwf2 }}</td>
                                </tr>
                                <tr>
                                    <th>上年投档线</th>
                                    <td>{{item.sntdx2}}</td>
                                </tr>
                                <tr>
                                    <th>当年投档线</th>
                                    <td>{{item.dntdx2}}</td>
                                </tr>
                                <tr>
                                    <th>上年计划数</th>
                                    <td>{{ item.snjhs2 }}</td>
                                </tr>
                                <tr>
                                    <th>当年计划数</th>
                                    <td>{{ item.dnjhs2 }}</td>
                                </tr>

                                <tr>
                                    <th rowspan="7">专业(3)</th>
                                    <th>代码</th>
                                    <td>{{ item.zydh3 }}</td>
                                </tr>
                                <tr>
                                    <th>名称</th>
                                    <td>{{ item.zymc3 }}</td>
                                </tr>
                                <tr>
                                    <th>最低等效分</th>
                                    <td>{{ item.zdtwf3 }}</td>
                                </tr>
                                <tr>
                                    <th>上年投档线</th>
                                    <td>{{item.sntdx3}}</td>
                                </tr>
                                <tr>
                                    <th>当年投档线</th>
                                    <td>{{item.dntdx3}}</td>
                                </tr>
                                <tr>
                                    <th>上年计划数</th>
                                    <td>{{ item.snjhs3 }}</td>
                                </tr>
                                <tr>
                                    <th>当年计划数</th>
                                    <td>{{ item.dnjhs3 }}</td>
                                </tr>

                                <tr>
                                    <th rowspan="7">专业(4)</th>
                                    <th>代码</th>
                                    <td>{{ item.zydh4 }}</td>
                                </tr>
                                <tr>
                                    <th>名称</th>
                                    <td>{{ item.zymc4 }}</td>
                                </tr>
                                <tr>
                                    <th>最低等效分</th>
                                    <td>{{ item.zdtwf4 }}</td>
                                </tr>
                                <tr>
                                    <th>上年投档线</th>
                                    <td>{{item.sntdx4}}</td>
                                </tr>
                                <tr>
                                    <th>当年投档线</th>
                                    <td>{{item.dntdx4}}</td>
                                </tr>
                                <tr>
                                    <th>上年计划数</th>
                                    <td>{{ item.snjhs4 }}</td>
                                </tr>
                                <tr>
                                    <th>当年计划数</th>
                                    <td>{{ item.dnjhs4 }}</td>
                                </tr>

                                <tr>
                                    <th rowspan="7">专业(5)</th>
                                    <th>代码</th>
                                    <td>{{ item.zydh5 }}</td>
                                </tr>
                                <tr>
                                    <th>名称</th>
                                    <td>{{ item.zymc5 }}</td>
                                </tr>
                                <tr>
                                    <th>最低等效分</th>
                                    <td>{{ item.zdtwf5 }}</td>
                                </tr>
                                <tr>
                                    <th>上年投档线</th>
                                    <td>{{item.sntdx5}}</td>
                                </tr>
                                <tr>
                                    <th>当年投档线</th>
                                    <td>{{item.dntdx5}}</td>
                                </tr>
                                <tr>
                                    <th>上年计划数</th>
                                    <td>{{ item.snjhs5 }}</td>
                                </tr>
                                <tr>
                                    <th>当年计划数</th>
                                    <td>{{ item.dnjhs5 }}</td>
                                </tr>

                                <tr>
                                    <th rowspan="7">专业(6)</th>
                                    <th>代码</th>
                                    <td>{{ item.zydh6 }}</td>
                                </tr>
                                <tr>
                                    <th>名称</th>
                                    <td>{{ item.zymc6 }}</td>
                                </tr>
                                <tr>
                                    <th>最低等效分</th>
                                    <td>{{ item.zdtwf6 }}</td>
                                </tr>
                                <tr>
                                    <th>上年投档线</th>
                                    <td>{{item.sntdx6}}</td>
                                </tr>
                                <tr>
                                    <th>当年投档线</th>
                                    <td>{{item.dntdx6}}</td>
                                </tr>
                                <tr>
                                    <th>上年计划数</th>
                                    <td>{{ item.snjhs6 }}</td>
                                </tr>
                                <tr>
                                    <th>当年计划数</th>
                                    <td>{{ item.dnjhs6 }}</td>
                                </tr>
                            </table>
                        </td>
                        <td style="width:50px">
                            <img v-if="item.sffc===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                        </td>
                    </tr>
                            </table>
                            </div>
                            </td>

                        </tr>
                    </table>

                </div>
                <!-- <div style="max-height: 400px; overflow-y: auto;">
                <el-collapse v-model="activeYxName" accordion>
                <el-collapse-item  v-for="(item,index) in tableData2" :key="index"
                :title="item.yxmc+''+item.zyz" :name="index+1">
                    <div>院校名称：{{item.yxmc}}</div>
                    <div>院校代码：{{item.yxdm}}</div>
                    <div>专业组：{{item.zyz}}</div>
                    <div>专业代码(1)：{{item.zydh1}}</div>
                    <div>专业名称(1)：{{ item.zymc1 }}</div>
                    <div>最低等效分(1)：{{ item.zdtwf1 }}</div>
                    <div>上年投档线(1)：{{ item.sntdx1 }}</div>
                    <div>当年投档线(1)：{{ item.dntdx1 }}</div>
                    <div>上年计划数(1)：{{ item.snjhs1 }}</div>
                    <div>当年计划数(1)：{{ item.dnjhs1 }}</div>
                    <div>专业代码(2)：{{item.zydh2}}</div>
                    <div>专业名称(2)：{{ item.zymc2 }}</div>
                    <div>最低等效分(2)：{{ item.zdtwf2 }}</div>
                    <div>上年投档线(2)：{{ item.sntdx2 }}</div>
                    <div>当年投档线(2)：{{ item.dntdx2 }}</div>
                    <div>上年计划数(2)：{{ item.snjhs2 }}</div>
                    <div>当年计划数(2)：{{ item.dnjhs2 }}</div>
                    <div>专业代码(3)：{{item.zydh3}}</div>
                    <div>专业名称(3)：{{ item.zymc3 }}</div>
                    <div>最低等效分(3)：{{ item.zdtwf3 }}</div>
                    <div>上年投档线(3)：{{ item.sntdx3 }}</div>
                    <div>当年投档线(3)：{{ item.dntdx3 }}</div>
                    <div>上年计划数(3)：{{ item.snjhs3 }}</div>
                    <div>当年计划数(3)：{{ item.dnjhs3 }}</div>
                    <div>专业代码(4)：{{item.zydh4}}</div>
                    <div>专业名称(4)：{{ item.zymc4 }}</div>
                    <div>最低等效分(4)：{{ item.zdtwf4 }}</div>
                    <div>上年投档线(4)：{{ item.sntdx4 }}</div>
                    <div>当年投档线(4)：{{ item.dntdx4 }}</div>
                    <div>上年计划数(4)：{{ item.snjhs4 }}</div>
                    <div>当年计划数(4)：{{ item.dnjhs4 }}</div>
                    <div>专业代码(5)：{{item.zydh5}}</div>
                    <div>专业名称(5)：{{ item.zymc5 }}</div>
                    <div>最低等效分(5)：{{ item.zdtwf5 }}</div>
                    <div>上年投档线(5)：{{ item.sntdx5 }}</div>
                    <div>当年投档线(5)：{{ item.dntdx5 }}</div>
                    <div>上年计划数(5)：{{ item.snjhs5 }}</div>
                    <div>当年计划数(5)：{{ item.dnjhs5 }}</div>
                    <div>专业代码(6)：{{item.zydh6}}</div>
                    <div>专业名称(6)：{{ item.zymc6 }}</div>
                    <div>最低等效分(6)：{{ item.zdtwf6 }}</div>
                    <div>上年投档线(6)：{{ item.sntdx6 }}</div>
                    <div>当年投档线(6)：{{ item.dntdx6 }}</div>
                    <div>上年计划数(6)：{{ item.snjhs6 }}</div>
                    <div>当年计划数(6)：{{ item.dnjhs6 }}</div>
                    <div>专业服从：
                        <img v-if="item.sffc===1"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </div>
                </el-collapse-item>
                </el-collapse>
                </div> -->
            </el-tab-pane>
            </el-tabs>
            <!-- <el-table :data="tableData" border max-height="400"
                :header-cell-style="{'background-color':'#5287c6','border': '1px solid #1f4778',
                'color': '#FFF','text-align':'center'}"
                :cell-style="{'border': '1px solid #1f4778','text-align':'center'}">
                <el-table-column label="序号" type="index" :resizable="false"></el-table-column>
                <el-table-column label="院校代码" prop="sb" :resizable="false"></el-table-column>
                <el-table-column label="院校名称" prop="yxmc" width="300" :resizable="false"></el-table-column>
                <el-table-column label="专业组代码" prop="zyz" :resizable="false"></el-table-column>
                <el-table-column label="专业代码(1)" prop="zy1" :resizable="false"></el-table-column>
                <el-table-column label="专业代码(2)" prop="zy2" :resizable="false"></el-table-column>
                <el-table-column label="专业代码(3)" prop="zy3" :resizable="false"></el-table-column>
                <el-table-column label="专业代码(4)" prop="zy4" :resizable="false"></el-table-column>
                <el-table-column label="专业代码(5)" prop="zy5" :resizable="false"></el-table-column>
                <el-table-column label="专业代码(6)" prop="zy6" :resizable="false"></el-table-column>
                <el-table-column label="服从专业调剂" prop="sffc" width="" :resizable="false">
                    <template slot-scope="scope">
                        <img v-if="scope.row.sffc==='1'"
                        src="../../../assets/images/gou.png" width="20" height="20"/>
                    </template>
                </el-table-column>
            </el-table>-->
            <div><span>评估结果：</span></div>
        <div style="width:1100px;"><span v-html="pgjg"></span></div>
        <div>*****************************************************************************************************************************************************************************</div>
        <div><span>评估建议：</span></div>
        <div style="width:1100px;"><span v-html="pgjy"></span></div>
        </div>
        <div class="btn-div">
            <el-button type="primary" @click="downLoadResult">下载</el-button>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        tableData:[],
        tableData2:[],
        activeName:'first',
        activeYxName:'',
        result:"",
        pgjg:"",
        pgjy:"",
        planObj:{
            plan:"",
            time:""
        },
        pgObj:{},
        pgObj2:{},
        oldResult:'',
        filename:'评估报告'
    };
},
methods:{
    // 获取评估报告数据
    async getReult(id){
        // const loading=this.$loading({fullscreen:true,target:document.getElementById("load-data"),text:"正在加载中"});
        const res=await this.$http.getHttp("/zypg/getPgResult?id="+id);
        if(res.data.code===200){
            // const param=res.data.obj.param;
            const result=res.data.obj.result;
            const pgjg=res.data.obj.pgjg;
            const pgjy=res.data.obj.pgjy;
            if(result){
                if(result.indexOf("/n")>=0){
                    const arr=result.split("/n");
                    this.result=arr.join("</br>");
                    this.oldResult=result;
                }else if(result.indexOf("\n")>=0){
                    const arr=result.split("\n");
                    this.result=arr.join("</br>");
                    this.oldResult=result;
                }else if(result.indexOf('\\n')>=0){
                    const arr=result.split("\\n");
                    this.result=arr.join("</br>");
                    this.oldResult=result;
                }
            }
            if(pgjg){
                if(pgjg.indexOf("/n")>=0){
                    const arr=pgjg.split("/n");
                    this.pgjg=arr.join("</br>");
                }else if(pgjg.indexOf("\n")>=0){
                    const arr=pgjg.split("\n");
                    this.pgjg=arr.join("</br>");
                }else if(pgjg.indexOf('\\n')>=0){
                    const arr=pgjg.split("\\n");
                    this.pgjg=arr.join("</br>");
                }
            }
            if(pgjy){
                if(pgjy.indexOf("/n")>=0){
                    const arr=pgjy.split("/n");
                    this.pgjy=arr.join("</br>");
                }else if(pgjy.indexOf("\n")>=0){
                    const arr=pgjy.split("\n");
                    this.pgjy=arr.join("</br>");
                }else if(pgjy.indexOf('\\n')>=0){
                    const arr=pgjy.split("\\n");
                    this.pgjy=arr.join("</br>");
                }
            }
            // if(param){
            //     const arr=param.split(",");
            //     const tableData=[];
            //     for(let i=0;i<arr.length;i++){
            //         if(arr[i]){
            //             const arr2=arr[i].split("-");
            //                 const obj={};
            //                 obj.sb=arr2[0];
            //                 obj.yxmc=arr2[1];
            //                 obj.yxxh=arr2[2];
            //                 obj.pc=arr2[3];
            //                 obj.zyzTotal=arr2[4];
            //                 if(arr2[4]){
            //                     obj.zyz=arr2[4].split("|")[0];
            //                 }
            //                 obj.sffc=arr2[5];
            //                 obj.zyTotal=arr2[6];
            //                 if(arr2[6]){
            //                     let zys=arr2[6].split("_");
            //                     zys=zys.filter(item=>item!=='');
            //                     if(zys[0]){
            //                         obj.zy1=zys[0].split("|")[0];
            //                     }
            //                     if(zys[1]){
            //                         obj.zy2=zys[1].split("|")[0];
            //                     }
            //                     if(zys[2]){
            //                         obj.zy3=zys[2].split("|")[0];
            //                     }
            //                     if(zys[3]){
            //                         obj.zy4=zys[3].split("|")[0];
            //                     }
            //                     if(zys[4]){
            //                         obj.zy5=zys[4].split("|")[0];
            //                     }
            //                     if(zys[5]){
            //                         obj.zy6=zys[5].split("|")[0];
            //                     }
            //                 }
            //                 obj.status=arr2[7];
            //                 tableData.push(obj);
            //         }
            //     }
            //     this.tableData=tableData;
            // }
            const list=res.data.obj.list;
            if(list&&list.length>0){
                const arr=[];
                for(let i=0;i<list.length;i++){
                    if(list[i].zyz){
                        arr.push({
                            sb:list[i].sb,
                            yxxh:list[i].yxxh,
                            yxmc:list[i].yxmc,
                            zyz:list[i].zyz,
                            sffc:list[i].sffc+'',
                            zy1:list[i].zydh1,
                            zy2:list[i].zydh2,
                            zy3:list[i].zydh3,
                            zy4:list[i].zydh4,
                            zy5:list[i].zydh5,
                            zy6:list[i].zydh6
                        });
                    }
                }
                this.tableData=arr;
            }
            this.planObj.plan=res.data.obj.plan;
            this.planObj.time=res.data.obj.updatetime;
            if(res.data.obj){
                this.pgObj={...res.data.obj};
            }
        }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        // loading.close();
    },
    async getResult2(id){
        const res=await this.$http.getHttp('/zypg/getPgResult2?id='+id);
        if(res.data.code===200){
            const list=res.data.obj.list;
            if(list&&list.length>0){
                const data=[...res.data.obj.list];
                const arr=[];
                for(let i=0;i<data.length;i++){
                    const obj={};
                    obj.th1='专业名称';
                    obj.th2='专业代码';
                    obj.id=data[i].id;
                    obj.mnzybid=data[i].mnzybid;
                    obj.yxdm=data[i].sb;
                    obj.yxmc=data[i].yxmc;
                    obj.yxxh=data[i].yxxh;
                    obj.xh=data[i].xh;
                    obj.zyz=data[i].zyz;
                    for(let j=1;j<7;j++){
                        if(data[i]['zydh'+j]){
                            obj['zydh'+j]=data[i]['zydh'+j];
                        }else{
                            obj['zydh'+j]=null;
                        }
                    }
                    for(let j=1;j<7;j++){
                        if(data[i]['zymc'+j]){
                            obj['zymc'+j]=data[i]['zymc'+j];
                        }else{
                            obj['zymc'+j]=null;
                        }
                    }
                    for(let j=1;j<7;j++){
                        if(data[i]['zdtwf'+j]){
                            obj['zdtwf'+j]=data[i]['zdtwf'+j];
                        }else{
                            obj['zdtwf'+j]=null;
                        }
                    }
                    for(let j=1;j<7;j++){
                        if(data[i]['sntdx'+j]){
                            obj['sntdx'+j]=data[i]['sntdx'+j];
                        }else{
                            obj['sntdx'+j]=null;
                        }
                    }
                    for(let j=1;j<7;j++){
                        if(data[i]['snjhs'+j]){
                            obj['snjhs'+j]=data[i]['snjhs'+j];
                        }else{
                            obj['snjhs'+j]=null;
                        }
                    }
                    for(let j=1;j<7;j++){
                        if(data[i]['dntdx'+j]){
                            obj['dntdx'+j]=data[i]['dntdx'+j];
                        }else{
                            obj['dntdx'+j]=null;
                        }
                    }
                    for(let j=1;j<7;j++){
                        if(data[i]['dnjhs'+j]){
                            obj['dnjhs'+j]=data[i]['dnjhs'+j];
                        }else{
                            obj['dnjhs'+j]=null;
                        }
                    }
                    obj.sffc=data[i].sffc;
                    arr.push(obj);
                }
                this.tableData2=[...arr];
                if(res.data.obj){
                    this.pgObj2={...res.data.obj};
                }
            }
        }else if(res.data.code===500){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'error'});
                }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
    },
    // 关闭当前页面
    closeWin() {
                const userAgent = navigator.userAgent;
                if (userAgent.indexOf("Firefox") !== -1 || userAgent.indexOf("Chrome") !== -1) {
                    window.open('','_self').close();
                }else {
                    window.opener = null;
                    window.open("about:blank", "_self");
                    window.close();
                }
    },
    // 下载评估报告
    async downLoadResult(){
        const id=this.$route.query.id;
        const active=this.activeName;
        if(active==='first'){
            const obj0={};
            // obj0.plan=this.planObj.plan;
            obj0.id=id;
            obj0.date=this.planObj.time;
            obj0.pgjg=this.pgObj.pgjg;
            obj0.pgjy=this.pgObj.pgjy;
            obj0.sjhm=this.userInfo.sjhm;
            obj0.pgsj=this.planObj.time;
            obj0.pgDate=this.planObj.time;
            const list=[];
            const arr=[...this.tableData];
            for(let i=0;i<arr.length;i++){
                if(arr[i].yxmc){
                    const sb=arr[i].sb;
                    const sffc=arr[i].sffc==='1'?'服从':'不服从';
                    const xh=(i+1);
                    const yxmc=arr[i].yxmc;
                    const yxxh=arr[i].yxxh;
                    const zyz=arr[i].zyz;
                    const zy1=!arr[i].zy1?'':arr[i].zy1;
                    const zy2=!arr[i].zy2?'':arr[i].zy2;
                    const zy3=!arr[i].zy3?'':arr[i].zy3;
                    const zy4=!arr[i].zy4?'':arr[i].zy4;
                    const zy5=!arr[i].zy5?'':arr[i].zy5;
                    const zy6=!arr[i].zy6?'':arr[i].zy6;
                    list.push({
                        sb,sffc,xh,yxmc,yxxh,zyz,zy1,zy2,zy3,zy4,zy5,zy6
                    });
                }
            }
            obj0.list=list;
            const res=await this.$http.postHttp("/down/downZypg",obj0,{responseType:'blob'});
            if(res.status===200){
                if(res.data.code===0){
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                }else if(res.data.code===500){
                            if(res.data.message){
                                this.$message({message:res.data.message,type:'error'});
                            }
                }else{
                            const blob=new Blob([res.data],{type:'application/vnd.ms-excel'});
                        if (window.navigator.msSaveOrOpenBlob) { // 兼容IE10
                        navigator.msSaveBlob(blob, this.filename);
                        }else{
                            const href=URL.createObjectURL(blob);
                            const a=document.createElement("a");
                            a.style.display="none";
                            a.href=href;
                            a.download=this.filename;
                            a.click();
                            URL.revokeObjectURL(a.href);
                        }
                }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }else{
            const obj0={};
            // obj0.plan=this.planObj.plan;
            obj0.id=id;
            obj0.date=this.pgObj2.UPDATETIME;
            obj0.pgjg=this.pgObj2.pgjg;
            obj0.pgjy=this.pgObj2.pgjy;
            obj0.sjhm=this.userInfo.sjhm;
            obj0.pgsj=this.pgObj2.UPDATETIME;
            obj0.pgDate=this.pgObj2.UPDATETIME;
            obj0.gkfs=this.pgObj2.gkfs;
            obj0.plan=this.pgObj2.plan;
            obj0.sxkm=this.pgObj2.sxkm;
            obj0.zxkma=this.pgObj2.zxkma;
            obj0.zxkmb=this.pgObj2.zxkmb;
            const tableData=[...this.tableData2];
            const list=[];
            for(let i=0;i<tableData.length;i++){
                const obj={};
                obj.id=tableData[i].id;
                obj.mnzybid=tableData[i].mnzybid;
                obj.sb=tableData[i].yxdm;
                obj.yxmc=tableData[i].yxmc;
                obj.zyz=tableData[i].zyz;
                obj.yxxh=tableData[i].yxxh;
                obj.xh=tableData[i].xh;
                for(let j=1;j<7;j++){
                    if(tableData[i]['zydh'+j]){
                        obj['zydh'+j]=tableData[i]['zydh'+j];
                    }else{
                        obj['zydh'+j]=null;
                    }
                }
                for(let j=1;j<7;j++){
                    if(tableData[i]['zymc'+j]){
                        obj['zymc'+j]=tableData[i]['zymc'+j];
                    }else{
                        obj['zymc'+j]=null;
                    }
                }
                for(let j=1;j<7;j++){
                    if(tableData[i]['zdtwf'+j]){
                        obj['zdtwf'+j]=tableData[i]['zdtwf'+j];
                    }else{
                        obj['zdtwf'+j]=null;
                    }
                }
                for(let j=1;j<7;j++){
                    if(tableData[i]['sntdx'+j]){
                        obj['sntdx'+j]=tableData[i]['sntdx'+j];
                    }else{
                        obj['sntdx'+j]=null;
                    }
                }
                for(let j=1;j<7;j++){
                    if(tableData[i]['dntdx'+j]){
                        obj['dntdx'+j]=tableData[i]['dntdx'+j];
                    }else{
                        obj['dntdx'+j]=null;
                    }
                }
                for(let j=1;j<7;j++){
                    if(tableData[i]['snjhs'+j]){
                        obj['snjhs'+j]=tableData[i]['snjhs'+j];
                    }else{
                        obj['snjhs'+j]=null;
                    }
                }
                for(let j=1;j<7;j++){
                    if(tableData[i]['dnjhs'+j]){
                        obj['dnjhs'+j]=tableData[i]['dnjhs'+j];
                    }else{
                        obj['dnjhs'+j]=null;
                    }
                }
                obj.sffc=tableData[i].sffc;
                obj.sffcText=tableData[i].sffc===1?'服从':'不服从';
                list.push(obj);
            }
            obj0.list=list;
            this.$message({message:'表格内容比较多，打印时要调整到一张纸上'});
            const res=await this.$http.postHttp('/down/downZypg2',obj0,{responseType:'blob'});
            if(res.status===200){
                if(res.data.code===0){
                        this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                        await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                        this.$router.replace({
                            path: '/home',
                            query: {redirect: this.$router.currentRoute.fullPath}
                        });
                }else if(res.data.code===500){
                            if(res.data.message){
                                this.$message({message:res.data.message,type:'error'});
                            }
                }else{
                            const blob=new Blob([res.data],{type:'application/vnd.ms-excel'});
                        if (window.navigator.msSaveOrOpenBlob) { // 兼容IE10
                        navigator.msSaveBlob(blob, this.filename);
                        }else{
                            const href=URL.createObjectURL(blob);
                            const a=document.createElement("a");
                            a.style.display="none";
                            a.href=href;
                            a.download=this.filename;
                            a.click();
                            URL.revokeObjectURL(a.href);
                        }
                }
            }else if(res.status===401){
                this.$message({message: '登录超时，请重新登录',type:"error"});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
        }
    }
},
computed:{
    ...mapState(["userInfo"])
},
async mounted(){
    const id=this.$route.query.id;
    const loading=this.$loading({fullscreen:true,target:document.getElementById('load-data'),text:'正在加载中'});
    await this.getReult(id);
    await this.getResult2(id);
    loading.close();
}
};
</script>
<style lang="less" scoped>
.container{
    min-width: 1100px;
    margin:0 auto;
    .card-top{
        width: 1100px;
        margin: 20px auto;
    }
    .plan-div{
        width:1100px;
        margin:20px auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .content-div{
        width:1150px;
        margin: 10px auto;
        // margin-left:200px;
    }
    .btn-div{
        width:1100px;
        margin:10px auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
.compareTable {
    border-top: 3px solid #2186de;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 15px;
    th{
        width: 150px;
        border: 1px solid #09F;
        text-align: center;
        padding: 10px 0;
        font-weight: normal;
    }
    td{
        width: 150px;
        border: 1px solid #09F;
        text-align: center;
        padding: 10px 0;
    }
}
</style>
