<template>
    <div class="update-pwd">
        <div class="title"><i class="el-icon-edit-outline"></i> 修改密码</div>
        <div class="pwd-content">
            <div>
                <div class="input-div">
                    <div style="margin-right:23px;">手机号码</div>
                    <el-input style="width:220px;" v-model="user.sjhm" disabled></el-input>
                </div>
                <div class="input-div">
                    <div style="margin-right:23px;">当前密码</div>
                    <el-input show-password auto-complete="password" style="width:220px;" v-model.trim="user.oldmm"></el-input>
                </div>
                <div class="input-div">
                    <div style="margin-right:37px;">新密码</div>
                    <el-input show-password auto-complete="password" style="width:220px;" v-model.trim="user.xmm"></el-input>
                </div>
                <div class="input-div">
                    <div style="margin-right:10px;">确认新密码</div>
                    <el-input show-password auto-complete="password" style="width:220px;" v-model.trim="user.confirmXmm"></el-input>
                </div>
                <div class="input-btn">
                    <el-button type="primary" @click="submitPwd" :disabled="btndisabled">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return {
        user:{
            sjhm:"",
            oldmm:"",
            xmm:"",
            confirmXmm:""
        },
        btndisabled:false
    };
},
methods:{
    // 提交修改密码
    async submitPwd(){
        this.btndisabled=true;
        const xmm1=this.user.xmm;
        const xmm2=this.user.confirmXmm;
        if(xmm1!==xmm2){
            this.$message({message:"二次密码不相同!",showClose:true});
            this.btndisabled=false;
            return false;
        }
        const param={};
        param.oldPwd=this.$md5(this.user.oldmm);
        param.newPwd=this.$md5(this.user.xmm);
        param.newPwd1=this.$md5(this.user.confirmXmm);
        param.sjhm=this.user.sjhm;
        param.yhid=this.userInfo.yhid;
        const res=await this.$http.postHttp("/users/modifyPwd",param);
        if(res.data.code===200){
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"修改成功",type:"success",showClose:true});
            }
            this.btndisabled=false;
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        this.btndisabled=false;
    }
},
mounted(){
    this.user.sjhm=this.userInfo.sjhm;
},
computed:{
    ...mapState(["userInfo"])
}
};
</script>
<style lang="less" scoped>
.update-pwd{
    margin-left:30px;
    width:900px;
    min-height:calc(100vh - 380px);
    min-height:-moz-calc(100vh - 380px);
    min-height:-webkit-calc(100vh - 380px);
    background-color:#ffffff;
    .title{
        width: 100%;
        height: 35px;
        box-sizing: border-box;
        padding-left: 10px;
        line-height: 35px;
        font-size: 15px;
        font-weight: 700;
        color: #2f4056;
        background-color: #d3d3d3;
    }
    .pwd-content{
        width:400px;
        margin: 0 auto;
        .input-div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin:20px 0;
        }
        .input-btn{
            width:400px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin:20px 0;
        }
    }
}
</style>
