<template>
    <div class="remark-container">
        <dl>
            <dt class="remark-title"><i class="el-icon-s-promotion"></i>数据说明</dt>
            <dd class="remark-text">1、金榜志愿通团队整理的数据由于各省加分政策不同、各高等院校的升学路径不同、普通批次志愿和征集志愿的不同等原因会导致与金榜志愿通系统显示的数据存在不完全一致的情况。数据来源途径不一样，显示的数据不一样，这是正常现象。</dd>
            <dd class="remark-text">2、省批次控制线、一分段数据，在当年高考成绩公布后，会及时更新;</dd>
            <dd class="remark-text">3、投档线展示近三年的数据，在当年录取结束后，会及时更新;</dd>
            <dd class="remark-text">4、招生计划数据在各个省公布最新的招生计划数据时，会及时更新;</dd>
            <dd class="remark-text">5、双一流、专业选课等数据来自官方权威发布。</dd>
        </dl>
    </div>
</template>
<script>
export default {

};
</script>
<style lang="less" scoped>
.remark-container{
    width: 1100px;
    margin: 20px auto;
    box-sizing: border-box;
    padding: 20px;
    color: #606266;
    background-color: #fff;
    .remark-title{
        line-height: 30px;
        color: #e6a23c;
    }
    .remark-text{
        line-height: 30px;
        font-size: 14px;
    }
}
</style>
