<template>
    <div class="findPwd-container">
        <div class="card-top">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="main_box">
        <el-form ref="pwdForm" :model="ruleForm" :rules="rules" label-width="100px"  size="small">
            <el-form-item label="手机号" prop="sjhm">
                <el-input v-model="ruleForm.sjhm"
                style="width:200px;" placeholder="请输入注册时的手机号"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="yzm">
                <el-input v-model="ruleForm.yzm" auto-complete="password"
                style="width:100px;" placeholder="短信验证码"></el-input>
                <el-button type="primary" style="margin-left:10px;width:90px;" :disabled="codeBtnDisabled" @click="sendCode">{{codeBtnText}}</el-button>
            </el-form-item>
            <el-form-item label="新密码" prop="password">
                <el-input type="password" auto-complete="password"
                v-model="ruleForm.password" style="width:200px;"
                placeholder="密码长度6位以上"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="confimPassword">
                <el-input type="password" auto-complete="password"
                v-model="ruleForm.confirmPassword" style="width:200px;"
                placeholder="确认密码和密码一致"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" style="width: 85%;height: 35px;font-size: 16px;
        margin: 0 0 0 20px;" @click="updatePwd('pwdForm')">确定</el-button>
            </el-form-item>
        </el-form>
    </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
export default {
data(){
    return{
        ruleForm:{
            sjhm:"",
            yzm:"",
            password:"",
            confirmPassword:""
        },
        rules:{
            sjhm:[{required:true,message:"请输入手机号",trigger:"change"}],
            yzm:[{required:true,message:"请输入验证码",trigger:"change"}],
            password:[
                {required:true,message:"请输入新密码",trigger:"change"},
                {min:6,message:"密码少于6位",trigger:"change"}
            ],
            confirmPassword:[{required:true,message:"请输入确认密码",trigger:"change"}]
        },
        codeId:"",
        codeBtnDisabled:false,
        codeBtnText:"发送验证码"
    };
},
methods:{
    // 发送验证码
    async sendCode(){
        this.codeBtnDisabled=true;
        let t=60;
        let timer=null;
        const sjhm=this.ruleForm.sjhm;
        if(sjhm===''){
            this.$message({message:"请输入手机号",type:'warning',showClose:true});
            return;
        }
        if(sjhm.length<11){
            this.$message({message:"请输入正确的手机号",type:'warning',showClose:true});
            return;
        }
        const res=await this.$http.getHttp("/msg/sendMsg?sjhm="+sjhm);
        if(res.data.code===200){
            this.codeId=res.data.obj;
            if(res.data.message){
                this.$message({message:res.data.message,type:'success',showClose:true});
            }else{
                this.$message({message:"设置成功",type:"success",showClose:true});
            }
        }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
        }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.data.code===0){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }else if(res.status===409||res.status===410||res.status===411){
            this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
        }
        timer=setInterval(() => {
            t--;
            this.codeBtnText=t+"秒";
            if(t===0){
                clearInterval(timer);
                this.codeBtnText="发送验证码";
                this.codeBtnDisabled=false;
            }
        }, 1000);
    },
    // 忘记密码
    async updatePwd(form){
        this.$refs[form].validate(async va=>{
            if(va){
            const sjhm=this.ruleForm.sjhm;
            if(sjhm===''){
                this.$message({message:"请输入手机号",type:'warning',showClose:true});
                return;
            }
            if(sjhm.length<11){
                this.$message({message:"请输入正确的手机号",type:'warning',showClose:true});
                return false;
            }
            const xmm=this.ruleForm.password;
            const xmm1=this.ruleForm.confirmPassword;
            if(xmm!==xmm1){
                this.$message({message:"新密码和确认密码不一致",type:'warning',showClose:true});
                return;
            }
            const obj={};
            obj.sjhm=sjhm;
            obj.code=this.ruleForm.yzm;
            obj.codeId=this.codeId;
            obj.newPwd=this.$md5(this.ruleForm.password);
            obj.newPwd1=this.$md5(this.ruleForm.confirmPassword);
            const res=await this.$http.postHttp("/users/updatePwd",obj);
            if(res.data.code===200){
                if(res.data.message){
                    this.$message({message:res.data.message,type:'success',showClose:true});
                }else{
                    this.$message({message:"操作成功",type:'success',showClose:true});
                }
                this.$router.push("/home");
            }else if(res.data.code===500){
            if(res.data.message){
                this.$message({message:res.data.message,type:'error'});
            }
            }else if(res.status===401){
            this.$message({message: '登录超时，请重新登录',type:"error"});
            await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
            this.$router.replace({
                path: '/home',
                query: {redirect: this.$router.currentRoute.fullPath}
            });
            }else if(res.data.code===0){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }else if(res.status===409||res.status===410||res.status===411){
                this.$message({message:"您的账号在其他地点登陆了！",showClose:true,type:'warning'});
                await this.$store.dispatch("loginOut",{sjhm:this.userInfo.sjhm});
                this.$router.replace({
                    path: '/home',
                    query: {redirect: this.$router.currentRoute.fullPath}
                });
            }
            }
        });
    }
},
computed:{
    ...mapState(['userInfo'])
}
};
</script>
<style lang="less" scoped>
.findPwd-container{
    width:900px;
    min-height:calc(100vh - 410px);
    min-height:-moz-calc(100vh - 410px);
    min-height:-webkit-calc(100vh - 410px);
    margin:30px auto;
    .card-top{
        width: 1200px;
        margin: 20px auto;
    }
.main_box{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: nowrap;
        width: 100%;
}
}
</style>
